import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const DebtsQueue = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [listLoading, setListLoading] = useState(true)
  const [listError, setListError] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    setListLoading(true)
    Client.getList(Client.ENTITY_TYPE.DEBTS_QUEUE)
      .then((list) => {
        setList(list)
        if (list && list.length) {
          console.log('useEffect: redirect', list)
          navigate(
            `/loan-view?id=${list[0].loan_id}&collection_queue_id=${
              list[0].collection_queue_id || ''
            }`,
          )
        }
      })
      .catch((err) => {
        setListError(err)
      })
      .finally(() => {
        setListLoading(false)
      })
  }, [navigate])

  const title = t('Debts queue')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  const bread = <BreadCrumb title={title} />
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              {!list || list.length === 0 ? (
                <div className="p-3">{t('Queue is empty')}</div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
