import { InputField } from './types'

export const paymentsLink = '/payments'

export const inputInitials: Record<string, InputField> = {
  loan_id: { label: 'id', initial: '', placeholder: '-' },
  direction: { label: 'direction', initial: '', placeholder: '-' },
  amount: { isAmount: true, label: 'amount', initial: '', placeholder: '-' },
  comment: { label: 'comment', initial: '', placeholder: '-' },
  is_accepted: { label: 'is_accepted', initial: '-', placeholder: '-' },
  accept_date: {
    isDateTime: true,
    label: 'accept_date',
    initial: '',
    placeholder: '-'
  },
  last_name: { label: 'last_name', initial: '', placeholder: '-' },
  first_name: { label: 'first_name', initial: '', placeholder: '-' },
  middle_name: { label: 'middle_name', initial: '', placeholder: '-' }
}
