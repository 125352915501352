import React, { useEffect, useState } from 'react'
import { Button, Label, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Client from '../../../client'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

export const BlockClient = ({ application, className }) => {
  const { t, i18n } = useTranslation()
  const [reasons, setReasons] = useState([])
  const [reason, setReason] = useState('-')
  const [comment, setComment] = useState('')
  const [blockLoading, setBlockLoading] = useState(false)
  const [blockError, setBlockError] = useState('')
  const [blockStatus, setBlockStatus] = useState('')

  const [blockedLoading, setBlockedLoading] = useState(undefined)
  const [blocked, setBlocked] = useState(undefined)
  const [blockedReason, setBlockedReason] = useState('')
  const [id, setId] = useState(undefined)

  useEffect(() => {
    window.location.pathname === '/client-view' && setId(application.id)
    window.location.pathname === '/application-view' &&
      setId(application.client_id)
    window.location.pathname === '/profile-view' && setId(application.client_id)
    window.location.pathname === '/verification-applications' &&
      setId(application.client_id)
  }, [application])

  useEffect(() => {
    console.log(id, '!!!!')
  }, [id])

  const getBlockInfo = () => {
    setBlockedLoading(true)
    Client.isBlockedClient(id)
      .then((res) => {
        console.log('Client.isBlockedClient() =>', res)
        setBlocked(res.is_blocked)
        setBlockedReason(res.reason || t('no_reason'))
      })
      .catch((err) => {})
      .finally(() => {
        setBlockedLoading(false)
      })
  }

  useEffect(() => {
    if (!application) return

    if (id) {
      getBlockInfo()
    }

    Client.blockReasons()
      .then((res) => {
        console.log('Client.blockReasons() =>', res)
        setReasons(res)
      })
      .catch((error) => {
        console.log('error getting reasons', error)
        setReasons([])
      })
  }, [id])

  const block = () => {
    const reasonToSend = reasons.find((r) => r.reason === reason)
    if (!reasonToSend) {
      setBlockError(t('Set_the_reason'))
      return
    }

    setBlockLoading(true)
    setBlockError('')

    Client.blockClient(id, reasonToSend.id, comment)
      .then((res) => {
        console.log('blockClient', res)
        setBlockStatus(t('saved'))
        getBlockInfo()
      })
      .catch((err) => setBlockError(err.toString()))
      .finally(() => setBlockLoading(false))
  }

  const unblock = () => {
    setBlockLoading(true)
    setBlockError('')
    Client.unblockClient(id, comment)
      .then((res) => {
        setBlockStatus(t('saved'))
        getBlockInfo()
      })
      .catch((err) => setBlockError(err.toString()))
      .finally(() => setBlockLoading(false))
  }

  if (!application) return null

  if (blockedLoading)
    return (
      <div className='p-3 text-center'>
        <Spinner className='p-2 text-center' size='sm'></Spinner>
      </div>
    )

  return (
    <div className={className}>
      <div>
        <div className='mb-3'>
          <small>
            {t('status')}: {t(blocked ? 'blocked' : 'not_blocked')} (
            {blockedReason})
          </small>
        </div>
      </div>
      {!blocked && (
        <div>
          <Label className='form-label'>{t('Block_reason')}</Label>
          <select
            name={`decision-status`}
            id={'decision-status'}
            className='form-select form-select-sm mb-3'
            onChange={(e) => {
              setBlockError('')
              setBlockStatus('')
              setReason(e.target.value)
            }}
            value={reason}
          >
            <option key={'-'}>{'-'}</option>
            {reasons.map((option) => {
              return (
                <option key={option.id} value={option.reason}>
                  {option.reason}
                </option>
              )
            })}
          </select>
        </div>
      )}
      <div className='mb-3'>
        <textarea
          className='form-control'
          id='textarea-comment'
          placeholder={t(
            blocked ? 'unblock_client_comment' : 'block_client_comment'
          )}
          onChange={(e) => {
            setBlockError('')
            setBlockStatus('')
            setComment(e.target.value)
          }}
          value={comment}
        />
      </div>
      <Button
        color='primary'
        size='sm'
        disabled={blockLoading || (!blocked && !reason)}
        onClick={blocked ? unblock : block}
      >
        {t(blocked ? 'unblock_client' : 'block_client')}
      </Button>
      {blockLoading && (
        <small className='text-warning mx-2'>{t('loading')}</small>
      )}
      {blockStatus && (
        <small className='text-success mx-2'>{blockStatus}</small>
      )}
      {blockError && <DangerAlert error={blockError} />}
    </div>
  )
}

export default BlockClient
