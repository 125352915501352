import { useDispatch } from 'react-redux'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import model from 'src/model'
import Client from 'src/client'

import { Link } from 'react-router-dom'
import { useGetHolidaysQuery } from 'src/services/holidays'
import { deleteEntities } from 'src/store/actions'
import { useTranslation } from 'react-i18next'

const headers = {
  id: {},
  created_at: { isDate: true },
  updated_at: { isDate: true },
  loan_id: { isShortId: false, isLink: true, linkFieldName: 'id_link' },
  holiday_type_id: {},
  begin_date: { label: 'holiday_begin_date', isDate: true },
  end_date: { label: 'holiday_end_date', isDate: true },
  is_active: {},
  user_id: {}
}

const actionsEnabled = true
const enableDelete = false

export const Holidays = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    data: list,
    isLoading: listLoading,
    error: listError
  } = useGetHolidaysQuery({ limit: 50, offset: 0 })

  const archive = (settingsId) => {
    dispatch(deleteEntities(settingsId, Client.ENTITY_TYPE.HOLIDAYS))
  }

  console.log('holidays =>', list)
  const title = t('Holidays')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  const bread = <BreadCrumb title={title} />
  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            <div className='table-responsive table-card mt-4'>
              <Table hover className='table-sm align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th />
                    {Object.keys(headers).map((header) => (
                      <th className='ps-3' key={header} scope='col'>
                        {t(headers[header].label || header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope='col'>{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/promocodes-view?id=${entity.id}`}
                              className='fw-medium'
                            >
                              <i className='ri-eye-line fs-15' />
                            </Link>
                          </td>
                          {Object.keys(headers).map((fieldName) => {
                            if (fieldName === 'id')
                              return (
                                <td className='ps-3' key={fieldName}>
                                  {entity.id}
                                </td>
                              )
                            return (
                              <td className='ps-3' key={fieldName}>
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  headers
                                )}
                              </td>
                            )
                          })}
                          {actionsEnabled && (
                            <td>
                              <div className='hstack gap-3 flex-wrap'>
                                <Link
                                  to={`/promocodes-edit?id=${entity.id}`}
                                  className='link-success fs-15'
                                >
                                  <i className='ri-edit-2-line'></i>
                                </Link>
                                {enableDelete && (
                                  <Link
                                    to='#'
                                    onClick={() => archive(entity.id)}
                                    className='link-danger fs-15'
                                  >
                                    <i className='ri-delete-bin-line'></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
