import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,

  UPDATE_DOCS,
  UPDATE_DOCS_SUCCESS,
  UPDATE_DOCS_FAIL,
} from "./actionType";


const INIT_STATE = {
  list: [],
  listLoading: false,
  listError: null,

  deleteLoading: false,
  deleteError: null,

  updateLoading: false,
  updateError: null,

  updateDocsLoading: false,
  updateDocsError: null,
};

const Applications = (state = INIT_STATE, action) => {
  switch (action.type) {
    //all
    case LIST:
      return {
        ...state,
        listLoading: true,
        listError: null,
      };

    case LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listLoading: false,
        listError: null,
      }

    case LIST_FAIL:
      return {
        ...state,
        listError: action.payload,
        listLoading: false,
      }

    //delete
    case DELETE:
      return {
        ...state,
        deleteError: null,
        deleteLoading: true,
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        deleteError: null,
        deleteLoading: false,
      }

    case DELETE_FAIL:
      return {
        ...state,
        deleteError: action.payload,
        deleteLoading: false,
      }

    //update
    case UPDATE:
      return {
        ...state,
        updateError: null,
        updateLoading: true,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        updateError: null,
        updateLoading: false,
      }

    case UPDATE_FAIL:
      return {
        ...state,
        updateError: action.payload,
        updateLoading: false,
      }

    //update docs
    case UPDATE_DOCS:
      return {
        ...state,
        updateDocsError: null,
        updateDocsLoading: true,
      };

    case UPDATE_DOCS_SUCCESS:
      return {
        ...state,
        updateDocsError: null,
        updateDocsLoading: false,
      }

    case UPDATE_DOCS_FAIL:
      return {
        ...state,
        updateDocsError: action.payload,
        updateDocsLoading: false,
      }

    default:
      return state;
  }
};

export default Applications;
