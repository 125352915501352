import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  useAddChatTemplateMutation,
  useGetChatTemplateQuery,
  useUpdateChatTemplateMutation,
} from 'src/services/chats'
import * as Yup from 'yup'

export const ChatTemplateEdit = () => {
  const { id } = useParams()

  const { t } = useTranslation()

  const {
    data: chatTemplate,
    isFetching,
    error: getError,
  } = useGetChatTemplateQuery(id!, {
    skip: id == null,
  })

  const [
    updateChatTemplate,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess },
  ] = useUpdateChatTemplateMutation()

  const [
    addChatTemplate,
    { isLoading: isCreating, error: createError, isSuccess: isCreateSuccess },
  ] = useAddChatTemplateMutation()

  const title = id ? t('Edit chat template') : t('Create chat template')
  document.title = title

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      text_template: '',
      ...chatTemplate,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Cannot be empty'),
      text_template: Yup.string().required('Cannot be empty'),
    }),
    onSubmit: (values) => {
      id ? updateChatTemplate({ ...values, id }) : addChatTemplate(values)
    },
  })

  return (
    <div className="page-content">
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar backLink="/chats/templates" />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              {Object.entries(formik.values)
                .filter(([key]) => key === 'name' || key === 'text_template')
                .map(([key, value]) => (
                  <Col md="4" key={key}>
                    <FormGroup>
                      <Label htmlFor={key}>{t(`${key}`)}</Label>
                      <Input
                        name={key}
                        type="text"
                        id={key}
                        className="form-control form-control-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={value ?? ''}
                        disabled={
                          isCreating || isUpdating || isFetching || isFetching
                        }
                        invalid={
                          // @ts-ignore
                          formik.touched[key] && !!formik.errors[key]
                        }
                      />
                      <FormFeedback type="invalid">
                        {
                          // @ts-ignore
                          t(formik.errors[key] || 'Error')
                        }
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                ))}
            </Row>

            {updateError && (
              <Alert color="warning">
                <strong> {JSON.stringify(updateError)} </strong>
              </Alert>
            )}

            {createError && (
              <Alert color="warning">
                <strong> {JSON.stringify(createError)} </strong>
              </Alert>
            )}

            {getError && (
              <Alert color="warning">
                <strong> {JSON.stringify(getError)} </strong>
              </Alert>
            )}

            {(isUpdateSuccess || isCreateSuccess) && (
              <Alert color="success">
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              className="text-nowrap"
              color="primary"
              style={{ backgroundColor: '#405189' }}
              type="submit"
              disabled={
                !formik.isValid ||
                isCreating ||
                isUpdating ||
                isFetching ||
                (!!id && !formik.dirty)
              }
            >
              {id ? t('Update') : t('Create')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
