import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Alert,
  Label,
  Input,
  FormFeedback,
  Form
} from 'reactstrap'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import {
  useCreateLoanHolidaysTypeMutation,
  useEditLoanHolidaysTypeMutation,
  useGetLoanHolidaysTypeQuery
} from 'src/services/holidays'

export const HolidayEdit: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState<string | null>(null)

  const id = searchParams.get('id')

  const [
    editLoanHolidaysType,
    { isSuccess: isSuccessEditType, isError: isErrorEditType }
  ] = useEditLoanHolidaysTypeMutation()
  const [
    createLoanHolidaysType,
    { isSuccess: isSuccessCreateType, error: isErrorCreateType }
  ] = useCreateLoanHolidaysTypeMutation()

  const { data: type } = useGetLoanHolidaysTypeQuery(id as string, {
    skip: !id
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: type?.name || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('Field is required'))
    }),
    onSubmit: (values, { resetForm }) => {
      const updated_at = new Date().toISOString().slice(0, -1)

      if (id && type) {
        editLoanHolidaysType({
          id: type.id,
          name: values.name,
          is_deleted: type.is_deleted,
          created_at: type.created_at,
          updated_at: updated_at
        })
      } else {
        createLoanHolidaysType({ name: values.name })
          .unwrap()
          .then(() => {
            resetForm()
          })
          .catch((error: any) => {
            setError(t('Error') + ' ' + (error?.data?.detail || ''))
          })
      }
    }
  })

  const title = `Тип каникул : ${id ? t('Edit') : t('Create')}`
  document.title = title

  if (error) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar backLink={'/holidays'} entity={type} />
            <Form
              className='needs-validation mt-4'
              onSubmit={validation.handleSubmit}
            >
              <Row>
                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='name'>{t('name')}</Label>
                    <Input
                      name='name'
                      type='text'
                      className='form-control form-control-sm'
                      id='name'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name}
                      invalid={
                        !!(validation.touched.name && validation.errors.name)
                      }
                    />
                    {validation.touched.name && validation.errors.name && (
                      <FormFeedback type='invalid'>
                        {validation.errors.name}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Button
                className='text-nowrap'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                type='submit'
                disabled={!validation.dirty}
              >
                {id ? t('Update') : t('Create')}
              </Button>
            </Form>

            {(isSuccessCreateType || isSuccessEditType) && (
              <Alert color='success'>
                <strong>{t('Success')}</strong>
              </Alert>
            )}
            {(isErrorCreateType || isErrorEditType) && (
              <Alert color='warning'>
                <strong>{error}</strong>
              </Alert>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  )
}
