import {
  GET_STRATEGIES,
  GET_STRATEGIES_SUCCESS,
  GET_STRATEGIES_FAIL,
  GET_STRATEGY,
  GET_STRATEGY_SUCCESS,
  GET_STRATEGY_FAIL,
  CREATE_STRATEGY,
  CREATE_STRATEGY_SUCCESS,
  CREATE_STRATEGY_FAIL,
  UPDATE_STRATEGY,
  UPDATE_STRATEGY_SUCCESS,
  UPDATE_STRATEGY_FAIL,
} from './actionType'

//get all
export const getStrategies = (filter) => ({
  type: GET_STRATEGIES,
  filter,
})

export const getStrategiesSuccess = (strategies) => ({
  type: GET_STRATEGIES_SUCCESS,
  payload: strategies,
})

export const getStrategiesFail = (error) => ({
  type: GET_STRATEGIES_FAIL,
  payload: error,
})

//get one
export const getStrategy = (id) => ({
  type: GET_STRATEGY,
  id,
})

export const getStrategySuccess = (strategy) => ({
  type: GET_STRATEGY_SUCCESS,
  payload: strategy,
})

export const getStrategyFail = (error) => ({
  type: GET_STRATEGY_FAIL,
  payload: error,
})

//create
export const createStrategy = (strategy) => ({
  type: CREATE_STRATEGY,
  payload: strategy,
})

export const createStrategySuccess = () => ({
  type: CREATE_STRATEGY_SUCCESS,
})

export const createStrategyFail = (error) => ({
  type: CREATE_STRATEGY_FAIL,
  payload: error,
})

// update
export const updateStrategy = (strategy) => ({
  type: UPDATE_STRATEGY,
  payload: strategy,
})

export const updateStrategySuccess = () => ({
  type: UPDATE_STRATEGY_SUCCESS,
})

export const updateStrategyFail = (error) => ({
  type: UPDATE_STRATEGY_FAIL,
  payload: error,
})
