import { Input, Label } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './select.scss'
import { Address } from '../../../../services/clients'
import { useGetAddressesListQuery } from '../../../../services/address'
import useDebounce from '../../../../utils/useDebounce'

type AddressOptions = {
  data: Address | undefined
  value: string | undefined
}
interface ISelectProps {
  label: string
  value: string | undefined
  onChange: (value: AddressOptions) => void
  onError: (err: boolean) => void
}

export const Select = ({ label, value, onChange, onError }: ISelectProps) => {
  const { t } = useTranslation()
  const [showOptions, setShowOptions] = useState(false)
  const [addressString, setAddressString] = useState(value)
  const { debouncedValue } = useDebounce({
    value: addressString || '',
    delay: 750
  })
  const { data: addressRes, isLoading } = useGetAddressesListQuery(
    debouncedValue.trim() || '',
    {
      skip: !debouncedValue || debouncedValue.trim().split(' ')?.length <= 2
    }
  )

  useEffect(() => {
    setAddressString(value)
  }, [value])

  useEffect(() => {
    if (value !== addressString) {
      onError(true)
    } else {
      onError(false)
    }
  }, [addressString, onError, value])

  const addressOptions = addressRes?.suggestions || []

  return (
    <>
      <Label>{label}</Label>
      <Input
        type='text'
        className='form-control form-control-sm'
        onChange={(evv) => setAddressString(evv.target.value)}
        onFocus={() => setShowOptions(true)}
        value={addressString}
        invalid={value !== addressString}
      />
      {addressOptions.length === 0 && !isLoading && (
        <span style={{ color: 'red' }}>{t('please change your request')}</span>
      )}
      {value !== addressString && addressOptions.length > 0 && (
        <span style={{ color: 'red' }}>
          {t('Select an item from the list below')}
        </span>
      )}
      {showOptions && addressOptions.length > 0 && (
        <div style={{ border: '1px solid #cacfd5', borderRadius: '0.25rem' }}>
          {addressOptions.map((item) => (
            <div
              className={'select-option'}
              key={`${label}-${item.value}`}
              onClick={() => {
                onChange(item)
                setAddressString(item.value)
                setShowOptions(false)
              }}
            >
              {item.value}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
