import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,

  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,
} from "./actionType";

//get all
export const getTransactions = (id = null, filter = '') => ({
  type: LIST,
  id,
  filter,
})

export const getTransactionsSuccess = list => ({
  type: LIST_SUCCESS,
  payload: list,
})

export const getTransactionsFail = error => ({
  type: LIST_FAIL,
  payload: error,
})

//delete
export const deleteTransaction = (id) => ({
  type: DELETE,
  payload: id,
})

export const deleteTransactionSuccess = () => ({
  type: DELETE_SUCCESS,
})

export const deleteTransactionFail = error => ({
  type: DELETE_FAIL,
  payload: error,
})

//update
export const updateTransaction = (entity) => ({
  type: UPDATE,
  payload: entity,
})

export const updateTransactionSuccess = () => ({
  type: UPDATE_SUCCESS,
})

export const updateTransactionFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})

//create
export const createTransaction = (entity) => ({
  type: CREATE,
  payload: entity,
})

export const createTransactionSuccess = () => ({
  type: CREATE_SUCCESS,
})

export const createTransactionFail = error => ({
  type: CREATE_FAIL,
  payload: error,
})
