import { Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import LoadingPage from '../LoadingPage'
import UiContent from '../../../Components/Common/UiContent'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useGetMainCompanyInfoQuery } from 'src/services/companies'

export const Homepage = () => {
  const { t } = useTranslation()
  const title = t('Homepage')
  const { data: mainCompanyInfo, isLoading: loading } =
    useGetMainCompanyInfoQuery({})

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
        {loading ? (
          <LoadingPage backLink={undefined} title={undefined} />
        ) : (
          <Card>
            <CardBody>
              <img src={mainCompanyInfo?.logo_url} alt='' />
              <br />
              <br />
              <div>
                {t('Company')}: {mainCompanyInfo?.full_name}
              </div>
              <br />
              <div>
                {t('inn')}: {mainCompanyInfo?.inn}
              </div>
              <div>
                {t('ogrn')}: {mainCompanyInfo?.ogrn}
              </div>
              <div>
                {t('kpp')}: {mainCompanyInfo?.kpp}
              </div>
              <br />
              <div>
                {t('phone')}: {mainCompanyInfo?.phone}
              </div>
              <div>
                {t('legal_address')}: {mainCompanyInfo?.legal_address}
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    </>
  )
}
