export const LIST = "GET_LOANS"
export const LIST_SUCCESS = "GET_LOANS_SUCCESS"
export const LIST_FAIL = "GET_LOANS_FAIL"

export const DELETE = "DELETE_LOAN"
export const DELETE_SUCCESS = "DELETE_LOAN_SUCCESS"
export const DELETE_FAIL = "DELETE_LOAN_FAIL"

export const UPDATE = "UPDATE_LOAN"
export const UPDATE_SUCCESS = "UPDATE_LOAN_SUCCESS"
export const UPDATE_FAIL = "UPDATE_LOAN_FAIL"
