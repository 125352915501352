import { api } from './api'

type Holiday = {
  id: number
  created_at: string
  updated_at: string
  loan_id: number
  holiday_type_id: number
  begin_date: string
  end_date: string
  is_active: boolean
  user_id: number
}

type HolidayType = {
  name: string
  id: number
  created_at: string
  updated_at: string
  loan_id: number
  holiday_type_id: number
  is_deleted: boolean
}

const URL = 'crm/v1/loans-holidays'
const URLTypes = '/types'

const holidaysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHolidays: builder.query<
      Holiday[],
      {
        limit: number
        offset: number
      }
    >({
      query: (params) => ({ url: URL, params }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Holidays', id: `${id}` }) as const),
        { type: 'Holidays' as const, id: 'LIST' }
      ]
    }),
    getLoanHolidaysTypes: builder.query<
      HolidayType[],
      (Partial<HolidayType> & { offset?: number; limit?: number }) | void
    >({
      query: () => ({
        url: `${URL}${URLTypes}`
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Holidays', id: `${id}` }) as const),
        { type: 'Holidays' as const, id: 'LIST' }
      ]
    }),
    getLoanHolidaysType: builder.query<HolidayType, number | string>({
      query: (id) => `${URL}${URLTypes}/${id}`
    }),
    createLoanHolidaysType: builder.mutation<void, Partial<HolidayType>>({
      query: (body) => ({
        url: `${URL}${URLTypes}`,
        method: 'POST',
        body: body
      })
    }),
    editLoanHolidaysType: builder.mutation<void, Partial<HolidayType>>({
      query: (body) => ({
        url: `${URL}${URLTypes}/${body.id}`,
        method: 'PUT',
        body: body
      })
    }),
    deleteLoanHolidaysType: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `${URL}${URLTypes}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, error) => (!error ? ['Holidays'] : [])
    })
  })
})

export const {
  useGetHolidaysQuery,
  useGetLoanHolidaysTypesQuery,
  useGetLoanHolidaysTypeQuery,
  useCreateLoanHolidaysTypeMutation,
  useEditLoanHolidaysTypeMutation,
  useDeleteLoanHolidaysTypeMutation
} = holidaysApi
