import { all, fork } from 'redux-saga/effects'
//layout
import LayoutSaga from './layouts/saga'

//products
import productsSaga from './products/saga'
import profilesSaga from './profiles/saga'
import dialoguesSaga from './dialogues/saga'
import clientsSaga from './clients/saga'
import applicationsSaga from './applications/saga'
import loansSaga from './loans/saga'
import productExtensionSaga from './product-extensions/saga'
import transactionsSaga from './transactions/saga'
import externalServicesSettingsSaga from './external-services-settings/saga'
import entitiesSaga from './entities/saga'
import usersSaga from './users/saga'
import rolesSaga from './roles/saga'
import strategiesSaga from './strategies/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(productsSaga),
    fork(dialoguesSaga),
    fork(profilesSaga),
    fork(usersSaga),
    fork(rolesSaga),
    fork(clientsSaga),
    fork(applicationsSaga),
    fork(loansSaga),
    fork(transactionsSaga),
    fork(externalServicesSettingsSaga),
    fork(entitiesSaga),
    fork(productExtensionSaga),
    fork(strategiesSaga),
  ])
}
