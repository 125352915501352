import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Label, FormGroup } from 'reactstrap'

export const InputFilters = ({
  description,
  validation,
  validationKey,
  className,
}) => {
  const { t } = useTranslation()
  const htmlForId = `validation-${validationKey}`

  const [listItem, setListItem] = useState('-')

  const disabled =
    description.isOptional && !validation.values[`${validationKey}-enabled`]
  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row gap-2">
        <Label htmlFor={htmlForId}>{t(description.label)}</Label>
      </div>
      <div
        className="d-flex flex-row gap-2 align-items-center"
        style={{ maxWidth: 300 }}
      >
        <Input
          id={description.label}
          className="form-select form-select-sm"
          type="select"
          onChange={(e) => setListItem(Number(e.target.value))}
          defaultValue={listItem}
          disabled={disabled}
          key={description.label}
        >
          {description?.options.map((op, index) => (
            <option key={op.id || index} value={op.id}>
              {op.name}
            </option>
          ))}
        </Input>
        <div
          role="button"
          className="text-decoration-underline"
          onClick={() => {
            if (!listItem || listItem === '-') return
            if (validation.values[validationKey].includes(listItem)) return
            const newList = validation.values[validationKey]
              ? [...validation.values[validationKey], listItem]
              : []
            validation.setFieldValue(validationKey, newList)
          }}
        >
          <small>{t('Add')}</small>
        </div>
      </div>
      <div className="mt-2 mx-2">
        {validation.values[validationKey]?.map((item) => {
          const nameItem = description.options.filter(
            (fieldName) => fieldName.id === item,
          )
          return (
            <div className="d-flex flex-row gap-2" key={item.id}>
              <div style={{ width: 'auto' }}>{nameItem[0]?.name}</div>
              <div
                role="button"
                className="text-decoration-underline"
                onClick={() => {
                  const newList = validation.values[validationKey].filter(
                    (v) => v !== item,
                  )
                  validation.setFieldValue(validationKey, newList)
                }}
              >
                <small>{t('Remove')}</small>
              </div>
            </div>
          )
        })}
      </div>
    </FormGroup>
  )
}
