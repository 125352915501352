import cx from 'classnames'
import { formatDateWithDash, utcToLocal } from 'src/utils'

export default function MessageBox(props: {
  author: string
  content: string
  time: string
  isNew?: boolean
  contentType?: 'text' | 'image'
}) {
  const { content, time, author, contentType = 'text', isNew = true } = props

  const messageClass = author === 'user' ? 'message-employee' : 'message-client'
  const getAuthor = () => {
    if (author === 'client') {
      return 'Клиент'
    }
    if (author === 'user') {
      return 'Оператор'
    }
  }

  switch (contentType) {
    case 'image':
      return (
        <div className={`message-box ${messageClass}`}>
          <p className="messege-author">{getAuthor()}</p>
          <img
            src={`data:image/jpeg;base64,${content}`}
            alt="Uploaded"
            className="mw-100"
          />
          <span
            style={{
              bottom: '5px',
              right: '10px',
              position: 'absolute',
              display: 'flex',
              gap: '2px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <i
              className={cx({
                bx: author === 'user',
                'bx-check-double': author === 'user' && !isNew,
                'bx-check': author === 'user' && isNew,
              })}
            />
            <span
              style={{
                fontSize: '8px',
                color: '#666',
              }}
            >
              {`${formatDateWithDash(utcToLocal(time))} ${utcToLocal(time)
                .toTimeString()
                .slice(0, 5)}`}
            </span>
          </span>
        </div>
      )

    case 'text':
      return (
        <div className={`message-box ${messageClass}`}>
          <p className="messege-author">{getAuthor()}</p>
          <p className="message-content">{content}</p>
          <span
            style={{
              bottom: '5px',
              right: '10px',
              position: 'absolute',
              display: 'flex',
              gap: '2px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <i
              className={cx({
                bx: author === 'user',
                'bx-check-double': author === 'user' && !isNew,
                'bx-check': author === 'user' && isNew,
              })}
            />
            <span
              style={{
                fontSize: '8px',
                color: '#666',
              }}
            >
              {`${formatDateWithDash(utcToLocal(time))} ${utcToLocal(time)
                .toTimeString()
                .slice(0, 5)}`}
            </span>
          </span>
        </div>
      )

    default:
      return (
        <div className={`message-box ${messageClass}`}>
          <p className="messege-author">{getAuthor()}</p>
          <p className="message-content">{content}</p>
          <span className="message-time">
            {`${formatDateWithDash(utcToLocal(time))} ${utcToLocal(time)
              .toTimeString()
              .slice(0, 5)}`}
          </span>
        </div>
      )
  }
}
