import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from "./actionType";

//get all
export const getProductExtensions = (id = null, filter = '') => ({
  type: LIST,
  id,
  filter,
})

export const getProductExtensionsSuccess = entities => ({
  type: LIST_SUCCESS,
  payload: entities,
})

export const getProductExtensionsFail = error => ({
  type: LIST_FAIL,
  payload: error,
})

//delete
export const deleteProductExtension = (id) => ({
  type: DELETE,
  payload: id,
})

export const deleteProductExtensionSuccess = () => ({
  type: DELETE_SUCCESS,
})

export const deleteProductExtensionFail = error => ({
  type: DELETE_FAIL,
  payload: error,
})

//update
export const updateProductExtension = (entity) => ({
  type: UPDATE,
  payload: entity,
})

export const updateProductExtensionSuccess = () => ({
  type: UPDATE_SUCCESS,
})

export const updateProductExtensionFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})
