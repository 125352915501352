import { PartialExceptTheseRequired, api } from './api'

type Communication = {
  id: number
  created_at: string
  updated_at: string
  profile_id: string
  type_id: number
  email: string
  phone: string
  service_id: number
  response_code: number
  response_data: any
  request_data: string
  status: string
  attachments: {
    id: number
    created_at: string | null
    updated_at: string | null
    email_id: number
    file_type: string
    file_name: string
    file_vault_id: string
  }[]
  subject: string
  text: string
}

type CommunicationItem = {
  id: number
  profile_id: string
  type:
    | 'sms'
    | 'email-incoming'
    | 'email-outgoing'
    | 'call-robot'
    | 'call-human'
    | 'message-chat'
    | 'message-lk'
  created_at: string
  updated_at: string
  // eslint-disable-next-line no-restricted-globals
  status:
    | 'cancel'
    | 'answered'
    | 'noanswer'
    | 'busy'
    | 'rejected'
    | 'failed'
    | 'scheduled'
    | 'invalid'
    | 'unavailable'
    | 'expired'
    | 'blacklist'
    | `${number}`
  communication_id: number
  record_id: string
}

type SMSResponse = {
  template: string
  subject: string | null
  preferable_sender_id: string | null
  is_service: string | null
  text_template: string | null
  html_template: string | null
  id: number
}

const communicationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCommunication: builder.query<
      Communication,
      {
        type: string
        id: number
      }
    >({
      query: (params) => ({
        url: 'crm/v1/communications/get-communication-by-id',
        params
      }),
      providesTags: (_result, _err, { id }) => [{ type: 'Communications', id }]
    }),
    getCommunicationRecord: builder.query<string, string>({
      query: (recordId) => ({
        url: `communications-api/v1/communications/record/${recordId}`,
        headers: {
          'content-type': 'audio/mpeg'
        },
        responseHandler: async (response) => {
          const blob = await response.blob()
          return URL.createObjectURL(blob)
        }
      }),
      providesTags: (_result, _err, id) => [
        { type: 'CommunicationRecords', id }
      ]
    }),
    getCommunications: builder.query<
      CommunicationItem[],
      PartialExceptTheseRequired<
        {
          type: string
          created_at: string
          updated_at: string
          profile_id: string
          // eslint-disable-next-line no-restricted-globals
          status: string
          url: string
          limit: number
          offset: number
        },
        'profile_id'
      >
    >({
      query: (params) => ({
        url: 'communications-api/v1/communications/',
        params
      }),
      providesTags: (result = []) => [
        ...result.map(
          ({ id }) => ({ type: 'CommunicationItems', id }) as const
        ),
        { type: 'CommunicationItems' as const, id: 'LIST' }
      ]
    }),
    getAllSMSTemplates: builder.query<SMSResponse[], void>({
      query: () => ({
        url: `crm/v1/communications/templates/sms`
      })
    })
  })
})

export const {
  useGetCommunicationsQuery,
  useGetCommunicationQuery,
  useGetCommunicationRecordQuery,
  useGetAllSMSTemplatesQuery
} = communicationsApi
