import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Client from 'src/client'
import { Card, CardBody, Button, Input, Alert } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'

export const RecPayments = () => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const title = t('recurring_payments')
  document.title = title
  const metaBar = <MetaBar />

  const sendDpd = () => {
    const reqBody = {
      dpd: value,
    }
    Client.recPaymentDpD(reqBody)
      .then((res) => res === 'ok' && setSuccess(true))
      .catch((err) => setError(err))
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <div className="mt-4">
              {t('enter_dpd')}
              <Input
                style={{ width: '200px', margin: '0 0 15px' }}
                className="form-control form-control-sm"
                onChange={(e) => setValue(e.target.value)}
              />
              <Button type="button" onClick={sendDpd} disabled={value < 1}>
                {t('WriteOff')}
              </Button>

              {success && <Alert>{t('Done')}</Alert>}
              {error && <Alert color="danger">{error}</Alert>}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
