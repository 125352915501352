import { useCallback, useEffect, useState } from 'react'
import Client from 'src/client'
import { Card, CardBody, Input, Button, CardFooter, Alert } from 'reactstrap'
import MessageList from './MessageList'
import { useTranslation } from 'react-i18next'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const ERROR_DEBOUNCE = 3000

export default function Chat(props: { profile_id: string }) {
  const { profile_id } = props
  const { t } = useTranslation()
  const [message, setMessage] = useState<string>('')
  const [messageList, setMessageList] = useState([])
  const [errorSendMessage, setErrorSendMessage] = useState<boolean>(false)
  const [errorGetChatHistory, setErrorGetChatHistory] = useState<boolean>(false)

  const getChatHistory = useCallback(() => {
    Client.getChatHistory(profile_id)
      .then((res) => setMessageList(res))
      .catch((err) => {
        if (err) {
          setErrorGetChatHistory(err)
        }
      })
  }, [profile_id])

  useEffect(() => {
    getChatHistory()
  }, [getChatHistory])

  const sendMessage = () => {
    Client.answerToChat(profile_id, message)
      .then((res) => {
        if (res.successful) {
          setMessage('')
          getChatHistory()
        }
      })
      .catch((err) => {
        if (err) {
          setErrorSendMessage(true)
        }
      })
  }

  useEffect(() => {
    if (errorSendMessage) {
      setTimeout(() => {
        setErrorSendMessage(false)
      }, ERROR_DEBOUNCE)
    }
  }, [errorSendMessage])

  return (
    <Card>
      <CardBody>
        {messageList.length > 0 ? (
          <MessageList messages={messageList} />
        ) : errorGetChatHistory ? (
          <DangerAlert error={errorGetChatHistory} />
        ) : (
          <div>{t('ChatHistoryEmpty')}</div>
        )}
      </CardBody>
      <CardFooter className="d-flex gap-3">
        <Input
          type="text"
          placeholder={t('EnterChatMessage')}
          value={message}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && message.length > 0) {
              sendMessage()
            }
          }}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          type="button"
          onClick={sendMessage}
          disabled={message.length <= 0}
        >
          {'Ответить'}
        </Button>
      </CardFooter>
      {errorSendMessage && (
        <Alert color="danger">{t('ErrorSendMessage')}</Alert>
      )}
    </Card>
  )
}
