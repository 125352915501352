import { FC, useEffect } from 'react'
import { Input } from 'reactstrap'
import type { TemplatesClaim } from 'src/services/templatesClaim'
import { useState } from 'react'

type props = {
  setTemplateTopic: (el: string) => void
  templatesClaim: TemplatesClaim[] | undefined
}

export const SelectSearch: FC<props> = ({
  setTemplateTopic,
  templatesClaim,
}) => {
  const [list, setList] = useState<TemplatesClaim[]>([])
  const [valueInput, setValueInput] = useState('')

  useEffect(() => {
    templatesClaim &&
      setList(
        templatesClaim?.filter((el) =>
          el.name.toLowerCase().includes(valueInput),
        ),
      )
  }, [valueInput, templatesClaim])

  return (
    <div style={{ width: '100%' }}>
      <Input
        name="templates_name_list"
        type="text"
        id="templates_name_list"
        className="form-control form-control-sm"
        onChange={(e) => setValueInput(e.target.value)}
        value={valueInput}
      />
      {list.length > 0 && (
        <select
          className="form-select form-select-sm"
          size={valueInput === '' ? 10 : list.length + 1}
        >
          {list.map((template) => (
            <option
              key={template.id}
              onClick={() => {
                setTemplateTopic(template.text_template)
                setValueInput(template.name)
              }}
            >
              {template.name}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}
