import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import EntityInfo from 'src/Components/Common/EntityInfo'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'

import { Card, CardBody } from 'reactstrap'
import { useSearchParams } from 'react-router-dom'
import { useGetProductQuery } from 'src/services/products'

export const ProductView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const {
    data: product,
    isLoading: productLoading,
    error: productError,
  } = useGetProductQuery(searchParams.get('id'), {
    skip: searchParams.get('id') == null,
  })

  useEffect(() => {
    if (!searchParams.get('id')) setParamError('No product id found')
  }, [dispatch, searchParams])

  useEffect(() => {
    if (!product) return
    setPageReady(true)
  }, [product])

  const title = t('View product')
  document.title = title
  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/products'}
      editLink={product ? `/product-edit?id=${product.id}` : null}
      entity={product}
    />
  )

  if (paramError || productError) {
    return (
      <ErrorPage
        backLink={'/products'}
        title={title}
        error={[paramError, productError]}
      />
    )
  }

  if (!pageReady || productLoading) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  console.log('product =>', product)
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <EntityInfo
              entity={{ product_original: product }}
              entityId={searchParams.get('id')}
              entityType={Client.ENTITY_TYPE.PRODUCT}
              visible
            />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
