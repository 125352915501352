import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import * as Yup from 'yup'
import moment from 'moment'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { useAddBankruptMutation } from 'src/services/claims'

const descriptionType = [
  {
    label: 'extrajudicial',
    value: 'ExtraJudicial'
  },
  {
    label: 'judicial',
    value: 'Judicial'
  }
]

const descriptionStage = [
  {
    label:
      'the bankruptcy petition has been accepted by the court for proceedings',
    value: 'Statement'
  },
  {
    label: 'debt restructuring',
    value: 'Restructuring'
  },
  {
    label: 'sale of property',
    value: 'Realization'
  },
  {
    label: 'settlement agreement',
    value: 'Agreement'
  },
  {
    label: 'the case has been dismissed',
    value: 'ProductionStopped'
  },
  {
    label: 'extrajudicial bankruptcy proceedings have been initiated',
    value: 'ProceedingsInitiated'
  },
  {
    label: 'the extrajudicial bankruptcy procedure has been completed',
    value: 'ProcedureFinalized'
  },
  {
    label: 'the extrajudicial bankruptcy procedure has been terminated',
    value: 'ProcedureDiscontinued'
  }
]

export const Insolvent = () => {
  const { t } = useTranslation()

  const [addBankrupt, { isLoading, isSuccess, error }] =
    useAddBankruptMutation()

  const title = t('Insolvent')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientId: '',
      typeOfBankruptcy: '',
      considerationStage: '',
      decisionDate: ''
    },
    validationSchema: Yup.object().shape({
      clientId: Yup.string()
        .min(36, `${t('client_id')} ${t('is entered incorrectly')}`)
        .max(36, `${t('client_id')} ${t('is entered incorrectly')}`)
        .required(t('Fill in the field')),
      typeOfBankruptcy: Yup.string().required(t('Fill in the field')),
      considerationStage: Yup.string().required(t('Fill in the field')),
      decisionDate: Yup.string().required(t('Fill in the field'))
    }),
    onSubmit: (values) => {
      console.log('values', values)
      addBankrupt(values)
    }
  })

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor='clientId'>{t('client_id')}</Label>
                  <Input
                    id='clientId'
                    name='clientId'
                    type='text'
                    className='form-control form-control-sm'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.clientId ?? ''}
                  />
                </FormGroup>
                {formik.touched.clientId && formik.errors.clientId ? (
                  <FormFeedback
                    style={{ display: 'block', marginBottom: '10px' }}
                    type='invalid'
                  >
                    <Alert color='warning'>
                      <strong> {formik.errors.clientId} </strong>
                    </Alert>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col md='2'>
                <FormGroup>
                  <Label htmlFor='typeOfBankruptcy'>
                    {t('type of bankruptcy procedure')}
                  </Label>
                  <select
                    id='typeOfBankruptcy'
                    name='typeOfBankruptcy'
                    className='form-control form-control-sm'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.typeOfBankruptcy ?? ''}
                  >
                    <option hidden value='' />
                    {descriptionType.map((el) => (
                      <option key={el.value} value={el.value}>
                        {t(el.label)}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                {formik.touched.typeOfBankruptcy &&
                formik.errors.typeOfBankruptcy ? (
                  <FormFeedback
                    style={{ display: 'block', marginBottom: '10px' }}
                    type='invalid'
                  >
                    <Alert color='warning'>
                      <strong> {formik.errors.typeOfBankruptcy} </strong>
                    </Alert>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor='considerationStage'>
                    {t('stage of consideration')}
                  </Label>
                  <select
                    id='considerationStage'
                    name='considerationStage'
                    className='form-control form-control-sm'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.considerationStage ?? ''}
                  >
                    <option hidden value='' />
                    {descriptionStage.map((el) => (
                      <option key={el.value} value={el.value}>
                        {t(el.label)}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                {formik.touched.considerationStage &&
                formik.errors.considerationStage ? (
                  <FormFeedback
                    style={{ display: 'block', marginBottom: '10px' }}
                    type='invalid'
                  >
                    <Alert color='warning'>
                      <strong> {formik.errors.considerationStage} </strong>
                    </Alert>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor='decisionDate'>
                    {t('date of decision on completion/termination')}
                  </Label>
                  <Flatpickr
                    name='decisionDate'
                    id='decisionDate'
                    type='date'
                    className='form-control form-control-sm'
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                      onChange: (selectedDates: any) => {
                        formik.setFieldValue('decisionDate', selectedDates[0])
                      }
                    }}
                  />
                </FormGroup>
                {formik.touched.decisionDate && formik.errors.decisionDate ? (
                  <FormFeedback
                    style={{ display: 'block', marginBottom: '10px' }}
                    type='invalid'
                  >
                    <Alert color='warning'>
                      <strong> {formik.errors.decisionDate} </strong>
                    </Alert>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            {error && (
              <Alert color='warning'>
                <strong> {JSON.stringify(error)} </strong>
              </Alert>
            )}

            {isSuccess && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              type='submit'
              className='text-nowrap'
              color='primary'
              disabled={isLoading}
            >
              {t('Send')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
