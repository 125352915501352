import { ErrorResponse } from '../types/agencies.types'

export const handleErrors = (error: unknown) => {
  const { data } = error as ErrorResponse
  if (Array.isArray(data.detail))
    return data?.detail
      .map((obj) => {
        const nameError = obj.loc[1]
        const msgError = obj.msg

        return `${nameError}: ${msgError}`
      })
      .join('; ')
  return data?.detail
}

export const handleMessageError = (error: unknown, innMessage: string) => {
  const { data, status } = error as ErrorResponse

  if (status === 409) {
    return innMessage
  }
  if (Array.isArray(data?.detail)) return data.detail[0]?.msg
}
