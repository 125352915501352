import React from 'react'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import model from 'src/model'
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import { useGetPromocodeQuery } from 'src/services/promocodes'
import { useSearchParams } from 'react-router-dom'
import { useGetPromocodesGroupsQuery } from 'src/services/promocodesGroups'
import { PromocodeViewField } from './types/promocodes.types'

export const PromocodeView = () => {
  const { t } = useTranslation()

  const { data: groups } = useGetPromocodesGroupsQuery()

  const [searchParams] = useSearchParams()
  const promocodeId = searchParams.get('id')

  const fieldsDescription: Record<string, PromocodeViewField> = {
    id: {},
    sale: { fullWidth: true, isAmount: true },
    promocode: { fullWidth: true },
    begin_date: { label: 'promocode_start_date', isDate: true },
    end_date: { label: 'promocode_end_date', isDate: true },
    limitless: {
      label: 'type',
      mappingValue: (value) => (value ? 'Многоразовый' : 'Ограниченный')
    },
    available_usages: { label: 'Available usages' },
    type: {
      label: 'Group',
      mappingValue: (value) => {
        const group = groups?.find((group) => group.id === value)
        return group ? group?.group_name : ''
      }
    },
    total_usage_limit: { label: 'Total usage limit' }
  }

  const {
    data: entity,
    isLoading: entityLoading,
    error: entityError
  } = useGetPromocodeQuery(promocodeId || '', {
    skip: !promocodeId
  })

  const title = `${t('Promocodes')}: ${t('View')}`
  document.title = title

  const paramError = !promocodeId ? 'No entity id found' : null

  if (paramError || entityError) {
    return (
      <ErrorPage
        backLink={'/promocodes'}
        title={title}
        error={[paramError, entityError]}
      />
    )
  }

  if (entityLoading) {
    return <LoadingPage backLink={'/promocodes'} title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar
              backLink={'/promocodes'}
              editLink={entity && `/promocodes-edit?id=${entity.id}`}
              entity={entity}
            />
            <Row className='mt-3'>
              {Object.keys(fieldsDescription).map((fieldName) => (
                <Col
                  className={
                    fieldsDescription[fieldName].fullWidth
                      ? 'col-12 mt-3'
                      : 'mt-3 col-auto'
                  }
                  key={`${fieldName}`}
                >
                  <Label htmlFor={fieldName}>
                    {t(fieldsDescription[fieldName].label || fieldName)}
                  </Label>
                  <Input
                    type='text'
                    className='form-control form-control-sm'
                    value={model.fieldValueToText(
                      fieldName,
                      entity,
                      fieldsDescription
                    )}
                    disabled
                  />
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
