import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import model from 'src/model'
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import { useSearchParams } from 'react-router-dom'
import { useGetPromocodesGroupsQuery } from 'src/services/promocodesGroups'
import { PromocodesGroupsField } from './types/promocodes-groups.types'

const fieldsDescription: Record<string, PromocodesGroupsField> = {
  id: {},
  group_name: { label: 'name' }
}

export const PromocodesGroupsView = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')

  const { data: promocodesGroups } = useGetPromocodesGroupsQuery()

  const group = promocodesGroups?.find((item) => item.id === Number(id))

  const title = `${t('promo_code_groups')}: ${t('View')}`
  document.title = title

  const paramError = !id ? 'No entity id found' : null

  if (paramError || !group) {
    return (
      <ErrorPage
        backLink={'/promocodes-groups'}
        title={title}
        error={paramError}
      />
    )
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar
              backLink={'/promocodes-groups'}
              editLink={group ? `/promocodes-groups-edit?id=${group.id}` : null}
              entity={group}
            />
            <Row className='mt-3'>
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col
                    className={
                      fieldsDescription[fieldName].fullWidth
                        ? 'col-12 mt-3'
                        : 'mt-3 col-auto'
                    }
                    key={`${fieldName}`}
                  >
                    <Label htmlFor={fieldName}>
                      {t(fieldsDescription[fieldName].label || fieldName)}
                    </Label>
                    <Input
                      type='text'
                      className='form-control form-control-sm'
                      value={model.fieldValueToText(
                        fieldName,
                        group,
                        fieldsDescription
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
