import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Table } from 'reactstrap'
import { AcceptModal } from './Components/deleteModal'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'
import Client from 'src/client'

import { getEntities, deleteEntities } from 'src/store/actions'
import { useGetPromotionsQuery } from 'src/services/promotions'

const actionsEnabled = true
const enableDelete = false

export const Segments = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const { list, listLoading, listError } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.SEGMENTS].list,
      listLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].listLoading,
      listError: state.entities[Client.ENTITY_TYPE.SEGMENTS].listError,
      deleteLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].deleteLoading,
    }
  })

  const { data: promotions } = useGetPromotionsQuery()

  const fieldsDescription = useMemo(
    () => ({
      id: {},
      name: {},
      min_dpd: {},
      // created_at: {isDateTime: true},
      min_score: {},
      // updated_at: {isDateTime: true},
      max_dpd: {},
      max_score: {},
      promotion_id: {},
      request_type: {
        label: t('Loan_type'),
        mappingValue: (request_type) => {
          switch (request_type) {
            case 0:
              return t('All')
            case 1:
              return t('Primary')
            case 2:
              return t('Secondaries')
            default:
              return ''
          }
        },
      },
      is_auto: {
        label: 'Promotion activation',
        mappingValue: (is_auto) => {
          switch (is_auto) {
            case true:
              return t('Auto')
            case false:
              return t('Manual')
            default:
              return '-'
          }
        },
      },
    }),
    [t],
  )

  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.SEGMENTS))
  }, [dispatch])

  const archive = (settingsId) => {
    dispatch(deleteEntities(settingsId, Client.ENTITY_TYPE.SEGMENTS))
  }

  console.log('list =>', list)
  const title = t('Segments')
  document.title = title
  const metaBar = <MetaBar createLink={'/segments-create'} />

  const toggle = () => {
    setShow(!show)
  }

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <div className="d-flex gap-2">
              {metaBar}
              <Button
                className="btn-icon"
                color="primary"
                style={{ backgroundColor: '#405189', width: '100px' }}
                onClick={() => setShow(true)}
              >
                <i class="ri-delete-bin-line label-icon align-middle fs-16 me-2"></i>
                {t('Delete')}
              </Button>
            </div>
            <AcceptModal show={show} toggle={toggle} />
            <div className="table-responsive table-card mt-4">
              <Table hover className="table-sm align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th />
                    {Object.keys(fieldsDescription).map((header) => (
                      <th className="ps-3" key={header} scope="col">
                        {t(fieldsDescription[header].label || header)}
                      </th>
                    ))}
                    <th scope="col">{t('Promotion')}</th>
                    {actionsEnabled && <th scope="col">{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/segments-view?id=${entity.id}`}
                              className="fw-medium"
                            >
                              <i className="ri-eye-line fs-15" />
                            </Link>
                          </td>
                          {Object.keys(fieldsDescription).map((fieldName) => {
                            if (fieldName === 'id')
                              return (
                                <td className="ps-3" key="id">
                                  {entity.id}
                                </td>
                              )
                            return (
                              <td className="ps-3" key={fieldName}>
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  fieldsDescription,
                                )}
                              </td>
                            )
                          })}

                          <td className="ps-3">
                            {promotions?.find(
                              (promotion) =>
                                promotion.id === entity.promotion_id,
                            )?.promo_name || '-'}
                          </td>
                          {actionsEnabled && (
                            <td>
                              <div className="hstack gap-3 flex-wrap">
                                <Link
                                  to={`/segments-edit?id=${entity.id}`}
                                  className="link-success fs-15"
                                >
                                  <i className="ri-edit-2-line"></i>
                                </Link>
                                {enableDelete && (
                                  <Link
                                    to="#"
                                    onClick={() => archive(entity.id)}
                                    className="link-danger fs-15"
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
