import Table4Columns from './Table4Columns'
import { useGetProfileQuery } from 'src/services/profiles'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const columnsDescriptionDefault = [
  ['profile_id'],
  ['created_at'],
  ['updated_at'],
  ['profile_status'],
  ['phone'],
  ['phone_status'],
  ['email'],
  ['email_status'],
  ['lead_id'],
  ['track_id'],
  ['last_login'],
]

const fieldsDescription = {
  profile_id: { fieldName: 'id', isLink: true, linkFieldName: 'profile_link' },
  new_line: { isEmpty: true, newLine: true },
  created_at: { isDateTime: true },
  updated_at: { isDateTime: true },
  last_login: { isDateTime: true },
}

const EntityContent = ({ entityId, columnsDescription, visible }) => {
  const { data: profile, error } = useGetProfileQuery(entityId, {
    skip: !visible,
  })

  return (
    <>
      {profile && (
        <Table4Columns
          columnsDescription={columnsDescription || columnsDescriptionDefault}
          fieldsDescription={fieldsDescription}
          className="table-sm align-middle mb-0"
          style={{ width: 'max-content' }}
          entity={{
            ...profile,
            profile_link: `/profile-view?id=${profile.id}`,
          }}
        />
      )}
      {error && <DangerAlert error={error} />}
    </>
  )
}

export default EntityContent
