import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from "./actionType";

//get all
export const getLoans = (id = null, filter = '') => ({
  type: LIST,
  id,
  filter,
})

export const getLoansSuccess = list => ({
  type: LIST_SUCCESS,
  payload: list,
})

export const getLoansFail = error => ({
  type: LIST_FAIL,
  payload: error,
})

//delete
export const deleteLoan = (id) => ({
  type: DELETE,
  payload: id,
})

export const deleteLoanSuccess = () => ({
  type: DELETE_SUCCESS,
})

export const deleteLoanFail = error => ({
  type: DELETE_FAIL,
  payload: error,
})

//update
export const updateLoan = (entity) => ({
  type: UPDATE,
  payload: entity,
})

export const updateLoanSuccess = () => ({
  type: UPDATE_SUCCESS,
})

export const updateLoanFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})
