import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner
} from 'reactstrap'
import { useFormik } from 'formik'
import { useCreateUserMutation, useGetUserQuery } from 'src/services/keycloak'
import { useSearchParams } from 'react-router-dom'

const inputInitials = {
  username: { label: 'Username', initial: '', placeholder: '' },
  password: { label: 'password', initial: '', placeholder: '' },
  surname: { label: 'last_name', initial: '', placeholder: '' },
  name: { label: 'first_name', initial: '', placeholder: '' },
  midname: { label: 'middle_name', initial: '', placeholder: '' },
  phone: { label: 'phone', initial: '', placeholder: '' },
  email: { label: 'email', initial: '', placeholder: '' },
  company: { label: 'company', initial: '', placeholder: '' },
  position: { label: 'position', initial: '', placeholder: '' }
}

export const UserEdit = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const [wrongPassword, setWrongPassword] = useState<boolean>(false)

  const { data: user } = useGetUserQuery(searchParams.get('id')!, {
    skip: searchParams.get('id') == null
  })

  const [
    createUser,
    {
      error: createUserError,
      isLoading: isCreatingUser,
      isSuccess: isCreateUserSuccess
    }
  ] = useCreateUserMutation()

  const validatePassword = (password: string): string => {
    const minLength = 8
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)
    if (password.length < minLength) {
      return t('Password must be at least 8 characters long.')
    }
    if (!hasUpperCase) {
      return t('Password must contain at least one uppercase letter.')
    }
    if (!hasLowerCase) {
      return t('Password must contain at least one lowercase letter.')
    }
    if (!hasNumber) {
      return t('Password must contain at least one number.')
    }
    return ''
  }

  const createNewUser = async () => {
    setWrongPassword(false)
    //@ts-ignore WTF WITH TYPES??
    const validationError = validatePassword(validation?.values?.password)
    if (validationError) {
      setWrongPassword(true)
      return
    }
    try {
      await createUser(validation.values)
    } catch (error) {
      //
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...user },
    onSubmit: createNewUser
  })

  const title = `${t('UserSettings')}: ${
    searchParams.get('id') ? t('Edit') : t('Create')
  }`
  document.title = title

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar backLink='/users' />
            <Form
              className='needs-validation mt-4'
              onSubmit={validation.handleSubmit}
            >
              <Row>
                {Object.keys(inputInitials).map((stringKey) => {
                  const fieldKey = stringKey as keyof typeof validation.values &
                    keyof typeof inputInitials
                  const htmlForId = `validation-${fieldKey}`
                  return (
                    <Col
                      className={
                        // @ts-ignore
                        inputInitials[fieldKey].fullWidth
                          ? 'col-12'
                          : 'col-auto'
                      }
                      key={`${fieldKey}`}
                    >
                      <FormGroup className='mb-3'>
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        {
                          // @ts-ignore
                          inputInitials[fieldKey].options &&
                          // @ts-ignore
                          inputInitials[fieldKey].options.length ? (
                            <select
                              name={`${fieldKey}`}
                              id={htmlForId}
                              className='form-select form-select-sm'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              // @ts-ignore
                              value={validation.values[fieldKey]}
                            >
                              {
                                // @ts-ignore
                                inputInitials[fieldKey].options.map(
                                  // @ts-ignore
                                  (option) => (
                                    <option
                                      key={option.name}
                                      defaultValue={option.defaultValue}
                                    >
                                      {option.name}
                                    </option>
                                  )
                                )
                              }
                            </select>
                          ) : (
                            <Input
                              name={`${fieldKey}`}
                              placeholder={inputInitials[fieldKey].placeholder}
                              type='text'
                              className='form-control form-control-sm'
                              id={htmlForId}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values[fieldKey] || ''}
                              invalid={
                                !!(
                                  validation.touched[fieldKey] &&
                                  validation.errors[fieldKey]
                                )
                              }
                            />
                          )
                        }
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type='invalid'>
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {!!createUserError && (
                <Alert color='warning'>
                  <strong> {createUserError.toString()} </strong>
                </Alert>
              )}

              {wrongPassword && (
                <Alert color='warning'>
                  {
                    'Минимум 1 цифра, 1 буква в нижнем и верхем регистре, минимум 8 символов'
                  }
                </Alert>
              )}

              {isCreateUserSuccess && (
                <Alert color='success'>
                  <strong> {t('Operation success')} </strong>
                </Alert>
              )}

              {isCreatingUser ? (
                <Spinner color='primary'>{t('Loading...')}</Spinner>
              ) : (
                <Button
                  className='text-nowrap'
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  type='submit'
                >
                  {searchParams.get('id') ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
