import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,

  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,

  SET_FILTER,
} from "./actionType";

//get all
export const setEntitiesFilter = (entityType, strFilter = '', fieldsFilter = {}) => ({
  type: SET_FILTER,
  strFilter,
  fieldsFilter,
  entityType,
})

//get all
export const getEntities = (entityType, id = null, filter = '', offset = 0, limit = 0) => ({
  type: LIST,
  id,
  filter,
  offset,
  limit,
  entityType,
})

export const getEntitiesSuccess = (list, entityType, id) => ({
  type: LIST_SUCCESS,
  payload: list,
  entityType,
  id,
})

export const getEntitiesFail = (error, entityType, id) => ({
  type: LIST_FAIL,
  payload: error,
  entityType,
  id,
})

//delete
export const deleteEntities = (id, entityType) => ({
  type: DELETE,
  payload: id,
  entityType,
})

export const deleteEntitiesSuccess = (entityType) => ({
  type: DELETE_SUCCESS,
  entityType,
})

export const deleteEntitiesFail = (error, entityType) => ({
  type: DELETE_FAIL,
  payload: error,
  entityType,
})

//update
export const updateEntities = (entity, entityType) => ({
  type: UPDATE,
  payload: entity,
  entityType,
})

export const updateEntitiesSuccess = (entityType) => ({
  type: UPDATE_SUCCESS,
  entityType,
})

export const updateEntitiesFail = (error, entityType) => ({
  type: UPDATE_FAIL,
  payload: error,
  entityType,
})

//create
export const createEntities = (entity, entityType) => ({
  type: CREATE,
  payload: entity,
  entityType,
})

export const createEntitiesSuccess = (entityType) => ({
  type: CREATE_SUCCESS,
  entityType,
})

export const createEntitiesFail = (error, entityType) => ({
  type: CREATE_FAIL,
  payload: error,
  entityType,
})
