import React from "react";
import Table4Columns from "./Table4Columns";

const columnsDescriptionDefault = [
  ['product_id'],
  ['extension_enabled'],
  ['ext_with_penalty'],
  ['max_extensions_number'],
  ['ext_overdue_allowed_days'],
  ['ext_from_sign_day'],
  ['min_ext_days'],
  ['max_ext_days'],
  ['max_loan_days'],
  ['ext_fee_percent'],
  ['ext_fee_amount'],
  ['ext_all_interest_amount'],
];

const fieldsDescription = {
  product_id: {isLink: true, linkFieldName: 'product_link'},
  new_line: {isEmpty: true, newLine: true},
  ext_fee_amount: {isAmount: true},
  ext_fee_percent: {isAmount: true},
};

const EntityContent = ({entity, style, columnsDescription}) => {
  return (
    <Table4Columns
      columnsDescription={columnsDescription || columnsDescriptionDefault}
      fieldsDescription={fieldsDescription}
      className="table-sm align-middle mb-0"
      style={{width: 'fit-content'}}
      entity={{...entity, product_link: `/product-view?id=${entity.product_id}`}}
    />
  );
}

export default EntityContent;
