import { useEffect, useState } from 'react'

interface IUseDebounceProps {
  delay?: number
  value: string
}

const useDebounce = (props: IUseDebounceProps) => {
  const { value, delay = 1000 } = props
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const debounceHandler = setTimeout(() => setDebouncedValue(value), delay)

    return () => clearTimeout(debounceHandler)
  }, [value, delay])

  return { debouncedValue }
}

export default useDebounce
