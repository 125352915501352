import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import { useGetPromotionQuery } from 'src/services/promotions'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

export const PromotionView: FC = () => {
  const [searchParams] = useSearchParams()

  const { t } = useTranslation()

  const {
    data: promotion,
    isError,
    error,
  } = useGetPromotionQuery(searchParams.get('id')!, {
    skip: searchParams.get('id') == null,
  })

  const title = t('View promotion')
  document.title = title

  if (isError) {
    return <ErrorPage backLink={'/products'} title={title} error={error} />
  }

  if (!promotion) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={'/promotions'}
            editLink={promotion ? `/promotion-edit?id=${promotion.id}` : null}
            entity={promotion}
            createLink={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          {promotion && (
            <ListGroup>
              <ListGroupItem>
                <Row>
                  <Col>{t('ID')}</Col>
                  <Col>{promotion.id}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('Name')}</Col>
                  <Col>{promotion.promo_name}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('main_body_writeoff')} %</Col>
                  <Col>
                    {promotion.main_body_writeoff
                      ? promotion.main_body_writeoff * 100
                      : 0}
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('fines_writeoff')} %</Col>
                  <Col>
                    {promotion.fines_writeoff
                      ? promotion.fines_writeoff * 100
                      : 0}
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('interest_writeoff')} %</Col>
                  <Col>
                    {promotion.interest_writeoff
                      ? promotion.interest_writeoff * 100
                      : 0}
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('fines_freezing')}</Col>
                  <Col>{t(`${promotion.fines_freezing}`)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('interest_freezing')}</Col>
                  <Col>{t(`${promotion.interest_freezing}`)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('offer_duration')}</Col>
                  <Col>{promotion.offer_duration}</Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
