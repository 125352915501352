import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Entity {
  comment: string
  user_id: string
  decision: boolean
  created_at: string
  decision_status: string
}
interface VerificationHistoryKPKProps {
  entity: Entity[]
}

export const VerificationHistoryKPK: FC<VerificationHistoryKPKProps> = ({
  entity
}) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  return (
    <div>
      <div
        className='fs-7 text-info text-decoration-underline'
        role='button'
        onClick={() => setShow((prev) => !prev)}
      >
        {t('verification_history')}
      </div>

      <div className=''>
        {show && !entity && <small>{t('No_decisions')}</small>}

        {show &&
          entity &&
          entity?.map((item: Entity, index: number) => (
            <div className='shadow rounded border p-2 fs-7 mt-3' key={index}>
              <label>
                {t('verifier')}: {item?.user_id}
              </label>
              <br />
              <label>
                {t('status')}: {item?.decision_status}
              </label>
              <br />
              <label>
                {t('created_at')}: {item?.created_at}
              </label>
              <br />
              <label>
                {t('comment')}: {item?.comment}
              </label>
            </div>
          ))}
      </div>
    </div>
  )
}
