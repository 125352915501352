import { FC } from 'react'

interface IdentificationHistoryProps {
  application: any
}

export const IdentificationHistory: FC<IdentificationHistoryProps> = ({
  application
}) => {
  return (
    <div>
      {application?.approved_amount
        ? 'Сведения для полной идентификации получены'
        : 'Данные не получены'}
    </div>
  )
}
