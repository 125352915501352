import { api, omitBlankEntries } from './api'

const pathName = 'crm/v1/credit-Bureau'

interface getZIP {
  company_id: number
  sell_date: string | Date
}

type UploadCreditBureau = {
  company_id: string
  cession_number: string
  cession_date: string
  transfer_date: string
  cession_price: number
}

export const debtsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    //get's
    getCreditBureauCompanies: builder.query<any, any>({
      query: () => `${pathName}/get-companies`,
    }),
    getCreditBureauStatus: builder.query<
      any,
      { offset: number; limit: number }
    >({
      query: ({ offset, limit }) =>
        `${pathName}/get-loans-saletag?offset=${offset}&limit=${limit}`,
    }),
    getZipCreditBureau: builder.query<any, getZIP>({
      query: ({ company_id, sell_date }) =>
        `${pathName}/get-docs?company_id=${company_id}&sell_date=${sell_date}`,
    }),

    //post's
    uploadCreditBureauToSell: builder.mutation<
      void,
      { file: FormData; params: UploadCreditBureau }
    >({
      query: ({ file, params }) => ({
        url: `${pathName}/set-file-saletag`,
        method: 'POST',
        params: omitBlankEntries(params),
        body: file,
      }),
    }),

    deleteCreditBureauToSell: builder.mutation<
      any,
      { file: FormData; company_id: number }
    >({
      query: ({ file, company_id }) => ({
        url: `${pathName}/delete-file-saletag?company_id=${company_id}`,
        method: 'POST',
        body: file,
      }),
    }),
    autoSalePortfolio: builder.mutation<Blob, void>({
      query: () => ({
        url: 'crm/v1/auto-sales/autosale-portfolio',
        method: 'GET',
        headers: {
          'Content-Type': 'text/csv; charset=UTF-8',
        },
        responseHandler: (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
})

export const {
  useGetCreditBureauCompaniesQuery,
  useGetCreditBureauStatusQuery,
  useLazyGetZipCreditBureauQuery,
  useUploadCreditBureauToSellMutation,
  useDeleteCreditBureauToSellMutation,
  useAutoSalePortfolioMutation,
} = debtsApi
