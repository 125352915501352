import { api } from './api'

export type AgencyBody = {
  communication_email: string
  communication_phone: string
  customer_phone: string
  customer_support_email: string
  full_name: string
  inn: string
  is_external: null | boolean
  kpp: string
  legal_address: string
  ogrn: string
  postal_address: string
  registry_email: string
  short_name: string
}

export type AgencyResponse = AgencyBody & { id: string }

type GetAgenciesParams = {
  keyword: string
  offset: number
  limit: number
}

type CreateAgencyResponse = {
  success: boolean
}

type LinkKABody = {
  id: string
  selectedTemplate: number[] | []
}

export const {
  useGetAgenciesQuery,
  useAddAgencyMutation,
  useGetAgencyQuery,
  useLazyGetAgencyQuery,
  useUpdateAgencyMutation,
  useLinkKAMutation
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getAgencies: builder.query<AgencyResponse[], GetAgenciesParams>({
      query: (params) => ({
        url: `crm/v1/collections-external/agencies`,
        params
      })
    }),
    getAgency: builder.query<AgencyResponse, string>({
      query: (id) => ({
        url: `crm/v1/collections-external/agencies/${id}`
      }),
      providesTags: ['Agency']
    }),
    addAgency: builder.mutation<CreateAgencyResponse, AgencyBody>({
      query: (body) => ({
        url: `crm/v1/collections-external/agencies/add`,
        method: 'POST',
        body
      })
    }),
    updateAgency: builder.mutation<boolean, AgencyResponse>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/collections-external/agencies/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Agency']
    }),
    linkKA: builder.mutation<boolean, LinkKABody>({
      query: ({ id, selectedTemplate }) => ({
        url: `crm/v1/collections-external/agencies/${id}/link-ka`,
        method: 'PUT',
        body: selectedTemplate
      })
    })
  })
})
