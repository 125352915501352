import { api, omitBlankEntries } from './api'

type ExtensionSettings = {
  amount_fix: number
  amount_percent: number
  canceled_at: string | null
  created_at: string | null
  enabled: boolean
  id: string
  name: string
  updated_at: string | null
  // TODO костыль с типами, после инфы по фиксу эндпоинта убрать
  subtract_when_issuing: undefined | string
  amount: undefined | string
}
export type ExtensionSettingsKeys = keyof ExtensionSettings

export const {
  useGetExtensionSettingsListQuery,
  useDeleteExtensionSettingsByIdMutation,
  useGetExtensionSettingsByIdQuery,
  useUpdateExtensionSettingsByIdMutation,
  useCreateExtensionSettingsMutation
} = api.injectEndpoints({
  endpoints: (build) => ({
    getExtensionSettingsList: build.query<
      ExtensionSettings[],
      Record<string, unknown>
    >({
      providesTags: ['ExtensionSettings'],
      query: (params) => ({
        url: `/crm/v1/external-services-settings`,
        params: omitBlankEntries(params)
      })
    }),
    getExtensionSettingsById: build.query<ExtensionSettings, string | null>({
      query: (id) => ({
        url: `crm/v1/external-services-settings/${id}`
      }),
      providesTags: (_, __, arg) =>
        arg ? [{ type: 'ExtensionSettingsById', id: arg }] : []
    }),
    deleteExtensionSettingsById: build.mutation({
      query: (id) => ({
        url: `crm/v1/external-services-settings/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, __, arg) => [
        'ExtensionSettings',
        { type: 'ExtensionSettingsById', id: arg }
      ]
    }),
    updateExtensionSettingsById: build.mutation<
      unknown,
      Partial<ExtensionSettings>
    >({
      query: ({ id, ...fields }) => ({
        url: `crm/v1/external-services-settings/${id}`,
        method: 'PUT',
        body: { ...fields }
      })
    }),
    createExtensionSettings: build.mutation({
      query: (fields) => ({
        url: `crm/v1/external-services-settings`,
        method: 'POST',
        body: fields
      })
    })
  })
})
