import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody } from 'reactstrap'
import Client from 'src/client'

export default function DownloadDossier() {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
  const [uploadStatus, setUploadStatus] = useState<string | null | boolean>(
    null,
  )

  const isValidEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return re.test(String(email).toLowerCase())
  }

  const toggle = () => {
    setShow(!show)
  }

  const uploadFile = (file: any) => {
    setLoadingUpload(true)
    setUploadStatus(null)

    if (file.size / (1024 * 1024) > 10) {
      setUploadStatus('Большой размер файла (>10MB)')
      return
    }

    Client.uploadAgencies(file, email)
      .then((res) => {
        console.log('uploadDocument', res)
        if (res.status === 200) {
          setUploadStatus(true)
        } else {
          setUploadStatus(`ошибка загрузки: ${res.status}`)
        }
      })
      .catch((err) => {
        setUploadStatus(err)
      })
      .finally(() => {
        setLoadingUpload(false)
      })
  }

  const onUpload = () => {
    const fileInput = document.getElementById('upload-file')
    fileInput?.click()
  }

  const onChangeFile = (selectedFile: any) => {
    const fileInput: any = document.getElementById('upload-file')
    if (fileInput?.files?.length) {
      const file = fileInput.files[0]
      uploadFile(file)
    }
  }

  const invisibleInputFile = (
    <input
      id="upload-file"
      type="file"
      onChange={onChangeFile}
      className="d-none"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
  )

  return (
    <div className="d-flex">
      <Button
        type="button"
        onClick={toggle}
        className="btn-label"
        color="primary"
        style={{ backgroundColor: '#405189' }}
      >
        <i className="ri-add-line label-icon align-middle fs-16 me-2"></i>
        {t('download_dossier')}
      </Button>
      <Modal isOpen={show} toggle={toggle} centered>
        <ModalBody>
          {uploadStatus && (
            <div className="mt-2">
              <small
                className={
                  uploadStatus === true
                    ? 'text-bold text-success'
                    : 'text-bold text-danger'
                }
              >
                {uploadStatus === true
                  ? `Документ успешно загружен.`
                  : uploadStatus.toString()}
              </small>
            </div>
          )}
          {invisibleInputFile}
          <div>Введите email:</div>
          <br />
          <input
            required
            placeholder="Введите электронную почту"
            type="email"
            className="form-control form-control-sm"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <Button
            disabled={!isValidEmail(email)}
            className="btn-label"
            color="primary"
            style={{ backgroundColor: '#405189' }}
            onClick={onUpload}
          >
            <i className="ri-add-line label-icon align-middle fs-16 me-2"></i>
            {t('Загрузить реестр')}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  )
}
