import React from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'

import {
  useDeleteExtensionSettingsByIdMutation,
  useGetExtensionSettingsListQuery
} from '../../../services/external-services-settings'
import { LIST_LIMIT, LIST_LIMIT_DEBTS } from '../../../client'

const headers = {
  name: {},
  enabled: {},
  amount: { isAmount: true },
  subtract_when_issuing: {}
}
const HEADERS_KEYS = Object.keys(headers)

const LIMIT =
  window.location.pathname === '/debts' ? LIST_LIMIT_DEBTS : LIST_LIMIT
const OFFSET = 0

export const ExternalServicesSettings = () => {
  const { t } = useTranslation()
  const {
    data: list,
    isLoading: listLoading,
    isError,
    error: listError
  } = useGetExtensionSettingsListQuery({
    limit: LIMIT,
    offset: OFFSET
  })
  const [deleteExternalServicesSettings] =
    useDeleteExtensionSettingsByIdMutation()

  const archive = (settingsId: string) => {
    deleteExternalServicesSettings(settingsId)
  }

  const title = t('External_services_settings')

  document.title = title
  const metaBar = null

  if (isError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <div className='table-responsive table-card mt-4'>
              <Table hover className='table-sm align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th />
                    {HEADERS_KEYS.map((header) => (
                      <th key={header} scope='col'>
                        {t(header)}
                      </th>
                    ))}
                    <th scope='col'>{t('Actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/external-services-settings-view?id=${entity.id}`}
                              className='fw-medium'
                            >
                              <i className='ri-eye-line fs-15' />
                            </Link>
                          </td>
                          {HEADERS_KEYS.map((fieldName) => {
                            const key = `${fieldName}`
                            return (
                              <td key={key}>
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  headers
                                )}
                              </td>
                            )
                          })}
                          <td>
                            <div className='hstack gap-3 flex-wrap'>
                              <Link
                                to={`/external-services-settings-edit?id=${entity.id}`}
                                className='link-success fs-15'
                              >
                                <i className='ri-edit-2-line'></i>
                              </Link>
                              <Link
                                to='#'
                                onClick={() => archive(entity.id)}
                                className='link-danger fs-15'
                              >
                                <i className='ri-delete-bin-line'></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
