import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'

import { getProductExtensions, deleteProductExtension } from 'src/store/actions'

const enableDelete = false
const enableEdit = true

const headers = {
  ext_from_sign_day: { label: 'ext_from_sign_day' },
  ext_overdue_allowed_days: { label: 'ext_overdue_allowed_days' },
  ext_with_penalty: { label: 'ext_with_penalty' },
  extension_enabled: { label: 'extension_enabled' },
  max_ext_days: { label: 'max_ext_days' },
  min_ext_days: { label: 'min_ext_days' },
}

export const ProductExtensions = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { list, listLoading, listError } = useSelector((state) => ({
    list: state.productExtensions.list,
    listLoading: state.productExtensions.listLoading,
    listError: state.productExtensions.listError,
  }))

  useEffect(() => {
    dispatch(getProductExtensions())
  }, [dispatch])

  const archive = (id) => {
    dispatch(deleteProductExtension(id))
  }

  console.log('Extension_settings =>', list)
  const title = t('extensions_settings')
  document.title = title

  const bread = <BreadCrumb title={title} pageTitle={t('Admin')} />

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              <Table hover className="table-sm align-middle mb-0 mt-4">
                <thead>
                  <tr>
                    <th />
                    {Object.keys(headers).map((header) => (
                      <th key={header} scope="col">
                        {t(headers[header].label)}
                      </th>
                    ))}
                    {(enableEdit || enableDelete) && (
                      <th scope="col">{t('Actions')}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((client) => {
                      return (
                        <tr key={client.id}>
                          <td>
                            <Link
                              to={`/product-extensions-view?id=${client.id}`}
                              className="fw-medium"
                            >
                              <i className="ri-eye-line fs-15" />
                            </Link>
                          </td>
                          {Object.keys(headers).map((productFieldName) => {
                            const key = `${productFieldName}`
                            return (
                              <td key={key}>
                                {model.fieldValueToText(
                                  productFieldName,
                                  client,
                                )}
                              </td>
                            )
                          })}
                          {(enableEdit || enableDelete) && (
                            <td>
                              <div className="hstack gap-3 flex-wrap">
                                {enableEdit && (
                                  <Link
                                    to={`/product-extensions-edit?id=${client.id}`}
                                    className="link-success fs-15"
                                  >
                                    <i className="ri-edit-2-line"></i>
                                  </Link>
                                )}
                                {enableDelete && (
                                  <Link
                                    to="#"
                                    onClick={() => archive(client.id)}
                                    className="link-danger fs-15"
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
