import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, CardBody } from 'reactstrap'

import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import List from 'src/Components/Common/EntitiesView/List'
import { LIST_LIMIT } from 'src/client'
import { UseQueryHookArgs } from 'src/services/api'
import {
  useDownloadBkiFileMutation,
  useGetBkiListQuery
} from 'src/services/bkiSentFile'
import { Pagination } from '../Claims/Components/pagination'

export const BkiSent = () => {
  const { t } = useTranslation()

  const defaultValue = {
    bureau: 'equifax',
    unique_names: true
  }

  const [fieldsFilter, setFieldsFilter] =
    useState<UseQueryHookArgs<typeof useGetBkiListQuery>>(defaultValue)

  const { data, isFetching, isError, error } = useGetBkiListQuery({
    limit: LIST_LIMIT,
    offset: 0,
    ...fieldsFilter
  })

  const countRecords = data?.count_records ?? 0
  const bkiList = data?.results ?? []

  const [DownloadBkiFile] = useDownloadBkiFileMutation()

  const downloadFile = (item: any) => {
    DownloadBkiFile({ file_name: item.file_name, bureau: item.company }).then(
      (blob) => {
        if ('data' in blob) {
          const url = window.URL.createObjectURL(new Blob([blob.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${item.file_name}.xml`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      }
    )
  }

  const title = t('Viewing files in BKI')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <FieldsFilter
            fields={{
              bureau: {
                label: t('Company'),
                defaultValue: 'equifax',
                optionsValue: [
                  { value: 'equifax', name: 'Эквифакс' },
                  { value: 'nbki', name: 'НБКИ' }
                ]
              },
              unique_names: {
                label: t('show_only_unique_files'),
                defaultValue: true,
                optionsValue: [
                  { value: true, name: 'Да' },
                  { value: false, name: 'Нет' }
                ]
              },
              date_filter: { date: true, label: 'created_at' },
              file_name: {},
              event_name: {},
              loan_uuid_cbr: {}
            }}
            filter={defaultValue}
            applyFilter={(values: typeof fieldsFilter) =>
              setFieldsFilter(values)
            }
          />
          <Pagination
            list={bkiList || []}
            setFieldsFilter={setFieldsFilter}
            fieldsFilter={fieldsFilter}
          />

          <div className='ps-3' style={{ width: 'fit-content' }}>
            <Alert color='info'>
              {t('total_files_found')}: {countRecords}
            </Alert>
          </div>
          {isFetching ? (
            <Loader />
          ) : (
            bkiList && (
              <List
                list={bkiList.map((item) => ({ ...item }))}
                className={undefined}
                style={{ width: 'fit-content' }}
                fieldsDescription={{
                  company: { label: t('Company') },
                  created_at: { isDate: true },
                  file_name: {},
                  event_name: {},
                  loan_uuid_cbr: {}
                }}
                hover
                actions={bkiList.map((item: any, rank) => {
                  return (
                    <Button
                      key={`action-${rank}`}
                      size='sm'
                      onClick={() => downloadFile(item)}
                    >
                      {t('Download')}
                    </Button>
                  )
                })}
              />
            )
          )}
        </CardBody>
      </Card>
    </div>
  )
}
