import { api, omitBlankEntries } from './api'

type Product = {
  product_code: string
  product_name: string
  product_priority: string
  product_status: string
  group_id: string | null
  time_to_sign: number
  min_loan_term: number
  max_loan_term: number
  min_loan_amount: number
  max_loan_amount: number
  interest_rate: string
  extension_interest_rate: number
  delinquency_interest_rate: number
  late_loan_interest_rate: number
  late_fee: number
  issuance_fee: number
  repayment_fee: number
  coef_max_overpayment: number
  loan_holidays_total: number
  max_loan_holidays_term: number
  id: string
  created_at: string | null
  updated_at: string
  canceled_at: string | null
  extension_setting: any | null
}

const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<
      Product[],
      { offset?: number; limit?: number } | void
    >({
      query: (params) => ({
        url: `crm/v1/products`,
        params: omitBlankEntries(params),
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Products', id }) as const),
        { type: 'Products' as const, id: 'LIST' },
      ],
    }),
    getProduct: builder.query<Product, string>({
      query: (id) => `crm/v1/products/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Products', id }],
    }),
    updateProduct: builder.mutation<void, Partial<Product>>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/products/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _err, { id }) => [{ type: 'Products', id }],
    }),
    createProduct: builder.mutation<void, Partial<Product>>({
      query: (body) => ({ url: `crm/v1/products`, method: 'POST', body }),
      invalidatesTags: () => ['Products'],
    }),
    deleteProduct: builder.mutation<void, string | number>({
      query: (id) => ({ url: `crm/v1/products/${id}`, method: 'DELETE' }),
      invalidatesTags: () => ['Products'],
    }),
  }),
})

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useUpdateProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
} = productsApi
