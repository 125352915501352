export const LIST = "LIST_DIALOGUES"
export const LIST_SUCCESS = "LIST_DIALOGUES_SUCCESS"
export const LIST_FAIL = "LIST_DIALOGUES_FAIL"

export const ENTITY = "GET_DIALOGUE"
export const ENTITY_SUCCESS = "GET_DIALOGUE_SUCCESS"
export const ENTITY_FAIL = "GET_DIALOGUE_FAIL"

export const UPDATE = "UPDATE_DIALOGUE"
export const UPDATE_SUCCESS = "UPDATE_DIALOGUE_SUCCESS"
export const UPDATE_FAIL = "UPDATE_DIALOGUE_FAIL"
