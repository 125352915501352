import { api, omitBlankEntries } from './api'

type Collectors = {
  name: string
  surname: string
  id: string
  group_id: number
  username: string
  status: string
  midname: string
  collection_stage_id: number
  sip_username?: null
  sip_password?: null
}

const CollectorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCollectors: builder.query<
      Collectors[],
      Partial<{
        limit: number
        offset: number
      }>
    >({
      query: (params) => ({
        url: 'crm/v1/calling/collectors',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Collectors', id }) as const),
        { type: 'Collectors', id: 'LIST' }
      ]
    })
  })
})

export const { useGetCollectorsQuery } = CollectorsApi
