import { useEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { ToastContainer, toast } from 'react-toastify'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import userDummayImage from '../../../assets/images/users/user-dummy-img.jpg'

import { updateDialogue } from '../../../store/actions'

import { formatDateTime } from '../../../utils'

import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
} from 'reactstrap'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import {
  Pagination,
  Navigation,
  Scrollbar,
  EffectFade,
  EffectCreative,
  Mousewheel,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
} from 'swiper'

import model from '../../../model'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const enableEmoji = false

const EntityContent = (props) => {
  //const messages = entity;
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const ref = useRef()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [chatClientId, setChatClientId] = useState('')
  const [curMessage, setCurMessage] = useState('')
  const [messageToReply, setReply] = useState('')
  const [emptyMessageError, setEmptyMessageError] = useState(false)
  const fullscreen = props.fullscreen || false

  const { messages, entityError, updateError, updateLoading } = useSelector(
    (state) => ({
      messages: state.dialogues.entity,
      entityError: state.dialogues.entityError,
      updateError: state.dialogues.updateError,
      updateLoading: state.dialogues.updateLoading,
    }),
  )

  const setMessageToReply = (e, msg) => {
    e.preventDefault()
    setReply(msg)
  }

  const copyMessage = (e, msg) => {
    e.preventDefault()
    navigator?.clipboard?.writeText(msg)
    toast('Message copied', {
      position: 'bottom-center',
      hideProgressBar: true,
    })
  }

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.getScrollElement().scrollTop =
        ref.current.getScrollElement().scrollHeight
    }
  }

  const onKeyPress = (e) => {
    const { key, value } = e
    if (key === 'Enter') {
      e.preventDefault()
      addMessage(chatClientId)
    }
  }

  const addMessage = () => {
    const isValid = curMessage && curMessage.trim().length > 0
    if (!isValid) {
      setEmptyMessageError(true)
      return
    }
    setCurMessage('')
    const data = {
      id: chatClientId,
      message: curMessage,
    }
    console.log('data => ', data)
    setUpdating(true)
    dispatch(updateDialogue(data))
  }

  useEffect(() => {
    if (!updating) return

    if (updateLoading) {
      setSuccess(false)
      setError(false)
      return
    }

    setUpdating(false)
    if (!updateError) setSuccess(true)
    if (updateError) setError(updateError)
  }, [updateLoading, updateError])

  useEffect(() => {
    ref.current.recalculate()
  })

  useEffect(() => {
    if (messages && messages.length) {
      scrollToBottom()
    }
  }, [messages])

  console.log('messages, updateError =>', messages, updateError)

  if (error) return <DangerAlert error={props.error} />

  return (
    <div
      className="user-chat w-100 overflow-hidden"
      style={!fullscreen ? { maxWidth: 900 } : null}
    >
      <div className="chat-content d-lg-flex">
        <div className="w-100 overflow-hidden position-relative">
          <div className="position-relative">
            {fullscreen && (
              <div className="p-3 user-chat-topbar">
                <Row className="align-items-center">
                  <Col sm={4} xs={8}>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 d-block d-lg-none me-3">
                        <Link to="#" className="user-chat-remove fs-18 p-1">
                          <i className="ri-arrow-left-s-line align-bottom"></i>
                        </Link>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                            <img
                              src={userDummayImage}
                              className="rounded-circle avatar-xs"
                              alt=""
                            />
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="text-truncate mb-0 fs-16">
                              <Link
                                className="text-reset username"
                                data-bs-toggle="offcanvas"
                                to={`/client-view?id=${chatClientId}`}
                                aria-controls="userProfileCanvasExample"
                              >
                                {props.clientName || chatClientId}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <div className="position-relative" id="users-chat">
              <SimpleBar
                ref={ref}
                className="chat-conversation p-3 p-lg-4 "
                id="chat-conversation"
                style={!fullscreen ? { height: 'auto' } : null}
              >
                <ul
                  className="list-unstyled chat-conversation-list"
                  id="users-conversation"
                >
                  {(!messages || messages.length === 0) && (
                    <div className="d-flex align-items-center justify-content-center">
                      <small className="text-muted">
                        {t('no_messages_yet')}
                      </small>
                    </div>
                  )}
                  {messages &&
                    messages.sort(model.sortByCreated).map((message) => (
                      <li
                        className={
                          message.direction !== '2'
                            ? ' chat-list left'
                            : 'chat-list right'
                        }
                        key={message.id}
                      >
                        <div className="conversation-list">
                          {message.direction !== '2' && (
                            <div className="chat-avatar">
                              <img src={userDummayImage} alt="" />
                            </div>
                          )}

                          <div className="user-chat-content">
                            <div className="ctext-wrap">
                              <div className="ctext-wrap-content">
                                <p className="mb-0 ctext-content">
                                  {message.message}
                                </p>
                              </div>
                              <UncontrolledDropdown className="align-self-start message-box-drop">
                                <DropdownToggle
                                  href="#"
                                  className="btn nav-btn"
                                  tag="i"
                                >
                                  <i className="ri-more-2-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={(e) =>
                                      setMessageToReply(e, message.message)
                                    }
                                    href="#"
                                    className="reply-message"
                                  >
                                    <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                                    Reply
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={(e) =>
                                      copyMessage(e, message.message)
                                    }
                                    href="#"
                                  >
                                    <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                    Copy
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="conversation-name">
                              <small className="text-muted time">
                                {formatDateTime(message.created_at)}
                              </small>{' '}
                              <span className="text-success check-message-icon">
                                <i className="ri-check-double-line align-bottom"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </SimpleBar>
              <ToastContainer autoClose={1500} limit={1} />
            </div>

            <div className="chat-input-section p-3 p-lg-4 mt-4">
              <form id="chatinput-form">
                <Row className="g-0 align-items-center">
                  {enableEmoji && (
                    <div className="col-auto">
                      <div className="chat-input-links me-2">
                        <div className="links-list-item">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none emoji-btn"
                            id="emoji-btn"
                          >
                            <i className="bx bx-smile align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col">
                    <div
                      className="chat-input-feedback"
                      style={{ display: emptyMessageError ? 'block' : 'none' }}
                    >
                      Please Enter a Message
                    </div>
                    <input
                      type="text"
                      value={curMessage}
                      onKeyPress={onKeyPress}
                      onChange={(e) => {
                        setEmptyMessageError(false)
                        setCurMessage(e.target.value)
                      }}
                      className="form-control chat-input bg-light border-light"
                      id="chat-input"
                      placeholder={t('type_message')}
                    />
                  </div>
                  <div className="col-auto">
                    <div className="chat-input-links ms-2">
                      <div className="links-list-item">
                        <Button
                          type="button"
                          color="primary"
                          disabled={updateLoading}
                          onClick={() => addMessage(chatClientId)}
                          className="chat-send waves-effect waves-light"
                        >
                          <i className="ri-send-plane-2-fill align-bottom"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
            </div>

            <div className={`replyCard ${messageToReply ? 'show' : ''}`}>
              <Card className="mb-0">
                <CardBody className="py-3">
                  <div className="replymessage-block mb-0 d-flex align-items-start">
                    <div className="flex-grow-1">
                      {/* <h5 className="conversation-name"></h5> */}
                      <p className="mb-0">{messageToReply}</p>
                    </div>
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        id="close_toggle"
                        onClick={(e) => setMessageToReply(e, '')}
                        className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                      >
                        <i className="bx bx-x align-middle"></i>
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EntityContent
