import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  useCreateProductMutation,
  useGetProductQuery,
  useUpdateProductMutation,
} from 'src/services/products'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'

const status_options = [
  { name: 'active', defaultValue: 1 },
  { name: 'archived', defaultValue: 2 },
]
const boolean_options = [
  { name: 'true', defaultValue: 1 },
  { name: 'false', defaultValue: 0 },
]

const inputInitials = {
  product_code: { label: 'code', initial: '', placeholder: 'ex: PDL1' },
  product_name: {
    label: 'Product Name',
    initial: '',
    placeholder: 'ex: Pay Day Loan',
  },
  interest_rate: {
    label: 'interest rate',
    initial: '0.01',
    placeholder: 'ex: 0.01',
  },
  manual_verification: {
    label: 'manual_verification',
    initial: 'true',
    placeholder: '',
    options: boolean_options,
  },
  coef_max_overpayment: {
    label: 'coef_max_overpayment',
    initial: '1',
    placeholder: '',
  },
  min_loan_term: { label: 'min_loan_term', initial: '14', placeholder: 'days' },
  max_loan_term: { label: 'max_loan_term', initial: '14', placeholder: 'days' },
  min_loan_amount: {
    label: 'minimum loan amount',
    initial: 10000,
    placeholder: 'ex: 10000',
  },
  max_loan_amount: {
    label: 'maximum loan amount',
    initial: 30000,
    placeholder: 'ex: 30000',
  },
  loan_holidays_total: {
    label: 'number of possible loan holidays',
    initial: 5,
    placeholder: 'ex: 5',
  },
  max_loan_holidays_term: {
    label: 'maximum term of credit holidays',
    initial: 30,
    placeholder: 'ex: 30',
  },
  extension_interest_rate: {
    label: 'interest rate after loan extension',
    initial: '0.0001',
    placeholder: 'ex: 0.0001',
  },
  delinquency_interest_rate: {
    label: 'interest rate after loan delinquency',
    initial: '0.0001',
    placeholder: 'ex: 0.0001',
  },
  late_fee: {
    label: 'late fee (one-time)',
    initial: 100,
    placeholder: 'ex: 100.00',
  },
  late_loan_interest_rate: {
    label: 'interest on late loans (daily accrual)',
    initial: 100,
    placeholder: 'ex: 100.00',
  },
  issuance_fee: {
    label: 'loan issuance fee',
    initial: 100,
    placeholder: 'ex: 100.00',
  },
  repayment_fee: {
    label: 'loan repayment fee',
    initial: 100,
    placeholder: 'ex: 100.00',
  },
  autosign_application: {
    label: 'autosign_application',
    initial: 'true',
    placeholder: '-',
    options: boolean_options,
  },
  //call4life_fee: {label: "call4life_fee", initial: 1, placeholder: "-"},
  product_priority: { label: 'priority', initial: 1, placeholder: 'ex: 1' },
  product_status: {
    label: 'state',
    initial: 'active',
    placeholder: 'ex: not active | active',
    options: status_options,
  },
  time_to_sign: {
    label: 'time_to_sign',
    unit: 'hours',
    initial: '72',
    placeholder: '72',
  },
}

export const ProductEdit = () => {
  const { t } = useTranslation()
  const [id, setId] = useState(null)
  const [params] = useSearchParams()
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [paramError] = useState(null)
  const {
    data: product,
    isLoading: productLoading,
    error: productError,
  } = useGetProductQuery(params.get('id'), {
    skip: params.get('id') == null,
  })

  const [updateProduct, { error: updateError, isLoading: updateLoading }] =
    useUpdateProductMutation()

  const [createProduct, { error: createError, isLoading: createLoading }] =
    useCreateProductMutation()

  useEffect(() => {
    const id = params.get('id')
    console.log('id => ', id)
    setId(id || null)
    if (id) {
      //edit mode
      // dispatch(getProduct(id))
    } else {
      //create mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] = inputInitials[key].initial
      })
      setInitialFormValues(initialFormValues)
    }
  }, [params])

  useEffect(() => {
    if (id && product) {
      //edit mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] =
          product[key] !== null && product[key] !== undefined
            ? product[key].toString()
            : ''
      })
      setInitialFormValues(initialFormValues)
    }
  }, [id, product])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({
      /*product_code: Yup.string().max(4).required(`${t("Required")}: ${t("code")}`),
      product_name: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required("Required: Product Name"),
      interest_rate: Yup.number().required(`${t("Required")}: ${t("interest rate")}`),
      manual_verification: Yup.string().required(`${t("Required")}: ${t("manual_verification")}`),
      coef_max_overpayment: Yup.number().required(`${t("Required")}: ${t("coef_max_overpayment")}`),
      min_loan_term: Yup.number().required(`${t("Required")}: ${t("min_loan_term")}`),
      max_loan_term: Yup.number().required(`${t("Required")}: ${t("max_loan_term")}`),
      min_loan_amount: Yup.number().required(`${t("Required")}: ${t("minimum loan amount")}`),
      max_loan_amount: Yup.number().required(`${t("Required")}: ${t("maximum loan amount")}`),
      loan_holidays_total: Yup.number().required(t(`${t("Required")}: ${t("number of possible loan holidays")}`)),
      max_loan_holidays_term: Yup.number().required(`${t("Required")}: ${t("maximum term of credit holidays")}`),
      extension_interest_rate: Yup.number().required(`${t("Required")}: ${t("interest rate after loan extension")}`),
      delinquency_interest_rate: Yup.number().required(`${t("Required")}: ${t("interest rate after loan delinquency")}`),
      late_fee: Yup.number().required(t(`${t("Required")}: ${t("late fee (one-time)")}`)),
      late_loan_interest_rate: Yup.number().required(`${t("Required")}: ${t("interest on late loans (daily accrual)")}`),
      issuance_fee: Yup.number().required(`${t("Required")}: ${t("loan issuance fee")}`),
      repayment_fee: Yup.number().required(`${t("Required")}: ${t("loan repayment fee")}`),
      autosign_application: Yup.string().required(`${t("Required")}: ${t("autosign_application")}`),
      //call4life_fee: Yup.string(),
      product_priority: Yup.string().required(`${t("Required")}: ${t("priority")}`),
      product_status: Yup.string().required(`${t("Required")}: ${t("status")}`),
      time_to_sign: Yup.number().required(`${t("Required")}: ${t("time_to_sign")}`),*/
    }),
    onSubmit: (values) => {
      console.log('values', values)
      if (id) updateProduct({ id: product.id, ...values })
      else createProduct(values)
    },
  })

  const title = id ? t('Edit product') : t('Create product')
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>
  const metaBar = (
    <MetaBar backLink={'/products'} entity={id && !!product ? product : null} />
  )

  if (paramError || productError) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          {bread}
          <Card>
            <CardBody>
              {metaBar}
              <Alert color="warning" className="mt-4">
                <strong> {!!paramError && paramError.toString()} </strong>
                <strong> {!!productError && productError.toString()} </strong>
              </Alert>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    )
  }

  if (productLoading) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          {bread}
          <Card>
            <CardBody>
              {metaBar}
              <Container
                fluid={true}
                className="mt-4 d-flex justify-content-center"
              >
                {loading}
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className="needs-validation mt-4"
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                {Object.keys(inputInitials).map((fieldKey, i) => {
                  const htmlForId = `validation-${fieldKey}`
                  return (
                    <Col md="4" key={`${fieldKey}`}>
                      <FormGroup className="mb-3">
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        {inputInitials[fieldKey].options &&
                        inputInitials[fieldKey].options.length ? (
                          <select
                            name={`${fieldKey}`}
                            id={htmlForId}
                            className="form-select form-select-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey] || ''}
                          >
                            {inputInitials[fieldKey].options.map((option) => (
                              <option
                                key={option.name}
                                defaultValue={option.defaultValue}
                              >
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <Input
                            name={`${fieldKey}`}
                            placeholder={inputInitials[fieldKey].placeholder}
                            type="text"
                            className="form-control form-control-sm"
                            id={htmlForId}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey] || ''}
                            invalid={
                              !!(
                                validation.touched[fieldKey] &&
                                validation.errors[fieldKey]
                              )
                            }
                          />
                        )}
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type="invalid">
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {!!updateError && (
                <Alert color="warning">
                  <strong> {JSON.stringify(updateError)} </strong>
                </Alert>
              )}
              {!!createError && (
                <Alert color="warning">
                  <strong> {JSON.stringify(createError)} </strong>
                </Alert>
              )}

              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className="text-nowrap"
                  color="primary"
                  style={{ backgroundColor: '#405189' }}
                  type="submit"
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
