import React from 'react'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  ButtonGroup
} from 'reactstrap'
import { useFormik } from 'formik'
import Flatpickr from 'react-flatpickr'
import {
  useAddPromocodeMutation,
  useGetPromocodeQuery,
  useUpdatePromocodeMutation
} from 'src/services/promocodes'
import { useSearchParams } from 'react-router-dom'
import { omit } from 'src/utils'
import override from './css/override.css'
import { useGetPromocodesGroupsQuery } from 'src/services/promocodesGroups'
import { getPromocodeValidationSchema } from './schema/promocodeValidationSchema'
import { PromocodeEditFormValues } from './types/promocodes.types'

export const PromocodeEdit = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const promocodeId = searchParams.get('id')

  const {
    data: promocode,
    isLoading: isPromocodeLoading,
    error: getPromocodeError
  } = useGetPromocodeQuery(promocodeId || '', {
    skip: promocodeId == null
  })

  const [
    updatePromocode,
    { isLoading: isPromocodeUpdating, error: updatePromocodeError }
  ] = useUpdatePromocodeMutation()

  const [
    addPromocode,
    { isLoading: isPromocodeCreating, error: createPromocodeError }
  ] = useAddPromocodeMutation()

  const { data: promocodesGroups } = useGetPromocodesGroupsQuery()

  const validation = useFormik<PromocodeEditFormValues>({
    enableReinitialize: true,
    initialValues: {
      sale: 0,
      promocode: '',
      begin_date: '',
      end_date: '',
      type: null,
      limitless: true,
      available_usages: null,
      total_usage_limit: null,
      ...omit(promocode, ['id'])
    },
    validationSchema: getPromocodeValidationSchema(t),
    onSubmit: async (values) => {
      // do not send total_usage_limit if creating with limitless===true
      // do not send available_usages if creating with limitless===true
      // do not send available_usages if editing with limitless===false
      try {
        if (promocodeId) {
          await updatePromocode({
            id: +promocodeId,
            ...omit(values, ['available_usages', 'total_usage_limit'])
          })
        } else if (values.limitless) {
          await addPromocode(
            omit(values, ['available_usages', 'total_usage_limit'])
          )
        } else {
          await addPromocode(omit(values, ['available_usages']))
        }
      } catch (error) {
        console.log(error)
      }
    }
  })

  const title = `${t('Promocodes')}: ${promocodeId ? t('Edit') : t('Create')}`
  document.title = title

  if (getPromocodeError) {
    return (
      <ErrorPage
        backLink={'/promocodes'}
        title={title}
        error={[getPromocodeError]}
      />
    )
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar backLink={'/promocodes'} entity={promocode} />
            <Form
              className='needs-validation mt-4'
              onSubmit={validation.handleSubmit}
            >
              <Row>
                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='sale'>{t('sale')}</Label>
                    <Input
                      name='sale'
                      placeholder='0.00'
                      type='text'
                      className='form-control form-control-sm'
                      id='sale'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sale}
                      invalid={
                        !!(validation.touched.sale && validation.errors.sale)
                      }
                      disabled={
                        isPromocodeLoading ||
                        isPromocodeUpdating ||
                        isPromocodeCreating
                      }
                    />
                    {validation.touched.sale && validation.errors.sale ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.sale}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='promocode'>{t('name')}</Label>
                    <Input
                      name='promocode'
                      type='text'
                      className='form-control form-control-sm'
                      id='promocode'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.promocode}
                      invalid={
                        !!(
                          validation.touched.promocode &&
                          validation.errors.promocode
                        )
                      }
                      disabled={
                        isPromocodeLoading ||
                        isPromocodeUpdating ||
                        isPromocodeCreating
                      }
                    />
                    {validation.touched.promocode &&
                    validation.errors.promocode ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.promocode}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='type'>{t('Group')}</Label>
                    <select
                      name='type'
                      id='type'
                      className='form-select form-select-sm'
                      onChange={(e) => {
                        validation.setFieldValue(
                          'type',
                          parseInt(e.target.value, 10)
                        )
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.type ?? ''}
                    >
                      <option value=''>{t('Select group')}</option>
                      {promocodesGroups?.map(({ group_name, id }) => (
                        <option key={id} value={id}>
                          {group_name}
                        </option>
                      ))}
                    </select>
                    {validation.touched.type && validation.errors.type ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.type}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='begin_date'>{t('begin_date')}</Label>
                    <Flatpickr
                      name='begin_date'
                      id='begin_date'
                      className='form-control form-control-sm'
                      options={{
                        dateFormat: 'Y-m-d',
                        defaultDate: [validation.values.begin_date || null],
                        onChange: (selectedDates: Date[], dateStr: string) => {
                          console.log('dateStr', dateStr)
                          validation.setFieldValue('begin_date', dateStr)
                        }
                      }}
                      value={validation.values.begin_date}
                      disabled={
                        isPromocodeLoading ||
                        isPromocodeUpdating ||
                        isPromocodeCreating
                      }
                    />
                    {validation.touched.begin_date &&
                    validation.errors.begin_date ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.begin_date}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='end_date'>{t('end_date')}</Label>
                    <Flatpickr
                      name='end_date'
                      id='end_date'
                      className='form-control form-control-sm'
                      options={{
                        dateFormat: 'Y-m-d',
                        defaultDate: [validation.values.end_date || null],
                        onChange: (selectedDates: Date[], dateStr: string) => {
                          console.log('dateStr', dateStr)
                          validation.setFieldValue('end_date', dateStr)
                        }
                      }}
                      value={validation.values.end_date}
                      disabled={
                        isPromocodeLoading ||
                        isPromocodeUpdating ||
                        isPromocodeCreating
                      }
                    />
                    {validation.touched.end_date &&
                    validation.errors.end_date ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.end_date}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='limitless'>{t('type')}</Label>
                    <ButtonGroup className='w-100'>
                      <Button
                        color='primary'
                        outline
                        size='sm'
                        cssModule={override}
                        active={
                          validation.values.limitless === true ||
                          validation.values.limitless == null
                        }
                        onClick={() => {
                          validation.setFieldValue('limitless', true)
                          validation.setFieldValue('total_usage_limit', null)
                          validation.setFieldValue('available_usages', null)
                        }}
                        disabled={
                          isPromocodeLoading ||
                          isPromocodeUpdating ||
                          isPromocodeCreating ||
                          promocodeId != null
                        }
                      >
                        {t('Limitless')}
                      </Button>
                      <Button
                        color='primary'
                        outline
                        cssModule={override}
                        size='sm'
                        active={validation.values.limitless === false}
                        onClick={() => {
                          validation.setFieldValue('limitless', false)
                          validation.setFieldValue('total_usage_limit', 0)
                          validation.setFieldValue('available_usages', 0)
                        }}
                        disabled={
                          isPromocodeLoading ||
                          isPromocodeUpdating ||
                          isPromocodeCreating ||
                          promocodeId != null
                        }
                      >
                        {t('Restricted')}
                      </Button>
                    </ButtonGroup>
                    {validation.touched.limitless &&
                    validation.errors.limitless ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.limitless}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='available_usages'>
                      {t('Available usages')}
                    </Label>
                    <Input
                      name='available_usages'
                      type='number'
                      className='form-control form-control-sm'
                      id='promocode'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.available_usages ?? ''}
                      invalid={
                        !!(
                          validation.touched.available_usages &&
                          validation.errors.available_usages
                        )
                      }
                      disabled
                    />
                    {validation.touched.available_usages &&
                    validation.errors.available_usages ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.available_usages}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='total_usage_limit'>
                      {t('Total usage limit')}
                    </Label>
                    <Input
                      name='total_usage_limit'
                      type='number'
                      className='form-control form-control-sm'
                      id='promocode'
                      onChange={(e) => {
                        validation.setFieldValue(
                          'available_usages',
                          parseInt(e.target.value)
                        )
                        validation.setFieldValue(
                          'total_usage_limit',
                          parseInt(e.target.value)
                        )
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.total_usage_limit ?? ''}
                      invalid={
                        !!(
                          validation.touched.total_usage_limit &&
                          validation.errors.total_usage_limit
                        )
                      }
                      disabled={
                        validation.values.limitless == null ||
                        validation.values.limitless ||
                        isPromocodeLoading ||
                        isPromocodeUpdating ||
                        isPromocodeCreating
                      }
                    />
                    {validation.touched.total_usage_limit &&
                    validation.errors.total_usage_limit ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.total_usage_limit}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              {!!updatePromocodeError && (
                <Alert color='warning'>
                  <strong> {JSON.stringify(updatePromocodeError)} </strong>
                </Alert>
              )}
              {!!createPromocodeError && (
                <Alert color='warning'>
                  <strong> {JSON.stringify(createPromocodeError)} </strong>
                </Alert>
              )}

              <Button
                className='text-nowrap'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                type='submit'
                disabled={
                  isPromocodeLoading ||
                  isPromocodeUpdating ||
                  isPromocodeCreating ||
                  !validation.dirty
                }
              >
                {promocodeId ? t('Update') : t('Create')}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
