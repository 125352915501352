import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, Input } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import Clients from 'src/client'
import Select from 'src/Components/Common/Select'
import { Alert } from 'reactstrap'

const ALERT_DEBOUNCE = 3000

export const Tags = () => {
  const { t } = useTranslation()
  const [tag, setTag] = useState('')
  const [type, setType] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const title = t('Custom Tags')
  document.title = title

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), ALERT_DEBOUNCE)
    }
    if (error) {
      setTimeout(() => setError(false), ALERT_DEBOUNCE)
    }
  }, [success, error])

  const option = [
    {
      name: '',
      value: '',
    },
    {
      name: 'loan',
      value: 'loan',
    },
    {
      name: 'client',
      value: 'client',
    },
  ]

  const createTag = () => {
    Clients.addCustomTags(tag, type)
      .then((res) => {
        if (res.successful) {
          setTag('')
          setType('')
        }
      })
      .catch((error) => {
        if (error) {
          setError(true)
        }
      })
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
              <Input
                type="text"
                style={{ width: '300px' }}
                value={tag}
                onChange={(event) => setTag(event.target.value)}
              />
              <Select value={type} options={option} onChange={setType} />
            </div>
            <Button
              color="success"
              type="button"
              onClick={createTag}
              style={{ marginTop: '30px', marginBottom: '30px' }}
              disabled={tag.length === 0 || type.value === ''}
            >
              {t('Save')}
            </Button>
            {success && <Alert color="success">{t('Done')}</Alert>}
            {error && <Alert color="danger">{t('Error')}</Alert>}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
