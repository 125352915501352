import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Alert,
  Spinner,
} from 'reactstrap'
import client from 'src/client'

export const CollectorEdit = () => {
  const { t } = useTranslation()
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [stage, setStage] = useState(null)
  const [options, setOptions] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    client.getSegments().then((resolve) => {
      setOptions(resolve)
      setStage(resolve[0])
      setPageReady(true)
    })
  }, [])

  const onSubmit = () => {
    const body = { collection_stage_id: stage.id }
    try {
      client.postSegmentToCollector(id, body).then(() => setUpdateSuccess(true))
    } catch (e) {
      setParamError(e)
    }
  }

  document.title = t('Edit collector')
  const bread = <BreadCrumb title={`${t('Edit collector')}`} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>

  if (paramError) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          <Container fluid={true}>
            {bread}
            <Alert color="warning">
              <strong> {!!paramError && paramError.toString()} </strong>
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  if (!pageReady) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          <Container fluid={true}>
            {bread}
            {loading}
          </Container>
        </div>
      </React.Fragment>
    )
  }

  let metaBar = <MetaBar backLink={`/collectors`} />

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Container fluid={true} className="mt-4">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col md="4">
                      <Label>{t('Choose segment')}</Label>
                      <select
                        className="form-select form-select-sm"
                        onChange={(evv) =>
                          setStage(
                            options.find(
                              (item) => item.name === evv.target.value,
                            ),
                          )
                        }
                        value={stage.name}
                      >
                        {options.map((option) => (
                          <option key={option.name}>{option.name}</option>
                        ))}
                      </select>
                    </Col>
                  </Row>

                  {updateSuccess && (
                    <Alert style={{ marginTop: '10px' }} color="success">
                      <strong> {t('Operation success')} </strong>
                    </Alert>
                  )}
                  <Button
                    style={{ marginTop: '10px' }}
                    color="success"
                    onClick={onSubmit}
                  >
                    {t('Update')}
                  </Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
