export const LIST = "GET_APPLICATIONS"
export const LIST_SUCCESS = "GET_APPLICATIONS_SUCCESS"
export const LIST_FAIL = "GET_APPLICATIONS_FAIL"

export const DELETE = "DELETE_APPLICATION"
export const DELETE_SUCCESS = "DELETE_APPLICATION_SUCCESS"
export const DELETE_FAIL = "DELETE_APPLICATION_FAIL"

export const UPDATE = "UPDATE_APPLICATION"
export const UPDATE_SUCCESS = "UPDATE_APPLICATION_SUCCESS"
export const UPDATE_FAIL = "UPDATE_APPLICATION_FAIL"

export const UPDATE_DOCS = "UPDATE_DOCS_APPLICATION"
export const UPDATE_DOCS_SUCCESS = "UPDATE_DOCS_APPLICATION_SUCCESS"
export const UPDATE_DOCS_FAIL = "UPDATE_DOCS_APPLICATION_FAIL"
