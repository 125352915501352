import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'
import Client from 'src/client'

import { getEntities } from 'src/store/actions'

const headers = {
  username: {},
  FIO: {},
  status: {},
  last_logout: {},
  last_login: {},
  collection_stage_id: {},
}

const actionsEnabled = true

export const Collectors = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { list, listLoading, listError } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.COLLECTORS].list,
      listLoading: state.entities[Client.ENTITY_TYPE.COLLECTORS].listLoading,
      listError: state.entities[Client.ENTITY_TYPE.COLLECTORS].listError,
    }
  })

  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.COLLECTORS))
  }, [dispatch])

  const title = t('Collectors')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <div className="table-responsive table-card mt-4">
              <Table hover className="table-sm align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    {/*<th/>*/}
                    {Object.keys(headers).map((header) => (
                      <th className="ps-3" key={header} scope="col">
                        {t(headers[header].label || header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope="col">{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          {/*<td style={{width: '1%'}}>*/}
                          {/*    <Link to={`/segments-view?id=${entity.id}`} className="fw-medium">*/}
                          {/*        <i className="ri-eye-line fs-15"/>*/}
                          {/*    </Link>*/}
                          {/*</td>*/}
                          {Object.keys(headers).map((fieldName) => {
                            if (fieldName === 'id')
                              return <td className="ps-3">{entity.id}</td>
                            if (fieldName === 'FIO')
                              return (
                                <td className="ps-3">{`${entity?.surname} ${entity?.name} ${entity?.midname}`}</td>
                              )
                            return (
                              <td className="ps-3" key={fieldName}>
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  headers,
                                )}
                              </td>
                            )
                          })}
                          {actionsEnabled && (
                            <td>
                              <div className="hstack gap-3 flex-wrap">
                                <Link
                                  to={`/collectors-edit?id=${entity.id}`}
                                  className="link-success fs-15"
                                >
                                  <i className="ri-edit-2-line"></i>
                                </Link>
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
