import * as Yup from 'yup'
import { TFunction } from 'i18next'

export const getPromocodeValidationSchema = (t: TFunction) =>
  Yup.object({
    sale: Yup.number().required(t('Field is required')),
    promocode: Yup.string().required(t('Field is required')),
    type: Yup.number().required(t('Field is required')),
    limitless: Yup.boolean().required(t('Field is required')),
    available_usages: Yup.number().when('limitless', {
      is: (value: boolean) => value === false,
      then: (schema) =>
        schema
          .required(t('Field is required'))
          .min(0, t('Cannot be less than zero')),
      otherwise: (schema) => schema.nullable()
    }),
    total_usage_limit: Yup.number().when('limitless', {
      is: (value: boolean) => value === false,
      then: (schema) =>
        schema
          .required(t('Field is required'))
          .min(0, t('Cannot be less than zero')),
      otherwise: (schema) => schema.nullable()
    })
  })
