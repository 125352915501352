import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import List from 'src/Components/Common/EntitiesView/List'
import LimitNotice from 'src/Components/Common/LimitNotice'
import MetaBar from 'src/Components/Common/MetaBar'
import model from 'src/model'
import { useGetPromotionsQuery } from 'src/services/promotions'

export const Promotions = () => {
  const { data: promotions, error, isError } = useGetPromotionsQuery()

  const { t } = useTranslation()

  const title = t('Promotions')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  if (!promotions) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={'/promotion-edit'}
            backLink={undefined}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <LimitNotice limit={model.LIST_LIMIT} />
          <List
            list={promotions.map((promotion) => ({
              ...promotion,
              id_link: `/promotion-view?id=${promotion.id}`,
            }))}
            className={undefined}
            style={{ width: 'fit-content' }}
            fieldsDescription={{
              id: {
                label: ' ',
                mappingValue: () => <i className="ri-eye-line fs-15" />,
                isLink: true,
                linkFieldName: 'id_link',
              },
              promo_name: {},
              main_body_writeoff: {
                mappingValue: (floatValue: number | null) =>
                  floatValue ? floatValue * 100 : 0,
              },
              interest_writeoff: {
                mappingValue: (floatValue: number | null) =>
                  floatValue ? floatValue * 100 : 0,
              },
              fines_writeoff: {
                mappingValue: (floatValue: number | null) =>
                  floatValue ? floatValue * 100 : 0,
              },
              interest_freezing: {
                mappingValue: (value: boolean) => t(`${value}`),
              },
              fines_freezing: {
                mappingValue: (value: boolean) => t(`${value}`),
              },
              offer_duration: {},
            }}
            hover
            actions={undefined}
          />
        </CardBody>
      </Card>
    </div>
  )
}
