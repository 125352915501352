import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import { OffsetNotice } from 'src/Components/Common/LimitNotice'
import List from 'src/Components/Common/EntitiesView/List'
import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'

import model from 'src/model'
import Client from 'src/client'

import { useTranslation } from 'react-i18next'

import { getEntities } from 'src/store/actions'

const debounceTimers = {}
const DEBOUNCE_TIMEOUT = 2000

export const Debts = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [strFilter, setStrFilter] = useState('')
  const [fieldsFilter, setFieldsFilter] = useState({})
  const [offset] = useState(0)
  const [filter, setFilter] = useState([])
  const [sortOrder, setSortOrder] = useState({
    issue_date: 'desc',
    repayment_date: 'desc',
    amount_debt: 'desc',
    term: 'desc',
    dpd: 'desc'
  })

  const { list, listLoading, listError } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.DEBTS].list,
      listLoading: state.entities[Client.ENTITY_TYPE.DEBTS].listLoading,
      listError: state.entities[Client.ENTITY_TYPE.DEBTS].listError
    }
  })

  useEffect(() => {
    if (list) {
      setFilter(list)
    }
  }, [list])

  const sortByValue = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc'
    }
    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return a[keyName] - b[keyName]
      } else {
        return b[keyName] - a[keyName]
      }
    })
    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  const sortByDate = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc'
    }
    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return new Date(a[keyName]) - new Date(b[keyName])
      } else {
        return new Date(b[keyName]) - new Date(a[keyName])
      }
    })
    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  const fieldsDescription = {
    id: {
      label: ' ',
      mappingValue: () => <i className='ri-eye-line fs-15' />,
      isLink: true,
      linkFieldName: 'id_link'
    },
    status: {},
    fio: {},
    phone: {},
    issue_date: { isDate: true, onClick: () => sortByDate('issue_date') },
    dpd: { rightAligned: true, onClick: () => sortByValue('dpd') },
    last_login: { isDate: true, onClick: () => sortByDate('last_login') },
    amount_debt: {
      isAmount: true,
      rightAligned: true,
      onClick: () => sortByValue('amount_debt')
    },
    extension_available: {},
    timezone: {},
    request_id: {
      label: 'request_id_debts',
      mappingValue: () => <i className='ri-file-edit-line fs-15' />,
      isLink: true,
      linkFieldName: 'application_link'
    },
    profile_id: {
      label: 'profile',
      mappingValue: () => <i className='ri-account-circle-line fs-15' />,
      isLink: true,
      linkFieldName: 'profile_link'
    }
  }

  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.DEBTS, null, '', 0))
  }, [dispatch])

  useEffect(() => {
    if (!strFilter) return

    clearTimeout(debounceTimers[`filtered-entities-${strFilter}`])
    debounceTimers[`filtered-entities-${strFilter}`] = setTimeout(() => {
      console.log(`request list with ${strFilter}`)
      dispatch(getEntities(Client.ENTITY_TYPE.DEBTS, null, strFilter, 0))
    }, DEBOUNCE_TIMEOUT)

    return () => {
      if (debounceTimers[`filtered-entities-${strFilter}`]) {
        clearTimeout(debounceTimers[`filtered-entities-${strFilter}`])
      }
    }
  }, [strFilter])

  useEffect(() => {
    if (Object.keys(fieldsFilter).length === 0) return

    const fieldsFilterJson = JSON.stringify(fieldsFilter)
    clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`])
    debounceTimers[`filtered-entities-${fieldsFilterJson}`] = setTimeout(() => {
      console.log(`request list with ${fieldsFilterJson}`)
      if (fieldsFilter.gender)
        fieldsFilter.gender = model.getGenderCode(fieldsFilter.gender)
      dispatch(getEntities(Client.ENTITY_TYPE.DEBTS, null, fieldsFilter, 0))
    }, DEBOUNCE_TIMEOUT)

    return () => {
      if (debounceTimers[`filtered-entities-${fieldsFilterJson}`]) {
        clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`])
      }
    }
  }, [fieldsFilter])

  console.log('loans =>', list)
  const title = t('Debts')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  const bread = <BreadCrumb title={title} />
  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            <div className='live-preview'>
              <div className='table-responsive table-card'>
                {false && (
                  <div className='gridjs-head'>
                    <div className='gridjs-search'>
                      <input
                        type='search'
                        className='gridjs-input gridjs-search-input'
                        id='iconInput'
                        placeholder='Search...'
                        onChange={(event) => {
                          const filter = event.target.value
                          setStrFilter(filter)
                        }}
                      />
                    </div>
                  </div>
                )}

                <FieldsFilter
                  fields={{
                    loan_id: {},
                    status: { options: ['Opened', 'Closed'] },
                    application_id: {},
                    client_id: {},
                    profile_id: {},
                    phone: {},
                    email: {},
                    group_delimiter: {},
                    first_name: {},
                    last_name: {},
                    middle_name: {},
                    birth_date: { date: true },
                    gender: { options: ['male', 'female'] },
                    prolongation_count: {},
                    prolongation_count_min: {},
                    prolongation_count_max: {},
                    num_of_loan: {},
                    count_paid: {},
                    day_past_due_promise: {},
                    amount_principal_accrued_min: {},
                    amount_principal_accrued_max: {},
                    login_date_min: { date: true },
                    login_date_max: { date: true },
                    income_min: {},
                    income_max: {}
                  }}
                  applyFilter={(values) => setFieldsFilter(values)}
                />
                <OffsetNotice offset={offset} limit={model.LIST_LIMIT} />
                {listLoading ? (
                  <Loader />
                ) : (
                  <List
                    style={{ width: 'fit-content' }}
                    list={filter.map((item) => ({
                      ...item,
                      id_link: `/loan-view?id=${item.id}`,
                      profile_link: `/profile-view?id=${item.profile_id}`,
                      application_link: `/application-view?id=${item.request_id}`,
                      extension_available:
                        item.product_copy?.extension_setting?.extension_enabled,
                      extension_status: item.extensions?.status
                    }))}
                    fieldsDescription={fieldsDescription}
                    className='table-sm align-middle table-nowrap mb-0'
                    hover
                  />
                )}
                {/* <div
                  className="btn-group gap-2 p-3"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn btn-primary d-flex align-items-center"
                    disabled={offset === 0}
                    onClick={prev}
                  >
                    <i className="ri-arrow-left-line fs-15 me-2" />
                    {`${t("Prev")} ${model.LIST_LIMIT}`}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary d-flex align-items-center"
                    disabled={list?.length < model.LIST_LIMIT}
                    onClick={next}
                  >
                    {`${t("Next")} ${model.LIST_LIMIT}`}
                    <i className="ri-arrow-right-line fs-15 ms-2" />
                  </button>
                </div> */}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
