import { useTranslation } from 'react-i18next'
import List from './List'
import Client from 'src/client'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

import { useGetClientSignedDocumentsQuery } from 'src/services/clients'

const fieldsDescription = {
  type: {},
  signed_at: { isDateTime: true },
}

export const ClientSignedDocuments = ({ id, style, visible }: any) => {
  const { t } = useTranslation()

  const download = (doc: any) => {
    Client.getSignedDocuments(doc.doc_id)
      .then((file) => file.blob())
      .then((blob) => window.URL.createObjectURL(blob))
      .then((url) => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${doc.doc_name}.html`
        a.click()
        document.body.removeChild(a)
      })
      .catch((err) => console.log(err))
  }

  const { data: signedDocuments, error } = useGetClientSignedDocumentsQuery(
    id,
    {
      skip: !visible || id == null,
    },
  )

  if (signedDocuments?.length === 0)
    return (
      <div style={style}>
        <dl className="row mb-0">
          <small>{t('No_signed_documents')}</small>
        </dl>
      </div>
    )

  if (error) return <DangerAlert error={error} />

  return (
    signedDocuments && (
      <List
        hover
        list={signedDocuments.map((doc) => ({
          ...doc,
          onPress: () => download(doc),
        }))}
        fieldsDescription={fieldsDescription}
        style={style}
        // @ts-expect-error TODO: check if this property is used at all
        onRowPress={download}
      />
    )
  )
}
