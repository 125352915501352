import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
} from 'reactstrap'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import { useSetConsetLoanMutation } from 'src/services/loans'

export default function ConsentAcceptante(props: {
  loanId: string
  loanConsent?: boolean | null
}) {
  const { loanId, loanConsent } = props
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [consentLoan, { isError, isSuccess, isLoading }] =
    useSetConsetLoanMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      receipt_date: '',
    },
    validationSchema: Yup.object().shape({
      receipt_date: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      consentLoan({ loanId: loanId, body: values })
    },
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Button
        size="sm"
        color="primary"
        style={{ marginTop: '10px', marginBottom: '5px', marginLeft: '10px' }}
        onClick={toggle}
        disabled={loanConsent === false}
      >
        {t('Revocation of consent to non-acceptance')}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          {t('Revocation of consent to non-acceptance')}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label htmlFor="receipt_date">{t('receipt_date')}</Label>
              <Flatpickr
                name="receipt_date"
                id="receipt_date"
                type="date"
                className="form-control form-control-sm"
                options={{
                  dateFormat: 'Y-m-d',
                  onChange: (selectedDates: any, dateStr: any) => {
                    console.log('dateStr', dateStr)
                    formik.setFieldValue('receipt_date', dateStr)
                  },
                }}
              />
            </FormGroup>
            <Button
              type="submit"
              color="primary"
              style={{ backgroundColor: '#405189' }}
              disabled={!formik.values.receipt_date || isLoading}
            >
              {t('Send')}
            </Button>
            <br />
            {isSuccess && (
              <Alert color="success">{t('Operation success')}</Alert>
            )}
            {isError && <Alert color="warning">{t('Access Error')}</Alert>}
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
