import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  ModalHeader,
  Label,
  Input
} from 'reactstrap'
import Loader from 'src/Components/Common/Loader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDeleteCallSettingsMutation } from 'src/services/calling'
import { Link } from 'react-router-dom'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from '../ErrorPage'

interface DeleteSettingCallResultProps {
  client_type: string
  result_type: string
  className?: string
}

export const DeleteSettingCallResult: FC<DeleteSettingCallResultProps> = ({
  client_type,
  result_type,
  className
}) => {
  const { t } = useTranslation()
  const { isOpen, open, close, toggle } = useDisclosure(false)

  const [deleteCallSettings, { isLoading, error }] =
    useDeleteCallSettingsMutation()

  const toggleModal = () => {
    if (isOpen) {
      validation.resetForm()
    }
    toggle()
  }

  const validation = useFormik({
    initialValues: {
      name: result_type
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Required'))
    }),
    onSubmit: async () => {
      await deleteCallSettings({ client_type, result_type })
      if (!error) {
        close()
      }
    }
  })

  return (
    <div className={`d-flex ${className}`}>
      <Link to='#' color='link' className='link-danger fs-15' onClick={open}>
        <i className='ri-delete-bin-line'></i>
      </Link>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          {t('Delete call result')}
        </ModalHeader>
        <ModalBody>
          <Form className='needs-validation' onSubmit={validation.handleSubmit}>
            <FormGroup className='mb-3'>
              <Label htmlFor='person'>{t('Call result')}</Label>
              <Input type='text' disabled value={result_type}></Input>
            </FormGroup>
            {!!error && <DangerAlert error={error} />}
            {isLoading ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                color='primary'
                style={{ backgroundColor: '#405189' }}
              >
                {t('Delete')}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}
