import { api, omitBlankEntries } from '../api'

export interface VerificationsListResponse {
  verification_id: string
  created_at: string
  updated_at: string | null
  verification_status: string
  profile_id: string
  application_id: string
  client_id: string
  surname: string
  name: string
  midname: string
  phone: string
  email: string
  status: string
  requested_period: number
  requested_amount: number
  verifier_username: string | null
  verifier_surname: string | null
  verifier_name: string | null
  verifier_midname: string | null
}

//TODO: add types after mvp
// kpk_v_id => kpk verification id (id application from kpk application list)
const verificationKpkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    //get all statuses
    getVerificationStatus: builder.query<any, any>({
      query: () => `crm/v1/verifications/statuses`
    }),
    //get list for verification
    getVerificationsList: builder.mutation<VerificationsListResponse, any>({
      query: (params) => ({
        url: `crm/v1/verifications`,
        method: 'GET',
        params: omitBlankEntries(params)
      })
    }),
    //get application for verif by id
    getVerificationsById: builder.query<any, any>({
      query: (kpk_v_id) => `crm/v1/verifications/${kpk_v_id}`
    }),
    //assing to work
    assingApplicationById: builder.mutation<any, any>({
      query: (kpk_v_id) => ({
        url: `crm/v1/verifications/assign/${kpk_v_id}`,
        method: 'POST'
      })
    }),
    //un assign
    unassingApplicationById: builder.mutation<any, any>({
      query: (kpk_v_id) => ({
        url: `crm/v1/verifications/assign/${kpk_v_id}`,
        method: 'POST'
      })
    }),
    //save kpk verification
    saveVerificationStatusKPK: builder.mutation<any, any>({
      query: ({ verificationId, payload }) => ({
        url: `crm/v1/verifications/${verificationId}`,
        method: 'POST',
        body: payload
      })
    }),
    //get documents list
    getDocsByProfile: builder.mutation<any, any>({
      query: (profile_id) => ({
        url: `crm/v1/documents/form-cards/profile/${profile_id}`,
        method: 'GET'
      })
    }),
    //get documents by id
    getDocsById: builder.mutation<any, string>({
      query: (fileId) => ({
        url: `crm/v1/documents/${fileId}`,
        method: 'GET',
        responseHandler: (response) => response.blob()
      })
    }),
    getApplicationStatuses: builder.query<unknown[], string>({
      query: (id) => `crm/v1/roles/verifications/applications/${id}/decide`
    })
  })
})

export const {
  useGetVerificationStatusQuery,
  useGetVerificationsListMutation,
  useGetVerificationsByIdQuery,
  useAssingApplicationByIdMutation,
  useGetApplicationStatusesQuery,
  useSaveVerificationStatusKPKMutation,
  useGetDocsByProfileMutation,
  useGetDocsByIdMutation
} = verificationKpkApi
