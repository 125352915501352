import { api, omitBlankEntries } from './api'

type ClaimChild = {
  client_id: string | null
  id: string
  profile_id: string | null
  channel_type:
    | 'email'
    | 'mail'
    | 'phone'
    | 'sms'
    | 'messenger'
    | 'widget'
    | 'lk'
    | 'landing'
    | 'proxy'
  created_at: string
  updated_at: string | null
  assignee_id: string | null
  status:
    | 'Получено'
    | 'В работе'
    | 'Запрошены данные'
    | 'Закрыто'
    | 'Темы обработаны'
    | 'Темы назначены'
    | 'На проверке'
  comment: string
  claim_topic: string
  claim_body: string
  claim_topic_id: number | null
  claim_subtopic_id: number | null
  claim_topic_name: string | null
  claim_subtopic_name: string | null
  repeated: boolean
  claim_url: string
  result: string | null
  days_to_reply: number | null
  claim_number: string
  client_surname: string | null
  client_name: string | null
  client_midname: string | null
  autoassign: boolean
  new: boolean
  is_complaint: boolean
  parent_claim_uuid: string | null
  client_email: string
  email_from: string | null
  is_fraud: boolean
  fraud_comment: string
  email_to: string
  closed_at: string | null
  attachments: AttachmentMessage[]
  answer_type: string | null
}

interface IClaim extends ClaimChild {
  proxy_claims: ClaimChild[] | null
}

type AddClaimMain = {
  email_from: string | null
  client_id: string | null
  claim_topic: string
  claim_body: string
  comment: string
  repeated: boolean
  is_complaint: boolean
}

type Topic = {
  id: number
  parent_topic_id: number | null
  priority: number | null
  topic_name: string
  roles: string
}

type TemplatesEmail = {
  templates: string[]
}

export type SendEmail = {
  title: string
  templates_name_list: string[]
  attachments: UploadAttachment[]
  text: string
  proxy_claims: {
    id: string
    result: string
  }[]
  custom_email: string
  result: string | null
  answer_type: string | null
}

export type UploadAttachment = {
  file_type: string
  file_name: string
  file_path: string
}

type AttachmentMessage = {
  id: number
  created_at: string
  updated_at: string | null
  email_id: number
  file_type: string
  file_name: string
  file_vault_id: string
}

type EmailMessages = {
  id: number
  created_at: string
  updated_at: string
  profile_id: string
  session_id: string
  claim_id: string
  form_id: number
  email: string
  type_id: number
  subject: string
  text: string
  service_id: number
  request_data: string
  response_code: number
  response_data?: {
    code: string
    message: string
  }
  author_username: string
  attachments: AttachmentMessage[]
}

type AssignTopics = {
  topics: {
    topic_id: number
    subtopic_id: number
    is_complaint: boolean
    repeated: boolean
    comment: string
  }[]
}

type FraudCommit = {
  is_fraud: boolean
  fraud_comment: string
}

type ClaimManager = {
  id: string
  username: string
  status: string
  surname: string
  name: string
  midname: string
}

type Insolvent = {
  clientId: string
  typeOfBankruptcy: string
  considerationStage: string
  decisionDate: string
}

export const claimsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addClaim: builder.mutation<AddClaimMain, Partial<AddClaimMain>>({
      query: (body) => ({
        url: `crm/v1/claims`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      // could potentially do `providesTags` if backend returns a new claim or id
      invalidatesTags: () => ['Claims']
    }),
    getClaim: builder.query<
      IClaim,
      {
        id: string
      }
    >({
      query: ({ id, ...params }) => ({
        url: `crm/v1/claims/${id}`,
        params: omitBlankEntries(params)
      }),
      providesTags: (_result, _err, { id }) => [{ type: 'Claims', id }]
    }),
    updateClaim: builder.mutation<IClaim, Partial<IClaim>>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/claims/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: (claim) => [{ type: 'Claims', id: claim?.id }]
    }),
    getClaims: builder.query<
      IClaim[],
      Partial<{
        limit: number
        offset: number
        status: IClaim['status']
        chanelType: string
        assigneeId: string
        assigneeUsername: string
        claimTopicId: number
        claimSubtopicId: number
        planDate: string
        claimNumber: string
        clientId: string
        id: string
        createdAt: string
        updatedAt: string
        emailTo: string
        emailFrom: string
        closedAt: string
      }>
    >({
      query: (params) => ({
        url: 'crm/v1/claims',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Claims', id }) as const),
        { type: 'Claims' as const, id: 'LIST' }
      ]
    }),
    getMyClaims: builder.query<
      IClaim[],
      Partial<{
        status: IClaim['status']
        channelType: string
        limit: number
        offset: number
        claimTopicId: number
        claimSubtopicId: number
        planDate: string
        claimNumber: string
        clientId: string
        id: string
        createdAt: string
        updatedAt: string
        emailTo: string
        emailFrom: string
      }>
    >({
      query: (params) => ({
        url: 'crm/v1/claims/my',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Claims', id }) as const),
        { type: 'Claims' as const, id: 'LIST' }
      ]
    }),
    addMainClaimTopic: builder.mutation<
      IClaim,
      { id: string; body: AssignTopics }
    >({
      query: ({ id, body }) => ({
        url: `crm/v1/claims/${id}/assign-topics`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (claim) => [{ type: 'Claims', id: claim?.id }]
    }),
    addMainCliamForceTopic: builder.mutation<
      IClaim,
      { id: string; body: AssignTopics }
    >({
      query: ({ id, body }) => ({
        url: `/crm/v1/claims/${id}/force-add-topics`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (claim) => [{ type: 'Claims', id: claim?.id }]
    }),
    getAllTopic: builder.query<Topic[], Partial<Topic[]>>({
      query: (params) => ({
        url: 'crm/v1/claims/claim-topics',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Topic', id }) as const),
        { type: 'Claims' as const, id: 'LIST' }
      ]
    }),
    updateTopic: builder.mutation<Topic, Partial<Topic>>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/claims/claim-topics?topicId=${id}`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: (topic) => [{ type: 'Topic', id: topic?.id }]
    }),
    addTopic: builder.mutation<Topic, Partial<Topic>>({
      query: (body) => ({
        url: `crm/v1/claims/claim-topics`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      // could potentially do `providesTags` if backend returns a new topic or id
      invalidatesTags: (topic) => [{ type: 'Topic', id: topic?.id }]
    }),
    getTopic: builder.query<
      Topic,
      {
        id: string
      }
    >({
      query: ({ id, ...params }) => ({
        url: `crm/v1/claims/claim-topics/${id}`,
        params: omitBlankEntries(params)
      }),
      providesTags: (_result, _err, { id }) => [{ type: 'Topic', id }]
    }),
    getTemplatesEmail: builder.query<TemplatesEmail, any>({
      query: (params) => ({
        url: `crm/v1/claims/email-templates`,
        params: omitBlankEntries(params)
      }),
      providesTags: () => ['TemplatesEmail']
    }),
    setSendEmail: builder.mutation<void, { id: IClaim['id']; body: SendEmail }>(
      {
        query: ({ id, body }) => ({
          url: `crm/v1/claims/${id}/send-email`,
          method: 'POST',
          body: body
        }),
        invalidatesTags: () => ['Claims']
      }
    ),
    setPreviewDocx: builder.mutation<
      Blob,
      { id: string; body: { template_name: string } }
    >({
      query: ({ id, body }) => ({
        url: `crm/v1/claims/${id}/preview-docx`,
        method: 'POST',
        body: body,
        responseHandler: (response) => response.blob(),
        formData: true
      }),
      invalidatesTags: (result) => [{ type: 'PreviewDocx', result: result }]
    }),
    getUploadAttachment: builder.mutation<Blob, { id: number }>({
      query: ({ id }) => ({
        url: `crm/v1/claims/download-attachment/${id}`,
        method: 'GET',
        responseHandler: (response) => response.blob()
      })
    }),
    setUploadAttachment: builder.mutation<
      UploadAttachment,
      { id: string; file: File }
    >({
      query: ({ id, file }) => ({
        url: `crm/v1/claims/${id}/upload-attachment`,
        method: 'POST',
        body: file,
        formData: true
      }),
      invalidatesTags: (result) => [
        { type: 'UploadAttachment', result: result }
      ]
    }),
    getMessagesClaim: builder.query<
      EmailMessages[],
      Partial<{
        claim_id: string
        params: { includeProxy: boolean }
      }>
    >({
      query: ({ claim_id, params }) => ({
        url: `crm/v1/claims/${claim_id}/email-messages`,
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'EmailMessages', id }) as const),
        { type: 'EmailMessages' as const, id: 'LIST' }
      ]
    }),
    getReportClaim: builder.query<
      { username: string; closed: number }[],
      { dateFrom: string; dateTo: string }
    >({
      query: (params) => ({
        url: `crm/v1/claims/report`,
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(
          ({ username }) => ({ type: 'ReportClaims', username }) as const
        ),
        { type: 'ReportClaims' as const, id: 'LIST' }
      ]
    }),
    getReportClaimFile: builder.query<
      Blob,
      { dateFrom: string; dateTo: string }
    >({
      query: (params) => ({
        url: `/crm/v1/claims/report/excel`,
        params: omitBlankEntries(params),
        responseHandler: (response) => response.blob(),
        formData: true
      })
    }),
    getFraudCheck: builder.mutation<IClaim, { id: string }>({
      query: ({ id }) => ({
        url: `crm/v1/claims/${id}/fraud-check`,
        method: 'GET'
      }),
      invalidatesTags: (claim) => [{ type: 'Claims', id: claim?.id }]
    }),
    setFraudCommit: builder.mutation<
      IClaim,
      { id: string; params: FraudCommit }
    >({
      query: ({ id, params }) => ({
        url: `crm/v1/claims/${id}/fraud-commit`,
        body: omitBlankEntries(params),
        method: 'POST'
      }),
      invalidatesTags: (claim) => [{ type: 'Claims', id: claim?.id }]
    }),
    getAssignClient: builder.mutation<
      IClaim,
      { claim_id: string; params: { client_id: string | null } }
    >({
      query: ({ claim_id, params }) => ({
        url: `/crm/v1/claims/${claim_id}/assign-client`,
        method: 'POST',
        body: omitBlankEntries(params)
      }),
      invalidatesTags: (claim) => [{ type: 'Claims', id: claim?.id }]
    }),
    getClaimManager: builder.query<ClaimManager[], void>({
      query: () => ({
        url: '/crm/v1/claims/claim-managers'
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'ClaimManager', id }) as const),
        { type: 'ClaimManager' as const, id: 'LIST' }
      ]
    }),
    getClaimChannelTypes: builder.query<string[], void>({
      query: () => 'crm/v1/claims/channel-types'
    }),
    addBankrupt: builder.mutation<any, Insolvent>({
      query: (body) => ({
        url: '/crm/v1/claims/add_bankrupt',
        method: 'POST',
        body: body
      })
    })
  })
})

export const {
  useGetClaimQuery,
  useGetClaimsQuery,
  useGetMyClaimsQuery,
  useUpdateClaimMutation,
  useAddClaimMutation,
  useGetAllTopicQuery,
  useUpdateTopicMutation,
  useAddTopicMutation,
  useGetTopicQuery,
  useGetTemplatesEmailQuery,
  useSetSendEmailMutation,
  useAddMainClaimTopicMutation,
  useSetPreviewDocxMutation,
  useSetUploadAttachmentMutation,
  useGetMessagesClaimQuery,
  useAddMainCliamForceTopicMutation,
  useGetReportClaimQuery,
  useGetReportClaimFileQuery,
  useGetFraudCheckMutation,
  useSetFraudCommitMutation,
  useGetUploadAttachmentMutation,
  useGetAssignClientMutation,
  useGetClaimManagerQuery,
  useGetClaimChannelTypesQuery,
  useAddBankruptMutation
} = claimsApi
