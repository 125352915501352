import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  LIST,
  DELETE,
  UPDATE,
} from "./actionType";

import {
  getLoans,
  getLoansFail,
  getLoansSuccess,
  deleteLoanSuccess,
  deleteLoanFail,
  updateLoanSuccess,
  updateLoanFail,
} from "./action";

import Client from "../../client";

function* deleteEntity({ payload }) {
  try {
    const response = yield call(Client.deleteEntity, Client.ENTITY_TYPE.LOANS, payload);
    yield put(deleteLoanSuccess());
    yield put(getLoans());
  } catch (error) {
    yield put(deleteLoanFail(error));
  }
}

function* updateEntity({ payload }) {
  try {
    // const response = yield call(Client.updateEntity, Client.ENTITY_TYPE.LOANS, payload);
    if (payload?.prolongation) {
      const responseProlongation = yield call(Client.postLoanProlongation, payload.id, payload.prolongation);
    }
    if (payload?.writeoff) {
      const responseWriteOff = yield call(Client.postLoanWriteoff, payload.id, payload.writeoff);
    }
    yield put(updateLoanSuccess());
    yield put(getLoans(payload.id));
  } catch (error) {
    yield put(updateLoanFail(error));
  }
}

function* getList({ id, filter }) {
  try {
    if (id) {
      const loan = yield call(Client.getEntity, Client.ENTITY_TYPE.LOANS, id);

      //console.log('saga::loan', loan);
      if (loan.product_copy) {
        const product_original = yield call(Client.getEntity, Client.ENTITY_TYPE.PRODUCT, loan.product_copy.id);
        loan.product_original = product_original;
      }

      try {
        const card = yield call(Client.getLoanCard, loan.id);
        loan.loan_issue_card = card.data;
      } catch {
        loan.loan_issue_card = null;
      }

      const [
        profile,
        funds_transactions,
        //loan_tranches,
        operations,
        //loan_issue_card,
        documentsSigned,
        communications,
        persons,
      ] = yield all([
        call(Client.getEntity, Client.ENTITY_TYPE.PROFILES, loan.profile_id),
        call(Client.getLoanData, Client.ENTITY_TYPE.LOAN_PAYMENTS, loan.id),
        //call(Client.getLoanData, Client.ENTITY_TYPE.LOAN_TRACNHES, loan.id),
        call(Client.getLoanData, Client.ENTITY_TYPE.LOAN_OPERATIONS, loan.id),
        //call(Client.getLoanCard, loan.id),
        call(Client.getLoanSignedDocs, loan.id),
        call(Client.getCommunications, loan.profile_id),
        call(Client.getPersons),
      ]);

      loan.profile = profile || null;
      loan.funds_transactions = funds_transactions || [];
      //loan.loan_tranches = loan_tranches || [];
      loan.operations = operations || [];
      loan.documentsSigned = documentsSigned || [];
      loan.communications = communications || [];
      loan.persons = persons || [];
      //loan.loan_issue_card = loan_issue_card || null;

      yield put(getLoansSuccess([loan]));
    } else {
      const response = yield call(Client.getList, Client.ENTITY_TYPE.LOANS, filter);
      yield put(getLoansSuccess(response));
    }
  } catch (error) {
    yield put(getLoansFail(error));
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getList);
}

export function* watchOnDelete() {
  yield takeEvery(DELETE, deleteEntity);
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateEntity);
}

function* loansSaga() {
  yield all([
    fork(watchOnGetList),
    fork(watchOnDelete),
    fork(watchOnUpdate),
  ]);
}

export default loansSaga;
