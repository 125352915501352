import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PropsWithDisclosure } from 'src/ModalDisclosureProvider'
import { ExtensionType } from 'src/services/extension-settings'
import { omit } from 'src/utils'

export const EditExtensionTypeForm: FC<
  PropsWithDisclosure<{
    extensionType?: ExtensionType
  }>
> = ({ extensionType, ...props }) => {
  const { t } = useTranslation()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      enable: false,
      min_amount: 0,
      max_amount: 0,
      min_dpd: 0,
      max_dpd: 0,
      min_loans: 0,
      max_loans: 0,
      ...omit(extensionType, ['id'])
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Field is required')),
      enable: Yup.boolean()
        .oneOf([true, false], t('Select true or false'))
        .required(t('Field is required')),
      min_amount: Yup.number()
        .min(0, t('Must be greater than or equal to 0'))
        .required(t('Field is required'))
        .when('max_amount', (max_amount, schema) =>
          max_amount
            ? schema.max(
                max_amount,
                t('Minimum amount must be less than or equal to maximum amount')
              )
            : schema
        ),
      max_amount: Yup.number()
        .min(
          Yup.ref('min_amount'),
          t('Maximum amount must be greater than or equal to minimum amount')
        )
        .required(t('Field is required')),
      min_dpd: Yup.number()
        .min(0, t('Must be greater than or equal to 0'))
        .required(t('Field is required'))
        .when('max_dpd', (max_dpd, schema) =>
          max_dpd
            ? schema.max(
                max_dpd,
                t('Minimum DPD must be less than or equal to maximum DPD')
              )
            : schema
        ),
      max_dpd: Yup.number()
        .min(
          Yup.ref('min_dpd'),
          t('Maximum DPD must be greater than or equal to minimum DPD')
        )
        .required(t('Field is required')),
      min_loans: Yup.number()
        .min(0, t('Must be greater than or equal to 0'))
        .required(t('Field is required'))
        .when('max_loans', (max_loans, schema) =>
          max_loans
            ? schema.max(
                max_loans,
                t('Minimum loans must be less than or equal to maximum loans')
              )
            : schema
        ),
      max_loans: Yup.number()
        .min(
          Yup.ref('min_loans'),
          t('Maximum loans must be greater than or equal to minimum loans')
        )
        .required(t('Field is required'))
    }),
    onSubmit: props.resolve
  })
  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Label for='name'>{t('Name')}</Label>
        <Input
          id='name'
          name='name'
          type='text'
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.name}
        />
        <FormFeedback>{formik.errors.name}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for='enable'>{t('enabled')}</Label>
        <Input
          name='enable'
          type='checkbox'
          checked={formik.values.enable}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.enable}
        />
        <FormFeedback>{formik.errors.enable}</FormFeedback>
      </FormGroup>
      <Row>
        <Col md={12}>
          <Label>{t('amount')}</Label>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for='min_amount'>{t('min')}</Label>
            <Input
              id='min_amount'
              name='min_amount'
              type='number'
              value={formik.values.min_amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!formik.errors.min_amount}
            />
            <FormFeedback>{formik.errors.min_amount}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for='max_amount'>{t('max')}</Label>
            <Input
              id='max_amount'
              name='max_amount'
              type='number'
              value={formik.values.max_amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!formik.errors.max_amount}
            />
            <FormFeedback>{formik.errors.max_amount}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label>{t('dpd')}</Label>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for='min_dpd'>{t('min')}</Label>
            <Input
              id='min_dpd'
              name='min_dpd'
              type='number'
              value={formik.values.min_dpd}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!formik.errors.min_dpd}
            />
            <FormFeedback>{formik.errors.min_dpd}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for='max_dpd'>{t('max')}</Label>
            <Input
              id='max_dpd'
              name='max_dpd'
              type='number'
              value={formik.values.max_dpd}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!formik.errors.max_dpd}
            />
            <FormFeedback>{formik.errors.max_dpd}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label>{t('loan_short_name')}</Label>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for='min_loans'>{t('min')}</Label>
            <Input
              id='min_loans'
              name='min_loans'
              type='number'
              value={formik.values.min_loans}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!formik.errors.min_loans}
            />
            <FormFeedback>{formik.errors.min_loans}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for='max_loans'>{t('max')}</Label>
            <Input
              id='max_loans'
              name='max_loans'
              type='number'
              value={formik.values.max_loans}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!formik.errors.max_loans}
            />
            <FormFeedback>{formik.errors.max_loans}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Button type='submit' color='primary' block disabled={!formik.isValid}>
        {t('Save')}
      </Button>
    </Form>
  )
}
