import { useTranslation } from 'react-i18next'
import { useGetProfileNotificationsQuery } from 'src/services/profiles'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

import List from './List'

const fieldsDescription = {
  created_at: { isDateTime: true },
  text: {},
}

const EntityContent = ({ profile_id, style }) => {
  const { t } = useTranslation()

  const { data: list, error } = useGetProfileNotificationsQuery(
    {
      profile_id,
    },
    { skip: profile_id == null },
  )

  if (error) return <DangerAlert error={error} />

  if (list?.length === 0)
    return (
      <div style={style}>
        <dl className="row mb-0">
          <small>{t('No_notifications')}</small>
        </dl>
      </div>
    )

  return (
    list && (
      <List list={list} fieldsDescription={fieldsDescription} style={style} />
    )
  )
}

export default EntityContent
