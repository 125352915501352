import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import EntityInfo from 'src/Components/Common/EntityInfo'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'

import { getProductExtensions } from 'src/store/actions'

import { Row, Card, CardBody } from 'reactstrap'

const enableEdit = true

export const ProductExtensionView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const { entity, listLoading, listError } = useSelector((state) => ({
    entity:
      state.productExtensions.list && state.productExtensions.list.length
        ? state.productExtensions.list[0]
        : null,
    listLoading: state.productExtensions.listLoading,
    listError: state.productExtensions.listError,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    if (id) dispatch(getProductExtensions(id))
    else setParamError('No entity id found')
  }, [window.location])

  useEffect(() => {
    setPageReady(!!entity)
  }, [entity])

  const title = `${t('extensions_settings')}`
  document.title = title
  const bread = <BreadCrumb title={title} />

  if (paramError || listError) {
    return (
      <ErrorPage
        backLink={'/product-extensions'}
        title={title}
        error={[paramError, listError]}
      />
    )
  }

  if (!pageReady || listLoading) {
    return <LoadingPage backLink={'/product-extensions'} title={title} />
  }

  const metaBar = (
    <MetaBar
      backLink={'/product-extensions'}
      editLink={
        enableEdit && entity ? `/product-extensions-edit?id=${entity.id}` : null
      }
      entity={entity}
    />
  )

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-0">
              <EntityInfo
                entity={entity}
                entityId={entity.id}
                entityType={Client.ENTITY_TYPE.EXTENSIONS}
              />
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
