import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Input, Row, Col, Label } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import model from 'src/model'
import Client from 'src/client'
import { useGetCompanyNamesQuery } from 'src/services/companies'

const fieldsDescription = {
  template: {
    label: 'template',
    inputType: 'textarea',
    options: ['sms', 'phonerobot', 'chat'],
  },
  text_template: {
    label: 'text template',
    inputType: 'textarea',
    options: ['email'],
  },
  html_template: {
    label: 'html template',
    inputType: 'textarea',
    options: ['email'],
  },
  subject: { label: 'subject', options: ['email'] },
  preferable_sender_id: {
    label: 'preferable_sender_id',
    options: ['sms', 'phonerobot', 'chat', 'email'],
  },
  is_service: {
    label: 'is_service',
    options: ['sms', 'phonerobot', 'chat', 'email'],
  },
}

export const TemplateView = () => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [type, setType] = useState(null)
  const [state, setState] = useState([])
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)

  const { data: companyNames } = useGetCompanyNamesQuery({})

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    const type = params.get('type')
    setId(id || null)
    setType(type || null)
    if (id) Client.getTemplate(type, id).then((data) => setState(data))
    else setParamError('No template id found')
  }, [window.location])

  useEffect(() => {
    if (!state) return
    setPageReady(true)
  }, [state])

  const title = `${t('templates')}: ${t('View')}`

  if (paramError) {
    return (
      <ErrorPage
        backLink={'/strategy/templates'}
        title={title}
        error={[paramError]}
      />
    )
  }

  if (!pageReady) {
    return <LoadingPage backLink={'/strategy/templates'} title={title} />
  }
  return (
    <div className="page-content">
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={'/strategy/templates'}
            editLink={
              state
                ? `/strategy/templates-edit?type=${type}&id=${state.id}`
                : null
            }
            entity={state}
          />
          <Row className="mt-3">
            {Object.keys(fieldsDescription).map((fieldName) => {
              const optionsValue = fieldsDescription[fieldName].options
              const preferableValue = companyNames?.filter(
                (el) => el.id === state.preferable_sender_id,
              )
              if (optionsValue?.includes(type))
                return (
                  <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                    <Label htmlFor={fieldName}>
                      {t(fieldsDescription[fieldName].label || fieldName)}
                    </Label>
                    {fieldName === 'preferable_sender_id' ? (
                      <Input
                        type={fieldsDescription[fieldName].inputType || 'text'}
                        className="form-control form-control-sm"
                        value={
                          preferableValue ? preferableValue[0].short_name : '-'
                        }
                        disabled
                      />
                    ) : (
                      <Input
                        type={fieldsDescription[fieldName].inputType || 'text'}
                        className="form-control form-control-sm"
                        value={model.fieldValueToText(
                          fieldName,
                          state,
                          fieldsDescription,
                        )}
                        disabled
                      />
                    )}
                  </Col>
                )
            })}
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
