import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,
} from "./actionType";

//get all
export const getProfiles = (id = null, filter = '') => ({
  type: LIST,
  id,
  filter,
})

export const getProfilesSuccess = entities => ({
  type: LIST_SUCCESS,
  payload: entities,
})

export const getProfilesFail = error => ({
  type: LIST_FAIL,
  payload: error,
})
