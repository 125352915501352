import { api, omitBlankEntries } from './api'

export type Client = {
  id: string
  last_name: string
  first_name: string
  middle_name: string
  gender: number
  birth_date: string
  occupation: string
  salary: number
}

type ClientSignedDocument = {
  id: number
}

type GetClientsParam = {
  client_id: string
  first_name: string
  middle_name: string
  last_name: string
  birth_date: string
  gender: number
  inn: string
  snils: string
  profile_id: string
  phone: string
  email: string
  offset: number
  limit: number
  keyword: string
}

export type Address = {
  beltway_hit: string
  capital_marker: string
  city: string
  city_area: string
  city_fias_id: string
  city_kladr_id: string
  city_type: string
  city_type_full: string
  city_with_type: string
  country: string
  country_iso_code: string
  federal_district: string
  fias_actuality_state: string
  fias_id: string
  fias_level: string
  flat: string
  flat_area: string
  flat_cadnum: string
  flat_fias_id: string
  flat_price: string
  flat_type: string
  flat_type_full: string
  geo_lat: string
  geo_lon: string
  geoname_id: string
  house: string
  house_cadnum: string
  house_fias_id: string
  house_kladr_id: string
  house_type: string
  house_type_full: string
  kladr_id: string
  metro: { line: string; name: string; distance: number }[]
  okato: string
  postal_code: string
  qc_geo: string
  region: string
  region_fias_id: string
  region_iso_code: string
  region_kladr_id: string
  region_type: string
  region_type_full: string
  region_with_type: string
  square_meter_price: string
  street: string
  street_fias_id: string
  street_kladr_id: string
  street_type: string
  street_type_full: string
  street_with_type: string
  tax_office: string
  tax_office_legal: string
  timezone: string
}

type ClientEntity = {
  addresses: {
    fact_address: Address
    work_address: Address
    reg_address: Address
  }
  addresses_label: {
    fact_address: string
    reg_address: string
    work_address: string
  }
  birth_date: string
  birth_place: string
  contact_person_name_1: string
  contact_person_phone_1: string
  created_at: string
  current_address_apt: string
  current_address_city: string
  current_address_house: string
  current_address_street: string
  docdate: string
  docorg: string
  docorgcode: string
  email: string
  extsalary: null | string | number
  fact_address_apt: string
  fact_address_city: string
  fact_address_house: string
  fact_address_street: string
  first_name: string
  gender: number
  id: string
  inn: string
  last_name: string
  middle_name: string
  number: string
  occupation: string
  phone: string
  phonework_add: null | string
  profile_id: string
  salary: number
  series: string
  snils: null | string
  typework: string
  work_address: string
  work_phone: string
}
type Document = {
  id?: string
  fileTypeId: string
  file_type: { name_eng?: string; id?: string }
  isNotCorrect: boolean
}

type DocumentsRes = { documents: Document[]; total: number }

type AddonRes = {
  created_at: string
  id: string
  short_name?: string
}

export type UnionClientEntity = ClientEntity &
  Pick<DocumentsRes, 'documents'> & {
    addon_loans?: Record<string, unknown>[] | Record<string, unknown>
    addon_applications?: Record<string, unknown>[] | Record<string, unknown>
    addon_payments?: Record<string, unknown>[] | Record<string, unknown>
  }

export type WorkDataType = {
  client_id: string
  typework: string | undefined
  placework: string | undefined
  occupation: string | undefined
  salary: number
  phonework: string | undefined
  extsalary?: number
}

export const clientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<Client[], Partial<GetClientsParam>>({
      query: (params) => ({
        url: 'crm/v1/clients',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Clients', id }) as const),
        { type: 'Clients' as const, id: 'LIST' }
      ]
    }),
    getClientSignedDocuments: builder.query<ClientSignedDocument[], string>({
      query: (id) => `crm/v1/clients/${id}/signed-documents`,
      providesTags: (result = []) => [
        ...result.map(
          ({ id }) => ({ type: 'ClientSignedDocument', id }) as const
        ),
        { type: 'ClientSignedDocument' as const, id: 'LIST' }
      ]
    }),
    deleteClient: builder.mutation({
      query: (id) => ({ url: `crm/v1/clients/${id}`, method: 'DELETE' }),
      invalidatesTags: () => ['Clients']
    }),
    getClientEntity: builder.query<ClientEntity, string | null>({
      query: (id) => `crm/v1/clients/${id}`,
      providesTags: (_, __, arg) =>
        arg ? [{ type: 'ClientEntity', id: arg }] : []
    }),
    getClientDocuments: builder.query({
      query: (id) => `crm/v1/documents/form-cards/client/${id}`,
      providesTags: (_, __, arg) =>
        arg ? [{ type: 'ClientDocuments', id: arg }] : []
    }),
    getClientAddon: builder.query<AddonRes, { id: string; type: string }>({
      query: ({ id, type }) => `crm/v1/clients/${id}/${type}`,
      providesTags: (_, __, arg) =>
        arg ? [{ type: 'ClientAddon', id: `${arg.id}${arg.type}` }] : []
    }),
    getFormByClientId: builder.mutation({
      query: (id) => ({
        url: `/crm/v1/clients/${id}/get-rendered-client-template`,
        method: 'GET',
        responseHandler: (response) => response.text()
      })
    }),
    updateClient: builder.mutation<unknown, FormData>({
      query: (formData) => ({
        formData: true,
        url: 'crm/v1/clients/forms-data',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: (_, __, arg) => {
        const id = arg.get('client_id') as string | null
        return id ? [{ type: 'ClientEntity', id: id }] : []
      }
    }),
    updateClientWork: builder.mutation<unknown, WorkDataType>({
      query: (formData) => ({
        url: 'crm/v1/clients/work-data',
        body: formData,
        method: 'POST'
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'ClientEntity', id: arg.client_id }
      ]
    }),
    updateClientAddress: builder.mutation({
      query: (formData) => ({
        url: 'crm/v1/clients/address-data',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'ClientEntity', id: arg.client_id }
      ]
    }),
    updateClientDocuments: builder.mutation({
      query: (formData) => ({
        url: '/crm/v1/clients/documents-data',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'ClientDocuments', id: arg.client_id }
      ]
    })
  })
})

export const {
  useGetClientsQuery,
  useGetClientSignedDocumentsQuery,
  useDeleteClientMutation,
  useGetClientEntityQuery,
  useGetClientDocumentsQuery,
  useGetClientAddonQuery,
  useGetFormByClientIdMutation,
  useUpdateClientMutation,
  useUpdateClientWorkMutation,
  useUpdateClientAddressMutation,
  useUpdateClientDocumentsMutation
} = clientsApi
