import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

import Loader from 'src/Components/Common/Loader'
import { UseQueryHookResultData } from 'src/services/api'
import {
  useGetClaimQuery,
  useGetUploadAttachmentMutation,
} from 'src/services/claims'
import { formatDateWithDash } from 'src/utils'
import {
  useGetCommunicationQuery,
  useGetCommunicationsQuery,
} from 'src/services/communications'

type Props = {
  claim?: UseQueryHookResultData<typeof useGetClaimQuery>
  communication?: UseQueryHookResultData<typeof useGetCommunicationsQuery>[0]
  active: string
}

export const HistoryClaimsView: FC<Props> = ({
  claim,
  communication,
  active,
}) => {
  const { t } = useTranslation()

  const [uploadAttachment] = useGetUploadAttachmentMutation()

  const downloadFile = (file: any) => {
    uploadAttachment({ id: file.id }).then((blob) => {
      if ('data' in blob) {
        const url = window.URL.createObjectURL(new Blob([blob.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${file.file_name}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    })
  }

  const { data: communicationExtra, isFetching: isCommunicationExtraFetching } =
    useGetCommunicationQuery(
      {
        id: communication?.communication_id!,
        type: 'email-outgoing',
      },
      {
        skip: communication?.communication_id == null || !communication?.type,
      },
    )

  if (isCommunicationExtraFetching) return <Loader />
  return (
    <ListGroup>
      {active === 'email-outgoing' && communicationExtra ? (
        <>
          <ListGroupItem>
            <Row>
              <Col>{t('ID')}</Col>
              <Col>{communicationExtra.id}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('profile_id')}</Col>
              <Col>{communicationExtra.profile_id}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('status')}</Col>
              <Col>{communicationExtra.status}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('created_at')}</Col>
              <Col>{formatDateWithDash(communicationExtra.created_at)}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('Email')}</Col>
              <Col>{communicationExtra.email}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('subject')}</Col>
              <Col>{communicationExtra.subject}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('text_response')}</Col>
              <Col>{communicationExtra.text}</Col>
            </Row>
          </ListGroupItem>

          {communicationExtra.attachments.length > 0 && (
            <>
              <ListGroupItem>
                <Row>
                  <Col>{t('template_file')}</Col>
                  <Col>
                    {communicationExtra.attachments.map((field) => (
                      <div className="d-flex flex-row gap-2" key={field.id}>
                        {field.file_name}
                        <div
                          role="button"
                          className="text-decoration-underline"
                          onClick={() => downloadFile(field)}
                        >
                          <small>{t('Download')}</small>
                        </div>
                      </div>
                    ))}
                  </Col>
                </Row>
              </ListGroupItem>
            </>
          )}
        </>
      ) : (
        <>
          <ListGroupItem>
            <Row>
              <Col>{t('ID')}</Col>
              <Col>
                <Link
                  to={`/main-claim-view?id=${claim?.id}`}
                  className="fw-medium"
                >
                  {claim?.id}
                </Link>
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('status')}</Col>
              <Col>{claim?.status}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('created')}</Col>
              <Col>{formatDateWithDash(claim?.created_at)}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('closed_at')}</Col>
              <Col>{formatDateWithDash(claim?.closed_at || undefined)}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('email_to')}</Col>
              <Col>{claim?.email_to}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('claim_topic')}</Col>
              <Col>{claim?.claim_topic}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('claim_body')}</Col>
              <Col>{claim?.claim_body}</Col>
            </Row>
          </ListGroupItem>
        </>
      )}
    </ListGroup>
  )
}
