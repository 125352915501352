import MessageBox from './MessageBox'

interface Props {
  id: number
  created_at: string
  updated_at: string | null
  chat_id: number
  author_type: string
  author_id: string
  content_type: 'text' | 'image'
  is_new: boolean
  content: string
}
export default function MessageList(props: { messages: Array<Props> }) {
  const { messages } = props

  return (
    <>
      {messages.map((item) => (
        <MessageBox
          key={item.id}
          content={item.content}
          contentType={item.content_type}
          time={item.created_at}
          author={item.author_type}
          isNew={item.is_new}
        />
      ))}
    </>
  )
}
