import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'

export default function InProgress(props: {
  reject: Function
  number: string
  hold: Function
  unHold: Function
}) {
  const { reject, number, hold, unHold } = props
  const [muted, setMuted] = useState(false)
  return (
    <div>
      <div>
        <div>
          Номер клиента: <strong>{number}</strong>
        </div>
        <div className="d-flex">
          <div style={{ marginRight: '10px' }}>Микрофон:</div>
          <div
            className={`bx ${
              muted ? 'bxs-microphone-off' : 'bxs-microphone'
            } fs-22`}
            onClick={() => {
              if (!muted) {
                setMuted(true)
                hold()
              } else {
                setMuted(false)
                unHold()
              }
            }}
          ></div>
        </div>
      </div>
      <br />
      <Button
        type="button"
        color="danger"
        onClick={() => {
          reject()
        }}
      >
        Завершить звонок
      </Button>
    </div>
  )
}
