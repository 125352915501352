import { useTranslation } from 'react-i18next'
import { useSelector } from 'src/store'
import { formatDateTime } from 'src/utils'
import { FC } from 'react'

const Footer: FC = () => {
  const { t } = useTranslation()
  const userInfo = useSelector((state) => state.Login.userInfo)
  return (
    <footer className='footer'>
      <div>
        <small>{new Date().getFullYear()} © Кибертех.</small>
      </div>
      <div>
        <small>
          {t('LastLogin')}: {formatDateTime(userInfo?.last_login!)}
        </small>
      </div>
    </footer>
  )
}

export default Footer
