import React from 'react'
import { Button, Input } from 'reactstrap'

export default function Connected(props: {
  reject: Function
  call: Function
  value: string
  onChange: any
}) {
  const { reject, call, value, onChange } = props
  return (
    <div>
      <div>Введите номер телефона, начиная с цифры 8</div>
      <div className="muted">Пример: 80000000000</div>
      <br />
      <Input
        placeholder="Номер телефона"
        value={value}
        onChange={(e) => {
          const inputValue = e.target.value
          if (inputValue.length <= 11) {
            onChange(inputValue)
          } else {
            // Если введено больше 11 символов, обрезаем значение
            onChange(inputValue.slice(0, 11))
          }
        }}
      />
      <br />
      <Button
        type="button"
        color="success"
        onClick={() => {
          call()
        }}
      >
        Вызов
      </Button>
    </div>
  )
}
