import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bounce, toast, ToastContainer } from 'react-toastify'
import { Table } from 'reactstrap'
import {
  useAssingApplicationByIdMutation,
  VerificationsListResponse
} from 'src/services/kpk/verification'
import { formatDateTime, formatAmount } from 'src/utils'
import { VerifModalKPK } from './VerifModalKPK'

interface VerificationsTableProps {
  entity: VerificationsListResponse[]
}

function valueAlign(header: any) {
  if (header.right) return 'right'
  return 'left'
}
const minimizeTextStyle = {
  textOverflow: 'ellipsis',
  maxWidth: 200,
  overflow: 'hidden'
}
function headerStyle(header: any) {
  let textAlign = 'start'
  if (header.right) textAlign = 'end'
  return { textAlign, whiteSpace: 'break-spaces' }
}

export const VerificationsTableKPK: FC<VerificationsTableProps> = ({
  entity
}) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [currentId, setCurrentId] = useState('')
  const [assign] = useAssingApplicationByIdMutation()
  const headers = {
    verification_id: { name: 'id' },
    surname: { name: 'surname' },
    name: { name: 'firstname' },
    midname: { name: 'midname' },
    phone: { name: 'mobile' },
    email: { name: 'email' },
    verification_status: { name: 'status' },
    requested_amount: { name: 'requested_amount', right: true },
    requested_period: { name: 'requested_period', right: true },
    verifier_username: { name: 'verificator_surname', right: true }
  }

  const toggle = () => {
    setShowModal(false)
    setCurrentId('')
  }

  const assignToUser = async (id: string) => {
    try {
      await assign(id)
        .unwrap()
        .then((res) => {
          console.log('assign res =>', res)
          setCurrentId(id)
          setShowModal(true)
        })
    } catch (error: any) {
      // Already verified - уже отверифицировано
      // Already assigned - уже привязано к этому же пользователю
      // Already assigned to another user - уже привязано к другому пользователю
      // Wrong user status - неверный статус пользователя (только Готов к работе можно)
      if (error?.data?.detail === 'Already assigned to another user') {
        setCurrentId(id)
        setShowModal(true)
        return
      }
      if (error) {
        return toast.warn(`${t(`${error?.data?.detail}`)}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce
        })
      }
    }
  }

  return (
    <>
      <VerifModalKPK
        isOpen={showModal}
        verificationId={currentId}
        closeModal={toggle}
      />
      <ToastContainer />
      <Table hover className='table-sm align-middle table-nowrap mb-0'>
        <thead>
          <tr>
            {Object.keys(headers).map((header) => (
              //@ts-ignore
              <th style={headerStyle(headers[header])} key={header} scope='col'>
                {/* @ts-ignore */}
                {t(headers[header]?.name)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entity &&
            entity.length > 0 &&
            entity.map((entity: any) => {
              return (
                <tr key={entity.id}>
                  {Object.keys(headers).map((fieldName) => {
                    const value = entity[fieldName]
                    const key = `${fieldName}`
                    if (fieldName === 'verification_id')
                      return (
                        <th scope='row' key={key}>
                          <div
                            role='button'
                            className='link-danger fs-15'
                            onClick={() => {
                              assignToUser(entity.verification_id)
                            }}
                          >
                            <i className='ri-edit-2-line fs-15' />
                          </div>
                        </th>
                      )

                    if (fieldName === 'phone')
                      return (
                        <td key={key} style={minimizeTextStyle}>
                          {entity.phone}
                        </td>
                      )
                    if (fieldName === 'email')
                      return (
                        <td key={key} style={minimizeTextStyle}>
                          {entity.email}
                        </td>
                      )
                    if (fieldName === 'client')
                      return (
                        <td
                          key={key}
                          style={{
                            whiteSpace: 'nowrap',
                            ...minimizeTextStyle
                          }}
                        >
                          {`${entity.client_surname} ${entity.client_name} ${entity.client_midname}`}
                        </td>
                      )

                    if (fieldName === 'updated_at')
                      return (
                        <td key={key} style={minimizeTextStyle}>
                          {formatDateTime(
                            entity.updated_at || entity.created_at
                          )}
                        </td>
                      )

                    if (
                      fieldName === 'approved_amount' ||
                      fieldName === 'requested_amount' ||
                      fieldName === 'signed_amount'
                    )
                      return (
                        <td
                          style={minimizeTextStyle}
                          //@ts-ignore
                          align={valueAlign(headers[fieldName])}
                          key={key}
                        >
                          {formatAmount(value, undefined)}
                        </td>
                      )

                    return (
                      <td
                        style={minimizeTextStyle}
                        //@ts-ignore
                        align={valueAlign(headers[fieldName])}
                        key={key}
                      >
                        {value || '-'}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
      </Table>
    </>
  )
}
