import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import model from 'src/model'
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import { Pagination, Navigation } from 'swiper'
import { useGetDocsByIdMutation } from 'src/services/kpk/verification'
import Loader from '../Common/Loader'
import { Button, Modal } from 'reactstrap'

interface FileType {
  id: number
  name: string
  is_primary: boolean
}

interface Document {
  id: string
  created_at: string
  updated_at: string | null
  file_path: string
  file_type: FileType
  profile_id: string
  doc_name: string | null
}

interface PhotosProps {
  photos: Document[]
  total: number
}

interface ModalPhotoProps {
  isOpen: boolean
  toggle: () => void
  photos: Document[]
  imageUrls: any
}

const ModalPhoto: FC<ModalPhotoProps> = ({
  isOpen,
  photos,
  toggle,
  imageUrls
}) => {
  const { t } = useTranslation()

  if (!isOpen && !photos) return null
  return (
    <Modal isOpen={isOpen} centered toggle={toggle}>
      <div>
        <Swiper
          pagination={{ type: 'fraction', clickable: true }}
          navigation={true}
          modules={[Pagination, Navigation]}
          loop={true}
          grabCursor
          className='mySwiper swiper default-swiper rounded bg-soft-primary'
        >
          <div className='swiper-wrapper align-items-center'>
            {photos &&
              photos?.map((doc: any) => {
                return (
                  <SwiperSlide key={doc.id}>
                    <div className='vstack d-flex align-items-center justify-content-center p-2'>
                      <img
                        //@ts-ignore
                        src={imageUrls[doc.id]}
                        alt=''
                        className='img-thumbnail rounded'
                      />
                      <div
                        className='vstack mb-5 align-self-center'
                        style={{ width: 'fit-content' }}
                      >
                        {doc.file_type_id && (
                          <small>{`${t(
                            'file_type_id'
                          )}: ${model.fieldValueToText(
                            'file_type_id',
                            doc,
                            {}
                          )}`}</small>
                        )}
                        {doc.file_type?.name && (
                          <small>{`${model.fieldValueToText(
                            'name',
                            doc.file_type,
                            {}
                          )}`}</small>
                        )}
                        {doc.created_at && (
                          <small>{`${t('created')}: ${model.fieldValueToText(
                            'created_at',
                            doc,
                            {
                              created_at: { isDateTime: true }
                            }
                          )}`}</small>
                        )}
                        {Object.keys(doc).includes('is_incorrect') && (
                          <small>{`${t(
                            'is_incorrect'
                          )}: ${model.fieldValueToText(
                            'is_incorrect',
                            doc,
                            {}
                          )}`}</small>
                        )}
                        <small>{`${t('id')}: ${model.fieldValueToText(
                          'id',
                          doc,
                          {}
                        )}`}</small>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
          </div>
        </Swiper>

        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Button className='mt-4 w-100' onClick={toggle}>
            {t('Close')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const PhotosContainer: FC<PhotosProps> = ({ photos, total }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [getDocsById, { isLoading: loadingDocs }] = useGetDocsByIdMutation()
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({})

  const toggle = () => {
    setShow(!show)
  }

  useEffect(() => {
    const fetchImages = async () => {
      const urls: Record<string, string> = {}
      for (const doc of photos) {
        try {
          //@ts-ignore
          const blob = await getDocsById(doc.id).unwrap()
          if (blob instanceof Blob) {
            const url = URL.createObjectURL(blob)
            urls[doc.id] = url
          }
        } catch (error) {
          console.error('url error =>', error)
        }
      }
      setImageUrls(urls)
    }

    fetchImages()
  }, [photos, getDocsById])

  if (!photos || !total) {
    return <small>{t('No_photos')}</small>
  }

  if (loadingDocs) return <Loader />

  return (
    <>
      <div style={{ maxWidth: 400 }} className='mt-4' onClick={toggle}>
        <Swiper
          pagination={{ type: 'fraction', clickable: true }}
          navigation={true}
          modules={[Pagination, Navigation]}
          loop={true}
          grabCursor
          className='mySwiper swiper default-swiper rounded bg-soft-primary'
        >
          <div className='swiper-wrapper align-items-center'>
            {photos &&
              photos?.map((doc: any) => {
                return (
                  <SwiperSlide key={doc.id}>
                    <div className='vstack d-flex align-items-center justify-content-center p-2'>
                      <img
                        //@ts-ignore
                        src={imageUrls[doc.id]}
                        alt=''
                        className='img-thumbnail rounded'
                      />
                      <div
                        className='vstack mb-5 align-self-center'
                        style={{ width: 'fit-content' }}
                      >
                        {doc.file_type_id && (
                          <small>{`${t(
                            'file_type_id'
                          )}: ${model.fieldValueToText(
                            'file_type_id',
                            doc,
                            {}
                          )}`}</small>
                        )}
                        {doc.file_type?.name && (
                          <small>{`${model.fieldValueToText(
                            'name',
                            doc.file_type,
                            {}
                          )}`}</small>
                        )}
                        {doc.created_at && (
                          <small>{`${t('created')}: ${model.fieldValueToText(
                            'created_at',
                            doc,
                            {
                              created_at: { isDateTime: true }
                            }
                          )}`}</small>
                        )}
                        {Object.keys(doc).includes('is_incorrect') && (
                          <small>{`${t(
                            'is_incorrect'
                          )}: ${model.fieldValueToText(
                            'is_incorrect',
                            doc,
                            {}
                          )}`}</small>
                        )}
                        <small>{`${t('id')}: ${model.fieldValueToText(
                          'id',
                          doc,
                          {}
                        )}`}</small>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
          </div>
        </Swiper>
      </div>
      <ModalPhoto
        isOpen={show}
        photos={photos}
        toggle={toggle}
        imageUrls={imageUrls}
      />
    </>
  )
}
