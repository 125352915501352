import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  ENTITY,
  ENTITY_SUCCESS,
  ENTITY_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from "./actionType";

//get one
export const getDialogue = (id = null) => ({
  type: ENTITY,
  id,
})

export const getDialogueSuccess = entities => ({
  type: ENTITY_SUCCESS,
  payload: entities,
})

export const getDialogueFail = error => ({
  type: ENTITY_FAIL,
  payload: error,
})

//get all
export const getDialogues = (id = null, filter = '') => ({
  type: LIST,
  id,
  filter,
})

export const getDialoguesSuccess = entities => ({
  type: LIST_SUCCESS,
  payload: entities,
})

export const getDialoguesFail = error => ({
  type: LIST_FAIL,
  payload: error,
})

//update
export const updateDialogue = (entity) => ({
  type: UPDATE,
  payload: entity,
})

export const updateDialogueSuccess = (entity) => ({
  type: UPDATE_SUCCESS,
  payload: entity,
})

export const updateDialogueFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})
