import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import { useSearchParams } from 'react-router-dom'
import { useGetLoanHolidaysTypeQuery } from 'src/services/holidays'
import { FieldDescriptionView } from './types'
import { FC } from 'react'
import Loader from 'src/Components/Common/Loader'

const fieldsDescription: Record<string, FieldDescriptionView> = {
  id: {},
  created_at: { label: 'Дата создания', isDate: true },
  updated_at: { label: 'Дата обновления', isDate: true },
  name: {},
  is_deleted: {}
}

export const HolidaysView: FC = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')?.toString() ?? ''

  const {
    data: type,
    isLoading,
    error: typeError
  } = useGetLoanHolidaysTypeQuery(id, {
    skip: !id
  })

  const title = `${t('Holidays')}: ${t('View')}`
  document.title = title

  if (typeError) {
    return <ErrorPage backLink={'/holidays'} title={title} error={typeError} />
  }

  if (!type) {
    return <LoadingPage backLink={'/holidays'} title={title} />
  }
  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar
              backLink={'/holidays'}
              editLink={type ? `/holidays-edit?id=${type.id}` : null}
              entity={type}
            />
            <Row className='mt-3'>
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col
                    className={
                      fieldsDescription[fieldName].fullWidth
                        ? 'col-12 mt-3'
                        : 'mt-3 col-auto'
                    }
                    key={`${fieldName}`}
                  >
                    <Label htmlFor={fieldName}>
                      {t(fieldsDescription[fieldName].label || fieldName)}
                    </Label>
                    <Input
                      type='text'
                      className='form-control form-control-sm'
                      value={model.fieldValueToText(
                        fieldName,
                        type,
                        fieldsDescription
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
