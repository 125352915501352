import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { useChangeUserPasswordMutation } from 'src/services/password'
import { useSelector } from 'src/store'

interface ChangePasswordProps {
  isOpen: boolean
  toggle: () => void
}

interface Password {
  new: string
  confirm: string
}

export const ChangePassword: FC<ChangePasswordProps> = ({ isOpen, toggle }) => {
  const { t } = useTranslation()
  const user = useSelector((state) => state.Login.userInfo)
  const [alert, setAlert] = useState({
    message: '',
    color: ''
  })
  const [changeUserPassword, { isLoading: loading }] =
    useChangeUserPasswordMutation({})
  const [password, setPassword] = useState<Password>({
    new: '',
    confirm: ''
  })

  const validatePassword = (password: string): string => {
    const minLength = user?.roles.includes('admin') ? 16 : 8
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)
    if (password.length < minLength) {
      return t('Password must be at least 8 characters long.')
    }
    if (!hasUpperCase) {
      return t('Password must contain at least one uppercase letter.')
    }
    if (!hasLowerCase) {
      return t('Password must contain at least one lowercase letter.')
    }
    if (!hasNumber) {
      return t('Password must contain at least one number.')
    }
    return ''
  }

  const changeNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [event.target.name]: event.target.value })
  }

  const changePassword = async () => {
    const validationError = validatePassword(password.new)
    if (validationError) {
      setAlert({
        message: `Минимум 1 цифра, 1 буква в нижнем и верхем регистре, минимум ${
          user?.roles.includes('admin') ? 16 : 8
        } символов`,
        color: 'warning'
      })
      return
    }
    if (password.new !== password.confirm) {
      setAlert({
        message: t('Passwords do not match'),
        color: 'warning'
      })
      return
    }
    try {
      await changeUserPassword({ password: password.confirm })
        .unwrap()
        .then((res) => {
          if (res.successful) {
            setAlert({
              message: 'Успешно',
              color: 'success'
            })
          }
        })
    } catch (error: any) {
      setAlert({
        message: error?.toString()!,
        color: 'warning'
      })
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{t('ChangePassword')}</ModalHeader>
      <ModalBody>
        <label htmlFor='new-passwor'>{t('NewPassword')}</label>
        <Input
          name='new'
          id='new-password'
          value={password.new}
          onChange={changeNewPassword}
        />
        <br />
        <label htmlFor='repeat-password'>{t('RepeatNewPassowrd')}</label>
        <Input
          name='confirm'
          id='repeat-password'
          value={password.confirm}
          onChange={changeNewPassword}
        />
        {alert.message && (
          <>
            <br />
            <Alert color={alert.color}>{alert.message}</Alert>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={loading} type='button' onClick={changePassword}>
          {t('AcceptChangePassword')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
