import React from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import EntityInfo from 'src/Components/Common/EntityInfo'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Related from './Related'
import Client from 'src/client'

import { Row, Card, CardBody, Button } from 'reactstrap'
import { formatCalculateAge } from 'src/utils'
import { useGetClientTagsQuery } from 'src/services/tags'
import { useGetFormByClientIdMutation } from '../../../services/clients'
import useGetClient from './hooks/useGetClient'

const columnsDescription = [
  ['title_contact_info'],
  ['id'],
  // ['profile_id'],
  //  ['last_name'],
  //  ['first_name'],
  //  ['middle_name'],
  ['FIO'],
  // ['id_type'],
  // ['id_number'], //'id_series', 'id_issuer', 'id_code', 'id_date',
  ['gender'],
  ['age'],
  // ['birth_date'],
  ['birth_place'],
  ['email'],
  ['series'],
  ['number'],
  ['docdate'],
  ['docorgcode'],
  ['docorg'],
  ['snils'],
  ['inn'],
  // ['birth_date'],
  // ['marital_status'],
  // ['number_of_children'],
  ['contact_person_name_1'],
  ['contact_person_phone_1'],
  // ['contact_person_2'],

  ['title_busy_info'],
  // ['university'],
  // ['field_of_activity'],
  ['typework'],
  ['work_address'],
  ['salary'],
  ['extsalary'],
  // ['describe_work'],
  // ['level_of_education'],
  ['occupation'],
  ['work_phone'],

  // ['title_payment'],
  // ["select_payment"],
  // ["bank_name"],
  // ["account_number"],
  // ["electronic_wallet"],
  // ["electronic_wallet_phone"],

  ['title_residence'],
  // ['time_of_residence'],
  // ['fact_address_match'],

  // ['title_current_address', 'title_fact_address'],

  ['current_address'],
  ['fact_address'],
  ['workAddress']
  // ['current_address_city', 'fact_address_city'],
  //  ['current_address_city_not_exist', 'fact_address_city_not_exist'],
  //  ['current_address_enter_city', 'fact_address_enter_city'],
  //  ['current_address_barangay', 'fact_address_barangay'],
  //  ['current_address_district', 'fact_address_district'],
  //   ['current_address_street', 'fact_address_street'],
  //   ['current_address_house', 'fact_address_house'],
  //   ['current_address_apt', 'fact_address_apt'],

  // ['title_other'],
  // ['loan_purpose'],
  // ['enter_purpose'],
  // ['direct_conditions'],
]

export const ClientView = () => {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')

  const [getForm] = useGetFormByClientIdMutation()

  const { client, someLoading, someError, clientData } = useGetClient(id || '')

  const { data: mark } = useGetClientTagsQuery(id || '')

  const getAnketa = async () => {
    const res = await getForm(id)
    if ('data' in res) {
      const newWindow = window.open('', '_blank')
      if (newWindow) {
        newWindow.document.write(res.data)
        newWindow.document.close()
      } else {
        console.error('Не удалось открыть новое окно')
      }
    } else {
      console.log(res.error)
    }
  }

  const title = t('View client')
  document.title = title

  if (someError) {
    return <ErrorPage backLink={'/clients'} title={title} error={someError} />
  }

  if (!clientData || someLoading) {
    return <LoadingPage backLink={'/clients'} title={title} />
  }

  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/clients'}
      editLink={client ? `/client-edit?id=${client.id}` : null}
      entity={client}
    />
  )

  const { city, street, house, flat } = client?.addresses?.work_address || {
    city: '',
    street: '',
    house: '',
    flat: ''
  }
  const workAddress = `
    ${city || ''},
    ${street || ''},
    д. ${house || ''},
    кв. ${flat || ''}
    `

  const FIO = `${client?.last_name} ${client?.first_name} ${client?.middle_name}`
  const age = client?.birth_date
    ? `${formatCalculateAge(client?.birth_date)}`
    : ''

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Button
              color='primary'
              style={{ backgroundColor: '#405189', margin: '10px 0' }}
              onClick={getAnketa}
            >
              {t('GetAnketa115FZ')}
            </Button>
            {mark && mark?.length > 0 && (
              <div className='d-flex gap-2 mb-3 mt-2'>
                {mark.map((item, index) => (
                  <div
                    style={{
                      display: 'flex',
                      border: '1px solid #405189',
                      borderRadius: '10px',
                      padding: '3px 10px',
                      alignItems: 'center'
                    }}
                    key={index}
                  >
                    {item.tag}
                  </div>
                ))}
              </div>
            )}
            <Row className='mt-0'>
              <EntityInfo
                entity={{ ...client, FIO, age, workAddress }}
                entityId={client.id}
                entityType={Client.ENTITY_TYPE.CLIENTS}
                columnsDescription={columnsDescription}
              />
            </Row>
            <Row className='mt-5'>
              <Related client={client} />
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
