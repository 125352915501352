import React, { FC, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import Client from 'src/client'

interface PopupProps {
  isOpen: boolean
}

export const ForbiddenPopup: FC<PopupProps> = ({ isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.location.href = Client.logout()
      }, 5000)
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody>
        Доступ запрещен. <br />
        Пожалуйста, подключитесь к корпоративному VPN и повторите попытку.
      </ModalBody>
      <ModalFooter>
        <a href={Client.logout()}>
          <Button>Выход</Button>
        </a>
      </ModalFooter>
    </Modal>
  )
}
