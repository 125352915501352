import {
  OPEN_WINDOW_VERIFICATION,
} from "./actionType";

const INIT_STATE = {
  task: null,
};

const Verifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    //all
    case OPEN_WINDOW_VERIFICATION:
      return {
        ...state,
        task: action.task,
      };

    default:
      return state;
  }
};

export default Verifications;
