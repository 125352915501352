import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'

import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import { useGetAgencyQuery } from '../../../services/agencies'
import ErrorPage from '../ErrorPage'
import { INITIAL_AGENCY_FIELDS } from './constants/agencies.constants'
import { AgencyFieldNames } from './types/agencies.types'

export const AgencyView = () => {
  const { t } = useTranslation()

  const [id, setId] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')

    if (id) {
      setId(id)
    }
  }, [])

  const { data: agency, isLoading } = useGetAgencyQuery(id, {
    skip: !id
  })

  const title = `${t('Agencies')}: ${t('View')}`
  document.title = title

  if (isLoading) {
    return <LoadingPage backLink={'/external-agencies'} title={title} />
  }

  if (!agency?.id) {
    return (
      <ErrorPage
        backLink={'/external-agencies'}
        title={title}
        error={`invalid agency_id : ${id}`}
      />
    )
  }

  const bread = <BreadCrumb title={title} />

  const metaBar = (
    <MetaBar
      backLink={'/external-agencies'}
      editLink={agency ? `/external-agencies-edit?id=${agency.id}` : null}
      entity={agency}
    />
  )

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className='mt-3'>
              {Object.keys(INITIAL_AGENCY_FIELDS).map((fieldName) => {
                const field =
                  INITIAL_AGENCY_FIELDS[fieldName as AgencyFieldNames]

                return (
                  <Col
                    className={field.fullWidth ? 'col-12 mt-3' : 'col-auto'}
                    key={`${fieldName}`}
                  >
                    <Label htmlFor={fieldName}>{t(fieldName)}</Label>
                    <Input
                      type='text'
                      className='form-control form-control-sm'
                      value={model.fieldValueToText(
                        fieldName,
                        agency,
                        INITIAL_AGENCY_FIELDS
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
