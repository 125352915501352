import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Alert, Card, CardBody, Table } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage, { DangerAlert } from '../ErrorPage'

import model from 'src/model'
import {
  useDeleteLoanHolidaysTypeMutation,
  useGetLoanHolidaysTypesQuery
} from 'src/services/holidays'
import { HeaderType } from './types'
import Loader from 'src/Components/Common/Loader'

const headers: HeaderType = {
  id: {},
  created_at: { label: 'Дата создания', isDate: true },
  updated_at: { label: 'Дата обновления', isDate: true },
  name: {},
  is_deleted: {}
}

export const HolidaysTypes: FC = () => {
  const { t } = useTranslation()
  const { data: types, isLoading, error } = useGetLoanHolidaysTypesQuery()
  const [deleteLoanHolidaysType, { isSuccess: isSuccessCreateType }] =
    useDeleteLoanHolidaysTypeMutation()
  const [actionsEnabled, _setActionsEnabled] = useState<boolean>(true)
  const [enableDelete, _setEnableDelete] = useState<boolean>(true)
  const renderIsDeleted = (isDeleted: boolean) => (isDeleted ? 'Да' : 'Нет')

  const title = t('holidays_type_credit')
  document.title = title

  if (error) {
    return <ErrorPage title={title} error={error} />
  }
  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar createLink={'/holidays-create'} />
            {error ? <DangerAlert error={error} /> : null}
            <div className='table-responsive table-card mt-4'>
              <Table hover className='table-sm align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th />
                    {Object.keys(headers).map((header) => (
                      <th className='ps-3' key={header} scope='col'>
                        {t(headers[header as keyof HeaderType].label || header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope='col'>{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {types &&
                    types.length > 0 &&
                    types.map((entity) => (
                      <tr key={entity.id}>
                        <td style={{ width: '1%' }}>
                          <Link
                            to={`/holidays-view?id=${entity.id}`}
                            className='fw-medium'
                          >
                            <i className='ri-eye-line fs-15' />
                          </Link>
                        </td>
                        {Object.keys(headers).map((fieldName) => {
                          const value =
                            fieldName === 'is_deleted'
                              ? renderIsDeleted(entity.is_deleted)
                              : model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  headers
                                )

                          return (
                            <td className='ps-3' key={fieldName}>
                              {value}
                            </td>
                          )
                        })}
                        {actionsEnabled && (
                          <td>
                            <div className='hstack gap-3 flex-wrap'>
                              <Link
                                to={`/holidays-edit?id=${entity.id}`}
                                className='link-success fs-15'
                              >
                                <i className='ri-edit-2-line'></i>
                              </Link>
                              {enableDelete && (
                                <Link
                                  to='#'
                                  onClick={() =>
                                    deleteLoanHolidaysType(entity.id)
                                  }
                                  className='link-danger fs-15'
                                >
                                  <i className='ri-delete-bin-line'></i>
                                </Link>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
          {isSuccessCreateType && (
            <Alert color='success'>
              <strong>{t('Success')}</strong>
            </Alert>
          )}
        </Card>
      </div>
    </>
  )
}
