import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'
import { Modal, ModalProps } from 'reactstrap'
import { useDisclosure } from './utils/useDisclosure'

const ModalDisclosureContext = createContext<
  Omit<ReturnType<typeof useDisclosure>, 'open'> & {
    open: <T = any>(
      component: (props: PropsWithDisclosure<T>) => JSX.Element,
      modalProps?: ModalProps
    ) => Promise<T>
  }
  // @ts-expect-error Argument of type 'undefined' is not assignable to parameter of type
>(undefined)

export function ModalDisclosureProvider({
  children
}: PropsWithChildren<Record<never, never>>) {
  const { isOpen, open, ...useDisclosureProps } = useDisclosure()

  const [modalProps, setModalProps] = useState<ModalProps>()

  // TODO: replace any with a reasonable type
  const [Disclosurable, setDisclosurable] = useState<any>(<></>)

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const rejectFnRef = useRef(() => {})

  const handleOpen = useCallback(
    function <T = any, R = any>(
      Component: typeof Disclosurable,
      modalProps?: ModalProps
    ) {
      setModalProps(modalProps)
      return new Promise<T>((resolve, reject) => {
        open()

        const rejectFn = (value: R) => {
          reject(value)
          useDisclosureProps.close()
        }
        // @ts-expect-error Type '(value: T) => void' is not assignable to type '() => void'.
        rejectFnRef.current = rejectFn

        setDisclosurable(
          <Component
            {...Component.props}
            resolve={(value: T) => {
              resolve(value)
              useDisclosureProps.close()
            }}
            reject={rejectFn}
          />
        )
      })
    },
    [open, useDisclosureProps]
  )

  return (
    <ModalDisclosureContext.Provider
      value={{
        ...useDisclosureProps,
        open: handleOpen,
        isOpen
      }}
    >
      {children}
      <Modal
        centered
        backdrop
        keyboard
        {...modalProps}
        isOpen={isOpen}
        toggle={rejectFnRef.current}
      >
        {Disclosurable}
      </Modal>
    </ModalDisclosureContext.Provider>
  )
}

export type PropsWithDisclosure<T = any> = T & {
  resolve: <T = any>(result?: T) => void
  reject: () => void
  isOpen: boolean
}

export const useModalDisclosureContext = () =>
  useContext(ModalDisclosureContext)
