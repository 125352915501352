import { useEffect, useState, useCallback, useMemo } from 'react'
import { Button, Label, FormGroup, Form } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'

import List from './List'
import Client from 'src/client'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import { useGetLoanHolidaysTypesQuery } from 'src/services/holidays'

const fieldsDescription = {
  begin_date: { label: 'holiday_begin_date', isDate: true },
  end_date: { label: 'holiday_end_date', isDate: true },
  is_active: {},
  created_at: { isDateTime: true }
}

const EntityContent = (props) => {
  const { entityId, style } = props
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [error, setError] = useState('')
  const [selected, setSelected] = useState()
  const [visitDay, setVisitDay] = useState()
  const [startDay, setStartDay] = useState(new Date())
  const [endDay, setEndDay] = useState()
  const [isSVOType, setIsSVOType] = useState(false)

  useEffect(
    () =>
      selected?.name.includes('СВО') ? setIsSVOType(true) : setIsSVOType(false),
    [isSVOType, selected]
  )

  const minStartDate = useMemo(
    () => (isSVOType ? '2022-09-21' : null),
    [isSVOType]
  )

  const maxFinishDate = useMemo(() => {
    const result = new Date(startDay)
    if (isSVOType) {
      return null
    } else {
      return result.setMonth(result.getMonth() + 6)
    }
  }, [isSVOType, startDay])

  const fetchTranches = useCallback(() => {
    Client.getLoanHolidays(entityId)
      .then((res) => {
        console.log('getLoanHolidays', res)
        setList(res ? [res] : [])
      })
      .catch((error) => setError(error))
  }, [entityId])

  const { data: types, errorFetch } = useGetLoanHolidaysTypesQuery({
    skip: !props.visible
  })

  const create = () => {
    setError('')
    if (!startDay || !endDay) {
      setError(t('Select dates'))
    }

    Client.addLoanHolidays(entityId, startDay, endDay, selected.id, visitDay)
      .then(() => {
        fetchTranches()
      })
      .catch((err) => {
        setError(err)
      })
  }

  const edit = () => {
    setError('')
    if (!startDay || !endDay) {
      setError(t('Select dates'))
    }

    Client.editLoanHolidays(entityId, startDay, endDay, selected.id, visitDay)
      .then(() => {
        fetchTranches()
      })
      .catch((err) => {
        setError(err)
      })
  }

  useEffect(() => {
    if (props.visible) {
      fetchTranches()
    }
  }, [fetchTranches, props.visible])

  return (
    <div>
      {(error || errorFetch) && <DangerAlert error={error} />}
      <List list={list} fieldsDescription={fieldsDescription} style={style} />

      <Form className='needs-validation mt-4'>
        <FormGroup>
          <Label htmlFor='start_date'>Дата обращения клиента в компанию</Label>
          <Flatpickr
            name='start_date'
            id='start_date'
            type='date'
            style={{ maxWidth: '40%' }}
            className='form-control form-control-sm'
            options={{
              dateFormat: 'Y-m-d',
              onChange: (selectedDates, dateStr) => {
                setVisitDay(dateStr)
              }
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor='holiday_type_id'>Тип Каникул</Label>
          <select
            className='form-select form-select-sm'
            style={{ width: '40%', textOverflow: 'ellipsis' }}
            defaultValue={selected}
            onChange={(e) => {
              if (Number(e.target.value) === 0) {
                setSelected()
              } else {
                setSelected(types.find((tag) => tag.id == e.target.value))
              }
            }}
          >
            <option value={''}></option>
            {types
              ?.filter((type) => type.is_deleted === false)
              .map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </FormGroup>

        {selected && (
          <FormGroup>
            <Label htmlFor={'holidays'}>Начало каникул</Label>
            <Flatpickr
              name='start_date'
              id='start_date'
              type='date'
              style={{ maxWidth: '40%' }}
              className='form-control form-control-sm'
              options={{
                dateFormat: 'Y-m-d',
                minDate: minStartDate,
                onChange: (selectedDates, dateStr) => {
                  setStartDay(dateStr)
                }
              }}
            />
            {startDay && (
              <>
                <Label htmlFor={'holidays'}>Конец каникул</Label>
                <Flatpickr
                  name='end_date'
                  id='end_date'
                  type='date'
                  style={{ maxWidth: '40%' }}
                  className='form-control form-control-sm'
                  options={{
                    dateFormat: 'Y-m-d',
                    minDate: startDay,
                    maxDate: maxFinishDate,
                    onChange: (selectedDates, dateStr) => {
                      setEndDay(dateStr)
                    }
                  }}
                />
              </>
            )}
            <Button
              className='btn-label'
              size='sm'
              color='primary'
              style={{ backgroundColor: '#405189', marginTop: 12 }}
              onClick={list && list.length > 0 ? edit : create}
            >
              <i className='ri-add-line label-icon align-middle fs-16 me-2'></i>
              {t(list && list.length > 0 ? 'Update' : 'Create')}
            </Button>
          </FormGroup>
        )}
      </Form>
    </div>
  )
}

export default EntityContent
