import { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { Button, Card, CardBody, Input, Label } from 'reactstrap'
import MetaBar from '../../../Components/Common/MetaBar'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getRoles } from '../../../store/roles/action'
import LoadingPage from '../LoadingPage'
import API from '../../../client'
import ErrorPage from '../ErrorPage'
import { useNavigate } from 'react-router-dom'

export const RoleView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = new URLSearchParams(window.location.search)
  const [roleId, setRoleId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [paramError, setParamError] = useState(null)
  const title = t('View role')
  document.title = title
  const { role, listLoading, listError } = useSelector((state) => ({
    role:
      state.roles.list && state.roles.list.length ? state.roles.list[0] : null,
    listLoading: state.roles.listLoading,
    listError: state.roles.listError,
  }))

  useEffect(() => {
    const id = params.get('id')
    setRoleId(id || null)
    if (id) dispatch(getRoles(id))
  }, [])

  useEffect(() => {
    if (!role) return
    setPermissions(role.activePermissions)
    setPageReady(true)
  }, [role])

  if (!pageReady || listLoading) {
    return <LoadingPage backLink={'/roles'} title={title} />
  }

  if (paramError || listError) {
    return (
      <ErrorPage
        backLink={'/roles'}
        title={title}
        error={[paramError, listError]}
      />
    )
  }

  return (
    <div className="page-content">
      <BreadCrumb title={t('View role') + ` ${roleId}`} />
      <Card>
        <CardBody>
          <MetaBar backLabel={'back'} backLink={`/roles`} />
          <div style={{ width: '600px', marginTop: '5px' }}>
            {role && role.allPermissions.length > 0 && permissions ? (
              role.allPermissions.map((permission) => (
                <Label
                  style={{
                    verticalAlign: 'baseline',
                    display: 'block',
                    lineHeight: '21px',
                    fontSize: '14px',
                    marginBottom: '10px',
                  }}
                  key={permission}
                  className="form-check-label"
                >
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={permissions.includes(permission.toUpperCase())}
                    id={permission}
                    style={{
                      width: '21px',
                      height: '21px',
                      marginRight: '15px',
                      cursor: 'pointer',
                    }}
                    onChange={() => {
                      const index = permissions.indexOf(permission)
                      if (index > -1) {
                        const newPermissions = JSON.parse(
                          JSON.stringify(permissions),
                        )
                        newPermissions.splice(index, 1)
                        setPermissions(newPermissions)
                      } else {
                        setPermissions([...permissions, permission])
                      }
                    }}
                  />
                  <span>{permission}</span>
                </Label>
              ))
            ) : (
              <p>{'Для роли пока нет доступных разрешений'}</p>
            )}
          </div>
          <div style={{ marginTop: '20px' }}>
            <Button
              style={{ marginRight: '15px' }}
              color="danger"
              onClick={async () => {
                try {
                  await API.deleteRole(roleId)
                  navigate(`/roles`)
                } catch (e) {
                  setParamError(e)
                }
              }}
            >
              {t('Delete')}
            </Button>
            <Button
              color="success"
              onClick={async () => {
                try {
                  await API.addRolePermission(roleId, permissions)
                  navigate(`/roles`)
                } catch (e) {
                  setParamError(e)
                }
              }}
            >
              {t('Save')}
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
