import { useTranslation } from 'react-i18next'

export const Select = ({ size, onChange, value, options, onFocus }) => {
  const { t, i18n } = useTranslation()
  return (
    <select
      className="form-select form-select-sm"
      onChange={(e) => onChange(e.target.value)}
      onFocus={(e) => onFocus(e.target.value)}
      value={value}
      style={{ width: 'auto', textOverflow: 'ellipsis' }}
    >
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {t(option.name)}
        </option>
      ))}
    </select>
  )
}

export default Select
