import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,

  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,

  SET_FILTER,
} from "./actionType";

import Client from "../../client";

const INIT_STATE = {};

Object.values(Client.ENTITY_TYPE).map((entityType) => {
  const state = {
    strFilter: '',
    fieldsFilter: {},

    list: [],
    listLoading: false,
    listError: null,

    entity: null,
    entityLoading: false,
    entityError: null,

    deleteLoading: false,
    deleteError: null,

    updateLoading: false,
    updateError: null,

    createLoading: false,
    createError: null,
  };
  INIT_STATE[entityType] = state;
});

const Entities = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILTER:
      state[action.entityType].strFilter = action.strFilter;
      state[action.entityType].fieldsFilter = action.fieldsFilter;
      break;
    //get
    case LIST:
      if (action.id) {
        state[action.entityType].entityLoading = true;
        state[action.entityType].entityError = null;
      } else {
        state[action.entityType].listLoading = true;
        state[action.entityType].listError = null;
      }
      break;

    case LIST_SUCCESS:
      if (action.id) {
        state[action.entityType].entity = action.payload[0];
        state[action.entityType].entityLoading = false;
        state[action.entityType].entityError = null;
      } else {
        state[action.entityType].list = action.payload;
        state[action.entityType].listLoading = false;
        state[action.entityType].listError = null;
      }
      break;

    case LIST_FAIL:
      if (action.id) {
        state[action.entityType].entityLoading = false;
        state[action.entityType].entityError = action.payload;
      } else {
        state[action.entityType].listLoading = false;
        state[action.entityType].listError = action.payload;
      }
      break;

    //delete
    case DELETE:
      state[action.entityType].deleteLoading = true;
      state[action.entityType].deleteError = null;
      break;

    case DELETE_SUCCESS:
      state[action.entityType].deleteLoading = false;
      state[action.entityType].deleteError = null;
      break;

    case DELETE_FAIL:
      state[action.entityType].deleteLoading = false;
      state[action.entityType].deleteError = action.payload;
      break;

    //update
    case UPDATE:
      state[action.entityType].updateLoading = true;
      state[action.entityType].updateError = null;
      break;

    case UPDATE_SUCCESS:
      state[action.entityType].updateLoading = false;
      state[action.entityType].updateError = null;
      break;

    case UPDATE_FAIL:
      state[action.entityType].updateLoading = false;
      state[action.entityType].updateError = action.payload;
      break;

    //create
    case CREATE:
      state[action.entityType].createLoading = true;
      state[action.entityType].createError = null;
      break;

    case CREATE_SUCCESS:
      state[action.entityType].createLoading = false;
      state[action.entityType].createError = null;
      break;

    case CREATE_FAIL:
      state[action.entityType].createLoading = false;
      state[action.entityType].createError = action.payload;
      break;

    default:
      return state;
  }
  //console.log(`${action.type} state=>`, state);
  return {
    ...state,
  };
};

export default Entities;
