import React, { useEffect, useState } from 'react'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import {
  Card,
  CardBody,
  Input,
  Label,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from 'reactstrap'
import MetaBar from 'src/Components/Common/MetaBar'
import { useTranslation } from 'react-i18next'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import {
  useAddUserToRoleMutation,
  useGetPermissionsQuery,
  useGetRolesQuery,
  useGetUserPermissionsQuery,
  useGetUserQuery,
  useRemoveUserFromRoleMutation,
  useUpdateUserPermissionsMutation
} from 'src/services/keycloak'
import { useSearchParams } from 'react-router-dom'

export const UserView = () => {
  const { t } = useTranslation()

  const [paramError, setParamError] = useState(null)

  const title = t('View user')
  document.title = title

  const [searchParams] = useSearchParams()

  const { data: user, error: getUserError } = useGetUserQuery(
    searchParams.get('id')!,
    {
      skip: searchParams.get('id') == null
    }
  )

  const { data: permissions } = useGetPermissionsQuery()
  const { data: userPermissions } = useGetUserPermissionsQuery(
    searchParams.get('id')!,
    { skip: searchParams.get('id') == null }
  )
  const [activePermissions, setActivePermissions] = useState<string[]>([])
  useEffect(() => {
    if (userPermissions)
      setActivePermissions(
        Array.from(
          new Set(
            Object.values(userPermissions).reduce(
              (accumulator, current) => [...accumulator, ...current],
              []
            )
          )
        )
      )
  }, [userPermissions])
  const [updateUserPermissions] = useUpdateUserPermissionsMutation()

  const { data: roles } = useGetRolesQuery()
  const [activeRoles, setActiveRoles] = useState<string[]>([])
  useEffect(() => {
    if (userPermissions) setActiveRoles(Object.keys(userPermissions))
  }, [userPermissions])
  const [removeUserFromRole] = useRemoveUserFromRoleMutation()
  const [addUserToRole] = useAddUserToRoleMutation()

  console.log('allPermissions =>', permissions)
  console.log('activePermissions =>', activePermissions)
  console.log('activeRoles =>', activeRoles)

  if (!user || !userPermissions || !permissions) {
    return <LoadingPage backLink={'/users'} title={title} />
  }

  if (getUserError) {
    return (
      <ErrorPage backLink={'/users'} title={title} error={[getUserError]} />
    )
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={t('View user') + ` ${searchParams.get('id')}`} />
      <Card>
        <CardBody>
          <MetaBar backLink={`/users`} />

          <ListGroup>
            {Object.entries(user)
              .filter(
                ([key]) =>
                  key in
                  {
                    id: {
                      label: 'id',
                      isLink: true,
                      linkFieldName: 'id'
                    },
                    username: {},
                    surname: {},
                    name: {},
                    midname: {},
                    state: {},
                    blocked_cause: {},
                    phone: {},
                    email: {},
                    company: {}
                  }
              )
              .map(([key, value]) => (
                <ListGroupItem key={key}>
                  <Row>
                    <Col>{t(key)}</Col>

                    <Col>{value}</Col>
                  </Row>
                </ListGroupItem>
              ))}
          </ListGroup>

          <div
            className='mt-3'
            style={{
              display: 'flex',
              paddingTop: '5px',
              width: 'auto',
              gap: 60
            }}
          >
            <div>
              <p
                style={{
                  fontSize: '16px',
                  marginBottom: '15px',
                  fontWeight: 'bold'
                }}
              >
                {t('Roles')}
              </p>
              {roles?.map((role) => (
                <div
                  style={{
                    verticalAlign: 'baseline',
                    display: 'block',
                    lineHeight: '21px',
                    fontSize: '14px',
                    marginBottom: '10px'
                  }}
                  key={role.name}
                  className='form-check-label'
                >
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    checked={!!activeRoles.find((item) => role.name === item)}
                    id={role.name}
                    style={{
                      width: '21px',
                      height: '21px',
                      marginRight: '15px',
                      cursor: 'pointer'
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addUserToRole({
                          name: role.name,
                          username: searchParams.get('id')!
                        })
                      } else {
                        removeUserFromRole({
                          name: role.name,
                          username: searchParams.get('id')!
                        })
                      }
                    }}
                  />
                  <span>{role.name}</span>
                </div>
              ))}
            </div>
            <div style={{ width: 'auto' }}>
              <p
                style={{
                  fontSize: '16px',
                  marginBottom: '15px',
                  fontWeight: 'bold'
                }}
              >
                {t('Permissions')}
              </p>

              {activePermissions.map((permission) => (
                <p key={permission}>{permission}</p>
              ))}

              <Button
                color='success'
                onClick={async () => {
                  try {
                    await updateUserPermissions({
                      username: searchParams.get('id')!,
                      permissions: activePermissions
                    }).unwrap()
                  } catch (error) {
                    setParamError((error as any).toString())
                  }
                }}
              >
                {t('Save')}
              </Button>

              {paramError && <div className='text-danger'>{paramError}</div>}
            </div>
            <div>
              {Object.keys(permissions).map((permission) => (
                <Label
                  style={{
                    verticalAlign: 'baseline',
                    display: 'block',
                    lineHeight: '21px',
                    fontSize: '14px',
                    marginBottom: '10px'
                  }}
                  key={permission}
                  className='form-check-label'
                >
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    checked={activePermissions.includes(
                      permission.replaceAll('_', ' ').toUpperCase()
                    )}
                    id={permission}
                    style={{
                      width: '21px',
                      height: '21px',
                      marginRight: '15px',
                      cursor: 'pointer'
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        //add
                        setActivePermissions((prev) => [
                          ...prev,
                          permission.replaceAll('_', ' ')
                        ])
                      } else {
                        //remove
                        setActivePermissions((prev) =>
                          prev.filter(
                            (p) => p !== permission.replaceAll('_', ' ')
                          )
                        )
                      }
                    }}
                  />
                  <span>{permission}</span>
                </Label>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
