import { useEffect, useState } from 'react'
import { Web } from 'sip.js'
import { Button, Input, ModalBody, ModalHeader } from 'reactstrap'
import Connected from './Connected'
import sounds from '../sounds.json'
import { SimpleUserDelegate } from 'sip.js/lib/platform/web'
import InProgress from './InProgress'
import OutComming from './OutComming'
import { useSelector } from 'src/store'
import { PropsWithDisclosure } from 'src/ModalDisclosureProvider'

let simpleUser: Web.SimpleUser
export default function WebPhoneUI({
  numberFromLoan,
  ...disclosureProps
}: PropsWithDisclosure<{
  numberFromLoan?: string
}>) {
  const audioRingtone = new Audio()

  const [socketUrl, setSocketUrl] = useState<string>('')
  const [login, setLogin] = useState<string>('')
  const [number, setNumber] = useState<string>(
    numberFromLoan ? numberFromLoan : ''
  )
  const [password, setPassword] = useState<string>('')
  const [connected, setConnected] = useState<boolean>(false)
  const [isOutCommingCall, setIsOutCommingCall] = useState<boolean>(false)
  const [sessionInProgress, setSessionInProgress] = useState<boolean>(false)
  const [calling, setCalling] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(
    simpleUser ? simpleUser.isConnected() : false
  )
  const [id, setId] = useState<string>('')

  const user = useSelector((state) => state.Login.userInfo)

  //TODO: need testing && fix
  useEffect(() => {
    if (user?.status === 'Готов к работе' && user) {
      setLogin(user.asterisk.login ? user.asterisk.login : '')
      setPassword(user.asterisk.password ? user.asterisk.password : '')
      setSocketUrl(user.asterisk.url)
    }
    if (user?.status !== 'Готов к работе') {
      setLogin('')
      setPassword('')
      setSocketUrl('')
      if (simpleUser) {
        simpleUser.disconnect()
        simpleUser.unregister()
        setConnected(false)
      }
    }
  }, [user])

  // WebSocket server to connect with
  // const server = 'wss://rtp.dev.cash-u.com/ws'
  const server = socketUrl
  const delegate: SimpleUserDelegate = {
    onCallHangup: async () => {
      setIsOutCommingCall(false)
      setCalling(false)
      audioRingtone.pause()
    },
    onCallAnswered: async () => {
      audioRingtone.pause()
    },
    onCallReceived: async () => {
      audioRingtone.src = sounds.ringing
      audioRingtone.loop = true
      setIsOutCommingCall(true)
      audioRingtone.play()
      // auto answer
      // await simpleUser.answer()
      setId(simpleUser.id)
    }
  }

  const authInAsterisk = async () => {
    function getAudioElement(id: string): HTMLAudioElement {
      const el = document.getElementById(id)
      if (!(el instanceof HTMLAudioElement)) {
        throw new Error(`Element "${id}" not found or not an audio element.`)
      }
      return el
    }

    const options: Web.SimpleUserOptions = {
      aor: `sip:${login}@${server.split('://')[1].toString().split('/')[0]}`, // caller
      delegate: delegate,
      userAgentOptions: {
        authorizationPassword: `${password}`,
        authorizationUsername: `${login}`
      },
      media: {
        constraints: { audio: true, video: false }, // audio only call
        remote: { audio: getAudioElement('remoteAudio') } // play remote audio
      }
    }
    console.log(options, password, login, '!!!')
    simpleUser = new Web.SimpleUser(server, options)
    await simpleUser.connect()
    await simpleUser.register().then(() => {
      setConnected(simpleUser.isConnected())
    })
  }

  const call = () => {
    audioRingtone.src = sounds.ringback
    if (simpleUser) {
      simpleUser
        .call(
          `sip:${number}@${server.split('://')[1].toString().split('/')[0]}`
        )
        .then(() => audioRingtone.play())
        .then(() => setSessionInProgress(true))
    } else {
      authInAsterisk().then(() => {
        simpleUser
          .call(
            `sip:${number}@${server.split('://')[1].toString().split('/')[0]}`
          )
          .then(() => audioRingtone.play())
          .then(() => setSessionInProgress(true))
      })
    }
  }

  const decline = () => {
    simpleUser.hangup().then(() => setSessionInProgress(false))
  }

  const answer = async () => {
    await simpleUser.answer().then(() => setCalling(true))
  }

  const holdMic = () => {
    simpleUser.hold()
  }

  const unholdMic = () => {
    simpleUser.unhold()
  }

  const toggle = () => {
    disclosureProps.reject()
    audioRingtone.pause()
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    simpleUser && setStatus(true)
    if (!simpleUser) {
      setStatus(false)
      setConnected(false)
    }
  }, [])

  return (
    <>
      <ModalHeader toggle={toggle}>
        <div className='d-flex gap-3'>
          <div>WebPhone</div>
          <div>
            Статус: {status ? 'Соединение установлено' : 'Соединения нет'}
          </div>
        </div>
      </ModalHeader>
      <ModalBody id='sipPhoneModal'>
        <audio id='remoteAudio' style={{ display: 'none' }}></audio>
        {!connected && (
          <>
            <div>Логин</div>
            <Input
              type='text'
              placeholder='Введите Ваш логин'
              value={login}
              onChange={(e) => {
                setLogin(e.target.value)
              }}
            />
            <br />
            <div>Пароль</div>
            <Input
              type='password'
              placeholder='Введите Ваш пароль'
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            <br />
            <Button
              type='button'
              onClick={authInAsterisk}
              disabled={login.length < 4}
            >
              Авторизоваться в системе
            </Button>
            <br />
          </>
        )}
        {connected && (
          <>
            {isOutCommingCall ? (
              <OutComming
                id={id}
                call={calling}
                reject={decline}
                answer={answer}
                hold={holdMic}
                unHold={unholdMic}
              />
            ) : (
              <>
                {sessionInProgress ? (
                  <InProgress
                    reject={decline}
                    number={number}
                    hold={holdMic}
                    unHold={unholdMic}
                  />
                ) : (
                  <Connected
                    reject={decline}
                    call={call}
                    value={number}
                    onChange={setNumber}
                  />
                )}
              </>
            )}
          </>
        )}
      </ModalBody>
    </>
  )
}
