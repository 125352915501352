import { FieldValue, TableHeader, Values } from '../types/queues.types'
import {
  mappingFilterValue,
  mappingListValue,
  mappingOrderValue
} from '../utils/queues.utils'
import { formatAPIDate } from '../../../../utils'

export const ENABLE_ACTIONS = true
export const ENABLE_DELETE = true

export const TABLE_HEADERS: Record<string, TableHeader> = {
  id: {},
  queue_name: { label: 'queue_name' },
  users: { label: 'collectors', mappingValue: mappingListValue },
  groups: { label: 'Groups', mappingValue: mappingListValue },
  seq: { label: 'Seq' }
  //created_at: {isDateTime: true},
  //order_income: {},
  //order_promise_date: {},
  //order_day_past_due_promise: {},
  //order_prolongation_count: {},
  //order_loan_stage: {},

  //login_date: {label: 'login_date', mappingValue: (arr) => arr?.join(', ') || '-'},
  //promise_date: {label: 'promise_date', mappingValue: (arr) => arr?.join(', ') || '-'},
  //income: {label: 'income', mappingValue: (arr) => arr?.join(' - ') || '-'},
  //day_past_due_promise: {label: 'day_past_due_promise', mappingValue: (arr) => arr?.join(' - ') || '-'},
  //prolongation_count: {label: 'prolongation_count', mappingValue: (arr) => arr?.join(' - ') || '-'},
  //loan_stage: {label: 'loan_stage', mappingValue: (arr) => arr?.join(' - ') || '-'},
}

export const FIELDS_DESCRIPTION: Record<string, FieldValue> = {
  //order
  order_income: {
    type: 'order',
    label: 'order_income',
    mappingValue: mappingOrderValue
  },
  order_login_date: {
    type: 'order',
    label: 'order_login_date',
    mappingValue: mappingOrderValue
  },
  order_promise_date: {
    type: 'order',
    label: 'order_promise_date',
    mappingValue: mappingOrderValue
  },
  order_day_past_due_promise: {
    type: 'order',
    label: 'order_day_past_due_promise',
    mappingValue: mappingOrderValue
  },
  order_prolongation_count: {
    type: 'order',
    label: 'order_prolongation_count',
    mappingValue: mappingOrderValue
  },
  order_loan_stage: {
    type: 'order',
    label: 'order_loan_stage',
    mappingValue: mappingOrderValue
  },
  order_collection_score: {
    type: 'order',
    label: 'order_collection_score',
    mappingValue: mappingOrderValue
  },
  order_dpd: {
    type: 'order',
    label: 'order_dpd',
    mappingValue: mappingOrderValue
  },
  order_amount_debt: {
    type: 'order',
    label: 'order_amount_debt',
    mappingValue: mappingOrderValue
  },
  order_timezone: {
    type: 'order',
    label: 'order_timezone',
    mappingValue: mappingOrderValue
  },

  //filter
  login_date: {
    type: 'filter',
    label: 'login_date',
    mappingValue: mappingFilterValue
  },
  promise_date: {
    type: 'filter',
    label: 'promise_date',
    mappingValue: mappingFilterValue
  },
  income: { type: 'filter', label: 'income', mappingValue: mappingFilterValue },
  day_past_due_promise: {
    type: 'filter',
    label: 'day_past_due_promise',
    mappingValue: mappingFilterValue
  },
  prolongation_count: {
    type: 'filter',
    label: 'prolongation_count',
    mappingValue: mappingFilterValue
  },
  loan_stage: {
    type: 'filter',
    label: 'loan_stage',
    mappingValue: mappingFilterValue
  },
  collection_score: {
    type: 'filter',
    label: 'collection_score',
    mappingValue: mappingFilterValue
  },
  dpd: { type: 'filter', label: 'dpd', mappingValue: mappingFilterValue },
  amount_debt: {
    type: 'filter',
    label: 'amount_debt',
    mappingValue: mappingFilterValue
  },
  timezone: {
    type: 'filter',
    label: 'timezone',
    mappingValue: mappingFilterValue
  }
}

// Edit.tsx
const booleanOptions = [
  { name: '-', value: '-' },
  { name: 'asc', value: 1 },
  { name: 'desc', value: -1 }
]

export const INITIAL_INPUTS: Record<string, Partial<Values>> = {
  //who
  users: { type: 'common', label: 'collector_fio', initial: [], isList: true },
  groups: {
    type: 'common',
    label: 'group_name',
    initial: [],
    isList: true,
    options: booleanOptions
  },
  queue_name: {
    type: 'common',
    label: 'queue_name',
    initial: '',
    style: { maxWidth: 240 }
  },
  seq: {
    type: 'common',
    label: 'seq',
    initial: 0,
    isNumber: true,
    style: { maxWidth: 240 }
  },

  //order
  order_income: {
    type: 'order',
    order: {
      label: 'order_income',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_login_date: {
    type: 'order',
    order: {
      label: 'order_login_date',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_promise_date: {
    type: 'order',
    order: {
      label: 'order_promise_date',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_day_past_due_promise: {
    type: 'order',
    order: {
      label: 'order_day_past_due_promise',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_prolongation_count: {
    type: 'order',
    order: {
      label: 'order_prolongation_count',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_loan_stage: {
    type: 'order',
    order: {
      label: 'order_loan_stage',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_collection_score: {
    type: 'order',
    order: {
      label: 'order_collection_score',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_dpd: {
    type: 'order',
    order: {
      label: 'order_dpd',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_amount_debt: {
    type: 'order',
    order: {
      label: 'order_amount_debt',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_timezone: {
    type: 'order',
    priority: { label: 'priority', initial: 0, isNumber: true },
    order: {
      label: 'order_timezone',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    }
  },

  //filter
  login_date: {
    type: 'filter',
    label: 'login_date',
    initial: [formatAPIDate(new Date()), formatAPIDate(new Date())],
    dateRange: true,
    isOptional: true
  },
  promise_date: {
    type: 'filter',
    label: 'promise_date',
    initial: [formatAPIDate(new Date()), formatAPIDate(new Date())],
    dateRange: true,
    isOptional: true
  },
  income: {
    type: 'filter',
    label: 'income',
    initial: [0, 200000],
    min: 0,
    max: 200000,
    step: 1000,
    numberRange: true,
    isOptional: true
  },
  day_past_due_promise: {
    type: 'filter',
    label: 'day_past_due_promise',
    initial: [0, 365],
    min: 0,
    max: 365,
    step: 1,
    numberRange: true,
    isOptional: true
  },
  prolongation_count: {
    type: 'filter',
    label: 'prolongation_count',
    initial: [0, 50],
    min: 0,
    max: 50,
    step: 1,
    numberRange: true,
    isOptional: true
  },
  loan_stage: {
    type: 'filter',
    label: 'loan_stage',
    initial: [0, 10],
    min: 0,
    max: 10,
    step: 1,
    numberRange: true,
    isOptional: true
  },
  collection_score: {
    type: 'filter',
    label: 'collection_score',
    initial: [0, 200],
    min: 0,
    max: 200,
    step: 1,
    numberRange: true,
    isOptional: true
  },
  dpd: {
    type: 'filter',
    label: 'dpd',
    initial: [],
    isList: true,
    isNumber: true,
    isOptional: true
  },
  amount_debt: {
    type: 'filter',
    label: 'amount_debt',
    initial: [0, 100000],
    min: 0,
    max: 100000,
    step: 1000,
    numberRange: true,
    isOptional: true
  }
}

export const TIME_ZONE_OPTIONS = [
  { value: 'UTC+2', label: 'UTC+2' },
  { value: 'UTC+3', label: 'UTC+3' },
  { value: 'UTC+4', label: 'UTC+4' },
  { value: 'UTC+5', label: 'UTC+5' },
  { value: 'UTC+6', label: 'UTC+6' },
  { value: 'UTC+7', label: 'UTC+7' },
  { value: 'UTC+8', label: 'UTC+8' },
  { value: 'UTC+9', label: 'UTC+9' },
  { value: 'UTC+10', label: 'UTC+10' },
  { value: 'UTC+11', label: 'UTC+11' },
  { value: 'UTC+12', label: 'UTC+12' }
]

export const ALL_OPTIONS = { value: 'selectAll', label: 'Выбрать все' }
