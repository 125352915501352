import { useState } from 'react'
import {
  useSetFreezeAccrualMutation,
  useGetFreezeAvailableQuery,
} from 'src/services/loans'
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Form,
  FormGroup,
  Col,
  Row,
  Label,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'

export default function FreezeAccrual(props: { loanId: string }) {
  const { loanId } = props
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [setFreezeAccrual, { isSuccess, isError }] =
    useSetFreezeAccrualMutation()
  const { data } = useGetFreezeAvailableQuery(loanId, { skip: !loanId })

  const mapBooleanStringToBoolean = (
    s: 'true' | 'false' | string | undefined | null | boolean,
  ) => {
    if (typeof s === 'boolean') return s

    switch (s) {
      case 'true':
        return true
      case 'false':
        return false
      default:
        return false
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      start_date: '',
      end_date: '',
      interest_frozen: 'false',
      penalties_frozen: 'false',
      comment: '',
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.string().nullable(),
      end_date: Yup.string().nullable(),
      interest_frozen: Yup.string().nullable(),
      penalties_frozen: Yup.string().nullable(),
      comment: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      setFreezeAccrual({
        loanId: loanId,
        body: {
          ...values,
          interest_frozen: mapBooleanStringToBoolean(values.interest_frozen),
          penalties_frozen: mapBooleanStringToBoolean(values.penalties_frozen),
        },
      })
    },
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Button type="button" onClick={toggle} disabled={!data}>
        {t('freeze_accrual')}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('freeze_accrual')}</ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="start_date">{t('start_date')}</Label>
                  <Flatpickr
                    name="start_date"
                    id="start_date"
                    type="date"
                    className="form-control form-control-sm"
                    options={{
                      dateFormat: 'Y-m-d',
                      onChange: (selectedDates: any, dateStr: any) => {
                        console.log('dateStr', dateStr)
                        formik.setFieldValue('start_date', dateStr)
                      },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="end_date">{t('end_date')}</Label>
                  <Flatpickr
                    name="end_date"
                    id="end_date"
                    type="date"
                    className="form-control form-control-sm"
                    options={{
                      dateFormat: 'Y-m-d',
                      onChange: (selectedDates: any, dateStr: any) => {
                        console.log('dateStr', dateStr)
                        formik.setFieldValue('end_date', dateStr)
                      },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="interest_frozen">
                    {t('interest_frozen')}
                  </Label>
                  <select
                    name="interest_frozen"
                    id="interest_frozen"
                    className="form-select form-select-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.interest_frozen}
                  >
                    <option value="false">{t('false')}</option>
                    <option value="true">{t('true')}</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="penalties_frozen">
                    {t('penalties_frozen')}
                  </Label>
                  <select
                    name="penalties_frozen"
                    id="penalties_frozen"
                    className="form-select form-select-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.penalties_frozen}
                  >
                    <option value="false">{t('false')}</option>
                    <option value="true">{t('true')}</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{t('comment')}</Label>
                  <Input
                    name="comment"
                    id="comment"
                    type="text"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit">{t('Save')}</Button>
            <br />
            {isSuccess && <Alert color="success">{t('Success')}</Alert>}
            {isError && <Alert color="danger">{t('Error')}</Alert>}
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
