import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  LIST,
  UPDATE,
} from "./actionType";

import {
  getDialoguesFail,
  getDialoguesSuccess,
  updateDialogueSuccess,
  updateDialogueFail,
  getDialogues,
  getDialogueSuccess,
  getDialogueFail,
} from "./action";

import Client from "../../client";

function* getList({ id, filter }) {
  try {
    if (id) {
      const response = yield call(Client.getDialogue, id);
      yield put(getDialogueSuccess(response));
    } else {
      const response = yield call(Client.getList, Client.ENTITY_TYPE.DIALOGUES, filter);
      yield put(getDialoguesSuccess(response));
    }
  } catch (error) {
    if (id) {
      yield put(getDialogueFail(error));
    } else {
      yield put(getDialoguesFail(error));
    }
  }
}

function* updateEntity({ payload }) {
  try {
    const response = yield call(Client.updateDialogue, payload);
    console.log('updateDialogue response =>', response);
    yield put(updateDialogueSuccess(response));
    yield put(getDialogues(payload.id));
  } catch (error) {
    yield put(updateDialogueFail(error));
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getList);
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateEntity);
}

function* saga() {
  yield all([
    fork(watchOnGetList),
    fork(watchOnUpdate),
  ]);
}

export default saga;
