import React from 'react'
import { useTranslation } from 'react-i18next'
import UiContent from 'src/Components/Common/UiContent'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import Loader from 'src/Components/Common/Loader'
import LoadingPage from '../LoadingPage'
import ErrorPage, { DangerAlert } from '../ErrorPage'
import {
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  useGetCurrentPaymentGateQuery,
  useGetPaymentGatesQuery,
  useUpdateCurrentPaymentGateMutation
} from 'src/services/payments'

export const PaymentGate = () => {
  const { t } = useTranslation()

  const {
    data: currentGate,
    isLoading: currentGateLoading,
    error: currentGateError
  } = useGetCurrentPaymentGateQuery()

  const {
    data: gates = [],
    isLoading: gatesLoading,
    error: gatesError
  } = useGetPaymentGatesQuery()

  const [
    updateCurrentGate,
    { isLoading: updatingCurrentGate, error: updatingError }
  ] = useUpdateCurrentPaymentGateMutation()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      gate: currentGate
    },
    validationSchema: Yup.object().shape({
      gate: Yup.string()
        .required(t('Required'))
        .test(
          'is-not-current',
          t('The selected payment gate is already installed'),
          (value) => value !== currentGate
        )
    }),
    onSubmit: async (values) => {
      await updateCurrentGate(values.gate!)
    }
  })

  const title = t('Payment gate')
  document.title = title
  const bread = <BreadCrumb title={title} />

  if (currentGateLoading) return <LoadingPage title={title} />
  if (currentGateError)
    return <ErrorPage title={title} error={currentGateError} />

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            <h5 className='mb-3'>
              {t('Current payment gate')}:{' '}
              <span className={'text-primary'}>{currentGate}</span>
            </h5>
            {gatesLoading ? (
              <Loader />
            ) : gatesError ? (
              <DangerAlert error={gatesError} />
            ) : (
              <Form
                className='needs-validation'
                style={{ maxWidth: '300px' }}
                onSubmit={validation.handleSubmit}
              >
                <FormGroup>
                  <Label htmlFor='gate'>{t('Change payment gate')}</Label>
                  <Input
                    id='gate'
                    name='gate'
                    type='select'
                    className='form-select'
                    value={validation.values.gate}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={!!validation.errors.gate}
                  >
                    {gates.map((gate) => (
                      <option key={gate} value={gate}>
                        {gate}
                      </option>
                    ))}
                  </Input>
                  {validation.errors.gate && (
                    <FormFeedback type='invalid'>
                      {validation.errors.gate}
                    </FormFeedback>
                  )}
                </FormGroup>
                {updatingCurrentGate ? (
                  <Loader />
                ) : (
                  <Button
                    type='submit'
                    color='primary'
                    style={{ backgroundColor: '#405189' }}
                  >
                    {t('Update')}
                  </Button>
                )}
                {!!updatingError && <DangerAlert error={updatingError} />}
              </Form>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  )
}
