import { api, omitBlankEntries } from './api'

export type TemplatesClaim = {
  id: number
  created_at: string
  updated_at: string
  name: string
  text_template: string
}

export const TemplatesClaimApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTextTemplates: builder.query<
      TemplatesClaim[],
      Partial<{ name: string }>
    >({
      query: (params) => ({
        url: `crm/v1/claims/text-templates`,
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'TemplatesClaim', id }) as const),
        { type: 'TemplatesClaim' as const, id: 'LIST' },
      ],
    }),
    getTextTemplate: builder.query<TemplatesClaim, { id: number }>({
      query: ({ id }) => ({
        url: `/crm/v1/claims/text-templates/${id}`,
      }),
      providesTags: (_result, _err, { id }) => [{ type: 'TemplatesClaim', id }],
    }),
    updateTextTemplate: builder.mutation<
      TemplatesClaim,
      Partial<TemplatesClaim>
    >({
      query: ({ id, ...body }) => ({
        url: `/crm/v1/claims/text-templates/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: (template) => [
        { type: 'TemplatesClaim', id: template?.id },
      ],
    }),
    addTextTemplate: builder.mutation<TemplatesClaim, Partial<TemplatesClaim>>({
      query: (body) => ({
        url: '/crm/v1/claims/text-templates',
        method: 'POST',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: () => ['TemplatesClaim'],
    }),
    deleteTextTemplate: builder.mutation<any, number | null>({
      query: (id) => ({
        url: `/crm/v1/claims/text-templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['TemplatesClaim'],
    }),
  }),
})

export const {
  useGetTextTemplatesQuery,
  useGetTextTemplateQuery,
  useAddTextTemplateMutation,
  useDeleteTextTemplateMutation,
  useUpdateTextTemplateMutation,
} = TemplatesClaimApi
