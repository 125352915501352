import { suggestionsApi } from './suggestionsApi'
import { Address } from './clients'

type AddressesSuggestions = {
  unrestricted_value: string
  value: string
  data: Address
}
type AddressesListRes = { suggestions: AddressesSuggestions[] }

export const { useGetAddressesListQuery } = suggestionsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAddressesList: builder.query<AddressesListRes, string>({
      query: (query) => ({
        url: `suggestions/api/4_1/rs/suggest/address`,
        method: 'POST',
        body: { query, count: 5 }
      })
    })
  })
})
