import { api, omitBlankEntries } from './api'

type CompanyNames = {
  id: number
  short_name: string
}

type MainCompanyInfo = {
  id?: number
  full_name: string
  short_name: string
  ceo_name: string
  inn: number
  kpp: number
  ogrn: number
  legal_address: string
  postal_address: string
  legal_email: string
  communication_email: string
  registry_email: string
  customer_support_email: string
  phone: string
  phone_prefix: number
  logo_url: string
  is_owner: boolean
  blocked: boolean | null
  website: string
  bank_name: string
  bic: string
  bank_account: string
  bank_corr_account: string
  other_name: string
  lei: string
  tax_code: string
  right_of_claim_code: number
}

export const CompaniesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMainCompanyList: builder.query<MainCompanyInfo[], void>({
      query: () => '/crm/v1/companies/companies',
      providesTags: () => ['Companies'],
    }),
    getMainCompanyView: builder.query<MainCompanyInfo, string>({
      query: (id) => ({
        url: `/crm/v1/companies/company-info`,
        params: omitBlankEntries({ id: id }),
      }),
    }),
    getCompanyNames: builder.query<CompanyNames[], null>({
      query: () => ({
        url: `crm/v1/companies/company-names`,
      }),
    }),
    getMainCompanyInfo: builder.query<MainCompanyInfo, any>({
      query: () => ({
        url: `crm/v1/companies/main-company-info`,
      }),
    }),
    addMainCompany: builder.mutation<void, MainCompanyInfo>({
      query: (body) => ({
        url: '/crm/v1/companies/companies/add',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => ['Companies'],
    }),
    editMainCompany: builder.mutation<void, MainCompanyInfo>({
      query: (body) => ({
        url: '/crm/v1/companies/edit',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: () => ['Companies'],
    }),
  }),
})

export const {
  useGetMainCompanyListQuery,
  useGetMainCompanyViewQuery,
  useGetCompanyNamesQuery,
  useGetMainCompanyInfoQuery,
  useAddMainCompanyMutation,
  useEditMainCompanyMutation,
} = CompaniesApi
