import { api, omitBlankEntries } from './api'

type Call = any

export type CallPerson = {
  id: number
  is_deleted: boolean
  name: string
  type: string
  type_id: number
}

type Group = {
  id: number
  name: string
  created_at: string
}

export type CallReason = {
  id: string
  name: string
  type: string
  type_id: number
}

export type CallResult = {
  id: string
  name: string
}

export type CallSetting = {
  id: number
  client_type: string
  result_type: string
  client_type_id: number
  result_type_id: number
  need_reason: boolean
  need_promise_amount: boolean
  need_promise_date: boolean
  need_comment: boolean
  is_default: boolean
}

export type QueuesResponse = {
  groups: any[]
  id: number
  order_promise_date: {
    order: number
    priority: number
  }
  promise_date: Date[]
  queue_name: string
  seq: number
  users: string[]
}

export type QueueOrder = {
  order: number
  priority: number
}

export type QueueResponse = {
  id: number
  queue_name: string
  users: string[] | []
  groups: number[] | []
  seq: number
  income?: number[]
  login_date?: string[]
  promise_date?: string[]
  day_past_due_promise?: number[]
  prolongation_count?: number[]
  loan_stage?: number[]
  collection_score?: number[]
  amount_debt?: number[]
  timezone?: string[]
  order_income?: QueueOrder
  order_timezone?: QueueOrder
  order_amount_debt?: QueueOrder
  order_dpd?: QueueOrder
  order_collection_score?: QueueOrder
  order_loan_stage?: QueueOrder
  order_day_past_due_promise?: QueueOrder
  order_promise_date?: QueueOrder
  order_login_date?: QueueOrder
  order_prolongation_count?: QueueOrder
  dpd?: unknown
}

type QueuesParams = {
  keyword: string
  limit: number
  offset: number
}

type QueueParams = {
  queue_params_id: number
}

export type QueueBody = {
  amount_debt?: number[]
  collection_score?: number[]
  day_past_due_promise?: number[]
  dpd?: number[]
  groups?: [4]
  income?: number[]
  loan_stage?: number[]
  login_date?: string[]
  order_income?: { order: number; priority: number }
  order_login_date?: { order: number; priority: number }
  order_promise_date?: { order: number; priority: number }
  prolongation_count?: number[]
  promise_date?: string[]
  queue_name: string
  seq: number
  timezone?: string[]
  users?: string[]
}

export const {
  useGetCallsQuery,
  useGetCallPersonsQuery,
  useGetGroupsQuery,
  useUpdateGroupMutation,
  useDeleteSegmentMutation,
  useGetCallReasonsByProfileIdQuery,
  useAddCallResultMutation,
  useAddCallEntityMutation,
  useUpdateCallEntityMutation,
  useDeleteCallEntityMutation,
  useGetCallSettingsQuery,
  useAddCallSettingsMutation,
  useUpdateCallSettingsMutation,
  useDeleteCallSettingsMutation,
  useArchiveEntityMutation,
  useGetQueuesQuery,
  useGetQueueQuery,
  useDeleteQueueMutation,
  useCreateQueueMutation,
  useUpdateQueueMutation
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query<Call[], string>({
      query: (id) => `crm/v1/calling/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Calls', id }]
    }),
    getCallPersons: builder.query<CallPerson[], void>({
      query: () => `crm/v1/calling/person`,
      providesTags: ['CallPerson']
    }),
    getGroups: builder.query<Group[], void>({
      query: () => `crm/v1/calling/groups`,
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Groups', id }) as const),
        { type: 'Groups' as const, id: 'LIST' }
      ]
    }),
    getCallReasonsByProfileId: builder.query<
      CallReason[],
      Partial<{ person_id: number }> | void
    >({
      query: (params) => ({
        url: `crm/v1/calling/reason`,
        params: params || undefined
      }),
      providesTags: ['CallReason']
    }),
    getCallResultsByProfileId: builder.query<CallResult[], any>({
      query: (params) => ({
        url: `crm/v1/calling/result`,
        params: params || undefined
      }),
      providesTags: ['CallResult']
    }),
    archiveEntity: builder.mutation<void, string>({
      query: (id) => ({
        url: `crm/v1/calling/queue-params/${id}`,
        method: 'POST'
      })
    }),
    addCallResult: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/call-result`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['Calls']
    }),
    updateGroup: builder.mutation<
      void,
      Pick<Group, 'id'> & { user_ids: string[]; new_name: string }
    >({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/groups/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: 'Groups', id: request.id }
      ]
    }),
    deleteSegment: builder.mutation<any, string | null>({
      query: (id) => ({
        url: `crm/v1/calling/stages/${id}`,
        method: 'DELETE'
      })
    }),
    addCallEntity: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason']
    }),
    updateCallEntity: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason']
    }),
    deleteCallEntity: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'DELETE',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason', 'CallSettings']
    }),
    getCallSettings: builder.query<
      CallSetting[],
      Partial<{ person_id: number }> | void
    >({
      query: (params) => ({
        url: `crm/v1/calling/settings`,
        params: params || undefined
      }),
      providesTags: ['CallSettings']
    }),
    addCallSettings: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/settings`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallSettings']
    }),
    updateCallSettings: builder.mutation<void, Partial<any>>({
      query: (body) => {
        const { id, ...rest } = body
        return {
          url: `crm/v1/calling/settings?settings_id=${id}`,
          method: 'PUT',
          body: omitBlankEntries(rest)
        }
      },
      invalidatesTags: ['CallSettings']
    }),
    deleteCallSettings: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/settings`,
        method: 'DELETE',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallSettings']
    }),
    getQueues: builder.query<QueuesResponse[], QueuesParams>({
      query: (params) => ({
        url: `crm/v1/calling/queue-params`,
        params
      }),
      providesTags: ['Queues']
    }),
    getQueue: builder.query<QueueResponse[], QueueParams>({
      query: (params) => ({
        url: `crm/v1/calling/queue-params`,
        params
      })
    }),
    deleteQueue: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `crm/v1/calling/queue-params/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['Queues']
    }),
    updateQueue: builder.mutation<boolean, { id: string } & QueueBody>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/queue-params/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Queues']
    }),
    createQueue: builder.mutation<boolean, QueueBody>({
      query: (body) => ({
        url: `crm/v1/calling/queue-params`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Queues']
    })
  })
})
