import { api, omitBlankEntries, PartialExceptTheseRequired } from './api'

export type ExtensionType = {
  name: string
  enable: boolean
  min_amount: number
  max_amount: number
  min_dpd: number
  max_dpd: number
  min_loans: number
  max_loans: number
  id: number
}

// TODO: add extension-settings to src/services/extension-settings.ts
// TODO: remove product-settings saga
// TODO: remove product-settings reducer
// TODO: remove product-settings actions
export const {
  useGetExtensionTypeQuery,
  useGetExtensionTypesQuery,
  useAddExtensionTypeMutation,
  useUpdateExtensionTypeMutation,
  useDeleteExtensionTypeMutation
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getExtensionTypes: builder.query<
      ExtensionType[],
      {
        limit: number
        offset: number
      }
    >({
      query: (params) => ({
        url: `crm/v1/extension-settings/extension-types`,
        params
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'ExtensionType', id }) as const),
        { type: 'ExtensionType' as const, id: 'LIST' }
      ]
    }),
    getExtensionType: builder.query<ExtensionType, string>({
      query: (extensionTypeId) =>
        `crm/v1/extension-settings/extension-types/${extensionTypeId}`,
      providesTags: (_result, _err, id) => [{ type: 'ExtensionType', id }]
    }),
    addExtensionType: builder.mutation<void, Omit<ExtensionType, 'id'>>({
      query: (body) => ({
        url: `crm/v1/extension-settings/extension-types`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: () => ['ExtensionType']
    }),
    updateExtensionType: builder.mutation<
      void,
      PartialExceptTheseRequired<ExtensionType, 'id'>
    >({
      query: ({ id, ...body }) => ({
        url: `crm/v1/extension-settings/extension-types/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: () => ['ExtensionType']
    }),
    deleteExtensionType: builder.mutation<void, string>({
      query: (extensionTypeId) => ({
        url: `crm/v1/extension-settings/extension-types/${extensionTypeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => ['ExtensionType']
    })
  })
})
