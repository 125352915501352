import { ComponentProps, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'

// if using with useFormik and modal then replace useFormik with
// <Formik...>{(formik) => ({...})}
// because useFormik looses connection with the elements because Modal creates portal
export const ConfirmButton: FC<
  {
    onConfirm: () => void
  } & ComponentProps<typeof Button>
> = ({ disabled, onConfirm, size, children, ...buttonProps }) => {
  const { t } = useTranslation()

  const [showConfirm, setShowMark] = useState(false)

  return showConfirm ? (
    <Row className="align-items-center" style={{ whiteSpace: 'nowrap' }}>
      <Col>
        <Button
          size={size}
          onClick={() => {
            setShowMark(false)
            onConfirm()
          }}
          block
          color="danger"
          disabled={disabled}
        >
          {t('Yes, I am sure')}
        </Button>
      </Col>
      <Col>
        <Button
          size={size}
          onClick={() => setShowMark(false)}
          disabled={disabled}
          color="success"
          block
        >
          {t('No, cancel')}
        </Button>
      </Col>
    </Row>
  ) : (
    <Button
      disabled={showConfirm || disabled}
      color="primary"
      size={size}
      block
      onClick={() => setShowMark(true)}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}
