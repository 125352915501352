import {
    LIST,
    LIST_SUCCESS,
    LIST_FAIL,
} from "./actionType";

//get all
export const getRoles = (id = null, filter = '') => ({
    type: LIST,
    id,
    filter,
})

export const getRolesSuccess = entities => ({
    type: LIST_SUCCESS,
    payload: entities,
})

export const getRolesFail = error => ({
    type: LIST_FAIL,
    payload: error,
})