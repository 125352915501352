import { useTranslation } from 'react-i18next'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import cx from 'classnames'
import { useGetMessagesQuery, useGetMyChatsQuery } from 'src/services/chats'
import moment from 'moment'
import { utcToLocal } from 'src/utils'
import { LIST_LIMIT } from 'src/client'
import { UseQueryHookResultData } from 'src/services/api'
import { useMemo } from 'react'

export const Chats = () => {
  const { t } = useTranslation()
  const {
    data: chats,
    error,
    isError
  } = useGetMyChatsQuery({
    limit: LIST_LIMIT,
    offset: 0,
    status: 'Active'
  })

  const title = t('Chats')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  if (!chats) {
    return <LoadingPage title={title} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} />
      <ListGroup>
        {chats.map((chat) => (
          <ListGroupItem key={chat.id} action>
            <ChatPreview chat={chat} />
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}

const ChatPreview = ({
  chat
}: {
  chat: UseQueryHookResultData<typeof useGetMyChatsQuery>[0]
}) => {
  const { data: messages, error } = useGetMessagesQuery(
    { chatId: chat?.id.toString() },
    { skip: chat?.id == null }
  )

  const unreadCount = useMemo(
    () =>
      messages?.reduce(
        (accumulator, current) =>
          current.is_new && current.author_type !== 'user'
            ? accumulator + 1
            : accumulator,
        0
      ),
    [messages]
  )

  const { t } = useTranslation()

  const lastMessage = messages?.[messages?.length - 1]

  return (
    <Link to={`/chats/${chat.id}`} className='fw-medium'>
      <div className='d-flex justify-content-between align-items-center'>
        <div style={{ maxWidth: '80%' }}>
          <h5>{chat.client_name}</h5>
          <p className='d-flex mb-0 gap-1'>
            {lastMessage?.author_type === 'user' && <b>{t('You')}:</b>}
            <span className='text-truncate w-100'>
              {lastMessage?.content_type === 'text' ? (
                lastMessage?.content
              ) : lastMessage?.content_type === 'image' ? (
                t('Image')
              ) : error ? (
                <b className='text-danger'>
                  {t('Unknown error')} {JSON.stringify(error)}
                </b>
              ) : (
                // if loading
                <span className='d-flex placeholder-glow gap-1 w-100 mb-0 py-1'>
                  <span className='placeholder col-4' />
                  <span className='placeholder col-4' />
                  <span className='placeholder col-5' />
                  <span className='placeholder col-7' />
                </span>
              )}
            </span>
          </p>
        </div>
        <div className='d-flex flex-column flex-shrink-0'>
          <div className='d-flex gap-1 justify-content-center align-items-center'>
            <i
              className={cx('bx-xs', {
                // required <i className="bx bx-..."
                bx: lastMessage?.author_type === 'user',
                // read
                'bx-check-double':
                  lastMessage?.author_type === 'user' && !lastMessage?.is_new,
                // unread
                'bx-check':
                  lastMessage?.author_type === 'user' && lastMessage?.is_new
              })}
            />
            <span>
              {moment(utcToLocal(lastMessage?.created_at))
                .locale('ru')
                .fromNow()}
            </span>
          </div>
          <div className='align-self-end'>
            <span
              className={cx('badge rounded-pill bg-primary', {
                'opacity-0': unreadCount === 0
              })}
            >
              {unreadCount}
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}
