import Client from "../../client";
import {LIST} from "./actionType";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {getUsersFail, getUsersSuccess} from "./action";

function* getList({ id, filter }) {
    try {
        if (id) {
            const user = {};
            user.activeRoles = yield call(Client.getUserPermissions, id);
            user.allRoles = yield call(Client.getList, Client.ENTITY_TYPE.ROLES);

            console.log(`GOT USER ${id}`, user);
            yield put(getUsersSuccess([user]));
        } else {
            const response = yield call(Client.getList, Client.ENTITY_TYPE.USERS, filter);
            yield put(getUsersSuccess(response));
        }
    } catch (error) {
        yield put(getUsersFail(error));
    }
}

export function* watchOnGetList() {
    yield takeEvery(LIST, getList);
}

function* usersSaga() {
    yield all([
        fork(watchOnGetList),
    ]);
}

export default usersSaga;
