import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  ModalHeader,
  Label,
  Input
} from 'reactstrap'
import Loader from 'src/Components/Common/Loader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CallPerson,
  useAddCallEntityMutation,
  useUpdateCallEntityMutation
} from 'src/services/calling'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from '../ErrorPage'

interface EditPersonProps {
  persons?: CallPerson[]
  className?: string
}

export const EditPerson: FC<EditPersonProps> = ({ persons, className }) => {
  const { t } = useTranslation()
  const { isOpen, open, close, toggle } = useDisclosure(false)

  const [selectedPersonId, setSelectedPersonId] = useState<number | null>(null)

  const [addPerson, { isLoading: isAdding, error: addError }] =
    useAddCallEntityMutation()
  const [updatePerson, { isLoading: isUpdating, error: updateError }] =
    useUpdateCallEntityMutation()

  const toggleModal = () => {
    if (isOpen) {
      validation.resetForm()
      setSelectedPersonId(null)
    }
    toggle()
  }

  const isEditMode = !!persons

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      type_id: selectedPersonId ?? ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Required')),
      ...(isEditMode && {
        type_id: Yup.number().required(t('Required'))
      })
    }),
    onSubmit: async (values) => {
      const { name, type_id } = values
      if (isEditMode) {
        await updatePerson({ type: 'client', type_id, name })
      } else {
        await addPerson({ type: 'client', name })
      }
      if (!(addError || updateError)) {
        close()
      }
    }
  })

  const handlePersonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type_id = Number(e.target.value)
    setSelectedPersonId(type_id)

    const selectedPerson = persons?.find((person) => person.type_id === type_id)
    validation.setFieldValue('type_id', type_id)
    validation.setFieldValue('name', selectedPerson?.name || '')
  }

  return (
    <div className={`d-flex ${className}`}>
      <Button
        color='primary'
        onClick={open}
        style={{ backgroundColor: '#405189' }}
      >
        {isEditMode ? (
          <i className='ri-edit-2-line label-icon align-middle fs-16'></i>
        ) : (
          <i className='ri-add-line label-icon align-middle fs-16'></i>
        )}
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          {isEditMode ? t('Edit client type') : t('Add client type')}
        </ModalHeader>
        <ModalBody>
          <Form className='needs-validation' onSubmit={validation.handleSubmit}>
            {isEditMode && (
              <FormGroup>
                <Label for='person-select'>
                  {t('Select a client to edit')}
                </Label>
                <select
                  name='type_id'
                  id='person-select'
                  onChange={handlePersonChange}
                  value={validation.values.type_id}
                  className={`form-control ${
                    validation.touched.type_id && validation.errors.type_id
                      ? 'is-invalid'
                      : ''
                  }`}
                >
                  <option value=''>{t('Select a client')}</option>
                  {persons?.map((person) => (
                    <option key={person.type_id} value={person.type_id}>
                      {person.name}
                    </option>
                  ))}
                </select>
                {validation.touched.type_id && validation.errors.type_id ? (
                  <div className='invalid-feedback d-block'>
                    {validation.errors.type_id}
                  </div>
                ) : null}
              </FormGroup>
            )}
            <FormGroup>
              <Label for='validation-name'>
                {isEditMode ? t('New name') : t('Who were you talking to?')}
              </Label>
              <Input
                type='text'
                name='name'
                id='validation-name'
                placeholder={
                  isEditMode
                    ? t('Enter a new name')
                    : t('Who were you talking to?')
                }
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name}
                invalid={!!(validation.touched.name && validation.errors.name)}
                disabled={isEditMode && selectedPersonId === null}
              />
              {validation.touched.name && validation.errors.name ? (
                <div className='invalid-feedback d-block'>
                  {validation.errors.name}
                </div>
              ) : null}
            </FormGroup>
            {!!addError && <DangerAlert error={addError} />}
            {!!updateError && <DangerAlert error={updateError} />}
            {isAdding || isUpdating ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                disabled={
                  !validation.values.name ||
                  (isEditMode && selectedPersonId === null)
                }
              >
                {isEditMode ? t('Update') : t('Create')}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}
