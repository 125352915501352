import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS,

  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT,

  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,

  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,

  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
} from "./actionType";

const INIT_STATE = {
  products: [],
  productsLoading: false,
  productsError: null,

  product: null,
  productLoading: false,
  productError: null,

  createLoading: false,
  createError: null,

  deleteLoading: false,
  deleteError: null,

  updateLoading: false,
  updateError: null,
};

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    //all
    case GET_PRODUCTS:
      return {
        ...state,
        productsLoading: true,
        productsError: null,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        productsLoading: false,
        productsError: null,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        productsError: action.payload,
        productsLoading: false,
      }

    //get one
    case GET_PRODUCT:
      return {
        ...state,
        productLoading: true,
        productError: null,
      };

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        productLoading: false,
        productError: null,
      }

    case GET_PRODUCT_FAIL:
      return {
        ...state,
        productError: action.payload,
        productLoading: false,
      }

    //create
    case CREATE_PRODUCT:
      return {
        ...state,
        createError: null,
        createLoading: true,
      };

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        createError: null,
        createLoading: false,
      }

    case CREATE_PRODUCT_FAIL:
      return {
        ...state,
        createError: action.payload,
        createLoading: false,
      }

    //delete
    case DELETE_PRODUCT:
      return {
        ...state,
        deleteError: null,
        deleteLoading: true,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deleteError: null,
        deleteLoading: false,
      }

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        deleteError: action.payload,
        deleteLoading: false,
      }

    //update
    case UPDATE_PRODUCT:
      return {
        ...state,
        updateError: null,
        updateLoading: true,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        updateError: null,
        updateLoading: false,
      }

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        updateError: action.payload,
        updateLoading: false,
      }

    default:
      return state;
  }
};

export default Products;
