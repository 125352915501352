import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import List from 'src/Components/Common/EntitiesView/List'
import { useGetMainCompanyListQuery } from 'src/services/companies'
import { Link } from 'react-router-dom'

export const Companies = () => {
  const { t } = useTranslation()

  const { data: companyList, isError, error } = useGetMainCompanyListQuery()

  const title = t('Companies')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  if (!companyList) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={'/company-create'}
            backLink={undefined}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          {companyList && (
            <List
              list={companyList.map((company) => {
                return {
                  ...company,
                  id_link: `/company-view?id=${company.id}`,
                }
              })}
              className={undefined}
              style={{ width: 'fit-content' }}
              fieldsDescription={{
                id: {
                  label: ' ',
                  mappingValue: () => <i className="ri-eye-line fs-15" />,
                  isLink: true,
                  linkFieldName: 'id_link',
                },
                full_name: {},
                ceo_name: {},
                inn: {},
                phone: {},
                legal_address: {},
              }}
              hover
              actions={companyList.map((company) => {
                return (
                  <Link
                    to={`/company-edit?id=${company.id}`}
                    className="link-success fs-15"
                  >
                    <i className="ri-edit-2-line"></i>
                  </Link>
                )
              })}
            />
          )}
        </CardBody>
      </Card>
    </div>
  )
}
