import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

import ErrorPage from 'src/pages/Admin/ErrorPage'
import LoadingPage from 'src/pages/Admin/LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import List from 'src/Components/Common/EntitiesView/List'
import LimitNotice from 'src/Components/Common/LimitNotice'
import model from 'src/model'
import {
  ChatTopic,
  useGetChatTopicsQuery,
  useSetChatTopicHiddenMutation,
  useSetChatTopicVisibleMutation,
} from 'src/services/chats'
import { createPortal } from 'react-dom'
import { useState } from 'react'

const AddChatTopicModal = ({
  onToggle,
  isOpen,
  topic,
  onToggleTopicVisible,
}: {
  onToggle?: () => any
  isOpen?: boolean
  topic?: Partial<ChatTopic>
  onToggleTopicVisible?: () => any
}) => {
  const { t } = useTranslation()

  return createPortal(
    <Modal isOpen={isOpen} toggle={onToggle} centered>
      <ModalHeader toggle={onToggle}>
        {t('Change chat topic visibility')}
      </ModalHeader>
      <ModalBody>
        <Form>
          {Object.entries(topic || {})
            .filter(([key]) => key !== 'id')
            .map(([key, value]) => (
              <Col key={key}>
                <FormGroup className="mb-3">
                  <Label htmlFor={key}>{t(key)}</Label>
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    value={t(`${value}`)}
                    disabled
                  />
                </FormGroup>
              </Col>
            ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" block onClick={onToggleTopicVisible}>
          {topic?.chat_visible ? t('Hide from client') : t('Show to client')}
        </Button>
      </ModalFooter>
    </Modal>,
    document.body,
  )
}

export const ChatsTopics = () => {
  const { data: chatTopics, error, isError } = useGetChatTopicsQuery()

  const [topic, setTopic] = useState<ChatTopic>()

  const [setChatTopicHidden] = useSetChatTopicHiddenMutation()

  const [setChatTopicVisible] = useSetChatTopicVisibleMutation()

  const [show, setShow] = useState(false)

  const toggleModal = () => setShow((prev) => !prev)

  const handleToggleChatTopicVisibility = () => {
    toggleModal()
    topic?.chat_visible
      ? setChatTopicHidden({ topic_id: topic.id })
      : setChatTopicVisible({ topic_id: topic!.id })
  }

  const { t } = useTranslation()

  const title = t('Chats topics')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  if (!chatTopics) {
    return <LoadingPage title={title} />
  }

  return (
    <div className="page-content">
      <AddChatTopicModal
        isOpen={show}
        onToggle={toggleModal}
        topic={topic}
        onToggleTopicVisible={handleToggleChatTopicVisibility}
      />
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <LimitNotice limit={model.LIST_LIMIT} />
          <List
            list={chatTopics}
            style={{ width: 'fit-content' }}
            fieldsDescription={{
              id: {
                isButton: true,
                onClick: (selectedTopic: ChatTopic) => {
                  setTopic(selectedTopic)
                  toggleModal()
                },
              },
              topic_name: {},
              parent_topic_id: {},
              priority: {},
              roles: {},
              chat_visible: {
                mappingValue: (visible: boolean | null) =>
                  visible ? t('Yes') : t('No'),
              },
            }}
            hover
          />
        </CardBody>
      </Card>
    </div>
  )
}
