import {LIST, LIST_FAIL, LIST_SUCCESS} from "./actionType";

export const getUsers = (id = null, filter = '') => ({
    type: LIST,
    id,
    filter,
})

export const getUsersSuccess = entities => ({
    type: LIST_SUCCESS,
    payload: entities,
})

export const getUsersFail = error => ({
    type: LIST_FAIL,
    payload: error,
})