import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  ModalFooter,
} from 'reactstrap'
import { useDeleteSegmentMutation } from 'src/services/calling'

export const AcceptModal = (props: { show: boolean; toggle: any }) => {
  const { show, toggle } = props
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const id = searchParams?.get('id')
  const [deleteSegment] = useDeleteSegmentMutation()
  const [value, setValue] = useState(id ? id : '')
  const navigate = useNavigate()

  return (
    <Modal isOpen={show} centered toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('Delete segment')}</ModalHeader>
      <ModalBody>
        <label>{t('Enter segment ID')}</label>
        <Input
          type="text"
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          placeholder={t('Enter segment ID')}
        />
      </ModalBody>
      <ModalFooter>
        <div>
          <Button
            onClick={() => {
              deleteSegment(value)
              toggle()
              setTimeout(() => {
                navigate('/segments')
              }, 1500)
            }}
            color="danger"
            style={{ marginTop: '10px', marginBottom: '5px' }}
          >
            {t('Yes, I am sure')}
          </Button>
          <Button
            onClick={toggle}
            color="success"
            style={{
              marginTop: '10px',
              marginBottom: '5px',
              marginLeft: '30px',
            }}
          >
            {t('No, cancel')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
