import { api, omitBlankEntries } from './api'

type BkiList = {
  id: string
  company: string
  file_name: string
  created_at: string
  updated_at: string | null
  event_name: string
  event_type: string | null
  loan_uuid_cbr: string
  unique_names: boolean
}

export const bkiSentFile = api.injectEndpoints({
  endpoints: (builder) => ({
    getBkiList: builder.query<
      { count_records: number; results: BkiList[] },
      Partial<{
        limit: number
        offset: number
        bureau: string
        unique_names: boolean
        file_name: string
        request_id: string
        loan_uuid_cbr: string
        event_name: string
      }>
    >({
      query: (params) => ({
        url: 'crm/v1/bki-review/view-docs',
        params: omitBlankEntries(params)
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(
                ({ id }) => ({ type: 'BkiSentFile', id }) as const
              ),
              { type: 'BkiSentFile' as const, id: 'LIST' }
            ]
          : [{ type: 'BkiSentFile' as const, id: 'LIST' }]
    }),
    downloadBkiFile: builder.mutation<
      Blob,
      { file_name: string; bureau: string }
    >({
      query: (params) => ({
        url: `crm/v1/bki-review/download-doc`,
        method: 'POST',
        params: omitBlankEntries(params),
        responseHandler: (response) => response.text()
      })
    })
  })
})

export const { useGetBkiListQuery, useDownloadBkiFileMutation } = bkiSentFile
