import { api } from './api'

const passwordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeUserPassword: builder.mutation<any, any>({
      query: (payload) => ({
        url: 'crm/v1/keycloak/change-user-password',
        method: 'POST',
        body: payload
      })
    })
  })
})

export const { useChangeUserPasswordMutation } = passwordApi
