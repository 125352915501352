import {
    LIST,
    LIST_SUCCESS,
    LIST_FAIL,
} from "./actionType";

const INIT_STATE = {
    list: [],
    listLoading: false,
    listError: null,
};

const Roles = (state = INIT_STATE, action) => {
    switch (action.type) {
        //all
        case LIST:
            return {
                ...state,
                listLoading: true,
                listError: null,
            };

        case LIST_SUCCESS:
            return {
                ...state,
                list: action.payload,
                listLoading: false,
                listError: null,
            }

        case LIST_FAIL:
            return {
                ...state,
                listError: action.payload,
                listLoading: false,
            }

        default:
            return state;
    }
};

export default Roles;