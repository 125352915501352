import {
  GET_STRATEGIES,
  GET_STRATEGIES_SUCCESS,
  GET_STRATEGIES_FAIL,
  GET_STRATEGY,
  GET_STRATEGY_SUCCESS,
  GET_STRATEGY_FAIL,
  CREATE_STRATEGY,
  CREATE_STRATEGY_SUCCESS,
  CREATE_STRATEGY_FAIL,
  UPDATE_STRATEGY,
  UPDATE_STRATEGY_SUCCESS,
  UPDATE_STRATEGY_FAIL,
} from './actionType'

const INIT_STATE = {
  strategies: [],
  strategiesLoading: false,
  strategiesError: null,

  strategy: null,
  strategyLoading: false,
  strategyError: null,

  createLoading: false,
  createError: null,

  updateLoading: false,
  updateError: null,
}

const Strategies = (state = INIT_STATE, action) => {
  switch (action.type) {
    // all
    case GET_STRATEGIES:
      return {
        ...state,
        strategiesLoading: true,
        strategiesError: null,
      }

    case GET_STRATEGIES_SUCCESS:
      return {
        ...state,
        strategies: action.payload,
        strategiesLoading: false,
        strategiesError: null,
      }

    case GET_STRATEGIES_FAIL:
      return {
        ...state,
        strategiesLoading: false,
        strategiesError: action.payload,
      }

    // one
    case GET_STRATEGY:
      return {
        ...state,
        strategyLoading: true,
        strategyError: null,
      }

    case GET_STRATEGY_SUCCESS:
      return {
        ...state,
        strategy: action.payload,
        strategyLoading: false,
        strategyError: null,
      }

    case GET_STRATEGY_FAIL:
      return {
        ...state,
        strategyError: action.payload,
        strategyLoading: false,
      }

    //create
    case CREATE_STRATEGY:
      return {
        ...state,
        createError: null,
        createLoading: true,
      }

    case CREATE_STRATEGY_SUCCESS:
      return {
        ...state,
        createError: null,
        createLoading: false,
      }

    case CREATE_STRATEGY_FAIL:
      return {
        ...state,
        createError: action.payload,
        createLoading: false,
      }

    //update
    case UPDATE_STRATEGY:
      return {
        ...state,
        updateError: null,
        updateLoading: true,
      }

    case UPDATE_STRATEGY_SUCCESS:
      return {
        ...state,
        updateError: null,
        updateLoading: false,
      }

    case UPDATE_STRATEGY_FAIL:
      return {
        ...state,
        updateError: action.payload,
        updateLoading: false,
      }
    default:
      return state
  }
}

export default Strategies
