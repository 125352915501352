import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  ModalHeader
} from 'reactstrap'
import Loader from 'src/Components/Common/Loader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CallPerson, useDeleteCallEntityMutation } from 'src/services/calling'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from '../ErrorPage'

interface DeletePersonProps {
  persons: CallPerson[]
  className?: string
}

export const DeletePerson: FC<DeletePersonProps> = ({ persons, className }) => {
  const { t } = useTranslation()
  const { isOpen, open, close, toggle } = useDisclosure(false)

  const [deletePerson, { isLoading, error }] = useDeleteCallEntityMutation()

  const toggleModal = () => {
    if (isOpen) {
      validation.resetForm()
    }
    toggle()
  }

  const validation = useFormik({
    initialValues: {
      name: persons[0]?.name
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Required'))
    }),
    onSubmit: async (values) => {
      const { name } = values
      await deletePerson({ type: 'client', name })
      if (!error) {
        close()
      }
    }
  })

  return (
    <div className={`d-flex ${className}`}>
      <Button
        color='primary'
        onClick={open}
        style={{ backgroundColor: '#405189' }}
      >
        <i className='ri-delete-bin-line label-icon align-middle fs-16'></i>
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          {t('Delete client type')}
        </ModalHeader>
        <ModalBody>
          <Form className='needs-validation' onSubmit={validation.handleSubmit}>
            <FormGroup>
              <select
                name='name'
                id='name'
                className='form-select'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name}
              >
                {persons.map((person) => (
                  <option key={person.id} value={person.name}>
                    {person.name}
                  </option>
                ))}
              </select>
            </FormGroup>
            {!!error && <DangerAlert error={error} />}
            {isLoading ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                color='primary'
                style={{ backgroundColor: '#405189' }}
              >
                {t('Delete')}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}
