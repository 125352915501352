import { useEffect, useState } from 'react'
import { Button, ModalBody, Label } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Client from '../client'

import { openWindowVerification } from '../store/actions'

import VerificationHistory from '../Components/Common/EntitiesView/VerificationHistory'
import { Photos } from '../Components/Common/EntitiesView/Client'
import { BlockClient } from '../Components/Common/EntitiesView/BlockClient'

export const TaskModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()

  const [decisionStatuses, setDecisionStatuses] = useState([])
  const [applicationDocuments, setApplicationDocuments] = useState([])
  const [decision, setDecision] = useState('')
  const [comment, setComment] = useState('')
  const [decideError, setDecideError] = useState('')
  const [showPhotos, setShowPhotos] = useState(false)
  const [showBlock, setShowBlock] = useState(false)
  const [disableDecide, setDisableDecide] = useState(false)
  const [decideLoading, setDecideLoading] = useState(false)

  const [collapsed, setCollapsed] = useState(false)

  const { task } = useSelector((state) => ({
    task: state.verification.task,
  }))

  useEffect(() => {
    if (!task || (decisionStatuses && decisionStatuses.length > 0)) return
    Client.decisionStatuses()
      .then((res) => {
        //console.log('Client.decisionStatuses() =>', res);
        const statuses = res.map((option) => {
          return { id: option.id, text: option.description }
        })
        setDecisionStatuses(
          statuses.sort((option1, option2) =>
            option1.text.localeCompare(option2.text),
          ),
        )
      })
      .catch((error) => {
        console.log('error getting decision statuses', error)
        setDecisionStatuses([])
      })
  }, [task])

  useEffect(() => {
    setDecideError('')
  }, [decision, comment])

  const getApplicationPhotos = () => {
    Client.getApplicationDocuments(task.id)
      .then((documents) => {
        setApplicationDocuments(documents.documents)
      })
      .catch((err) => {
        console.log('getApplicationDocuments error', err)
        setApplicationDocuments([])
      })
  }

  useEffect(() => {
    if (!task || !task.id) return

    getApplicationPhotos()
  }, [task])

  if (!task) return null

  const decide = (noDecision = false) => {
    noDecision ? setDecideLoading(false) : setDecideLoading(true)
    if (!task) return

    Client.decide(
      task.id,
      decision,
      comment,
      noDecision ? 'no-decision' : 'decision',
    )
      .then((res) => {
        console.log('decide success', res)
        setTimeout(() => {
          setDecideLoading(false)
          setDisableDecide(false)
        }, 3000)
      })
      .catch((err) => {
        console.log('decide error', err)
        setDecideError(err)
        setTimeout(() => {
          setDecideLoading(false)
          setDisableDecide(false)
        }, 3000)
      })
  }

  const decideNoDecision = () => {
    setDisableDecide(true)
    decide(true)
  }
  const toggle = () => setCollapsed((v) => !v)

  console.log('decisionStatuses() =>', decisionStatuses)
  console.log('decision =>', decision)
  console.log('task =>', task)

  const header = (
    <div className="d-flex flex-row justify-content-between w-100 p-3">
      <div className="modal-title">
        <Link
          to={`/client-view?id=${task.client_id}`}
          className="fw-medium text-decoration-underline"
        >
          {`${task.client_surname} ${task.client_name} ${task.client_midname}`}
          <br />
          {`${task.client_id}`}
        </Link>
      </div>
      <Button
        type="button"
        className="btn-close"
        onClick={() => {
          dispatch(openWindowVerification(null))
        }}
        aria-label="Close"
      />
    </div>
  )

  if (collapsed) {
    return (
      <div
        id="myModal"
        className="bg-light rounded shadow border"
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          zIndex: 1005,
        }}
      >
        <div
          style={{ position: 'relative' }}
          className="d-flex flex-column h-100"
        >
          <div className="text-center" role="button" onClick={toggle}>
            <small className="fw-medium text-decoration-underline">
              развернуть
            </small>
          </div>
          {header}
        </div>
      </div>
    )
  }

  return (
    <div
      id="verif-modal"
      className="bg-light rounded shadow border"
      style={{
        position: 'fixed',
        right: 50,
        bottom: 50,
        top: 50,
        zIndex: 1005,
      }}
    >
      <div
        style={{ position: 'relative' }}
        className="d-flex flex-column h-100"
      >
        <div className="text-center" role="button" onClick={toggle}>
          <small className="fw-medium text-decoration-underline">
            свернуть
          </small>
        </div>
        {header}
        <div className="border-bottom border-gray" />
        <ModalBody
          style={{
            overflow: 'auto',
            paddingBottom: 70,
          }}
        >
          <Label className="form-label">{t('Decision')}</Label>
          <select
            name={`decision-status`}
            id={'decision-status'}
            className="form-select form-select-sm mb-3"
            onChange={(e) => setDecision(e.target.value)}
            value={decision}
          >
            <option key={'-'}>{'-'}</option>
            {decisionStatuses.map((option) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.text}
                </option>
              )
            })}
          </select>

          <div className="mb-3">
            <textarea
              className="form-control"
              id="textarea-comment"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </div>

          <div
            className="fs-7 text-info text-decoration-underline mb-2"
            role="button"
            onClick={() => {
              setShowBlock((prev) => !prev)
            }}
          >
            {`${t('block_client')}/${t('unblock_client')}`}
          </div>
          {showBlock && <BlockClient className="mb-3" application={task} />}

          <div
            className="fs-7 text-info text-decoration-underline"
            role="button"
            onClick={() => {
              if (!showPhotos) getApplicationPhotos()
              setShowPhotos((prev) => !prev)
            }}
          >
            {t('photos')}
          </div>

          {showPhotos && (
            <Photos
              className="mt-2 mb-2"
              decisionView
              photos={applicationDocuments}
            />
          )}

          <VerificationHistory
            className="mt-2"
            decisionView
            applicationId={task?.id}
          />

          {decideError && (
            <div className="mt-2 mb-2">
              <small className="text-danger">
                {t('Error')}: {decideError.toString()}
              </small>
            </div>
          )}
        </ModalBody>

        <div
          className="border-top border-gray w-100 p-2 bg-light d-flex flex-column align-items-center"
          style={{
            position: 'absolute',
            bottom: 0,
          }}
        >
          <div className="d-flex flex-row gap-2">
            <Link
              to={`/application-view?id=${task.id}`}
              size="sm"
              className="btn btn-primary btn-sm"
            >
              <i className="ri-edit-2-line fs-7" />
            </Link>
            <Button
              color="primary"
              size="sm"
              disabled={decideLoading}
              onClick={() => decide()}
            >
              {decideLoading ? t('Request_loading') : t('Save')}
            </Button>
            <Button
              color="primary"
              size="sm"
              disabled={disableDecide}
              onClick={() => decideNoDecision()}
            >
              {disableDecide ? t('Request_loading') : t('Save_no_decision')}
            </Button>
          </div>
          {location.pathname !== '/verification-applications' && (
            <Link
              to={`/verification-applications`}
              className="mt-2 text-decoration-underline"
            >
              <small>Вернуться к заявкам</small>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default TaskModal
