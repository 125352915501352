import { ListError } from '../types/queues.types'
import { QueueOrder } from '../../../../services/calling'

export const mappingListValue = (list: string[]) =>
  list?.length > 0 ? list.join(', ') : '-'

export const mappingOrderValue = (order: QueueOrder) => {
  if (!order || !order.order) return '-'
  const strOrder = order.order == 1 ? 'asc' : order.order == -1 ? 'desc' : '-'
  return `${strOrder}, priority: ${order.priority}`
}

export const mappingFilterValue = (list: string[]) => {
  if (!list || !list.length) return '-'
  return list.join(', ')
}

export const handleErrors = (error: unknown) => {
  const { data } = error as ListError

  return data.detail
    .map((obj) => {
      const nameError = obj.loc[1]
      const msgError = obj.msg

      return `${nameError}: ${msgError}`
    })
    .join('; ')
}
