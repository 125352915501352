import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ButtonGroup
} from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  useAddClaimMutation,
  useGetClaimQuery,
  useUpdateClaimMutation,
  useGetAllTopicQuery,
  useGetClaimManagerQuery,
  useGetClaimChannelTypesQuery
} from 'src/services/claims'
import * as Yup from 'yup'
import { UseQueryHookResultData } from 'src/services/api'
import { useGetUserInfoQuery } from 'src/services/keycloak'
import { autoResizeInput } from 'src/utils'

export const ClaimEdit = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [isUserWork, setIsUserWork] = useState(true)

  const { data: userInfo } = useGetUserInfoQuery()

  const { data: topics, error: topicError } = useGetAllTopicQuery([])

  const { data: claimManager, isLoading } = useGetClaimManagerQuery()

  const {
    data: claim,
    isFetching,
    error: getError
  } = useGetClaimQuery(
    {
      id: searchParams.get('id')!
    },
    {
      skip: searchParams.get('id') == null
    }
  )

  const { data: channelTypes } = useGetClaimChannelTypesQuery()

  const [
    updateClaim,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess }
  ] = useUpdateClaimMutation()

  const [
    addClaim,
    { isLoading: isCreating, error: createError, isSuccess: isCreateSuccess }
  ] = useAddClaimMutation()

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    userInfo?.roles.includes('head_of_comunications')
      ? setIsUserWork(false)
      : setIsUserWork(true)
  }, [userInfo])

  const title = searchParams.get('id') ? t('Edit claim') : t('Create claim')
  document.title = title

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      client_id: '',
      created_at: '',
      assignee_id: '',
      channel_type: 'email',
      status: 'Получено',
      comment: '',
      claim_topic: '',
      claim_body: '',
      claim_topic_id: '',
      claim_subtopic_id: '',
      repeated: false,
      is_complaint: false,
      result: '',
      days_to_reply: '',
      email_from: '',
      ...claim
    } as UseQueryHookResultData<typeof useGetClaimQuery>,
    validationSchema: Yup.object().shape({
      client_id: Yup.string().nullable(),
      created_at: Yup.string(),
      assignee_id: Yup.string().nullable(),
      channel_type: Yup.string().nullable(),
      status: Yup.string(),
      comment: Yup.string().nullable(),
      claim_topic: Yup.string().nullable(),
      claim_body: Yup.string().nullable(),
      claim_topic_id: Yup.number().nullable(),
      claim_subtopic_id: Yup.number().nullable(),
      repeated: Yup.string(),
      result: Yup.string().nullable(),
      days_to_reply: Yup.number().nullable(),
      autoassign: Yup.boolean(),
      claim_number: Yup.string().nullable()
    }),
    onSubmit: (values) => {
      if (searchParams.get('id')) {
        const data = { ...values }
        if (values.result === '-') data.result = null
        data.autoassign = false
        updateClaim(data)
      } else {
        const data = {
          email_from: values.email_from,
          client_id: values.client_id,
          claim_topic: values.claim_topic,
          claim_body: values.claim_body,
          comment: values.comment,
          repeated: values.repeated,
          is_complaint: values.is_complaint,
          channel_type: values.channel_type
        }
        addClaim(data)
      }
    }
  })

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={
              searchParams.get('id')
                ? `/claim-view?id=${claim?.id}`
                : isUserWork
                ? '/my-claims'
                : '/claims-queue'
            }
            editLink={undefined}
            entity={claim}
            createLink={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              {!searchParams.get('id') && (
                <>
                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='client_id'>{t('client_id')}</Label>
                      <Input
                        name='client_id'
                        type='text'
                        id='client_id'
                        className='form-control form-control-sm'
                        placeholder='abcdef-123456'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.client_id ?? ''}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                        invalid={
                          formik.touched.client_id && !!formik.errors.client_id
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='claim_topic'>{t('claim_topic')}</Label>
                      <Input
                        name='claim_topic'
                        type='text'
                        className='form-control form-control-sm'
                        id='claim_topic'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.claim_topic}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                        invalid={
                          formik.touched.claim_topic &&
                          !!formik.errors.claim_topic
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='claim_body'>{t('claim_body')}</Label>
                      <Input
                        name='claim_body'
                        type='textarea'
                        className='form-control form-control-sm'
                        style={{ height: '25px' }}
                        onInput={autoResizeInput}
                        id='claim_body'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.claim_body}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                        invalid={
                          formik.touched.claim_body &&
                          !!formik.errors.claim_body
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='email_from'>{t('email')}</Label>
                      <Input
                        name='email_from'
                        type='email'
                        id='email_from'
                        className='form-control form-control-sm'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email_from ?? ''}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                        invalid={
                          formik.touched.email_from &&
                          !!formik.errors.email_from
                        }
                      />
                    </FormGroup>
                  </Col>
                </>
              )}

              <Col md='4'>
                <Label htmlFor='repeated'>{t('repeated')}</Label>
                <ButtonGroup className='w-100'>
                  <Button
                    color='primary'
                    outline
                    active={formik.values.repeated === true}
                    size='sm'
                    onClick={() => formik.setFieldValue('repeated', true)}
                    disabled={isCreating || isUpdating}
                  >
                    {t('yes')}
                  </Button>
                  <Button
                    color='primary'
                    outline
                    active={formik.values.repeated === false}
                    size='sm'
                    onClick={() => formik.setFieldValue('repeated', false)}
                    disabled={isCreating || isUpdating}
                  >
                    {t('no')}
                  </Button>
                </ButtonGroup>
              </Col>

              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='is_complaint'>{t('is_complaint')}</Label>
                  <ButtonGroup className='w-100'>
                    <Button
                      color='primary'
                      outline
                      active={formik.values.is_complaint === true}
                      size='sm'
                      onClick={() => formik.setFieldValue('is_complaint', true)}
                      disabled={isCreating || isUpdating}
                    >
                      {t('yes')}
                    </Button>
                    <Button
                      color='primary'
                      outline
                      active={formik.values.is_complaint === false}
                      size='sm'
                      onClick={() =>
                        formik.setFieldValue('is_complaint', false)
                      }
                      disabled={isCreating || isUpdating}
                    >
                      {t('no')}
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>
              {!searchParams.get('id') && (
                <Col md='4'>
                  <FormGroup>
                    <Label htmlFor='channel_type'>{t('channel_type')}</Label>
                    <select
                      name='channel_type'
                      id='channel_type'
                      className='form-select form-select-sm'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.channel_type}
                      disabled={
                        isCreating || isUpdating || isFetching || isLoading
                      }
                    >
                      <option hidden disabled selected />
                      {channelTypes?.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              )}
              {searchParams.get('id') && (
                <>
                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='days_to_reply'>
                        {t('days_to_reply')}
                      </Label>
                      <Input
                        name='days_to_reply'
                        type='number'
                        className='form-control form-control-sm'
                        id='days_to_reply'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.days_to_reply || 0}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                        invalid={
                          formik.touched.days_to_reply &&
                          !!formik.errors.days_to_reply
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='result'>{t('result_claim')}</Label>
                      <select
                        name='result'
                        id='result_claim'
                        className='form-select form-select-sm'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.result ?? ''}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                      >
                        <option value='-'>-</option>
                        <option value='Удовлетворено'>Удовлетворено</option>
                        <option value='Не удовлетворено'>
                          Не удовлетворено
                        </option>
                        <option value='Частично удовлетворено'>
                          Частично удовлетворено
                        </option>
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='claim_topic_id'>
                        {t('claim_topic_id')}
                      </Label>
                      <select
                        name='claim_topic_id'
                        id='claim_topic_id'
                        className='form-select form-select-sm'
                        onChange={(e) => {
                          const selectedTopicId = Number.parseInt(
                            e.target.value
                          )
                          formik.setFieldValue(
                            'claim_topic_id',
                            selectedTopicId
                          )

                          const availableSubtopicId = topics?.find(
                            (topic) => topic.parent_topic_id === selectedTopicId
                          )?.id
                          formik.setFieldValue(
                            'claim_subtopic_id',
                            availableSubtopicId || ''
                          )
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.claim_topic_id ?? ''}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                      >
                        <option hidden disabled value='' />
                        {topics
                          ?.filter((option) => option.parent_topic_id == null)
                          .map((option) => (
                            <option key={option.topic_name} value={option.id}>
                              {option.topic_name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='claim_subtopic_id'>
                        {t('claim_subtopic_id')}
                      </Label>
                      <select
                        name='claim_subtopic_id'
                        id='claim_subtopic_id'
                        className='form-select form-select-sm'
                        onChange={(e) =>
                          formik.setFieldValue(
                            'claim_subtopic_id',
                            Number.parseInt(e.target.value)
                          )
                        }
                        onBlur={formik.handleBlur}
                        value={formik.values.claim_subtopic_id ?? ''}
                        disabled={
                          isCreating ||
                          isUpdating ||
                          isFetching ||
                          isLoading ||
                          !formik.values.claim_topic_id
                        }
                      >
                        <option hidden disabled value='' />
                        {topics
                          ?.filter(
                            (option) =>
                              option.parent_topic_id ===
                              formik.values.claim_topic_id
                          )
                          .map((option) => (
                            <option key={option.topic_name} value={option.id}>
                              {option.topic_name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label htmlFor='assignee_id'>{t('assignee_id')}</Label>
                      <select
                        name='assignee_id'
                        id='assignee_id'
                        className='form-select form-select-sm'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.assignee_id ?? ''}
                        disabled={
                          isCreating || isUpdating || isFetching || isLoading
                        }
                      >
                        <option hidden disabled value='' />
                        {claimManager?.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.surname} {user.name} {user.midname}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </>
              )}

              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='comment'>{t('comment')}</Label>
                  <Input
                    name='comment'
                    type='textarea'
                    className='form-control form-control-sm'
                    style={{ height: '25px' }}
                    onInput={autoResizeInput}
                    id='comment'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    disabled={
                      isCreating || isUpdating || isFetching || isLoading
                    }
                    invalid={formik.touched.comment && !!formik.errors.comment}
                  />
                </FormGroup>
              </Col>
            </Row>

            {updateError && (
              <Alert color='warning'>
                <strong> {JSON.stringify(updateError)} </strong>
              </Alert>
            )}

            {createError && (
              <Alert color='warning'>
                <strong> {JSON.stringify(createError)} </strong>
              </Alert>
            )}

            {(getError || topicError) && (
              <Alert color='warning'>
                <strong> {JSON.stringify(getError)} </strong>
              </Alert>
            )}

            {(isUpdateSuccess || isCreateSuccess) && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              className='text-nowrap'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              type='submit'
              disabled={
                isCreating ||
                isUpdating ||
                isFetching ||
                (searchParams.get('id')
                  ? false
                  : !formik.values.client_id && !formik.values.email_from)
              }
            >
              {searchParams.get('id') ? t('Update') : t('Create')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
