import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useNavigate, useSearchParams } from 'react-router-dom'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from 'src/pages/Admin/LoadingPage'

import Client from 'src/client'

import {
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Alert,
  Spinner,
} from 'reactstrap'
import { useGetGroupsQuery, useUpdateGroupMutation } from 'src/services/calling'

export const GroupEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [id, setId] = useState()
  const [pageReady, setPageReady] = useState(false)
  const [error, setError] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const [filterName, setFilterName] = useState([])
  const [groupName, setGroupName] = useState(null)
  const [groupNameError, setGroupNameError] = useState(false)
  const [collectors, setCollectors] = useState([])
  const [collectorsByGroup, setCollectorsByGroup] = useState([])

  const entityLoading = useSelector(
    (state) => state.entities.groups.entityLoading,
  )

  const updateLoading = useSelector(
    (state) => state.entities.groups.updateLoading,
  )

  const createLoading = useSelector(
    (state) => state.entities.groups.createLoading,
  )

  const { group, isLoading: isGroupLoading } = useGetGroupsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      group: data?.find((it) => it.id === parseInt(id)),
      ...rest,
    }),
  })

  const [editGroup, { isLoading: isGroupUpdating, isSuccess: isGroupUpdated }] =
    useUpdateGroupMutation()

  useEffect(() => {
    const id = searchParams.get('id')
    console.log('id => ', id)
    setId(id || null)
    Client.getAllCollectors().then((res) => {
      setCollectors(
        res.map((collector) => ({
          ...collector,
          FIO: `${collector.surname} ${collector.name} ${collector.midname}`.toLowerCase(),
        })),
      )
    })
    if (id) {
      //edit mode
      Client.getGroupCollectors(id).then((res) => {
        setCollectorsByGroup(res)
      })
    }
    setPageReady(true)
  }, [dispatch, searchParams])

  const title = `${t('Groups')}: ${id ? t('Edit') : t('Create')}`
  document.title = title

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/groups'} title={title} />
  }

  const onSubmit = () => {
    if (id) {
      editGroup({
        id,
        new_name: groupName,
        user_ids: collectorsByGroup.map((item) => item.id),
      })
    } else {
      if (!groupName) {
        setGroupNameError(t('Fill in the field'))
      } else {
        const body = {
          name: groupName,
          user_ids: collectorsByGroup.map((item) => item.id),
        }
        Client.createGroupCollectors(body)
          .then(() => {
            setSuccessful(true)
          })
          .catch((error) => setError(error))
      }
    }
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar backLink={'/groups'} />
            {collectors.length > 0 && (
              <div style={{ width: '500px', marginBottom: '10px' }}>
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Input
                    invalid={groupNameError}
                    defaultValue={group?.name}
                    onChange={(evv) => setGroupName(evv.target.value)}
                    className="form-control form-control-sm"
                    placeholder={t('Enter group name')}
                  />
                  {groupNameError && (
                    <FormFeedback type="invalid">{groupNameError}</FormFeedback>
                  )}
                </div>
                <Input
                  onChange={(evv) =>
                    setFilterName(evv.target.value.toLowerCase())
                  }
                  style={{ marginBottom: '10px' }}
                  placeholder={t('Enter user name')}
                  className="form-control form-control-sm"
                />
                {collectors
                  .filter((col) => col.FIO.includes(filterName))
                  .map((collector) => (
                    <Label
                      style={{
                        verticalAlign: 'baseline',
                        display: 'block',
                        lineHeight: '21px',
                        fontSize: '14px',
                        marginBottom: '10px',
                      }}
                      key={collector.id}
                      className="form-check-label"
                    >
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        checked={Boolean(
                          collectorsByGroup.find(
                            (item) => collector.id === item.id,
                          ),
                        )}
                        id={collector.id}
                        style={{
                          width: '21px',
                          height: '21px',
                          marginRight: '15px',
                          cursor: 'pointer',
                        }}
                        onChange={() => {
                          const index = collectorsByGroup.findIndex(
                            (item) => collector.id === item.id,
                          )
                          if (index > -1) {
                            setCollectorsByGroup([
                              ...collectorsByGroup.slice(0, index),
                              ...collectorsByGroup.slice(
                                index + 1,
                                collectorsByGroup.length,
                              ),
                            ])
                          } else {
                            setCollectorsByGroup([
                              ...collectorsByGroup,
                              collector,
                            ])
                          }
                        }}
                      />
                      <span>
                        {collector.surname} {collector.name} {collector.midname}
                      </span>
                    </Label>
                  ))}
              </div>
            )}

            {error && (
              <Alert color="warning">
                <strong>{error}</strong>
              </Alert>
            )}

            {(successful || isGroupUpdated) && (
              <Alert color="success">
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            {updateLoading || createLoading ? (
              <Spinner color="primary">{t('Loading...')}</Spinner>
            ) : (
              <>
                {id && (
                  <Button
                    className="text-nowrap"
                    color="danger"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      Client.deleteGroupCollectors(id)
                        .then(() => {
                          navigate('/groups')
                        })
                        .catch((error) => setError(error))
                    }}
                    disabled={isGroupUpdating || isGroupLoading}
                  >
                    {t('Delete')}
                  </Button>
                )}
                <Button
                  className="text-nowrap"
                  color="primary"
                  style={{ backgroundColor: '#405189' }}
                  onClick={onSubmit}
                  disabled={isGroupUpdating || isGroupLoading}
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
