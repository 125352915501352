export const LIST = "GET_ENTITIES"
export const LIST_SUCCESS = "GET_ENTITIES_SUCCESS"
export const LIST_FAIL = "GET_ENTITIESS_FAIL"

export const DELETE = "DELETE_ENTITIES"
export const DELETE_SUCCESS = "DELETE_ENTITIES_SUCCESS"
export const DELETE_FAIL = "DELETE_ENTITIES_FAIL"

export const UPDATE = "UPDATE_ENTITIES"
export const UPDATE_SUCCESS = "UPDATE_ENTITIES_SUCCESS"
export const UPDATE_FAIL = "UPDATE_ENTITIES_FAIL"

export const CREATE = "CREATE_ENTITIES"
export const CREATE_SUCCESS = "CREATE_ENTITIES_SUCCESS"
export const CREATE_FAIL = "CREATE_ENTITIES_FAIL"

export const SET_FILTER = "SET_ENTITIES_FILTER"
