import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_PRODUCTS,
  GET_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
} from "./actionType";

import {
  getProducts as getProductsAction,
  getProductsFail,
  getProductsSuccess,
  getProductFail,
  getProductSuccess,
  createProductFail,
  createProductSuccess,
  deleteProductSuccess,
  deleteProductFail,
  updateProductSuccess,
  updateProductFail,
} from "./action";

import Client from "../../client";

function* deleteProduct({ payload }) {
  try {
    const response = yield call(Client.deleteEntity, Client.ENTITY_TYPE.PRODUCT, payload);
    yield put(deleteProductSuccess());
    yield put(getProductsAction());
  } catch (error) {
    yield put(deleteProductFail(error));
  }
}

function* updateProduct({ payload }) {
  try {
    const response = yield call(Client.updateEntity, Client.ENTITY_TYPE.PRODUCT, payload);
    yield put(updateProductSuccess());
    yield put(getProductsAction());
  } catch (error) {
    yield put(updateProductFail(error));
  }
}

function* createProduct({ payload }) {
  try {
    const response = yield call(Client.createEntity, Client.ENTITY_TYPE.PRODUCT, payload);
    yield put(createProductSuccess());
    yield put(getProductsAction());
  } catch (error) {
    yield put(createProductFail(error));
  }
}

function* getProducts({filter}) {
  try {
    const response = yield call(Client.getList, Client.ENTITY_TYPE.PRODUCT, filter);
    yield put(getProductsSuccess(response));
  } catch (error) {
    yield put(getProductsFail(error));
  }
}

function* getProduct({ id }) {
  try {
    const response = yield call(Client.getEntity, Client.ENTITY_TYPE.PRODUCT, id);
    yield put(getProductSuccess(response));
  } catch (error) {
    yield put(getProductFail(error));
  }
}

export function* watchOnCreateProduct() {
  yield takeEvery(CREATE_PRODUCT, createProduct);
}

export function* watchOnGetProducts() {
  yield takeEvery(GET_PRODUCTS, getProducts);
}

export function* watchOnGetProduct() {
  yield takeEvery(GET_PRODUCT, getProduct);
}

export function* watchOnDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

export function* watchOnUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProduct);
}

function* productsSaga() {
  yield all([
    fork(watchOnGetProducts),
    fork(watchOnGetProduct),
    fork(watchOnCreateProduct),
    fork(watchOnDeleteProduct),
    fork(watchOnUpdateProduct),
  ]);
}

export default productsSaga;
