import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import List from 'src/Components/Common/EntitiesView/List'
import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import { useGetTransactionsQuery } from 'src/services/payments'
import { LIST_LIMIT } from 'src/client'
import { UseQueryHookArgs } from 'src/services/api'
import { applyFilter, FieldsFilterState } from './utils'
import { SortOrder } from './types'

export const Payments: FC = () => {
  const { t } = useTranslation()

  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetTransactionsQuery>
  >({})

  const [sortOrder, setSortOrder] = useState<SortOrder>({
    issue_date: 'desc',
    repayment_date: 'desc',
    amount_debt: 'desc',
    term: 'desc',
    dpd: 'desc',
    amount: 'desc'
  })

  const {
    data: list = [],
    error: listError,
    isLoading: listLoading
  } = useGetTransactionsQuery({
    limit: LIST_LIMIT,
    offset: 0,
    ...fieldsFilter
  })

  const fieldFilter = {
    direction: { options: ['In', 'Out', 'WriteOff', 'Refund'] },
    is_accepted: { options: ['Confirmed', 'Waiting'] },
    group_delimiter: {},
    created_at: { date: true },
    accept_date: { date: true }
  }

  const getFieldsDescription = (
    sortByValue: (keyName: keyof SortOrder) => void
  ) => ({
    id: {
      label: ' ',
      mappingValue: () => <i className='ri-eye-line fs-15' />,
      isLink: true,
      linkFieldName: 'id_link'
    },
    created_at: { isDateTime: true },
    accept_date: { isDateTime: true },
    is_accepted: {
      mappingValue: (value: boolean) => (value ? 'Confirmed' : 'Waiting'),
      mappingClassName: (value: boolean) =>
        value ? 'text-success' : 'text-warning'
    },
    direction: {},
    amount: {
      isAmount: true,
      rightAligned: true,
      onClick: () => sortByValue('amount')
    },
    loan_id: {
      label: 'loan',
      mappingValue: () => <i className='ri-pie-chart-line fs-15' />,
      isLink: true,
      linkFieldName: 'loan_link'
    },
    profile_id: {
      label: 'profile',
      mappingValue: () => <i className='ri-account-circle-line fs-15' />,
      isLink: true,
      linkFieldName: 'profile_link'
    },
    last_name: {},
    first_name: {},
    middle_name: {},
    payment_gate: { fieldName: 'comment' }
  })

  const sortByValue = (keyName: keyof SortOrder) => {
    const newSortOrder: SortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc'
    }
    setSortOrder(newSortOrder)
  }

  const fieldsDescription = getFieldsDescription(sortByValue)

  const handleApplyFilter = (values: FieldsFilterState) => {
    applyFilter(values, setFieldsFilter)
  }

  const title = t('Transactions')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <Loader />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={t('Transactions')} />
        <FieldsFilter
          fields={fieldFilter}
          applyFilter={handleApplyFilter}
          resetFilter={() => setFieldsFilter({})}
        />
        <Card>
          <CardBody>
            <MetaBar createLink={'/payment-create'} />
            <div className='table-responsive table-card mt-3'>
              <List
                style={{ width: 'max-content' }}
                list={list.map((item) => ({
                  ...item,
                  id_link: `/payment-view?id=${item.id}`,
                  profile_link: `/profile-view?id=${item.profile_id}`,
                  loan_link: `/loan-view?id=${item.loan_id}`
                }))}
                fieldsDescription={fieldsDescription}
                className='table-sm align-middle table-nowrap mb-0 mt-3'
                hover
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
