import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import { FIELDS_DESCRIPTION } from './constants/queues.constants'

import { useGetQueueQuery } from '../../../services/calling'
import { Collector } from './types/queues.types'

export const QueueView = () => {
  const { t } = useTranslation()

  const [id, setId] = useState('')
  const [collectorsByStage] = useState([])
  const [filterName, setFilterName] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')

    if (id) {
      setId(id)
    }
  }, [])

  const {
    data: queue,
    isLoading,
    error: queueError
  } = useGetQueueQuery(
    { queue_params_id: +id },
    {
      skip: !id
    }
  )

  const title = `${t('Queues')}: ${t('View')}`
  document.title = title

  if (isLoading) {
    return <LoadingPage backLink={'/queues'} title={title} />
  }

  if (queueError || !queue) {
    return <ErrorPage backLink={'/queues'} title={title} error={[queueError]} />
  }

  if (queue && !queue[0]?.id) {
    return (
      <ErrorPage
        backLink={'/queues'}
        title={title}
        error={'Invalid queue_id'}
      />
    )
  }

  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/queues'}
      editLink={queue ? `/queues-edit?id=${queue[0]?.id}` : null}
      entity={queue[0]}
    />
  )

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className='mt-3'>
              <div>
                <b>{t('queue_name')}:</b>
              </div>
              <div>
                {t(
                  model.fieldValueToText(
                    'queue_name',
                    queue[0],
                    FIELDS_DESCRIPTION
                  )
                )}
              </div>
            </Row>
            <Row className='mt-3'>
              <div>
                <b>{t('Users')}:</b>
              </div>
              <div>
                {queue[0]?.users.length > 0
                  ? queue[0].users.map((user) => <div key={user}>{user}</div>)
                  : '-'}
              </div>
            </Row>
            <Row className='mt-3'>
              <div>
                <b>{t('Groups')}:</b>
              </div>
              <div>
                {queue[0]?.groups.length > 0
                  ? queue[0].groups.map((group) => (
                      <div key={group}>{group}</div>
                    ))
                  : '-'}
              </div>
            </Row>

            <Row className='mt-3'>
              <div>
                <b>{t('Seq')}:</b>
              </div>
              <div>
                {t(model.fieldValueToText('seq', queue[0], FIELDS_DESCRIPTION))}
              </div>
            </Row>

            <Row className='mt-3'>
              <div>
                <b>{t('Order')}:</b>
              </div>
              {Object.keys(FIELDS_DESCRIPTION)
                .filter((key) => FIELDS_DESCRIPTION[key].type === 'order')
                .map((fieldName) => {
                  return (
                    <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                      <Label htmlFor={fieldName}>
                        {t(FIELDS_DESCRIPTION[fieldName].label || fieldName)}
                      </Label>
                      <Input
                        type='text'
                        className='form-control form-control-sm'
                        value={t(
                          model.fieldValueToText(
                            fieldName,
                            queue[0],
                            FIELDS_DESCRIPTION
                          )
                        )}
                        disabled
                      />
                    </Col>
                  )
                })}
            </Row>
            <Row className='mt-3'>
              <div>
                <b>{t('Filters')}:</b>
              </div>
              {Object.keys(FIELDS_DESCRIPTION)
                .filter((key) => FIELDS_DESCRIPTION[key].type === 'filter')
                .map((fieldName) => {
                  return (
                    <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                      <Label htmlFor={fieldName}>
                        {t(FIELDS_DESCRIPTION[fieldName].label || fieldName)}
                      </Label>
                      <Input
                        type='text'
                        className='form-control form-control-sm'
                        value={model.fieldValueToText(
                          fieldName,
                          queue[0],
                          FIELDS_DESCRIPTION
                        )}
                        disabled
                      />
                    </Col>
                  )
                })}
            </Row>
            {collectorsByStage.length > 0 && (
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className={`ms-0 fs-16`}>{t('Employees')}</span>
                <Input
                  onChange={(event) =>
                    setFilterName(event.target.value.toLowerCase())
                  }
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  placeholder={t('Enter name')}
                  className='form-control form-control-sm'
                />
                {collectorsByStage
                  .filter((col: any) => col.FIO.includes(filterName))
                  .map((collector: Collector) => (
                    <p key={collector.id}>
                      {collector.surname} {collector.name} {collector.midname}
                    </p>
                  ))}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  )
}
