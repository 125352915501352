import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

export const crateLinkButtonView = (route: string, icon: ReactNode) => {
  return (
    <Link to={route}>
      <Button className='btn-icon' style={{ backgroundColor: '#405189' }}>
        {icon}
      </Button>
    </Link>
  )
}
