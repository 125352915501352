import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldsFilter from 'src/Components/Common/FieldsFilter'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap'
import {
  useGetReportClaimFileQuery,
  useGetReportClaimQuery
} from 'src/services/claims'
import { UseQueryHookArgs } from 'src/services/api'
import List from 'src/Components/Common/EntitiesView/List'
import { formatAPIDate } from 'src/utils'

export const ReportClaim = () => {
  const { t } = useTranslation()
  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetReportClaimQuery>
  >({
    dateFrom: '',
    dateTo: ''
  })

  const {
    data: reportClaim,
    error: createErrorList,
    isError
  } = useGetReportClaimQuery({
    ...fieldsFilter
  })

  const { data: reportClaimFile } = useGetReportClaimFileQuery({
    ...fieldsFilter
  })

  const downloadFile = () => {
    if (reportClaimFile) {
      const url = window.URL.createObjectURL(new Blob([reportClaimFile]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `report${formatAPIDate(fieldsFilter.dateFrom)}_${formatAPIDate(
          fieldsFilter.dateTo
        )}.xlsx`
      )
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const title = t('Report on appeals')
  document.title = title

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <FieldsFilter
            fields={{
              dateFrom: { date: true, label: 'start_date' },
              dateTo: { date: true, label: 'end_date' }
            }}
            applyFilter={(values: typeof fieldsFilter) =>
              setFieldsFilter(values)
            }
          />
          <Row className='ms-1'>
            <Col>
              <Button
                color='primary'
                style={{ backgroundColor: '#405189' }}
                onClick={() => downloadFile()}
                disabled={isError}
              >
                {t('Download')}
              </Button>

              {createErrorList && (
                <Alert color='success'>
                  <strong>{t('Fill in the field')}</strong>
                </Alert>
              )}

              {reportClaim && !isError ? (
                <List
                  list={reportClaim?.map((user) => user)}
                  className={undefined}
                  style={{ width: 'fit-content' }}
                  fieldsDescription={{
                    username: { label: 'assignee_username' },
                    closed: { label: 'number of closed appeals' }
                  }}
                  hover
                  actions={undefined}
                />
              ) : (
                <div>{t('No data available')}</div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
