import * as Yup from 'yup'

export const schema = Yup.object({
  series: Yup.number(),
  number: Yup.number(),
  snils: Yup.number().nullable(),
  inn: Yup.number().nullable(),
  salary: Yup.number(),
  extsalary: Yup.number().nullable(),
  phonework: Yup.number(),
  thirdparty_phone: Yup.number().nullable(),
  thirdparty_fio: Yup.string().nullable(),
  docdate: Yup.date(),
  docorgcode: Yup.string().matches(
    /^[0-9][0-9][0-9]-[0-9][0-9][0-9]+$/,
    'Format must be XXX-XXX'
  )
})
