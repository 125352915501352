import {LIST, LIST_FAIL, LIST_SUCCESS} from "./actionType";


const INIT_STATE = {
    list: [],
    listLoading: false,
    listError: null,
};

const Users = (state = INIT_STATE, action) => {
    switch (action.type) {
        //all
        case LIST:
            return {
                ...state,
                listLoading: true,
                listError: null,
            };

        case LIST_SUCCESS:
            return {
                ...state,
                list: action.payload,
                listLoading: false,
                listError: null,
            }

        case LIST_FAIL:
            return {
                ...state,
                listError: action.payload,
                listLoading: false,
            }

        default:
            return state;
    }
};

export default Users;