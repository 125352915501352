import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import { formatDate, formatAPIDate } from 'src/utils'

import { getProductExtensions, updateProductExtension } from 'src/store/actions'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

const booleanOptions = [
  { name: 'true', defaultValue: true },
  { name: 'false', defaultValue: false },
]
const inputInitials = {
  ext_all_interest_amount: {
    label: 'ext_all_interest_amount',
    initial: '',
    placeholder: '',
    options: booleanOptions,
  },
  ext_fee_amount: {
    label: 'ext_fee_amount',
    initial: '',
    placeholder: '',
    isAmount: true,
  },
  ext_fee_percent: {
    label: 'ext_fee_percent',
    initial: '',
    placeholder: '',
    isAmount: true,
  },
  ext_from_sign_day: {
    label: 'ext_from_sign_day',
    initial: '',
    placeholder: '',
    options: booleanOptions,
  },
  ext_overdue_allowed_days: {
    label: 'ext_overdue_allowed_days',
    initial: '',
    placeholder: '',
  },
  ext_with_penalty: {
    label: 'ext_with_penalty',
    initial: '',
    placeholder: '',
    options: booleanOptions,
  },
  extension_enabled: {
    label: 'extension_enabled',
    initial: '',
    placeholder: '',
    options: booleanOptions,
  },
  max_ext_days: { label: 'max_ext_days', initial: '', placeholder: '' },
  max_extensions_number: {
    label: 'max_extensions_number',
    initial: '',
    placeholder: '',
  },
  max_loan_days: { label: 'max_loan_days', initial: '', placeholder: '' },
  min_ext_days: { label: 'min_ext_days', initial: '', placeholder: '' },
  product_id: { label: 'product_id', initial: '', placeholder: '' },
}

export const ProductExtensionEdit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [entity, setEntity] = useState(null)

  const [initialFormValues, setInitialFormValues] = useState(null)
  const [paramError, setParamError] = useState(null)
  const { list, listLoading, listError, updateLoading, updateError } =
    useSelector((state) => ({
      list: state.productExtensions.list,
      listLoading: state.productExtensions.listLoading,
      listError: state.productExtensions.listError,
      updateError: state.productExtensions.updateError,
      updateLoading: state.productExtensions.updateLoading,
    }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    console.log(`getProductExtensions(${id})`)
    setPageReady(false)
    if (id) dispatch(getProductExtensions(id))
    else setParamError('No entity id found')
  }, [])

  useEffect(() => {
    if (id && listLoading === false) {
      //edit mode
      const listFiltered =
        list && list.length && id ? list.filter((item) => item.id === id) : []
      const entity = listFiltered.length ? listFiltered[0] : null

      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] =
          entity && entity[key] !== null && entity[key] !== undefined
            ? entity[key].toString()
            : ''
      })

      setInitialFormValues(initialFormValues)
      setEntity(entity)
      setPageReady(true)
    }
  }, [listLoading])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const updated = { id: entity.id, ...values }
      console.log('values (updated extension)', updated)
      dispatch(updateProductExtension(updated))
    },
  })

  console.log('entity=>', entity)
  const title = `${t('Edit')} ${t('extensions_settings')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>
  const metaBar = <MetaBar backLink={'/product-extensions'} entity={entity} />

  if (paramError || listError) {
    return (
      <ErrorPage
        backLink={'/product-extensions'}
        title={title}
        error={[paramError, listError]}
      />
    )
  }

  if (!pageReady || listLoading) {
    return <LoadingPage backLink={'/product-extensions'} title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-4">
              <Form
                className="needs-validation"
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  {Object.keys(inputInitials).map((fieldKey) => {
                    const htmlForId = `validation-${fieldKey}`
                    return (
                      <Col md="4" key={`${fieldKey}`}>
                        <FormGroup className="mb-3">
                          <Label htmlFor={htmlForId}>
                            {t(inputInitials[fieldKey].label)}
                          </Label>
                          {inputInitials[fieldKey].options &&
                          inputInitials[fieldKey].options.length ? (
                            <select
                              name={`${fieldKey}`}
                              id={htmlForId}
                              className="form-select form-select-sm"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values[fieldKey]}
                            >
                              {inputInitials[fieldKey].options.map((option) => (
                                <option
                                  key={option.name}
                                  defaultValue={option.defaultValue}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          ) : inputInitials[fieldKey].date ? (
                            <Flatpickr
                              name={`${fieldKey}`}
                              id={htmlForId}
                              className="form-control form-control-sm"
                              options={{
                                dateFormat: 'd-m-Y',
                                defaultDate: [
                                  formatDate(
                                    validation.values[fieldKey] ||
                                      entity[fieldKey],
                                  ),
                                ],
                                onChange: (selectedDates, dateStr) => {
                                  console.log(
                                    'selectedDate, dateStr',
                                    selectedDates,
                                    dateStr,
                                  )
                                  validation.setFieldValue(
                                    fieldKey,
                                    formatAPIDate(selectedDates[0]),
                                  )
                                },
                              }}
                            />
                          ) : (
                            <Input
                              name={`${fieldKey}`}
                              placeholder={inputInitials[fieldKey].placeholder}
                              type="text"
                              className="form-control form-control-sm"
                              id={htmlForId}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values[fieldKey] || ''}
                              invalid={
                                !!(
                                  validation.touched[fieldKey] &&
                                  validation.errors[fieldKey]
                                )
                              }
                            />
                          )}
                          {validation.touched[fieldKey] &&
                          validation.errors[fieldKey] ? (
                            <FormFeedback type="invalid">
                              {validation.errors[fieldKey]}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    )
                  })}
                </Row>

                {!!updateError && (
                  <Alert color="warning">
                    <strong> {updateError.toString()} </strong>
                  </Alert>
                )}

                {updateLoading ? (
                  loading
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    style={{ backgroundColor: '#405189' }}
                  >
                    {t('Update')}
                  </Button>
                )}
              </Form>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
