import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from 'src/pages/Admin/ErrorPage'
import LoadingPage from 'src/pages/Admin/LoadingPage'
import { useGetChatTemplateQuery } from 'src/services/chats'

export const ChatTemplateView: FC = () => {
  const { id } = useParams()

  const { t } = useTranslation()

  const {
    data: chatTemplate,
    isError,
    error,
  } = useGetChatTemplateQuery(id!, {
    skip: id == null,
  })

  const title = t('View chat template')
  document.title = title

  if (isError) {
    return (
      <ErrorPage backLink={'/chats/templates'} title={title} error={error} />
    )
  }

  if (!chatTemplate) {
    return <LoadingPage backLink={'/chats/templates'} title={title} />
  }

  return (
    <div className="page-content">
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={'/chats/templates'}
            editLink={
              chatTemplate ? `/chats/templates/${chatTemplate.id}/edit` : null
            }
          />
          {chatTemplate &&
            Object.entries(chatTemplate).map(([key, value]) => (
              <ListGroup key={key}>
                <ListGroupItem>
                  <Row>
                    <Col>{t(key)}</Col>
                    <Col>{value}</Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            ))}
        </CardBody>
      </Card>
    </div>
  )
}
