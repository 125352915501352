import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  ModalHeader,
  Label,
  FormFeedback
} from 'reactstrap'
import {
  CallSetting,
  useUpdateCallSettingsMutation
} from 'src/services/calling'
import Loader from 'src/Components/Common/Loader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from '../ErrorPage'

interface SetDefaultSettingsProps {
  settings: CallSetting[]
  className?: string
}

export const SetDefaultSettings: FC<SetDefaultSettingsProps> = ({
  settings,
  className
}) => {
  const { t } = useTranslation()
  const { isOpen, open, close, toggle } = useDisclosure(false)

  const [updateSettings, { isLoading, error }] = useUpdateCallSettingsMutation()

  const toggleModal = () => {
    if (isOpen) {
      validation.resetForm()
    }
    toggle()
  }

  const defaultSetting = settings.find((setting) => setting.is_default)

  const validation = useFormik({
    initialValues: {
      name: defaultSetting ? defaultSetting.result_type : ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Required'))
    }),
    onSubmit: async (values) => {
      const selectedSetting = settings.find(
        (setting) => String(setting.result_type) === values.name
      )
      if (selectedSetting) {
        await updateSettings({
          client_type: selectedSetting.client_type,
          result_type: selectedSetting.result_type,
          need_reason: selectedSetting.need_reason,
          need_promise_amount: selectedSetting.need_promise_amount,
          need_promise_date: selectedSetting.need_promise_date,
          need_comment: selectedSetting.need_comment,
          is_default: true,
          id: selectedSetting.id
        })
      }
      if (!error) {
        close()
      }
    }
  })

  return (
    <div className={`d-flex ${className}`}>
      <Button
        className='btn-label'
        color='primary'
        onClick={open}
        style={{ backgroundColor: '#405189' }}
      >
        <i className='ri-award-fill label-icon align-middle fs-16 me-2'></i>
        {t('Set default result')}
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          {t('Set the default result')}
        </ModalHeader>
        <ModalBody>
          <Form className='needs-validation' onSubmit={validation.handleSubmit}>
            <FormGroup>
              <Label for='name'>{t('Select result')}</Label>
              <select
                name='name'
                id='name'
                className={`form-select ${
                  validation.touched.name && validation.errors.name
                    ? 'is-invalid'
                    : ''
                }`}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name}
              >
                <option value=''>{t('Select result')}</option>
                {settings.map((setting) => (
                  <option key={setting.id} value={setting.result_type}>
                    {setting.result_type}
                  </option>
                ))}
              </select>
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback>{validation.errors.name}</FormFeedback>
              ) : null}
            </FormGroup>
            {!!error && <DangerAlert error={error} />}
            {isLoading ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                disabled={!validation.values.name}
              >
                {t('Update')}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}
