import {
  UnionClientEntity,
  useGetClientAddonQuery,
  useGetClientDocumentsQuery,
  useGetClientEntityQuery
} from '../../../../services/clients'
import Client from '../../../../client'
import { useMemo } from 'react'

const useGetClient = (id: string) => {
  const {
    data: clientData,
    isLoading: clientLoading,
    error: entityError
  } = useGetClientEntityQuery(id)
  const {
    data: documentsRes,
    isLoading: documentsLoading,
    error: documentsError
  } = useGetClientDocumentsQuery(id)

  const {
    data: applications,
    isLoading: applicationLoading,
    error: applicationError
  } = useGetClientAddonQuery({
    id: id || '',
    type: Client.ENTITY_TYPE.APPLICATIONS
  })
  const {
    data: loans,
    isLoading: loansLoading,
    error: loansError
  } = useGetClientAddonQuery({
    id: id || '',
    type: Client.ENTITY_TYPE.LOANS
  })
  const {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentError
  } = useGetClientAddonQuery({
    id: id || '',
    type: Client.ENTITY_TYPE.PAYMENTS
  })
  const unionAddons = useMemo(
    () => ({
      [`addon_${Client.ENTITY_TYPE.APPLICATIONS}`]: applications || [],
      [`addon_${Client.ENTITY_TYPE.LOANS}`]: loans || [],
      [`addon_${Client.ENTITY_TYPE.PAYMENTS}`]: payments || []
    }),
    [applications, loans, payments]
  )

  const someLoading =
    documentsLoading ||
    clientLoading ||
    paymentsLoading ||
    loansLoading ||
    applicationLoading
  const someError = !id
    ? 'No client id found'
    : paymentError ||
      loansError ||
      applicationError ||
      documentsError ||
      entityError

  const client: Partial<UnionClientEntity> = useMemo(
    () => ({
      ...clientData,
      documents: documentsRes?.documents || [],
      ...unionAddons
    }),
    [clientData, documentsRes?.documents, unionAddons]
  )
  return { client, someError, someLoading, clientData }
}

export default useGetClient
