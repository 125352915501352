import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { useGetServicesDocMutation } from 'src/services/loans'

export default function InfoServices(props: {
  loanId: string
  active: string | undefined | null
}) {
  const { loanId, active } = props
  const { t } = useTranslation()

  const [servicesDoc] = useGetServicesDocMutation()

  const getServicesDoc = async () => {
    try {
      const response = await servicesDoc(loanId!)
      if ('data' in response) {
        const htmlContent = await response.data.text()
        const newWindow = window.open('', '_blank')
        if (newWindow) {
          newWindow.document.write(htmlContent)
          newWindow.document.close()
        } else {
          console.error('Не удалось открыть новое окно!')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Button
        size="sm"
        style={{ marginTop: '10px', marginBottom: '5px', marginLeft: '10px' }}
        onClick={() => getServicesDoc()}
        disabled={active !== 'Closed'}
      >
        {t('Information on the calculation of add. services')}
      </Button>
    </>
  )
}
