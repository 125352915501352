export const LIST = "LIST_CLIENTS"
export const LIST_SUCCESS = "LIST_CLIENTS_SUCCESS"
export const LIST_FAIL = "LIST_CLIENTS_FAIL"

export const DELETE = "DELETE_CLIENTS"
export const DELETE_SUCCESS = "DELETE_CLIENTS_SUCCESS"
export const DELETE_FAIL = "DELETE_CLIENTS_FAIL"

export const UPDATE = "UPDATE_CLIENTS"
export const UPDATE_SUCCESS = "UPDATE_CLIENTS_SUCCESS"
export const UPDATE_FAIL = "UPDATE_CLIENTS_FAIL"
