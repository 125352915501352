import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardBody,
  Input,
  Form,
  Alert,
  Label,
  FormFeedback,
  FormGroup,
  Row,
  Col
} from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import List from 'src/Components/Common/EntitiesView/List'

import {
  useGetPersonExtremistsQuery,
  useUploadFileBankMutation,
  useUploadFileCommissionMutation,
  useUploadFileExtremistsMutation,
  useUploadFileOonMutation,
  useGetValidationQuery,
  useMassValidationMutation,
  useValidationDownloadMutation
} from 'src/services/rosfinmonitoring'
import { UseQueryHookArgs } from 'src/services/api'
import { PersonList } from './PersonList'

type UploadFile = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: Function
}

export const RosFinMonitoring = () => {
  const { t } = useTranslation()
  const [fileExtremists, setFileExtremists] = useState<File>()
  const [fileCommission, setFileCommision] = useState<File>()
  const [fileBank, setFileBank] = useState<File>()
  const [fileOon, setFileOon] = useState<File>()

  const [validationFilter, setValidationFilter] = useState<
    UseQueryHookArgs<typeof useGetValidationQuery>
  >({})

  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetPersonExtremistsQuery>
  >({})

  const { data: person, error: createErrorPerson } =
    useGetPersonExtremistsQuery(
      {
        ...fieldsFilter
      },
      { skip: Object.keys(fieldsFilter).length === 0 }
    )

  const { data: validation, error: createErrorValidation } =
    useGetValidationQuery({
      limit: 5,
      offset: 0,
      ...validationFilter
    })

  const [
    massValidation,
    {
      isLoading: isLoadMassValidation,
      error: createErrorMassValidation,
      isSuccess: isSuccessfulMassValidation
    }
  ] = useMassValidationMutation()

  const [validationDownload] = useValidationDownloadMutation()

  const [
    uploadExtremists,
    {
      isLoading: isLoadExtremists,
      error: createErrorExtremists,
      isSuccess: isSuccessfulExtremists
    }
  ] = useUploadFileExtremistsMutation()

  const [
    uploadCommission,
    {
      isLoading: isLoadCommission,
      error: createErrorCommission,
      isSuccess: isSuccessfulCommission
    }
  ] = useUploadFileCommissionMutation()

  const [
    uploadBank,
    {
      isLoading: isLoadBank,
      error: createErrorBank,
      isSuccess: isSuccessfulBank
    }
  ] = useUploadFileBankMutation()

  const [
    uploadOon,
    { isLoading: isLoadOon, error: createErrorOon, isSuccess: isSuccessfulOon }
  ] = useUploadFileOonMutation()

  // eslint-disable-next-line @typescript-eslint/ban-types
  const formFile = (file: any, fn: Function) => {
    const inputFile = new FormData()
    inputFile.append('xml_file', file)
    fn(inputFile)
  }

  const addFile = (file: any, entityType: string) => {
    const upload: UploadFile = {
      UPLOAD_DICT_EXTREMISTS: uploadExtremists,
      UPLOAD_DICT_INTERDEPARTMENTAL_COMMISSION: uploadCommission,
      UPLOAD_DICT_CENTRAL_BANK: uploadBank,
      UPLOAD_DICT_OON: uploadOon
    }
    formFile(file, upload[entityType])
  }

  const downloadFile = (path_to_file: string) => {
    validationDownload({ path_to_dict: path_to_file }).then((blob) => {
      if ('data' in blob) {
        const url = window.URL.createObjectURL(new Blob([blob.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${path_to_file}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      start_date: '',
      end_date: ''
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.string().test(
        'start_date',
        t(
          'The start date must be earlier than the end date. Please check if your entry is correct'
        ),
        (value, schema) => {
          return moment(value) < moment(schema.parent.end_date)
        }
      ),
      end_date: Yup.string()
      // .test(
      //   'end_date',
      //   t('The specified period must be no more than 100 days'),
      //   (value, schema) => {
      //     const dateAmount = moment(value).diff(
      //       moment(schema.parent.start_date),
      //       'days',
      //     )
      //     return dateAmount < 100
      //   },
      // ),
    }),
    onSubmit: (values) => {
      massValidation(values)
    }
  })
  const title = t('Rosfinmonitoring')

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <h4>{t('downloading directories')}</h4>
          <Form
            className='needs-validation mt-4'
            onSubmit={(e) => {
              e.preventDefault()
              addFile(fileExtremists, 'UPLOAD_DICT_EXTREMISTS')
              return false
            }}
          >
            <Label htmlFor='upload_extremists'>
              {t('Extremists and terrorists')}
            </Label>
            <Input
              type='file'
              onChange={(e) => setFileExtremists(e.target.files?.[0])}
              name='upload_extremists'
              id='upload_extremists'
              accept='.xml'
            />
            {isLoadExtremists && (
              <Alert color='info'>
                <strong> {t('File Uploads')} </strong>
              </Alert>
            )}
            {createErrorExtremists && (
              <Alert color='warning'>
                <strong>{JSON.stringify(createErrorExtremists)}</strong>
              </Alert>
            )}
            {isSuccessfulExtremists && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              style={{ marginTop: '15px' }}
              type='submit'
              disabled={!fileExtremists}
            >
              {t('Send')}
            </Button>
          </Form>

          <Form
            className='needs-validation mt-4'
            onSubmit={(e) => {
              e.preventDefault()
              addFile(
                fileCommission,
                'UPLOAD_DICT_INTERDEPARTMENTAL_COMMISSION'
              )
              return false
            }}
          >
            <Label htmlFor='upload_commission'>
              {t(
                'Interdepartmental Commission on Combating Legalization (Laundering) of Proceeds from Crime and the Financing of Terrorism'
              )}
            </Label>
            <Input
              type='file'
              onChange={(e) => setFileCommision(e.target.files?.[0])}
              name='upload_commission'
              id='upload_commission'
              accept='.xml'
            />
            {isLoadCommission && (
              <Alert color='info'>
                <strong> {t('File Uploads')} </strong>
              </Alert>
            )}
            {createErrorCommission && (
              <Alert color='warning'>
                <strong>{JSON.stringify(createErrorCommission)}</strong>
              </Alert>
            )}
            {isSuccessfulCommission && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              style={{ marginTop: '15px' }}
              type='submit'
              disabled={!fileCommission}
            >
              {t('Send')}
            </Button>
          </Form>

          <Form
            className='needs-validation mt-4'
            onSubmit={(e) => {
              e.preventDefault()
              addFile(fileOon, 'UPLOAD_DICT_OON')
              return false
            }}
          >
            <Label htmlFor='upload_OON'>{t('Security System OON')}</Label>
            <Input
              type='file'
              onChange={(e) => setFileOon(e.target.files?.[0])}
              name='upload_OON'
              id='upload_OON'
              accept='.xml'
            />
            {isLoadOon && (
              <Alert color='info'>
                <strong> {t('File Uploads')} </strong>
              </Alert>
            )}
            {createErrorOon && (
              <Alert color='warning'>
                <strong>{JSON.stringify(createErrorOon)}</strong>
              </Alert>
            )}
            {isSuccessfulOon && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              style={{ marginTop: '15px' }}
              type='submit'
              disabled={!fileOon}
            >
              {t('Send')}
            </Button>
          </Form>

          <Form
            className='needs-validation mt-4'
            onSubmit={(e) => {
              e.preventDefault()
              addFile(fileBank, 'UPLOAD_DICT_CENTRAL_BANK')
              return false
            }}
          >
            <Label htmlFor='upload_CentralBank'>
              {t('Mass mailing of the Central Bank')}
            </Label>
            <Input
              type='file'
              onChange={(e) => setFileBank(e.target.files?.[0])}
              name='upload_CentralBank'
              id='upload_CentralBank'
              accept='.xml'
            />
            {isLoadBank && (
              <Alert color='info'>
                <strong> {t('File Uploads')} </strong>
              </Alert>
            )}
            {createErrorBank && (
              <Alert color='warning'>
                <strong>{JSON.stringify(createErrorBank)}</strong>
              </Alert>
            )}
            {isSuccessfulBank && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              style={{ marginTop: '15px' }}
              type='submit'
              disabled={!fileBank}
            >
              {t('Send')}
            </Button>
          </Form>

          <h4 className='mt-4'>{t('Quarterly check')}</h4>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md='2'>
                <FormGroup>
                  <Label htmlFor='start_date'>{t('start_date')}</Label>
                  <Flatpickr
                    name='start_date'
                    id='start_date'
                    type='date'
                    className='form-control form-control-sm'
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                      onChange: (selectedDates: any, dateStr: any) => {
                        formik.setFieldValue('start_date', dateStr)
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md='2'>
                <FormGroup>
                  <Label htmlFor='end_date'>{t('end_date')}</Label>
                  <Flatpickr
                    name='end_date'
                    id='end_date'
                    type='date'
                    className='form-control form-control-sm'
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: moment(new Date()).format('YYYY-MM-DD'),

                      onChange: (selectedDates: any, dateStr: any) => {
                        formik.setFieldValue('end_date', dateStr)
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            {formik.touched.start_date && formik.errors.start_date ? (
              <FormFeedback
                style={{ display: 'block', marginBottom: '10px' }}
                type='invalid'
              >
                <Alert color='warning'>
                  <strong> {formik.errors.start_date} </strong>
                </Alert>
              </FormFeedback>
            ) : null}

            {formik.touched.end_date && formik.errors.end_date ? (
              <FormFeedback
                style={{ display: 'block', marginBottom: '10px' }}
                type='invalid'
              >
                <Alert color='warning'>
                  <strong> {formik.errors.end_date} </strong>
                </Alert>
              </FormFeedback>
            ) : null}

            {isLoadMassValidation && (
              <Alert color='info'>
                <strong> {t('loading')} </strong>
              </Alert>
            )}
            {createErrorMassValidation && (
              <Alert color='warning'>
                <strong>{JSON.stringify(createErrorMassValidation)}</strong>
              </Alert>
            )}
            {isSuccessfulMassValidation && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              className='text-nowrap'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              type='submit'
              disabled={!formik.values.start_date || !formik.values.end_date}
            >
              {t('Send')}
            </Button>
          </Form>

          <h4 className='mt-4'>{t('Check reports')}</h4>
          <FieldsFilter
            fields={{
              id: {},
              // created_at: { date: true },
              dict_creation_date: { date: true },
              dict_type: {
                optionsValue: [
                  { value: 'extremists', name: 'экстремистов-террористов' },
                  {
                    value: 'interdepartmental-commission',
                    name: 'межведомственная комиссия'
                  },
                  { value: 'oon', name: 'ООН' }
                ]
              },
              path_to_file: {}
            }}
            applyFilter={(values: typeof validationFilter) =>
              setValidationFilter(values)
            }
          />
          {createErrorValidation && (
            <Alert color='warning'>
              <strong>{JSON.stringify(createErrorValidation)}</strong>
            </Alert>
          )}
          {validation?.length ? (
            <List
              list={validation?.map((value) => {
                return { ...value }
              })}
              className={undefined}
              style={{ width: 'fit-content' }}
              fieldsDescription={{
                download: {
                  label: ' ',
                  mappingValue: () => <i className='mdi mdi-download' />,
                  isButton: true,
                  onClick: (item: any) => downloadFile(item.path_to_file)
                },
                id: {},
                created_at: {},
                dict_type: {},
                dict_creation_date: {},
                path_to_file: {}
              }}
              hover
              actions={undefined}
            />
          ) : (
            <Alert color='success'>
              <strong>{t('No data available')}</strong>
            </Alert>
          )}

          <h4 className='mt-4'>{t('Search from the directory')}</h4>
          <FieldsFilter
            fields={{
              inn: {},
              docSeries: {},
              docNumber: {},
              secondname: {},
              firstname: {},
              middlename: {},
              birthDate: { date: true }
            }}
            applyFilter={(values: typeof fieldsFilter) => {
              setFieldsFilter(values)
            }}
          />
          {(createErrorPerson && (
            <Alert color='success'>
              <strong>{t('Person not found')}</strong>
            </Alert>
          )) ||
            (person && <PersonList person={person} />)}
        </CardBody>
      </Card>
    </div>
  )
}
