import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, ListGroupItem, Row, ListGroup } from 'reactstrap'

import { UseQueryHookResultData } from 'src/services/api'
import { useGetPersonExtremistsQuery } from 'src/services/rosfinmonitoring'
import { formatDateWithDash } from 'src/utils'

type PropsPerson = {
  person?: UseQueryHookResultData<typeof useGetPersonExtremistsQuery>
}

export const PersonList: FC<PropsPerson> = ({ person }) => {
  const { t } = useTranslation()

  return (
    <ListGroup>
      <ListGroupItem>
        <Row>
          <Col>{t(`id`)}</Col>
          <Col>{person?.id || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`last_name`)}</Col>
          <Col>{person?.last_name || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`first_name`)}</Col>
          <Col>{person?.first_name || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`middle_name`)}</Col>
          <Col>{person?.middle_name || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`latin_full_name`)}</Col>
          <Col>{person?.latin_full_name || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`birthday_date`)}</Col>
          <Col>{formatDateWithDash(person?.birthday_date)}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`birth_place`)}</Col>
          <Col>{person?.birth_place || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`citizenship`)}</Col>
          <Col>{person?.citizenship || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`okato_code`)}</Col>
          <Col>{person?.okato_code || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`inn`)}</Col>
          <Col>{person?.inn || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`snils`)}</Col>
          <Col>{person?.snils || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`doc_type`)}</Col>
          <Col>{person?.doc_type || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`series`)}</Col>
          <Col>{person?.series || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`number`)}</Col>
          <Col>{person?.number || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`issue_date`)}</Col>
          <Col>{formatDateWithDash(person?.issue_date)}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`issuer`)}</Col>
          <Col>{person?.issuer || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`country_name`)}</Col>
          <Col>{person?.country_name || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`country_code`)}</Col>
          <Col>{person?.country_code || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`region`)}</Col>
          <Col>{person?.region || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`district`)}</Col>
          <Col>{person?.district || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`city`)}</Col>
          <Col>{person?.city || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`street`)}</Col>
          <Col>{person?.street || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`house`)}</Col>
          <Col>{person?.house || '-'}</Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          <Col>{t(`created_at`)}</Col>
          <Col>{formatDateWithDash(person?.created_at)}</Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  )
}
