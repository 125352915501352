export const GET_STRATEGIES = 'GET_STRATEGIES'
export const GET_STRATEGIES_SUCCESS = 'GET_STRATEGIES_SUCCESS'
export const GET_STRATEGIES_FAIL = 'GET_STRATEGIES_FAIL'

export const GET_STRATEGY = 'GET_STRATEGY'
export const GET_STRATEGY_SUCCESS = 'GET_STRATEGY_SUCCESS'
export const GET_STRATEGY_FAIL = 'GET_STRATEGY_FAIL'

export const CREATE_STRATEGY = 'CREATE_STRATEGY'
export const CREATE_STRATEGY_SUCCESS = 'CREATE_STRATEGY_SUCCESS'
export const CREATE_STRATEGY_FAIL = 'CREATE_STRATEGY_FAIL'

export const UPDATE_STRATEGY = 'UPDATE_STRATEGY'
export const UPDATE_STRATEGY_SUCCESS = 'UPDATE_STRATEGY_SUCCESS'
export const UPDATE_STRATEGY_FAIL = 'UPDATE_STRATEGY_FAIL'
