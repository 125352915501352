import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap'
import {
  mapStatusToClassName,
  mapStatusToName,
} from 'src/Components/Common/EntitiesView/Communications'

import { UseQueryHookResultData } from 'src/services/api'
import {
  useGetMessagesClaimQuery,
  useGetUploadAttachmentMutation,
} from 'src/services/claims'
import { formatDateWithDash } from 'src/utils'

type Props = {
  messages?: UseQueryHookResultData<typeof useGetMessagesClaimQuery>[0]
}

export const ClaimResponseView: FC<Props> = ({ messages }) => {
  const { t } = useTranslation()
  const [uploadAttachment] = useGetUploadAttachmentMutation()

  const downloadFile = (file: any) => {
    uploadAttachment({ id: file.id }).then((blob) => {
      if ('data' in blob) {
        const url = window.URL.createObjectURL(new Blob([blob.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${file.file_name}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    })
  }

  return (
    <ListGroup>
      <ListGroupItem>
        <Row>
          <Col>{t('ID')}</Col>
          <Col>{messages?.id}</Col>
        </Row>
      </ListGroupItem>

      {messages?.response_code && (
        <ListGroupItem>
          <Row>
            <Col>{t('status')}</Col>
            <Col className={mapStatusToClassName(`${messages.response_code}`)}>
              {t(mapStatusToName(`${messages.response_code}`))}
            </Col>
          </Row>
        </ListGroupItem>
      )}

      <ListGroupItem>
        <Row>
          <Col>{t('profile_id')}</Col>
          <Col>{messages?.profile_id}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('created_at')}</Col>
          <Col>{formatDateWithDash(messages?.created_at)}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('email')}</Col>
          <Col>{messages?.email}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('subject')}</Col>
          <Col>{messages?.subject}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('text_response')}</Col>
          <Col>{messages?.text}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('assignee_username')}</Col>
          <Col>{messages?.author_username}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('file_name')}</Col>
          <Col>
            {messages?.attachments.map((file) => {
              return (
                <div className="d-flex flex-row gap-2" key={file.file_vault_id}>
                  {file.file_name}
                  <div
                    role="button"
                    className="text-decoration-underline"
                    onClick={() => downloadFile(file)}
                  >
                    <small>{t('Download')}</small>
                  </div>
                </div>
              )
            })}
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  )
}
