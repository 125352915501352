import React from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import UiContent from '../../../Components/Common/UiContent'
import MetaBar from '../../../Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from '../../../model'

import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from 'src/services/products'

const fieldsDescription = {
  product_code: { label: 'code' },
  product_name: { label: 'name' },
  interest_rate: { label: 'interest rate' },
  autosign_application: { label: 'autosign_application' },
  manual_verification: { label: 'manual_verification' },
  product_priority: { label: 'priority' },
  product_status: { label: 'state' },
}

export const Products = () => {
  const { t } = useTranslation()
  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useGetProductsQuery({
    limit: 500,
    offset: 0,
  })

  const [deleteProduct] = useDeleteProductMutation()

  const archive = (productId) => {
    deleteProduct(productId)
  }

  console.log('products =>', products)
  const title = t('Products')
  document.title = title

  const metaBar = <MetaBar createLink={'/product-create'} />

  if (productsError) {
    return <ErrorPage title={title} error={productsError} />
  }

  if (productsLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={t('Products')} />
        <Card>
          <CardBody>
            {metaBar}
            <div className="table-responsive table-card mt-4">
              <Table hover className="table-sm align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th />
                    {Object.keys(fieldsDescription).map((header) => (
                      <th key={header} scope="col">
                        {t(fieldsDescription[header].label)}
                      </th>
                    ))}
                    <th scope="col">{t('Actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.length > 0 &&
                    products.map((product) => {
                      return (
                        <tr key={product.id} data-testid="product-table-row">
                          <td>
                            <Link
                              to={`/product-view?id=${product.id}`}
                              className="fw-medium"
                            >
                              <i className="ri-eye-line fs-15" />
                            </Link>
                          </td>
                          {Object.keys(fieldsDescription).map((fieldName) => {
                            return (
                              <td key={fieldName}>
                                {model.fieldValueToText(
                                  fieldName,
                                  product,
                                  fieldsDescription,
                                )}
                              </td>
                            )
                          })}
                          <td>
                            <div className="hstack gap-3 flex-wrap">
                              <Link
                                to={`/product-edit?id=${product.id}`}
                                className="link-success fs-15"
                              >
                                <i className="ri-edit-2-line"></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={() => archive(product.id)}
                                className="link-danger fs-15"
                              >
                                <i className="ri-delete-bin-line"></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
