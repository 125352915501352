import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  LIST,
} from "./actionType";

import {
  getProfilesFail,
  getProfilesSuccess,
} from "./action";

import Client from "../../client";

function* getList({ id, filter }) {
  try {
    if (id) {
      const response = yield call(Client.getEntity, Client.ENTITY_TYPE.PROFILES, id);

      if (response.client && response.client.id) {
        try {
          const documents = yield call(Client.getClientDocuments, response.client.id);
          response.client.documents = documents.documents;
        } catch (err) {
          response.client.documents = [];
        }
      }
      yield put(getProfilesSuccess([response]));
    } else {
      const response = yield call(Client.getList, Client.ENTITY_TYPE.PROFILES, filter);
      yield put(getProfilesSuccess(response));
    }
  } catch (error) {
    yield put(getProfilesFail(error));
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getList);
}

function* profilesSaga() {
  yield all([
    fork(watchOnGetList),
  ]);
}

export default profilesSaga;
