import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import List from './List'
import {
  useGetAtolListQuery,
  useSetAtolDownloadMutation,
} from 'src/services/payments'
import { Button } from 'reactstrap'
import Loader from '../Loader'

type Props = {
  entityId: string
  visible: boolean
  entityType: string
  icon: unknown | null
  title: string
}

export const AtolLoan: FC<Props> = ({ entityId, visible }) => {
  const { t } = useTranslation()

  const {
    data: atolList,
    isFetching,
    isSuccess,
  } = useGetAtolListQuery(entityId, {
    skip: entityId == null || !visible,
  })
  const [atolDownload] = useSetAtolDownloadMutation()

  const downloadFile = (atol_id: number, is_refunded: boolean) => {
    atolDownload({
      loanId: entityId,
      atolId: atol_id,
      refunded: is_refunded,
    }).then((blob) => {
      if ('data' in blob) {
        const url = window.URL.createObjectURL(new Blob([blob.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${atol_id}.json`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    })
  }

  return (
    <>
      {isFetching && <Loader />}
      {isSuccess && atolList?.length ? (
        <List
          list={atolList.map((item) => ({
            ...item,
            id_link: `/atol-view?id=${item.id}`,
          }))}
          className={undefined}
          fieldsDescription={{
            name: {},
            created_at: { isDate: true },
          }}
          style={undefined}
          hover
          actions={atolList.map((item, rank) => {
            if (item.is_refunded === true) {
              return (
                <div
                  key={`action-${rank}`}
                  className="d-flex flex-row align-items-center gap-3"
                >
                  <Button
                    size="sm"
                    onClick={() => downloadFile(item.id, false)}
                  >
                    {t('download check')}
                  </Button>

                  <Button size="sm" onClick={() => downloadFile(item.id, true)}>
                    {t('cancellation check')}
                  </Button>
                </div>
              )
            } else
              return (
                <Button
                  key={`action-${rank}`}
                  size="sm"
                  onClick={() => downloadFile(item.id, false)}
                >
                  {t('download check')}
                </Button>
              )
          })}
        />
      ) : (
        <div style={{ marginBottom: '10px' }}>
          <dl className="row mb-0">
            <small>{t('No data')}</small>
          </dl>
        </div>
      )}
    </>
  )
}
