import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  useAddPromotionMutation,
  useGetPromotionQuery,
  useUpdatePromotionMutation,
} from 'src/services/promotions'
import { mapBooleanStringToBoolean } from 'src/utils'
import * as Yup from 'yup'

export const PromotionEdit = () => {
  const [searchParams] = useSearchParams()

  const { t } = useTranslation()

  const {
    data: promotion,
    isFetching,
    error: getError,
  } = useGetPromotionQuery(searchParams.get('id')!, {
    skip: searchParams.get('id') == null,
  })

  const [
    updatePromotion,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess },
  ] = useUpdatePromotionMutation()

  const [
    addPromotion,
    { isLoading: isCreating, error: createError, isSuccess: isCreateSuccess },
  ] = useAddPromotionMutation()

  const title = searchParams.get('id')
    ? t('Edit promotion')
    : t('Create promotion')
  document.title = title

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      promo_name: '',
      interest_freezing: 'false',
      fines_freezing: 'false',
      offer_duration: 0,
      ...promotion,
      main_body_writeoff: promotion?.main_body_writeoff
        ? promotion?.main_body_writeoff * 100
        : 0,
      fines_writeoff: promotion?.fines_writeoff
        ? promotion?.fines_writeoff * 100
        : 0,
      interest_writeoff: promotion?.interest_writeoff
        ? promotion?.interest_writeoff * 100
        : 0,
    },
    validationSchema: Yup.object().shape({
      promo_name: Yup.string().required('Cannot be empty'),
      main_body_writeoff: Yup.number()
        .min(0, 'Cannot be less than 0')
        .max(100, 'Cannot be more than 100')
        .required('Cannot be empty'),
      interest_writeoff: Yup.number()
        .min(0, 'Cannot be less than 0')
        .max(100, 'Cannot be more than 100')
        .required('Cannot be empty'),
      fines_writeoff: Yup.number()
        .min(0, 'Cannot be less than 0')
        .max(100, 'Cannot be more than 100')
        .required('Cannot be empty'),
      interest_freezing: Yup.string().nullable(),
      fines_freezing: Yup.string().nullable(),
      offer_duration: Yup.number().required('Cannot be empty'),
    }),
    onSubmit: (values) => {
      searchParams.get('id')
        ? updatePromotion({
            ...values,
            id: promotion?.id,
            main_body_writeoff: Number.isInteger(values.main_body_writeoff)
              ? values.main_body_writeoff / 100
              : 0,
            fines_writeoff: Number.isInteger(values.fines_writeoff)
              ? values.fines_writeoff / 100
              : 0,
            interest_writeoff: Number.isInteger(values.interest_writeoff)
              ? values.interest_writeoff / 100
              : 0,
            interest_freezing: mapBooleanStringToBoolean(
              values.interest_freezing,
            ),
            fines_freezing: mapBooleanStringToBoolean(values.fines_freezing),
          })
        : addPromotion({
            ...values,
            main_body_writeoff: Number.isInteger(values.main_body_writeoff)
              ? values.main_body_writeoff / 100
              : 0,
            fines_writeoff: Number.isInteger(values.fines_writeoff)
              ? values.fines_writeoff / 100
              : 0,
            interest_writeoff: Number.isInteger(values.interest_writeoff)
              ? values.interest_writeoff / 100
              : 0,
            interest_freezing: mapBooleanStringToBoolean(
              values.interest_freezing,
            ),
            fines_freezing: mapBooleanStringToBoolean(values.fines_freezing),
          })
    },
  })

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={'/promotions'}
            editLink={undefined}
            entity={promotion}
            createLink={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="promo_name">{t('promo_name')}</Label>
                  <Input
                    name="promo_name"
                    type="text"
                    id="promo_name"
                    className="form-control form-control-sm"
                    placeholder="abcdef-123456"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.promo_name ?? ''}
                    disabled={
                      isCreating || isUpdating || isFetching || isFetching
                    }
                    invalid={
                      formik.touched.promo_name && !!formik.errors.promo_name
                    }
                  />
                  <FormFeedback type="invalid">
                    {t(formik.errors.promo_name || 'Error')}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label htmlFor="main_body_writeoff">
                    {t('main_body_writeoff')}
                  </Label>
                  <InputGroup size="sm">
                    <InputGroupText>%</InputGroupText>
                    <Input
                      name="main_body_writeoff"
                      type="number"
                      className="form-control form-control-sm"
                      id="main_body_writeoff"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.main_body_writeoff ?? ''}
                      disabled={
                        isCreating || isUpdating || isFetching || isFetching
                      }
                      invalid={
                        formik.touched.main_body_writeoff &&
                        !!formik.errors.main_body_writeoff
                      }
                    />
                    <FormFeedback type="invalid">
                      {t(formik.errors.main_body_writeoff || 'Error')}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label htmlFor="interest_writeoff">
                    {t('interest_writeoff')}
                  </Label>
                  <InputGroup size="sm">
                    <InputGroupText>%</InputGroupText>
                    <Input
                      name="interest_writeoff"
                      type="number"
                      className="form-control form-control-sm"
                      id="interest_writeoff"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.interest_writeoff}
                      disabled={
                        isCreating || isUpdating || isFetching || isFetching
                      }
                      invalid={
                        formik.touched.interest_writeoff &&
                        !!formik.errors.interest_writeoff
                      }
                    />
                    <FormFeedback type="invalid">
                      {t(formik.errors.interest_writeoff || 'Error')}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label htmlFor="fines_writeoff">{t('fines_writeoff')}</Label>
                  <InputGroup size="sm">
                    <InputGroupText>%</InputGroupText>
                    <Input
                      name="fines_writeoff"
                      type="number"
                      className="form-control form-control-sm"
                      id="fines_writeoff"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fines_writeoff ?? ''}
                      disabled={
                        isCreating || isUpdating || isFetching || isFetching
                      }
                      invalid={
                        formik.touched.fines_writeoff &&
                        !!formik.errors.fines_writeoff
                      }
                    />
                    <FormFeedback type="invalid">
                      {t(formik.errors.fines_writeoff || 'Error')}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label htmlFor="interest_freezing">
                    {t('interest_freezing')}
                  </Label>
                  <select
                    name="interest_freezing"
                    id="interest_freezing"
                    className="form-select form-select-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={`${formik.values.interest_freezing ?? 'false'}`}
                    disabled={
                      isCreating || isUpdating || isFetching || isFetching
                    }
                  >
                    <option value="true">{t('Yes')}</option>
                    <option value="false">{t('No')}</option>
                  </select>
                  <FormFeedback type="invalid">
                    {t(formik.errors.interest_freezing || 'Error')}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label htmlFor="fines_freezing">{t('fines_freezing')}</Label>
                  <select
                    name="fines_freezing"
                    id="fines_freezing"
                    className="form-select form-select-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={`${formik.values.fines_freezing ?? 'false'}`}
                    disabled={
                      isCreating || isUpdating || isFetching || isFetching
                    }
                  >
                    <option value="true">{t('Yes')}</option>
                    <option value="false">{t('No')}</option>
                  </select>
                  <FormFeedback type="invalid">
                    {t(formik.errors.fines_freezing || 'Error')}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label htmlFor="offer_duration">{t('offer_duration')}</Label>
                  <Input
                    name="offer_duration"
                    type="number"
                    className="form-control form-control-sm"
                    id="offer_duration"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.offer_duration}
                    disabled={
                      isCreating || isUpdating || isFetching || isFetching
                    }
                    invalid={
                      formik.touched.offer_duration &&
                      !!formik.errors.offer_duration
                    }
                  />
                  <FormFeedback type="invalid">
                    {t(formik.errors.offer_duration || 'Error')}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            {updateError && (
              <Alert color="warning">
                <strong> {JSON.stringify(updateError)} </strong>
              </Alert>
            )}

            {createError && (
              <Alert color="warning">
                <strong> {JSON.stringify(createError)} </strong>
              </Alert>
            )}

            {getError && (
              <Alert color="warning">
                <strong> {JSON.stringify(getError)} </strong>
              </Alert>
            )}

            {(isUpdateSuccess || isCreateSuccess) && (
              <Alert color="success">
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              className="text-nowrap"
              color="primary"
              style={{ backgroundColor: '#405189' }}
              type="submit"
              disabled={
                !formik.isValid ||
                isCreating ||
                isUpdating ||
                isFetching ||
                (!!searchParams.get('id') && !formik.dirty)
              }
            >
              {searchParams.get('id') ? t('Update') : t('Create')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
