import { api } from './api'

type PromocodesGroup = {
  id: number
  group_name: string
}

const URL = 'crm/v1/groups-promo'

const promocodesGroupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromocodesGroups: builder.query<PromocodesGroup[], void>({
      query: () => `${URL}`,
      providesTags: (result = []) => [
        ...result.map(
          ({ id }: { id: string | number }) =>
            ({ type: 'PromocodesGroup', id: `${id}` }) as const
        ),
        { type: 'PromocodesGroup' as const, id: 'LIST' }
      ]
    }),
    createPromocodesGroup: builder.mutation<void, Partial<PromocodesGroup>>({
      query: (body) => ({
        url: `${URL}`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['PromocodesGroup']
    }),
    editPromocodesGroup: builder.mutation<void, Partial<PromocodesGroup>>({
      query: (body) => ({
        url: `${URL}/${body.id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['PromocodesGroup']
    })
  })
})

export const {
  useGetPromocodesGroupsQuery,
  useCreatePromocodesGroupMutation,
  useEditPromocodesGroupMutation
} = promocodesGroupsApi
