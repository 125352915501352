import {all, call, fork, put, takeEvery} from "redux-saga/effects";

// Crypto Redux States
import {LIST,} from "./actionType";

import {getRolesFail, getRolesSuccess,} from "./action";

import Client from "../../client";

function* getList({ id, filter }) {
    try {
        const role = {};
        if (id) {
            role.activePermissions = yield call(Client.getRolePermission, id) || [];
            try {
                role.allPermissions = Object.keys(yield call(Client.getAllPermissions)).map(permission => {
                    return permission.split('_').join(' ');
                });
            } catch(e) {
                role.groups = [];
            }

            yield put(getRolesSuccess([role]));
        } else {
            const response = yield call(Client.getList, Client.ENTITY_TYPE.ROLES, filter);
            yield put(getRolesSuccess(response));
        }
    } catch (error) {
        yield put(getRolesFail(error));
    }
}

export function* watchOnGetList() {
    yield takeEvery(LIST, getList);
}

function* rolesSaga() {
    yield all([
        fork(watchOnGetList),
    ]);
}

export default rolesSaga;