import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  LIST,
  DELETE,
  UPDATE,
} from "./actionType";

import {
  getProductExtensions as getProductExtensionsAction,
  getProductExtensionsFail,
  getProductExtensionsSuccess,
  deleteProductExtensionSuccess,
  deleteProductExtensionFail,
  updateProductExtensionSuccess,
  updateProductExtensionFail,
} from "./action";

import Client from "../../client";

function* deleteProductExtension({ payload }) {
  try {
    const response = yield call(Client.deleteEntity, Client.ENTITY_TYPE.EXTENSIONS, payload);
    yield put(deleteProductExtensionSuccess());
    yield put(getProductExtensionsAction());
  } catch (error) {
    yield put(deleteProductExtensionFail(error));
  }
}

function* updateProductExtension({ payload }) {
  try {
    const response = yield call(Client.updateEntity, Client.ENTITY_TYPE.EXTENSIONS, payload);
    yield put(updateProductExtensionSuccess());
    yield put(getProductExtensionsAction());
  } catch (error) {
    yield put(updateProductExtensionFail(error));
  }
}

function* getProductExtensions({ id, filter }) {
  try {
    if (id) {
      const entity = yield call(Client.getEntity, Client.ENTITY_TYPE.EXTENSIONS, id);
      yield put(getProductExtensionsSuccess([entity]));
    } else {
      const response = yield call(Client.getList, Client.ENTITY_TYPE.EXTENSIONS, filter);
      yield put(getProductExtensionsSuccess(response));
    }
  } catch (error) {
    yield put(getProductExtensionsFail(error));
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getProductExtensions);
}

export function* watchOnDelete() {
  yield takeEvery(DELETE, deleteProductExtension);
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateProductExtension);
}

function* clientsSaga() {
  yield all([
    fork(watchOnGetList),
    fork(watchOnDelete),
    fork(watchOnUpdate),
  ]);
}

export default clientsSaga;
