import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'

import InputItem from './InputItem'

import Select, { MultiValue } from 'react-select'

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Alert,
  Spinner
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  ALL_OPTIONS,
  INITIAL_INPUTS,
  TIME_ZONE_OPTIONS
} from './constants/queues.constants'
import {
  QueueBody,
  QueueOrder,
  QueueResponse,
  useCreateQueueMutation,
  useGetGroupsQuery,
  useGetQueueQuery,
  useUpdateQueueMutation
} from '../../../services/calling'
import { useNavigate } from 'react-router-dom'
import { handleErrors } from './utils/queues.utils'
import ErrorPage from '../ErrorPage'
import { Timezone } from './types/queues.types'

export const QueueEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [id, setId] = useState('')
  const [entity, setEntity] = useState<QueueResponse | null>(null)
  const [initialFormValues, setInitialFormValues] = useState<
    Record<string, unknown>
  >({})
  const [selectedTimezone, setSelectedTimezone] = useState<string[]>([])

  const {
    data: queue,
    isLoading,
    error: queueError,
    refetch
  } = useGetQueueQuery(
    { queue_params_id: +id },
    {
      skip: !id
    }
  )
  const { data: listGroups, isLoading: listGroupsLoading } = useGetGroupsQuery()
  // const { data: listCollectors, isLoading: listCollectorsLoading } =
  //   useGetCollectorsQuery({})
  const [createQueue, { error: createError, isLoading: createLoading }] =
    useCreateQueueMutation()
  const [updateQueue, { error: updateError, isLoading: updateLoading }] =
    useUpdateQueueMutation()

  useEffect(() => {
    if (listGroups) {
      INITIAL_INPUTS.groups.options = [
        { name: '-', value: '-' },
        ...listGroups.map((group) => ({
          ...group,
          name: group.name,
          value: group.id
        }))
      ]

      INITIAL_INPUTS.groups.options.sort((a, b) =>
        a.name > b.name ? 1 : a.name === b.name ? 0 : -1
      )
    }
  }, [listGroups])

  useEffect(() => {
    // delete if you use useGetCollectorsQuery
    const listCollectors: any = []

    if (listCollectors) {
      INITIAL_INPUTS.users.options = [
        { name: '-', value: '-' },
        ...listCollectors.map((user: any) => ({
          ...user,
          name: `${user.surname} ${user.name} ${user.midname}`,
          value: user.id
        }))
      ]

      INITIAL_INPUTS.users.options.sort((a, b) =>
        a.name > b.name ? 1 : a.name === b.name ? 0 : -1
      )
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')

    if (id) {
      setId(id)
    }

    // create mode
    const initialFormValues: Record<string, unknown> = {}

    Object.keys(INITIAL_INPUTS).forEach((key) => {
      const input = INITIAL_INPUTS[key]

      if (input.type === 'order') {
        if (input.order && input.order.initial !== undefined) {
          initialFormValues[`${key}-order`] = input.order.initial
        }

        if (input.priority && input.priority.initial !== undefined) {
          initialFormValues[`${key}-priority`] = input.priority.initial
        }
      } else {
        if (input.initial !== undefined) {
          initialFormValues[key] = input.initial
        }
      }
    })

    setInitialFormValues(initialFormValues)
  }, [])

  useEffect(() => {
    if (id && !isLoading && queue && queue.length > 0) {
      //edit mode
      const initialFormValues: Record<string, unknown> = {}

      //targeters
      initialFormValues.users =
        queue[0]['users']?.length > 0 ? queue[0]['users'] : []
      initialFormValues.groups =
        queue[0]['groups']?.length > 0 ? queue[0]['groups'] : []
      initialFormValues.queue_name =
        queue[0]['queue_name'] !== undefined && queue[0]['queue_name'] !== null
          ? queue[0]['queue_name']
          : ''
      initialFormValues.seq =
        queue[0]['seq'] !== undefined && queue[0]['seq'] !== null
          ? queue[0]['seq']
          : ''

      //orders
      Object.keys(INITIAL_INPUTS)
        .filter((key) => INITIAL_INPUTS[key].type === 'order')
        .forEach((key) => {
          const queueItem = queue[0][key as keyof QueueResponse] as QueueOrder

          if (!queueItem || !queueItem.order) {
            initialFormValues[`${key}-order`] = '-'
            initialFormValues[`${key}-priority`] = 0
          } else {
            initialFormValues[`${key}-order`] = queueItem.order
            initialFormValues[`${key}-priority`] = queueItem.priority
          }
        })

      //filters
      Object.keys(INITIAL_INPUTS)
        .filter((key) => INITIAL_INPUTS[key].type === 'filter')
        .forEach((key) => {
          if (!queue[0][key as keyof QueueResponse]) {
            initialFormValues[`${key}-enabled`] = false
            initialFormValues[key] = INITIAL_INPUTS[key].initial
          } else {
            initialFormValues[`${key}-enabled`] = true
            initialFormValues[key] = queue[0][key as keyof QueueResponse]
          }
        })

      setInitialFormValues(initialFormValues)
      setEntity(queue[0])
    }
  }, [queue, isLoading, id])

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      const data: Record<string, unknown> = {}

      if (selectedTimezone) {
        data.timezone = selectedTimezone
      }

      //targeters
      data.users = values['users'] //  === '-' ? [] : [values['users']];
      data.groups = values['groups'] // === '-' ? [] : [values['groups']];
      data.queue_name = values['queue_name']
      data.seq = values['seq']

      //orders
      Object.keys(INITIAL_INPUTS)
        .filter((key) => INITIAL_INPUTS[key].type === 'order')
        .forEach((key) => {
          if (values[`${key}-order`] === '-') return
          data[key] = {
            order:
              values[`${key}-order`] === '-'
                ? null
                : parseInt(values[`${key}-order`] as string),
            priority: parseInt(values[`${key}-priority`] as string) || 0
          }
        })

      //filters
      Object.keys(INITIAL_INPUTS)
        .filter((key) => INITIAL_INPUTS[key].type === 'filter')
        .forEach((key) => {
          if (values[`${key}-enabled`]) {
            data[key] = values[key]
          } else {
            data[key] = null
          }
        })

      try {
        if (id) {
          await updateQueue({ id, ...(data as QueueBody) }).unwrap()
          navigate(`/queues-view?id=${id}`)
          refetch()
        } else {
          await createQueue(data as QueueBody).unwrap()
          navigate('/queues')
        }
      } catch (error) {
        console.error(error)
      }
    }
  })

  const handleSelectChangeTimezone = (selectedValues: MultiValue<Timezone>) => {
    const selectedOptionValues = selectedValues.map((option) => option.value)

    if (selectedOptionValues.includes('selectAll')) {
      setSelectedTimezone(TIME_ZONE_OPTIONS.map((option) => option.value))
    } else {
      setSelectedTimezone(selectedOptionValues)
    }
  }
  const title = `${t('Queues')}: ${id ? t('Edit') : t('Create')}`
  document.title = title

  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color='primary'>{t('Loading...')}</Spinner>
  const metaBar = <MetaBar backLink={'/queues'} entity={entity} />

  if (isLoading) {
    return <LoadingPage backLink={'/queues'} title={title} />
  }

  if (queueError) {
    if ('data' in queueError) {
      const messageError = handleErrors(queueError)

      return <ErrorPage title={title} error={messageError} />
    } else {
      return <ErrorPage title={title} error={queueError} />
    }
  }

  if (queue?.length === 0 && id) {
    return <ErrorPage title={title} error={'No queue id found'} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className='needs-validation mt-4'
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()

                return false
              }}
            >
              <h4>Распределить на</h4>
              {listGroupsLoading ? (
                <div className='w-100 p-3' style={{ height: 70 }}>
                  <Spinner color='primary' size='sm'>
                    {t('Loading...')}
                  </Spinner>
                </div>
              ) : (
                <div className='mt-3 mb-3 d-flex flex-column gap-3'>
                  {Object.keys(INITIAL_INPUTS)
                    .filter((d) => INITIAL_INPUTS[d].type === 'common')
                    .map((fieldKey) => {
                      return (
                        <div
                          key={fieldKey}
                          style={INITIAL_INPUTS[fieldKey].style}
                        >
                          <InputItem
                            description={INITIAL_INPUTS[fieldKey]}
                            validation={validation}
                            validationKey={fieldKey}
                          />
                        </div>
                      )
                    })}
                </div>
              )}

              <h4>Сортировка</h4>
              <div className='mt-3 mb-3'>
                {Object.keys(INITIAL_INPUTS)
                  .filter((d) => INITIAL_INPUTS[d].type === 'order')
                  .map((fieldKey) => {
                    const keyOrder = `${fieldKey}-order`
                    const keyPriority = `${fieldKey}-priority`

                    return (
                      <Row key={fieldKey}>
                        <Col className='col-6' style={{ maxWidth: 300 }}>
                          <InputItem
                            description={INITIAL_INPUTS[fieldKey].order!}
                            validation={validation}
                            validationKey={keyOrder}
                          />
                        </Col>
                        <Col className='col-6' style={{ maxWidth: 100 }}>
                          <InputItem
                            description={INITIAL_INPUTS[fieldKey].priority!}
                            validation={validation}
                            validationKey={keyPriority}
                          />
                        </Col>
                      </Row>
                    )
                  })}
              </div>

              <h4>Фильтры</h4>
              <div className='mt-3 mb-3 d-flex flex-row gap-3'>
                {Object.keys(INITIAL_INPUTS)
                  .filter(
                    (d) =>
                      INITIAL_INPUTS[d].type === 'filter' &&
                      INITIAL_INPUTS[d].dateRange
                  )
                  .map((fieldKey) => {
                    return (
                      <InputItem
                        key={fieldKey}
                        description={INITIAL_INPUTS[fieldKey]}
                        validation={validation}
                        validationKey={fieldKey}
                        className='mb-4'
                      />
                    )
                  })}
              </div>
              <div className='mt-3 mb-3 w-100'>
                {Object.keys(INITIAL_INPUTS)
                  .filter(
                    (d) =>
                      INITIAL_INPUTS[d].type === 'filter' &&
                      INITIAL_INPUTS[d].numberRange
                  )
                  .map((fieldKey) => {
                    return (
                      <InputItem
                        key={fieldKey}
                        description={INITIAL_INPUTS[fieldKey]}
                        validation={validation}
                        validationKey={fieldKey}
                        className='mb-4'
                      />
                    )
                  })}
              </div>
              <div className='mt-3 mb-3 w-100'>
                {Object.keys(INITIAL_INPUTS)
                  .filter(
                    (d) =>
                      INITIAL_INPUTS[d].type === 'filter' &&
                      INITIAL_INPUTS[d].isList
                  )
                  .map((fieldKey) => {
                    return (
                      <InputItem
                        key={fieldKey}
                        description={INITIAL_INPUTS[fieldKey]}
                        validation={validation}
                        validationKey={fieldKey}
                        className='mb-4'
                      />
                    )
                  })}
              </div>
              <div className='mt-3 mb-3 w-100'>
                {t('timezone')}
                <Select
                  isMulti
                  defaultValue={
                    queue && queue[0]?.timezone
                      ? queue[0].timezone.map((option) => ({
                          value: option,
                          label: option
                        }))
                      : []
                  }
                  options={[ALL_OPTIONS, ...TIME_ZONE_OPTIONS]}
                  onChange={handleSelectChangeTimezone}
                />
              </div>

              {updateError && (
                <Alert color='warning'>
                  <strong>
                    {handleErrors(updateError) || updateError.toString()}
                  </strong>
                </Alert>
              )}
              {createError && (
                <Alert color='warning'>
                  <strong>
                    {handleErrors(createError) || createError.toString()}
                  </strong>
                </Alert>
              )}

              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className='text-nowrap'
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  type='submit'
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
