import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/dist/query/react'
import { SUGGESTIONS_URL } from '../client'

const token = '59022ffd74cc4d28b69b211970a79d5e50c74016'
const secret = '2757838c50388eb7c9e2a84cd631ee31f6a452e9'

const baseQuery = fetchBaseQuery({
  baseUrl: SUGGESTIONS_URL,
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Authorization', 'Token ' + token)
    headers.set('X-Secret', secret)
    return headers
  }
})

export const suggestionsApi = createApi({
  reducerPath: 'suggestionsApi',
  baseQuery: baseQuery,
  endpoints: () => ({})
})
