import { UseQueryHookArgs } from 'src/services/api'
import model from 'src/model'

export type FieldsFilterState = {
  gender?: string
  is_accepted?: string | boolean
  [key: string]: any
}

export const applyFilter = <T>(
  values: FieldsFilterState,
  setFieldsFilter: (filter: UseQueryHookArgs<T>) => void
) => {
  const updatedFilter = { ...values }

  if (updatedFilter.gender) {
    updatedFilter.gender = model.getGenderCode(updatedFilter.gender)
  }

  if (updatedFilter.is_accepted) {
    updatedFilter.is_accepted = model.getPaymentStatus(
      updatedFilter.is_accepted
    )
  }

  setFieldsFilter(updatedFilter as UseQueryHookArgs<T>)
}
