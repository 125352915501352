import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  LIST,
  DELETE,
  UPDATE,
  CREATE
} from "./actionType";

import {
  getExternalServicesSettings,
  getExternalServicesSettingsFail,
  getExternalServicesSettingsSuccess,
  deleteExternalServicesSettingsSuccess,
  deleteExternalServicesSettingsFail,
  updateExternalServicesSettingsSuccess,
  updateExternalServicesSettingsFail,
  createExternalServicesSettingsFail,
  createExternalServicesSettingsSuccess,
} from "./action";

import Client from "../../client";

function* deleteEntity({ payload }) {
  try {
    const response = yield call(Client.deleteEntity, Client.ENTITY_TYPE.EXTERNAL_SERVICES_SETTINGS, payload);
    yield put(deleteExternalServicesSettingsSuccess());
    yield put(getExternalServicesSettings());
  } catch (error) {
    yield put(deleteExternalServicesSettingsFail(error));
  }
}

function* updateEntity({ payload }) {
  try {
    const response = yield call(Client.updateEntity, Client.ENTITY_TYPE.EXTERNAL_SERVICES_SETTINGS, payload);
    yield put(updateExternalServicesSettingsSuccess());
    yield put(getExternalServicesSettings());
  } catch (error) {
    yield put(updateExternalServicesSettingsFail(error));
  }
}

function* createEntity({ payload }) {
  try {
    const response = yield call(Client.createEntity, Client.ENTITY_TYPE.EXTERNAL_SERVICES_SETTINGS, payload);
    yield put(createExternalServicesSettingsSuccess());
    yield put(getExternalServicesSettings());
  } catch (error) {
    yield put(createExternalServicesSettingsFail(error));
  }
}

function* getList({ id, filter }) {
  try {
    if (id) {
      const payment = yield call(Client.getEntity, Client.ENTITY_TYPE.EXTERNAL_SERVICES_SETTINGS, id);
      yield put(getExternalServicesSettingsSuccess([payment]));
    } else {
      const response = yield call(Client.getList, Client.ENTITY_TYPE.EXTERNAL_SERVICES_SETTINGS, filter);
      yield put(getExternalServicesSettingsSuccess(response));
    }
  } catch (error) {
    yield put(getExternalServicesSettingsFail(error));
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getList);
}

export function* watchOnDelete() {
  yield takeEvery(DELETE, deleteEntity);
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateEntity);
}

export function* watchOnCreate() {
  yield takeEvery(CREATE, createEntity);
}

function* externalServicesSettingsSaga() {
  yield all([
    fork(watchOnGetList),
    fork(watchOnDelete),
    fork(watchOnUpdate),
    fork(watchOnCreate),
  ]);
}

export default externalServicesSettingsSaga;
