import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  LIST,
  DELETE,
  UPDATE,
  CREATE
} from "./actionType";

import {
  getEntities,
  getEntitiesFail,
  getEntitiesSuccess,
  deleteEntitiesSuccess,
  deleteEntitiesFail,
  updateEntitiesSuccess,
  updateEntitiesFail,
  createEntitiesFail,
  createEntitiesSuccess,
} from "./action";

import Client from "../../client";

function* deleteEntity({ payload, entityType }) {
  try {
    const response = yield call(Client.deleteQueue, payload);
    yield put(deleteEntitiesSuccess(entityType));
    yield put(getEntities(entityType));
  } catch (error) {
    yield put(deleteEntitiesFail(error, entityType));
  }
}

function* updateEntity({ payload, entityType }) {
  try {
    const response = yield call(Client.updateEntity, entityType, payload);
    yield put(updateEntitiesSuccess(entityType));
    yield put(getEntities(entityType, payload.id));
  } catch (error) {
    yield put(updateEntitiesFail(error, entityType));
  }
}

function* createEntity({ payload, entityType }) {
  try {
    const response = yield call(Client.createEntity, entityType, payload);
    yield put(createEntitiesSuccess(entityType));
    //yield put(getEntities(entityType));
  } catch (error) {
    yield put(createEntitiesFail(error, entityType));
  }
}

function* getList({ id, filter, entityType, offset, limit }) {
  try {
    if (id) {
      const response = yield call(Client.getEntity, entityType, id);
      if (entityType === Client.ENTITY_TYPE.LOANS) {
        try {
          const card = yield call(Client.getLoanCard, id);
          response.loan_issue_card = card.data;
        } catch {
          response.loan_issue_card = null;
        }
      }
      yield put(getEntitiesSuccess([response], entityType, id));
    } else {
      const response = yield call(Client.getList, entityType, filter, offset, limit);
      yield put(getEntitiesSuccess(response, entityType));
    }
  } catch (error) {
    yield put(getEntitiesFail(error, entityType, id));
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getList);
}

export function* watchOnDelete() {
  yield takeEvery(DELETE, deleteEntity);
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateEntity);
}

export function* watchOnCreate() {
  yield takeEvery(CREATE, createEntity);
}

function* entitiesSaga() {
  yield all([
    fork(watchOnGetList),
    fork(watchOnDelete),
    fork(watchOnUpdate),
    fork(watchOnCreate),
  ]);
}

export default entitiesSaga;
