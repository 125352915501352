import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'

import model from 'src/model'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Alert,
  Spinner,
  FormFeedback,
  Input,
  FormGroup,
  Label,
  Button
} from 'reactstrap'
// Formik validation
import { useFormik } from 'formik'
import { Address, UnionClientEntity } from '../../../services/clients'
import useGetClient from './hooks/useGetClient'
import { schema } from './config/schema'
import useGetClientUpdate from './hooks/useGetClientUpdate'
import { formatAPIDate } from '../../../utils'
import { Select } from './components/select'

type InitialParams = {
  label?: string
  isTitle?: boolean
  initial?: string
  options?: { defaultValue: string; name: string }[]
  date?: boolean
  placeholder?: string
}
type InputInitialsKeys =
  | keyof UnionClientEntity
  | 'Contact_info'
  | 'Busy_info'
  | 'Addresses'
  | 'thirdparty_fio'
  | 'thirdparty_phone'
export type Addresses = Record<
  string,
  { data: Address | undefined; value: string | undefined }
>
type FormFields = Partial<UnionClientEntity>

const inputInitials: Partial<Record<InputInitialsKeys, InitialParams>> = {
  Contact_info: { label: 'Contact_info', isTitle: true },
  last_name: { label: 'last name', initial: '' },
  first_name: { label: 'first name', initial: '' },
  middle_name: { label: 'middle name', initial: '' },
  birth_date: { label: 'birth_date', date: true },
  birth_place: { label: 'birth_place' },
  email: { label: 'email', initial: '' },
  series: { label: 'passport code', initial: '' },
  number: { label: 'passport number', initial: '' },
  docdate: { label: 'passport date', initial: '', date: true },
  docorgcode: { label: 'passport orgcode', initial: '' },
  docorg: { label: 'passport issuer', initial: '' },
  snils: { label: 'snils', initial: '' },
  inn: { label: 'inn', initial: '' },
  thirdparty_fio: { label: 'thirdparty_fio', initial: '' },
  thirdparty_phone: { label: 'thirdparty_phone', initial: '' },

  Busy_info: { label: 'Busy_info', isTitle: true },
  typework: { label: 'typework', initial: '' },
  work_address: { label: 'work_address', initial: '' },
  occupation: { label: 'occupation', initial: '' },
  salary: { label: 'salary', initial: '' },
  extsalary: { label: 'extsalary', initial: '' },
  work_phone: { label: 'work_phone', initial: '' },
  Addresses: { label: 'Addresses', isTitle: true }
}

export const ClientEdit = () => {
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')
  const { t } = useTranslation()
  const [initials, setInitials] = useState<
    Record<InputInitialsKeys, InitialParams> | Record<string, unknown>
  >({})
  const [initialFormValues, setInitialFormValues] = useState<
    FormFields | Record<string, unknown>
  >({})
  const [valueError, setValueError] = useState({
    fact_address: false,
    reg_address: false,
    work_address: false
  })
  const [addresses, setAddresses] = useState<Addresses>({})

  const { client, someLoading, someError } = useGetClient(id || '')

  const { handleSubmit, updateError, updateLoading, isUpdateSuccess } =
    useGetClientUpdate()

  useEffect(() => {
    if (!client) return

    const inputKeysArray = Object.keys(inputInitials) as (keyof FormFields)[]

    const resFormValues = inputKeysArray.reduce<Record<string, unknown>>(
      (acc, currentValue) => {
        if (currentValue === 'gender') {
          acc[currentValue] = model.getStrGender(client[currentValue])
          return acc
        }
        acc[currentValue] = client[currentValue]
        return acc
      },
      {}
    )

    const resInitials = inputKeysArray.reduce<
      Record<string, Record<string, unknown>>
    >((acc, currentValue) => {
      acc[currentValue] = {
        ...inputInitials[currentValue],
        initial: client[currentValue]
      }
      return acc
    }, {})

    setInitials(resInitials)
    setInitialFormValues(resFormValues)

    setAddresses({
      fact_address: {
        data: client.addresses?.fact_address,
        value: client.addresses_label?.fact_address
      },
      reg_address: {
        data: client.addresses?.reg_address,
        value: client.addresses_label?.reg_address
      },
      work_address: {
        data: client.addresses?.work_address,
        value: client.addresses_label?.work_address
      }
    })
  }, [client])

  // Form validation
  const validation = useFormik<FormFields>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit({ values, valueError, id, addresses })
    }
  })

  document.title = t('Edit client')
  const bread = <BreadCrumb title={`${t('Edit client')}`} />
  const loading = <Spinner color='primary'>{t('Loading...')}</Spinner>

  if (someError) {
    return (
      <React.Fragment>
        <UiContent />
        <div className='page-content'>
          <Container fluid={true}>
            {bread}
            <Alert color='warning'>
              <strong> {!!someError && someError.toString()} </strong>
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  if (someLoading) {
    return (
      <React.Fragment>
        <UiContent />
        <div className='page-content'>
          <Container fluid={true}>
            {bread}
            {loading}
          </Container>
        </div>
      </React.Fragment>
    )
  }

  const metaBar = <MetaBar backLink={`/client-view?id=${id}`} entity={client} />

  const entriesInitials = Object.entries(initials) as [
    keyof FormFields,
    InitialParams
  ][]

  const onSelectError = (key: keyof typeof valueError) => (cond: boolean) => {
    if (cond !== valueError[key]) {
      setValueError((prev) => ({ ...prev, [key]: cond }))
    }
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Container fluid={true} className='mt-4'>
              <Row>
                <Col lg={12}>
                  <Form
                    id='formElem'
                    className='needs-validation'
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      {entriesInitials.map(([fieldKey, value]) => {
                        const htmlForId = `validation-${fieldKey}`
                        if (value?.isTitle) {
                          return (
                            <div
                              key={fieldKey}
                              className={'bg-soft-primary'}
                              style={{
                                height: '30px',
                                lineHeight: '30px',
                                marginBottom: '5px'
                              }}
                            >
                              <strong>{t(value?.label || '')}</strong>
                            </div>
                          )
                        }
                        return (
                          <Col md='4' key={`${fieldKey}`}>
                            <FormGroup className='mb-3'>
                              <Label htmlFor={htmlForId}>
                                {t(value?.label || '')}
                              </Label>
                              {value?.options && value?.options.length ? (
                                <select
                                  name={`${fieldKey}`}
                                  id={htmlForId}
                                  className='form-select form-select-sm'
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[
                                    fieldKey
                                  ]?.toString()}
                                >
                                  {value?.options.map((option) => (
                                    <option
                                      key={option.name}
                                      defaultValue={option.defaultValue}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              ) : value.date ? (
                                <Flatpickr
                                  name={`${fieldKey}`}
                                  id={htmlForId}
                                  value={validation.values[fieldKey] || ''}
                                  className='form-control form-control-sm'
                                  options={{
                                    altInput: true,
                                    allowInput: true,
                                    dateFormat: 'Y-m-d',
                                    altFormat: 'd.m.Y',
                                    onChange: (selectedDates: Date[]) => {
                                      validation.setFieldValue(
                                        fieldKey,
                                        formatAPIDate(selectedDates[0])
                                      )
                                    }
                                  }}
                                />
                              ) : (
                                <Input
                                  name={`${fieldKey}`}
                                  placeholder={value?.placeholder}
                                  type='text'
                                  className='form-control form-control-sm'
                                  id={htmlForId}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[
                                    fieldKey
                                  ]?.toString()}
                                  invalid={
                                    !!(
                                      validation.touched[fieldKey] &&
                                      validation.errors[fieldKey]
                                    )
                                  }
                                />
                              )}
                              {validation.touched[fieldKey] &&
                              validation.errors[fieldKey] ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors[fieldKey]}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        )
                      })}
                      <Col md='4'>
                        <FormGroup className='mb-3'>
                          <Select
                            onError={onSelectError('fact_address')}
                            value={addresses?.fact_address?.value}
                            label={t('fact_address')}
                            onChange={(item) =>
                              setAddresses({ ...addresses, fact_address: item })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md='4'>
                        <FormGroup className='mb-3'>
                          <Select
                            onError={onSelectError('reg_address')}
                            value={addresses.reg_address?.value}
                            label={t('reg_address')}
                            onChange={(item) =>
                              setAddresses({ ...addresses, reg_address: item })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md='4'>
                        <FormGroup className='mb-3'>
                          <Select
                            onError={onSelectError('work_address')}
                            value={addresses.work_address?.value}
                            label={t('workAddress')}
                            onChange={(item) =>
                              setAddresses({ ...addresses, work_address: item })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {!!updateError && (
                      <Alert color='warning'>
                        <strong>
                          {' '}
                          {'data' in updateError
                            ? (updateError?.data as { detail?: string })?.detail
                            : 'Something went wrong'}{' '}
                        </strong>
                      </Alert>
                    )}

                    {!updateError && isUpdateSuccess && (
                      <Alert color='success'>
                        <strong> {t('Operation success')} </strong>
                      </Alert>
                    )}

                    {updateLoading ? (
                      loading
                    ) : (
                      <Button color='success' type='submit'>
                        {t('Update')}
                      </Button>
                    )}
                  </Form>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
