import React, {useEffect} from "react";
import Table4Columns from "./Table4Columns";

const columnsDescriptionDefault = [
  //['id'],
  ['created_at'],
  ['direction'],
  ['amount'],
  ['payment_gate'],
  ['is_accepted'],
  ['accept_date'],
  ['loan_id'],
  ['profile_id']
];

const fieldsDescription = {
  id: {isShortId: false, isLink: true, linkFieldName: 'id_link'},
  loan_id: {isShortId: false, isLink: true, linkFieldName: 'loan_id_link'},
  profile_id: {isShortId: false, isLink: true, linkFieldName: 'profile_id_link'},
  created_at: {isDateTime: true},
  direction: {},
  amount: {isAmount: true, rightAligned: true},
  payment_gate: {fieldName: 'comment'},
  /*is_accepted: {
    mappingValue: (value) => value ? 'Confirmed' : 'Waiting',
    mappingClassName: (value) => value ? 'text-success' : 'text-warning'
  },*/
  accept_date: {isDateTime: true},
};

const EntityContent = ({entity, style, columnsDescription}) => {
  useEffect(()=>{
    console.log('fund =>', entity);
  }, [entity?.id]);

  return (
    <Table4Columns
      columnsDescription={columnsDescription || columnsDescriptionDefault}
      fieldsDescription={fieldsDescription}
      className="table-sm align-middle mb-0"
      style={{width: 'max-content'}}
      entity={{
        ...entity,
        loan_id_link: `/loan-view?id=${entity.loan_id}`,
        profile_id_link: `/profile-view?id=${entity.profile_id}`,
      }}
    />
  );
}

export default EntityContent;
