import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { updateDocs } from '../../../store/actions'

import { formatDateTime } from '../../../utils'

import { Modal, Button, Input, Label, Spinner, Alert } from 'reactstrap'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'

import model from '../../../model'

const SMS = false

const ModalDocument = ({ isOpen, onClose, img, onVerified }) => {
  const { t } = useTranslation()
  const [meta, setMeta] = useState({})

  useEffect(() => {
    fetch(model.getDocumentImgUrl(img))
      .then((r) => r.blob())
      .then((blob) => {
        const file = new File(
          [blob],
          '1' /*{
        type: "image/png",
      }*/,
        )

        console.log(file)
        setMeta({
          size: file.size,
          modified: formatDateTime(file.lastModified),
        })
      }) //creating blob object
  }, [img])

  if (!img) return null

  const checkbox = (
    <div className="form-check form-check-secondary mb-3">
      <Input
        className="form-check-input"
        type="checkbox"
        checked={!!img.isNotCorrect}
        onChange={onVerified}
        id="formCheckModal"
      />
      <Label className="form-check-label" for="formCheckModal">
        {t('not_match')}
      </Label>
    </div>
  )

  return (
    <Modal isOpen={isOpen} centered>
      <div className="p-2 w-100 d-flex justify-content-end">
        <Button
          type="button"
          className="btn-close"
          onClick={() => {
            onClose()
            //setmodal_backdrop(false);
          }}
          aria-label="Close"
        ></Button>
      </div>
      <div className="modal-body vstack align-items-center justify-content-center p-2">
        <img
          src={model.getDocumentImgUrl(img)}
          alt=""
          className="rounded"
          width="400"
          style={{ maxWidth: 400 }}
        />
        <div className="fs-6 mt-1">
          {Object.keys(meta).map((metaField) => {
            return (
              <div key={metaField}>{`${t(metaField)}: ${meta[metaField]}`}</div>
            )
          })}
        </div>
        {<div className="mt-3 justify-content-center">{checkbox}</div>}
      </div>
    </Modal>
  )
}

const EntityContent = ({ entity, entityId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [modal_center, setmodal_center] = useState(false)
  const [imgActive, setImageActive] = useState(null)
  const [documents, setDocuments] = useState(entity)
  const [isPhotoshop, setIsPhotoshop] = useState(false)
  const [isSimilar, setIsSimilar] = useState(false)
  const [sendSms, setSendSms] = useState(false)
  const [fraud, setFraud] = useState('')
  const [comment, setComment] = useState('')

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [updating, setUpdating] = useState(false)

  const { updateDocsError, updateDocsLoading } = useSelector((state) => ({
    updateDocsError: state.applications.updateDocsError,
    updateDocsLoading: state.applications.updateDocsLoading,
  }))

  useEffect(() => {
    if (!updating) return

    if (updateDocsLoading) {
      setSuccess(false)
      setError(false)
      return
    }

    setUpdating(false)
    if (!updateDocsError) setSuccess(true)
    if (updateDocsError) setError(updateDocsError)
  }, [updateDocsLoading, updateDocsError])

  function tog_center(img) {
    setImageActive(img)
    setmodal_center(!modal_center)
  }

  const verify = () => {
    const data = {
      application_id: entityId,
      is_photoshop: !!isPhotoshop,
      same_fase_and_other_name: !!isSimilar,
      fraud: fraud,
      comment: comment,
      //send_sms: !!sendSms,
      photo: documents.map((doc) => ({
        file_id: doc.id,
        incorrect: !!doc.isNotCorrect,
        file_type_id: doc.fileTypeId || doc.file_type.id,
      })),
    }
    console.log('data => ', data)
    setUpdating(true)
    dispatch(updateDocs(data))
  }

  const getStrId = (file_type_id) => {
    return file_type_id === 1
      ? 'Selfie'
      : file_type_id === 2
      ? 'Passport'
      : 'Unknown'
  }

  const getTypeId = (file_type_str) => {
    return file_type_str === 'Selfie' ? 1 : file_type_str === 'Passport' ? 2 : 0
  }

  return (
    <div style={{}}>
      <div className="hstack gap-5 flex-wrap mt-3">
        {documents &&
          documents.map((doc) => {
            const imgSource = model.getDocumentImgUrl(doc) // doc.fileData;
            //console.log('doc', doc);
            return (
              <div key={doc.id}>
                <h6>{doc.file_type.name_eng}</h6>
                <select
                  id={`${doc.id}-type`}
                  className="form-select form-select-sm"
                  onChange={(event) => {
                    setDocuments(
                      documents.map((d) => {
                        if (doc.id !== d.id) return d
                        d.fileTypeId = getTypeId(event.target.value)
                        return d
                      }),
                    )
                  }}
                  value={getStrId(doc.fileTypeId || doc.file_type.id)}
                >
                  {['Unknown', 'Selfie', 'Passport'].map((option, rank) => (
                    <option key={`${rank + 1}`}>{option}</option>
                  ))}
                </select>
                <img
                  src={imgSource}
                  alt=""
                  className="img-thumbnail rounded avatar-xl mt-2"
                  onClick={() => tog_center(doc)}
                />
                <div className="form-check form-check-secondary mb-3 mt-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={!!doc.isNotCorrect}
                    id={doc.id}
                    onChange={(event) => {
                      console.log('onchange', event.target.checked)
                      setDocuments(
                        documents.map((d) => {
                          if (doc.id !== d.id) return d
                          d.isNotCorrect = event.target.checked
                          return d
                        }),
                      )
                    }}
                  />
                  <Label className="form-check-label" for={doc.id}>
                    {t('not_match')}
                  </Label>
                </div>
              </div>
            )
          })}
      </div>
      <h6 className="mt-3">{t('Verification')}</h6>
      <div className="form-check form-check-secondary mb-3 mt-3">
        <Input
          className="form-check-input"
          type="checkbox"
          checked={isPhotoshop}
          id="check-photoshop"
          onChange={(event) => setIsPhotoshop(event.target.checked)}
        />
        <Label className="form-check-label" for={'check-photoshop'}>
          {t('photoshop')}
        </Label>
      </div>
      <div className="form-check form-check-secondary mb-3 mt-3">
        <Input
          className="form-check-input"
          type="checkbox"
          checked={isSimilar}
          id="check-similar"
          onChange={(event) => setIsSimilar(event.target.checked)}
        />
        <Label className="form-check-label" for={'check-similar'}>
          {t('similar_other')}
        </Label>
      </div>
      {SMS && (
        <div className="form-check form-check-secondary mb-3 mt-3">
          <Input
            className="form-check-input"
            type="checkbox"
            checked={sendSms}
            id="check-sms"
            onChange={(event) => setSendSms(event.target.checked)}
          />
          <Label className="form-check-label" for={'check-sms'}>
            {t('send_sms')}
          </Label>
        </div>
      )}
      <div className="mb-3 mt-3">
        <Label for="input-fraud">{t('signs_of_fraud')}</Label>
        <Input
          name="input-fraud"
          placeholder={''}
          type="text"
          className="form-control form-control-sm"
          id="input-fraud"
          onChange={(event) => setFraud(event.target.value)}
          value={fraud}
        />
      </div>
      <div className="mb-3 mt-3">
        <Label for={'input-comment'}>{t('comment')}</Label>
        <Input
          name="input-comment"
          placeholder={''}
          type="text"
          className="form-control form-control-sm"
          id="input-comment"
          onChange={(event) => setComment(event.target.value)}
          value={comment}
        />
      </div>
      {!!success && (
        <Alert color="success">
          <strong> {t('Updated')} </strong>
        </Alert>
      )}

      {!!error && (
        <Alert color="warning">
          <strong> {error.toString()} </strong>
        </Alert>
      )}

      {updateDocsLoading ? (
        <Spinner color="primary">{t('Loading...')}</Spinner>
      ) : (
        <Button style={{ backgroundColor: '#405189' }} onClick={verify}>
          {t('Update')}
        </Button>
      )}

      <ModalDocument
        isOpen={modal_center}
        centered
        entity={entity}
        onClose={() => setmodal_center(false)}
        img={imgActive}
        onVerified={(event) => {
          console.log('onchange', event.target.checked)
          setDocuments(
            documents.map((doc) => {
              if (doc.id !== imgActive.id) return doc
              doc.isNotCorrect = event.target.checked
              return doc
            }),
          )
        }}
      />
    </div>
  )
}

export default EntityContent
