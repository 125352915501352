import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { Table } from 'reactstrap'

import model from '../../../model'

const NEW_LINE_HEIGHT = 30

const Table4Columns = ({
  entity,
  className,
  style,
  columnsDescription,
  fieldsDescription
}) => {
  const { t } = useTranslation()

  const getLink = (link, linkText) => (
    <Link to={link} className='fw-medium'>
      {linkText}
    </Link>
  )

  const keyToText = (fieldKey) => {
    const descr = fieldsDescription[fieldKey] || {}
    if (descr.isEmpty) return ''

    const unit = descr.unit ? `, ${t(descr.unit)}` : ''
    if (descr.label) return t(descr.label) + unit
    if (descr.fieldName) return t(descr.fieldName) + unit
    return t(fieldKey) + unit
  }

  const valueToText = (fieldKey) => {
    const value = model.fieldValueToText(fieldKey, entity, fieldsDescription)

    if (
      fieldsDescription[fieldKey] &&
      fieldsDescription[fieldKey].isLink &&
      fieldsDescription[fieldKey].linkFieldName
    ) {
      const link = entity[fieldsDescription[fieldKey].linkFieldName]
      return getLink(link, value)
    }

    if (fieldsDescription[fieldKey]) return value

    return t(value)
  }

  return (
    <div className='table-responsive'>
      <Table
        className={className || 'table-sm align-middle mb-0'}
        style={style}
      >
        <tbody>
          {Object.values(columnsDescription).map(
            ([columnKey1, columnKey2], i) => {
              const newLine =
                fieldsDescription[columnKey1] &&
                fieldsDescription[columnKey1].newLine
              const empty =
                columnKey1 === undefined || columnKey1 === null || newLine
              const empty2 =
                columnKey2 === undefined || columnKey2 === null || newLine
              const title1 =
                fieldsDescription[columnKey1] &&
                fieldsDescription[columnKey1].isTitle
                  ? fieldsDescription[columnKey1]
                  : null
              const title2 =
                fieldsDescription[columnKey2] &&
                fieldsDescription[columnKey2].isTitle
                  ? fieldsDescription[columnKey2]
                  : null
              const className1 =
                fieldsDescription[columnKey1] &&
                fieldsDescription[columnKey1].mappingClassName
                  ? fieldsDescription[columnKey1].mappingClassName(
                      entity[columnKey1]
                    )
                  : ''
              const className2 =
                fieldsDescription[columnKey2] &&
                fieldsDescription[columnKey2].mappingClassName
                  ? fieldsDescription[columnKey2].mappingClassName(
                      entity[columnKey2]
                    )
                  : ''

              if (title1 || title2)
                return (
                  <tr
                    key={`${columnKey1}-${i}`}
                    className={
                      title1?.titleLevel !== 2 && title2?.titleLevel !== 2
                        ? 'bg-soft-primary'
                        : ''
                    }
                  >
                    <td colSpan='2'>
                      <strong>{title1 ? t(title1.text) : ''}</strong>
                    </td>
                    <td colSpan='2' className='ps-4'>
                      <strong>{title2 ? t(title2.text) : ''}</strong>
                    </td>
                  </tr>
                )

              return (
                <tr
                  key={`${columnKey1}-${i}`}
                  style={newLine ? { height: NEW_LINE_HEIGHT } : {}}
                >
                  <td style={{ whiteSpace: 'nowrap', width: '1%' }}>
                    {empty ? '' : keyToText(columnKey1)}
                  </td>
                  <td className={`ps-4 ${className1}`}>
                    {empty ? '' : valueToText(columnKey1)}
                  </td>
                  <td
                    className='ps-4'
                    style={{ whiteSpace: 'nowrap', width: '1%' }}
                  >
                    {empty2 ? '' : keyToText(columnKey2)}
                  </td>
                  <td className={`ps-4 ${className2}`}>
                    {empty2 ? '' : valueToText(columnKey2)}
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default Table4Columns
