import { api } from './api'

type Tag = {
  created_at: string
  tag_type_id: number
  tag: string
  client_id: string
  loan_id: string
  state: boolean
}

type ClientTag = {
  id: number
  tag: string
}

const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLoanTags: builder.query<Tag[], string>({
      query: (id) => `crm/v1/tags/loans/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'LoanTags', id }],
    }),
    getClientTags: builder.query<Tag[], string>({
      query: (id) => `crm/v1/tags/clients/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'ClientTags', id }],
    }),
    getClientsTags: builder.query<ClientTag[], void>({
      query: () => '/crm/v1/tags/clients',
    }),
    setClientsTagsByID: builder.mutation<void, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/clients/${id}/add`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => ['ClientTags'],
    }),
    removeClientsTagsByID: builder.mutation<void, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/clients/${id}/delete`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => ['ClientTags'],
    }),
  }),
})

export const {
  useGetLoanTagsQuery,
  useGetClientTagsQuery,
  useGetClientsTagsQuery,
  useSetClientsTagsByIDMutation,
  useRemoveClientsTagsByIDMutation,
} = tagsApi
