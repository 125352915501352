import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import EntityInfo from 'src/Components/Common/EntityInfo'
import MetaBar from 'src/Components/Common/MetaBar'

import Client from 'src/client'

import { getDialogues } from 'src/store/actions'

import { Card, CardBody, Button, Container, Alert } from 'reactstrap'

export const DialogueView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [id, setId] = useState(null)
  const [paramError, setParamError] = useState(null)
  const { entityError } = useSelector((state) => ({
    entityLoading: state.dialogues.entityLoading,
    entityError: state.dialogues.entityError,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    if (id) {
      dispatch(getDialogues(id))
    } else setParamError('No dialogue id found')
  }, [window.location])

  const title = t('View dialogue')
  document.title = title
  const bread = <BreadCrumb title={title} />
  const clientLink = id ? (
    <Link to={`/client-view?id=${id}`}>
      <Button className="btn-icon" style={{ backgroundColor: '#405189' }}>
        <i className="ri-user-3-line fs-16"></i>
      </Button>
    </Link>
  ) : null
  const metaBar = (
    <MetaBar backLink={'/dialogues'} entity={{ id }} buttons={[clientLink]} />
  )

  if (paramError || entityError) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          {bread}
          {metaBar}
          <Container fluid={true}>
            <Alert color="warning">
              <strong> {!!paramError && paramError.toString()} </strong>
              <strong> {!!entityError && entityError.toString()} </strong>
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <EntityInfo
              entityId={id}
              entityType={Client.ENTITY_TYPE.DIALOGUES}
            />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
