import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  LIST,
  DELETE,
  UPDATE,
  CREATE
} from "./actionType";

import {
  getTransactions,
  getTransactionsFail,
  getTransactionsSuccess,
  deleteTransactionSuccess,
  deleteTransactionFail,
  updateTransactionSuccess,
  updateTransactionFail,
  createTransactionFail,
  createTransactionSuccess,
} from "./action";

import Client from "../../client";

function* deleteEntity({ payload }) {
  try {
    const response = yield call(Client.deleteEntity, Client.ENTITY_TYPE.PAYMENTS, payload);
    yield put(deleteTransactionSuccess());
    yield put(getTransactions());
  } catch (error) {
    yield put(deleteTransactionFail(error));
  }
}

function* updateEntity({ payload }) {
  try {
    const response = yield call(Client.updateEntity, Client.ENTITY_TYPE.PAYMENTS, payload);
    yield put(updateTransactionSuccess());
    yield put(getTransactions());
  } catch (error) {
    yield put(updateTransactionFail(error));
  }
}

function* createEntity({ payload }) {
  try {
    const response = yield call(Client.createEntity, Client.ENTITY_TYPE.HAND_PAYMENTS, payload);
    yield put(createTransactionSuccess());
    yield put(getTransactions());
  } catch (error) {
    yield put(createTransactionFail(error));
  }
}

function* getList({ id, filter }) {
  try {
    if (id) {
      const payment = yield call(Client.getEntity, Client.ENTITY_TYPE.PAYMENTS, id);
      yield put(getTransactionsSuccess([payment]));
    } else {
      const response = yield call(Client.getList, Client.ENTITY_TYPE.PAYMENTS, filter);
      yield put(getTransactionsSuccess(response));
    }
  } catch (error) {
    yield put(getTransactionsFail(error));
  }
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getList);
}

export function* watchOnDelete() {
  yield takeEvery(DELETE, deleteEntity);
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateEntity);
}

export function* watchOnCreate() {
  yield takeEvery(CREATE, createEntity);
}

function* transactionsloansSaga() {
  yield all([
    fork(watchOnGetList),
    fork(watchOnDelete),
    fork(watchOnUpdate),
    fork(watchOnCreate),
  ]);
}

export default transactionsloansSaga;
