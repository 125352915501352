import { useCallback, useEffect, useState } from 'react'

import List from './List'
import Client from 'src/client'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const fieldsDescription = {
  id: {},
  begin_date: { label: 'holiday_begin_date', isDate: true },
  end_date: { label: 'holiday_end_date', isDate: true },
  full_name: {},
  short_name: {},
  registry_email: {},
  communication_phone: {}
  //created_at: {isDateTime: true},
}

const EntityContent = (props) => {
  const { entityId, style } = props
  const [list, setList] = useState([])
  const [error, setError] = useState('')

  const fetchData = useCallback(() => {
    Client.getLoanAgencies(entityId)
      .then((res) => {
        console.log('getLoanAgencies', res)
        setList(res.map((agency) => ({ ...agency, ...agency.agency })))
      })
      .catch((error) => {
        setError(error)
        setList([])
      })
  }, [entityId])

  useEffect(() => {
    if (props.visible) {
      fetchData()
    }
  }, [fetchData, props.visible])

  return (
    <div>
      <List list={list} fieldsDescription={fieldsDescription} style={style} />
      {error && <DangerAlert error={error} />}
    </div>
  )
}

export default EntityContent
