import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'src/utils'

import { Button } from 'reactstrap'
import cx from 'classnames'

export const BackButton = ({ link }) => {
  const { t } = useTranslation()

  return (
    <Link to={link}>
      <Button
        className='btn-label text-nowrap'
        color='primary'
        style={{ backgroundColor: '#405189' }}
      >
        <i className='ri-reply-line label-icon align-middle fs-16 me-2'></i>
        {t('To List')}
      </Button>
    </Link>
  )
}

export const CreateButton = ({ link }) => {
  const { t } = useTranslation()

  return (
    <Link to={link}>
      <Button
        className='btn-label'
        color='primary'
        style={{ backgroundColor: '#405189' }}
      >
        <i className='ri-add-line label-icon align-middle fs-16 me-2'></i>
        {t('Create')}
      </Button>
    </Link>
  )
}

export const EditButton = ({ link }) => {
  return (
    <Link to={link}>
      <Button
        className='btn-icon'
        color='primary'
        style={{ backgroundColor: '#405189' }}
      >
        <i className='ri-edit-2-line fs-16'></i>
      </Button>
    </Link>
  )
}

/**
 * @param {{backLink?: string | null, createLink?:string | null, editLink?:string | null, entity?: any, buttons?: any, disableBorder?:boolean, children?: ReactNode, className?: string, showToastContainer?: boolean}}
 */
const MetaBar = ({
  backLink,
  createLink,
  editLink,
  entity,
  buttons,
  disableBorder,
  children,
  className,
  showToastContainer = true
}) => {
  const { t } = useTranslation()

  const copyButton =
    entity && entity.id ? (
      <i
        className='ri-file-copy-line ps-1 link-success'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigator?.clipboard?.writeText(entity.id)
          toast('Copied', { position: 'top-center', hideProgressBar: true })
        }}
      />
    ) : null

  return (
    <div
      className={cx(
        'd-flex flex-row align-items-center p-0 pb-3',
        {
          'border-bottom': !disableBorder
        },
        className
      )}
    >
      {showToastContainer && <ToastContainer autoClose={500} limit={1} />}
      {!!backLink && (
        <div className='me-2'>
          <BackButton link={backLink} />
        </div>
      )}
      {!!createLink && (
        <div className='me-2'>
          <CreateButton link={createLink} />
        </div>
      )}
      {!!editLink && (
        <div className='me-2'>
          <EditButton link={editLink} />
        </div>
      )}
      {buttons && buttons.length
        ? buttons.map((b, rank) => (
            <div key={rank} className='me-2'>
              {b}
            </div>
          ))
        : null}
      {!!entity && !!entity.id && (
        <div className='ms-0 fs-16 me-2'>
          {`${t('id')}: ${entity.id}`}
          {copyButton}
        </div>
      )}
      {!!entity && !!entity.created_at && (
        <small className='text-muted ms-auto'>{`${t(
          'created_at'
        )}: ${formatDateTime(entity.created_at)}`}</small>
      )}
      {!!entity &&
        !!entity.updated_at &&
        entity.updated_at !== entity.created_at && (
          <small className='text-muted'>{`${t('updated')}:  ${formatDateTime(
            entity.updated_at
          )}`}</small>
        )}
      {!!entity && !!entity.canceled_at && (
        <small className='text-muted'>{`${t('canceled')}: ${formatDateTime(
          entity.canceled_at
        )}`}</small>
      )}
      {children}
    </div>
  )
}

export default MetaBar
