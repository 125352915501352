import { api, omitBlankEntries } from './api'

type PersonExtremist = {
  last_name: string
  first_name: string
  middle_name: string
  [inn: string]: string
  snils: string
  birthday_date: string
  birth_place: string
  number: string
  series: string
  issue_date: string
  issuer: string
}

type ValidationRosFin = {
  id: number
  created_at: string
  dict_type: string
  dict_creation_date: string
  path_to_file: string
}

export const rosFinMonitoringApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPersonExtremists: builder.query<
      PersonExtremist,
      Partial<{
        inn: string
        docSeries: string
        docNumber: string
        secondname: string
        firstname: string
        middlename: string
        birthDate: string
      }>
    >({
      query: (params) => ({
        url: '/handbook-extremists/v1/person',
        params: omitBlankEntries(params),
      }),
      providesTags: () => ['PersonExtremists'],
    }),
    uploadFileExtremists: builder.mutation({
      query: (body) => ({
        url: `/crm/v1/validations/upload-dict-extremists`,
        method: 'POST',
        body,
        formData: true,
      }),
    }),
    uploadFileCommission: builder.mutation({
      query: (body) => ({
        url: `/crm/v1/validations/upload-dict-interdepartmental-commission`,
        method: 'POST',
        body,
        formData: true,
      }),
    }),
    uploadFileBank: builder.mutation({
      query: (body) => ({
        url: `/handbook-extremists/v1/upload-dict-central-bank`,
        method: 'POST',
        body,
        formData: true,
      }),
    }),
    uploadFileOon: builder.mutation({
      query: (body) => ({
        url: `/crm/v1/validations/upload-dict-oon`,
        method: 'POST',
        body,
        formData: true,
      }),
    }),
    massValidation: builder.mutation<
      void,
      { start_date: string; end_date: string }
    >({
      query: ({ start_date, end_date }) => ({
        url: `/crm/v1/validations/mass-validation?start_date=${start_date}&end_date=${end_date}`,
        method: 'POST',
      }),
    }),
    getValidation: builder.query<
      ValidationRosFin[],
      Partial<{
        limit: number
        offset: number
        id: number
        created_at: string
        dict_type: string
        dict_creation_date: string
        path_to_file: string
      }>
    >({
      query: (params) => ({
        url: '/crm/v1/validations/validations',
        params: omitBlankEntries(params),
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'ValidationRosFin', id }) as const),
        { type: 'ValidationRosFin' as const, id: 'LIST' },
      ],
    }),
    validationDownload: builder.mutation<Blob, { path_to_dict: string }>({
      query: ({ path_to_dict }) => ({
        url: `/crm/v1/validations/download-dict-pdf?path_to_dict=${path_to_dict}`,
        method: 'POST',
        formData: true,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useGetPersonExtremistsQuery,
  useUploadFileExtremistsMutation,
  useUploadFileCommissionMutation,
  useUploadFileBankMutation,
  useUploadFileOonMutation,
  useMassValidationMutation,
  useValidationDownloadMutation,
  useGetValidationQuery,
} = rosFinMonitoringApi
