import React from 'react'
import { useTranslation } from 'react-i18next'

import { useGetApplicationVerificationQuery } from 'src/services/applications'

const EntityContent = ({ style, applicationId }) => {
  const { t } = useTranslation()

  const { data: entity } = useGetApplicationVerificationQuery(applicationId, {
    skip: applicationId == null,
  })

  return (
    <div style={style}>
      {entity?.process.pass.length === 0 &&
        entity?.process.result.length === 0 &&
        entity?.process.requirement.length === 0 && (
          <dl className="row mb-0">
            <small>{t('No_decisions')}</small>
          </dl>
        )}

      <dl className="row mb-0">
        {entity?.process?.requirement.map((req, rank) => (
          <React.Fragment key={`${req}-${rank}`}>
            <dd className="col-12 mb-0">{`${req}`}</dd>
            <dd
              className={`${
                entity?.process?.pass[rank] ? 'text-success' : 'text-danger'
              } col-12`}
            >
              {`${entity?.process?.result[rank]}`}
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  )
}

export default EntityContent
