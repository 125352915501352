import React from 'react'
import { Spinner, Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import UiContent from 'src/Components/Common/UiContent'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'

/**
 * @param {{title?: string, backLink?: string }}
 */
const LoadingPage = ({ backLink, title }) => {
  const { t } = useTranslation()

  const bread = <BreadCrumb title={t(title)} />
  const metaBar = backLink ? <MetaBar backLink={backLink} /> : null
  const loading = (
    <div className="d-flex justify-content-center mt-5 mb-5">
      <Spinner color="primary">{t('Loading...')}</Spinner>
    </div>
  )

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            {loading}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default LoadingPage
