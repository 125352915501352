import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Alert,
  Label,
  Input,
  FormFeedback,
  Form
} from 'reactstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import {
  useCreatePromocodesGroupMutation,
  useEditPromocodesGroupMutation,
  useGetPromocodesGroupsQuery
} from 'src/services/promocodesGroups'
import { PromocodesGroupsEditFormValues } from './types/promocodes-groups.types'

export const PromocodesGroupsEdit = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')

  const [
    editPromocodesGroup,
    { isSuccess: isSuccessEditGroup, error: isErrorEditGroup }
  ] = useEditPromocodesGroupMutation()

  const [
    createPromocodesGroup,
    { isSuccess: isSuccessCreateGroup, error: isErrorCreateGroup }
  ] = useCreatePromocodesGroupMutation()

  const { data } = useGetPromocodesGroupsQuery()

  const group = data?.find((item) => item.id === Number(id))

  const validation = useFormik<PromocodesGroupsEditFormValues>({
    enableReinitialize: true,
    initialValues: {
      name: group?.group_name
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('Field is required'))
    }),
    onSubmit: async (values) => {
      const payload = { group_name: values.name }
      try {
        if (id) {
          await editPromocodesGroup({ ...payload, id: group?.id })
        } else {
          await createPromocodesGroup(payload)
        }
      } catch (error) {
        console.log(error)
      }
    }
  })

  const title = `${t('promo_code_groups')} : ${id ? t('Edit') : t('Create')}`
  document.title = title

  const error = isErrorCreateGroup || isErrorEditGroup

  if (error) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar backLink={'/promocodes-groups'} entity={group} />
            <Form
              className='needs-validation mt-4'
              onSubmit={validation.handleSubmit}
            >
              <Row>
                <Col className='col-auto'>
                  <FormGroup className='mb-3'>
                    <Label htmlFor='name'>{t('name')}</Label>
                    <Input
                      name='name'
                      type='text'
                      className='form-control form-control-sm'
                      id='name'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name}
                      invalid={
                        !!(validation.touched.name && validation.errors.name)
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Button
                className='text-nowrap'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                type='submit'
                disabled={!validation.dirty}
              >
                {id ? t('Update') : t('Create')}
              </Button>
            </Form>

            {(isSuccessCreateGroup || isSuccessEditGroup) && (
              <Alert color='success'>
                <strong>{t('Success')}</strong>
              </Alert>
            )}
            {error && (
              <Alert color='warning'>
                <strong>{error}</strong>
              </Alert>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  )
}
