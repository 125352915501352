import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,

  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,

  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,

  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,

  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
} from "./actionType";

//get all
export const getProducts = (filter) => ({
  type: GET_PRODUCTS,
  filter,
})

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})

//get one
export const getProduct = (id) => ({
  type: GET_PRODUCT,
  id,
})

export const getProductSuccess = product => ({
  type: GET_PRODUCT_SUCCESS,
  payload: product,
})

export const getProductFail = error => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
})

//create
export const createProduct = (product) => ({
  type: CREATE_PRODUCT,
  payload: product,
})

export const createProductSuccess = () => ({
  type: CREATE_PRODUCT_SUCCESS,
})

export const createProductFail = error => ({
  type: CREATE_PRODUCT_FAIL,
  payload: error,
})

//delete
export const deleteProduct = (id) => ({
  type: DELETE_PRODUCT,
  payload: id,
})

export const deleteProductSuccess = () => ({
  type: DELETE_PRODUCT_SUCCESS,
})

export const deleteProductFail = error => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
})

//update
export const updateProduct = (product) => ({
  type: UPDATE_PRODUCT,
  payload: product,
})

export const updateProductSuccess = () => ({
  type: UPDATE_PRODUCT_SUCCESS,
})

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
})
