import React from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { Row, Col, Card, CardBody, Input, Label } from 'reactstrap'
import { useGetExtensionSettingsByIdQuery } from '../../../services/external-services-settings'

const fieldsDescription = {
  enabled: {},
  name: {},
  amount: { isAmount: true },
  subtract_when_issuing: {}
}

export const ExternalServicesSettingsView = () => {
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')
  const { t } = useTranslation()
  const {
    data: entity,
    isLoading,
    isError,
    error
  } = useGetExtensionSettingsByIdQuery(id, {
    skip: !id
  })

  const title = t('View_external_services_settings')
  document.title = title
  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/external-services-settings'}
      editLink={
        entity ? `/external-services-settings-edit?id=${entity.id}` : null
      }
      entity={entity}
    />
  )

  if (isError) {
    return (
      <ErrorPage
        backLink={'/external-services-settings'}
        title={title}
        error={!id ? 'No entity id found' : [error]}
      />
    )
  }

  if (isLoading) {
    return (
      <LoadingPage backLink={'/external-services-settings'} title={title} />
    )
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className='mt-3'>
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col className='col-auto' key={`${fieldName}`}>
                    <Label htmlFor={fieldName}>{t(fieldName)}</Label>
                    <Input
                      type='text'
                      className='form-control form-control-sm'
                      value={model.fieldValueToText(
                        fieldName,
                        entity,
                        fieldsDescription
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
