import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import EntityInfo from 'src/Components/Common/EntityInfo'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'
import Related from './Related'

import { getEntities } from 'src/store/actions'

import { Row, Card, CardBody } from 'reactstrap'

const columnsDescription = [
  ['application_status'],
  ['new_line'],
  ['requested_amount', 'requested_period'],
  ['approved_amount', 'approved_period'],
  ['signed_amount', 'signed_period'],
]

export const VerificationView = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const { entity, entityError, entityLoading } = useSelector((state) => ({
    entity:
      state.entities[Client.ENTITY_TYPE.VERIFICATION_APPLICATIONS].entity ||
      null,
    entityError:
      state.entities[Client.ENTITY_TYPE.VERIFICATION_APPLICATIONS].entityError,
    entityLoading:
      state.entities[Client.ENTITY_TYPE.VERIFICATION_APPLICATIONS]
        .entityLoading,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    console.log(`getApplications(${id})`)
    setPageReady(false)
    if (id)
      dispatch(getEntities(Client.ENTITY_TYPE.VERIFICATION_APPLICATIONS, id))
    else setParamError('No application id found')
  }, [props.location])

  useEffect(() => {
    if (!entity) return
    setPageReady(true)
  }, [entity])

  console.log('verification application => ', entity)
  const title = t('View application')
  document.title = title
  const bread = <BreadCrumb title={title} />
  let metaBar = (
    <MetaBar backLink={'/verification-applications'} entity={entity} />
  )

  if (paramError || entityError) {
    return (
      <ErrorPage
        backLink={'/verification-applications'}
        title={title}
        error={[paramError, entityError]}
      />
    )
  }

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/verification-applications'} title={title} />
  }

  const content = entity
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              <EntityInfo
                entity={content}
                entityId={entity.id}
                entityType={Client.ENTITY_TYPE.VERIFICATION_APPLICATIONS}
                columnsDescription={columnsDescription}
              />
            </Row>
            <Row className="mt-5">
              <Related application={entity} />
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
