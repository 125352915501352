import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../Loader'
import { Alert, Button } from 'reactstrap'
import {
  useGetClientsTagsQuery,
  useRemoveClientsTagsByIDMutation,
  useSetClientsTagsByIDMutation,
} from 'src/services/tags'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

type tagProps = {
  entityId: string
  entityType: string
  title: string
  visible: boolean
}

const EntityContent = (props: tagProps) => {
  const { entityId, visible } = props
  const { t } = useTranslation()
  const [selected, setSelected] = useState<number[]>([])

  const {
    data: tags,
    error: getClientsTagsError,
    isLoading: isLoadingTags,
  } = useGetClientsTagsQuery(undefined, { skip: !visible })

  const [
    setClientsTags,
    { isSuccess: isSuccessSetClientsTags, error: createErrorSetClientsTags },
  ] = useSetClientsTagsByIDMutation()

  const [
    removeClientsTags,
    {
      isSuccess: isSuccessRemoveClientsTags,
      error: createErrorRemoveClientsTags,
    },
  ] = useRemoveClientsTagsByIDMutation()

  const setMark = () => {
    setClientsTags({ id: entityId, body: selected })
  }

  const removeMark = () => {
    removeClientsTags({ id: entityId, body: selected })
  }

  if (getClientsTagsError) return <DangerAlert error={getClientsTagsError} />

  return (
    <div>
      {isLoadingTags && <Loader />}

      {!isLoadingTags && (
        <div className="d-flex gap-5 mb-2">
          <select
            className="form-select form-select-sm"
            style={{ width: 'auto', textOverflow: 'ellipsis' }}
            onChange={(e) => {
              if (Number(e.target.value) === 0) {
                setSelected([])
              } else {
                setSelected([Number(e.target.value)])
              }
            }}
          >
            <option value={''}></option>
            {tags?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.tag}
              </option>
            ))}
          </select>
          <div className="d-flex gap-3">
            <Button
              size="sm"
              onClick={setMark}
              disabled={selected.length === 0}
            >
              {t('Save')}
            </Button>
            <Button
              size="sm"
              onClick={removeMark}
              color="danger"
              disabled={selected.length === 0}
            >
              {t('Delete')}
            </Button>
          </div>
        </div>
      )}
      {(isSuccessSetClientsTags || isSuccessRemoveClientsTags) && (
        <Alert color="success">
          <strong>{t('Success')}</strong>
        </Alert>
      )}
      {createErrorSetClientsTags && 'status' in createErrorSetClientsTags && (
        <Alert color="warning">
          <strong>{JSON.stringify(createErrorSetClientsTags.data)}</strong>
        </Alert>
      )}
      {createErrorRemoveClientsTags &&
        'status' in createErrorRemoveClientsTags && (
          <Alert color="warning">
            <strong>{JSON.stringify(createErrorRemoveClientsTags.data)}</strong>
          </Alert>
        )}
    </div>
  )
}

export default EntityContent
