import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'

import { getStrategies } from 'src/store/strategies/action'

const mappingListValue = (list) => list?.dpd

const headers = {
  id: {},
  name: { label: 'strategy_name' },
  loan_filters: { label: 'dpd', mappingValue: mappingListValue },
  start_time: { label: 'start_time' },
  is_active: { label: 'status' },
}

const actionsEnabled = true
const enableDelete = false

export const Strategies = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { strategies, strategiesLoading, strategiesError } = useSelector(
    (state) => {
      return {
        strategies: state.strategies.strategies,
        strategiesLoading: state.strategies.strategiesLoading,
        strategiesError: state.strategies.strategiesError,
      }
    },
  )

  const title = t('strategies')

  useEffect(() => {
    dispatch(getStrategies())
  }, [dispatch])

  console.log('strategies =>', strategies)

  if (strategiesError) {
    return <ErrorPage title={title} error={strategiesError} />
  }

  if (strategiesLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <MetaBar createLink={'/strategy-create'} />
            <div className="table-responsive table-card mt-4">
              <Table hover className="table-sm align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th />
                    {Object.keys(headers).map((header) => (
                      <th className="ps-3" key={header} scope="col">
                        {t(headers[header].label || header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope="col">{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {strategies &&
                    strategies.length > 0 &&
                    strategies.map((strategy) => {
                      return (
                        <tr key={strategy.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/strategy-view?id=${strategy.id}`}
                              className="fw-medium"
                            >
                              <i className="ri-eye-line fs-15" />
                            </Link>
                          </td>
                          {Object.keys(headers).map((fieldName) => {
                            return (
                              <td
                                className="ps-3"
                                key={fieldName}
                                style={{ whiteSpace: 'normal' }}
                              >
                                {model.fieldValueToText(
                                  fieldName,
                                  strategy,
                                  headers,
                                )}
                              </td>
                            )
                          })}
                          {actionsEnabled && (
                            <td>
                              <div className="hstack gap-3 flex-wrap">
                                <Link
                                  to={`/strategy-edit?id=${strategy.id}`}
                                  className="link-success fs-15"
                                >
                                  <i className="ri-edit-2-line"></i>
                                </Link>
                                {enableDelete && (
                                  <Link
                                    to="#"
                                    onClick={() => {}}
                                    className="link-danger fs-15"
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
