import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,
  ENTITY,
  ENTITY_SUCCESS,
  ENTITY_FAIL,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from "./actionType";

const INIT_STATE = {
  list: [],
  listLoading: false,
  listError: null,

  entity: null,
  entityLoading: false,
  entityError: null,

  updateLoading: false,
  updateError: null,
};

const Dialogues = (state = INIT_STATE, action) => {
  switch (action.type) {
    //one
    case ENTITY:
      return {
        ...state,
        entityLoading: true,
        entityError: null,
      };

    case ENTITY_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        entityLoading: false,
        entityError: null,
      }

    case ENTITY_FAIL:
      return {
        ...state,
        entityError: action.payload,
        entityLoading: false,
      }

    //all
    case LIST:
      return {
        ...state,
        listLoading: true,
        listError: null,
      };

    case LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listLoading: false,
        listError: null,
      }

    case LIST_FAIL:
      return {
        ...state,
        listError: action.payload,
        listLoading: false,
      }

    //update
    case UPDATE:
      return {
        ...state,
        updateError: null,
        updateLoading: true,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        dialogue: action.payload,
        updateError: null,
        updateLoading: false,
      }

    case UPDATE_FAIL:
      return {
        ...state,
        updateError: action.payload,
        updateLoading: false,
      }
    default:
      return state;
  }
};

export default Dialogues;
