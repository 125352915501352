import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import ErrorPage from '../ErrorPage'

import { useTranslation } from 'react-i18next'

import model from 'src/model'
import Client from 'src/client'

import { getEntities, deleteEntities } from 'src/store/actions'

const headers = {
  id: {},
  country: {},
  name: { fieldName: 'name_eng' },
  mask: {},
  validity: {},
  is_primary: {},
  //created_at: {isDateTime: true},
  //updated_at: {isDateTime: true},
}

const actionsEnabled = false

export const DocumentTypes = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { list, listError } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.DOCUMENTS_TYPES].list,
      listLoading:
        state.entities[Client.ENTITY_TYPE.DOCUMENTS_TYPES].listLoading,
      listError: state.entities[Client.ENTITY_TYPE.DOCUMENTS_TYPES].listError,
      deleteLoading:
        state.entities[Client.ENTITY_TYPE.DOCUMENTS_TYPES].deleteLoading,
    }
  })

  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.DOCUMENTS_TYPES))
  }, [dispatch])

  const archive = (settingsId) => {
    dispatch(deleteEntities(settingsId, Client.ENTITY_TYPE.DOCUMENTS_TYPES))
  }

  console.log('list =>', list)

  const title = t('Document_types')
  document.title = title
  //const metaBar = <MetaBar createLink={'/document-types-create'}/>;
  const metaBar = null

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <div className="table-responsive table-card mt-4">
              <Table hover className="table-sm align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    {Object.keys(headers).map((header) => (
                      <th key={header} scope="col">
                        {t(header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope="col">{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          {Object.keys(headers).map((fieldName) => {
                            if (fieldName === 'name') {
                              headers['name'] = {
                                fieldName:
                                  i18n.language === 'rs'
                                    ? 'name_rus'
                                    : 'name_eng',
                              }
                            }

                            return (
                              <td key={fieldName}>
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  headers,
                                )}
                              </td>
                            )
                          })}
                          {actionsEnabled && (
                            <td>
                              <div className="hstack gap-3 flex-wrap">
                                <Link
                                  to={`/document-types-edit?id=${entity.id}`}
                                  className="link-success fs-15"
                                >
                                  <i className="ri-edit-2-line"></i>
                                </Link>
                                <Link
                                  to="#"
                                  onClick={() => archive(entity.id)}
                                  className="link-danger fs-15"
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </Link>
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
