import { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import ErrorPage from 'src/pages/Admin/ErrorPage'
import LoadingPage from 'src/pages/Admin/LoadingPage'
import List from 'src/Components/Common/EntitiesView/List'
import { useGetUsersQuery } from 'src/services/keycloak'
import { UseQueryHookArgs } from 'src/services/api'
import FieldsFilter from 'src/Components/Common/FieldsFilter'

export const ChatUserSettings = () => {
  const { t } = useTranslation()

  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetUsersQuery>
  >({})

  const { data: users, error } = useGetUsersQuery({
    // limit: LIST_LIMIT,
    // offset: 0,
    ...fieldsFilter
  })

  const title = t('Users')
  document.title = title

  if (error) {
    return <ErrorPage title={title} error={error} />
  }

  if (!users) {
    return <LoadingPage backLink={'/'} title={title} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <FieldsFilter
            fields={{
              id: { label: 'id' },
              username: { label: 'username' }
            }}
            applyFilter={(values: typeof fieldsFilter) =>
              // FieldsFilter runs applyFilter function on reset instead of resetFilter
              setFieldsFilter(values)
            }
          />
          <List
            list={users}
            fieldsDescription={{
              username: {},
              id: {
                label: 'id',
                isLink: true,
                linkFieldName: 'id'
              }
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}
