import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetApplicationsKIQuery,
  useGetApplicationsKIReportMutation
} from 'src/services/applications'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import List from './List'

type ApplicationsKIProps = {
  applicationId: string
  style: any
}

const fieldsDescription = {
  id: {},
  company: {},
  created_at: { isDateTime: true }
}

export const ApplicationsKI: FC<ApplicationsKIProps> = ({
  applicationId,
  style
}) => {
  const { t } = useTranslation()

  const { data: KiListDoc, error } = useGetApplicationsKIQuery(applicationId, {
    skip: applicationId == null
  })

  const [KIReport] = useGetApplicationsKIReportMutation()

  const showXml = async (doc: any) => {
    KIReport({ report_id: doc.id, company_name: doc.company }).then(
      (response: any) => {
        try {
          const blob = new Blob([response.data], {
            type: 'text/plain; charset=UTF-8'
          })
          const url = URL.createObjectURL(blob)
          const newWindow = window.open(url, '_blank')
          newWindow?.addEventListener('unload', () => {
            URL.revokeObjectURL(url)
          })
        } catch (error) {
          console.error('Error parsing XML:', error)
        }
      }
    )
  }

  if (!KiListDoc || !KiListDoc.length)
    return (
      <div style={style}>
        <dl className='row mb-0'>
          <small>{t('No_signed_documents')}</small>
        </dl>
      </div>
    )

  return (
    <>
      {error ? <DangerAlert error={error} /> : null}
      {KiListDoc ? (
        <List
          hover
          list={KiListDoc?.map((doc) => ({
            ...doc,
            onPress: () => showXml(doc)
          }))}
          fieldsDescription={fieldsDescription}
          style={style}
          // @ts-expect-error TODO: check if this property is used at all
          onRowPress={showXml}
        />
      ) : null}
    </>
  )
}
