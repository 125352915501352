export const LIST = "GET_TRANSACTIONS"
export const LIST_SUCCESS = "GET_TRANSACTIONS_SUCCESS"
export const LIST_FAIL = "GET_TRANSACTIONS_FAIL"

export const DELETE = "DELETE_TRANSACTION"
export const DELETE_SUCCESS = "DELETE_TRANSACTION_SUCCESS"
export const DELETE_FAIL = "DELETE_TRANSACTION_FAIL"

export const UPDATE = "UPDATE_TRANSACTION"
export const UPDATE_SUCCESS = "UPDATE_TRANSACTION_SUCCESS"
export const UPDATE_FAIL = "UPDATE_TRANSACTION_FAIL"

export const CREATE = "CREATE_TRANSACTION"
export const CREATE_SUCCESS = "CREATE_TRANSACTION_SUCCESS"
export const CREATE_FAIL = "CREATE_TRANSACTION_FAIL"
