import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import {
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  Alert
} from 'reactstrap'
import { formatDateTime } from 'src/utils'
import { Link, useSearchParams } from 'react-router-dom'
import List from 'src/Components/Common/EntitiesView/List'

import {
  useGetClaimQuery,
  useGetFraudCheckMutation,
  useGetUploadAttachmentMutation
} from 'src/services/claims'
import { useGetClientsQuery } from 'src/services/clients'
import { useGetUserInfoQuery } from 'src/services/keycloak'
import { AskAboutAction } from 'src/Components/Common/AskAboutAction'

export const ViewMainClaim: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [clientName, setClientName] = useState('')
  const [isUserWork, setIsUserWork] = useState(true)
  const [id, setId] = useState<string>()
  const [showMark, setShowMark] = useState(false)
  const toggle = useCallback(() => setShowMark((prev) => !prev), [])

  const { data: userInfo } = useGetUserInfoQuery()

  const [fraudCheck, { isSuccess: isSuccessFraud, isError: updateErrorFraud }] =
    useGetFraudCheckMutation()

  const {
    data: claimMain,
    isError,
    error
  } = useGetClaimQuery(
    {
      id: id!
    },
    {
      skip: id == null
    }
  )

  const { data: clients } = useGetClientsQuery(
    { client_id: claimMain?.client_id! },
    { skip: !claimMain?.client_id }
  )

  const [uploadAttachment] = useGetUploadAttachmentMutation()

  const downloadFile = async (file: any) => {
    const blob = await uploadAttachment({ id: file.id })
    if ('data' in blob) {
      const url = window.URL.createObjectURL(new Blob([blob.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${file.file_name}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  useEffect(() => {
    const maybeId = searchParams.get('id')
    if (maybeId) setId(maybeId)
  }, [searchParams])

  useEffect(() => {
    clients?.forEach((client) => {
      if (client.id === claimMain?.client_id) {
        setClientName(
          `${client.last_name} ${client.first_name} ${client.middle_name}`
        )
      }
    })
  }, [clients, claimMain])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    userInfo?.roles.includes('head_of_comunications')
      ? setIsUserWork(false)
      : setIsUserWork(true)
  }, [userInfo])

  const title = t('View claim')
  document.title = title

  if (isError) {
    return <ErrorPage backLink={'/products'} title={title} error={error} />
  }

  if (!claimMain) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            backLink={isUserWork ? '/my-claims' : '/claims-queue'}
            editLink={claimMain ? `/main-claim-edit?id=${claimMain.id}` : null}
            entity={claimMain}
            createLink={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <div className='mt-1 mb-2' style={{ textAlign: 'end' }}>
            <Button
              disabled={showMark}
              color='danger'
              className='text-nowrap mt-1'
              onClick={() => setShowMark(true)}
            >
              {t('Mark as fraudulent')}
            </Button>
            {showMark && (
              <AskAboutAction
                cancelAction={() => setShowMark(false)}
                sureAction={() => fraudCheck({ id: claimMain.id })}
                isOpen={showMark}
                toggle={toggle}
                title={t('Mark as fraudulent')}
              />
            )}

            {updateErrorFraud && (
              <Alert color='warning'>
                <strong> {JSON.stringify(updateErrorFraud)} </strong>
              </Alert>
            )}

            {isSuccessFraud && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
          </div>

          {claimMain && (
            <ListGroup>
              <ListGroupItem>
                <Row>
                  <Col>{t('claim_number')}</Col>
                  <Col>{claimMain.claim_number}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('client_id')}</Col>
                  <Col>
                    <Link
                      to={`/client-view?id=${claimMain.client_id}`}
                      className='fw-medium'
                    >
                      {claimMain.client_id}
                    </Link>
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('fio_client')}</Col>
                  <Col>{clientName}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('created')}</Col>
                  <Col>{formatDateTime(claimMain.created_at)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('updated')}</Col>
                  <Col>{formatDateTime(claimMain.updated_at)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('closed_at')}</Col>
                  <Col>{formatDateTime(claimMain.closed_at)}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('channel_type')}</Col>
                  <Col>{claimMain.channel_type}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('status')}</Col>
                  <Col>{claimMain.status}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('client_email')}</Col>
                  <Col>{claimMain.client_email}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('email_from')}</Col>
                  <Col>{claimMain.email_from}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('email_to')}</Col>
                  <Col>{claimMain.email_to}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('claim_topic')}</Col>
                  <Col>{claimMain.claim_topic}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('claim_body')}</Col>
                  <Col>{claimMain.claim_body}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('template_file')}</Col>
                  <Col>
                    {claimMain?.attachments.map((file) => {
                      return (
                        <div
                          className='d-flex flex-row gap-2'
                          key={file.file_vault_id}
                        >
                          {file.file_name}
                          <div
                            role='button'
                            className='text-decoration-underline'
                            onClick={() => downloadFile(file)}
                          >
                            <small>{t('Download')}</small>
                          </div>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('comment')}</Col>
                  <Col>{claimMain.comment}</Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col>{t('fraud_comment')}</Col>
                  <Col>{claimMain.fraud_comment}</Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          )}
          <Link
            to={
              claimMain.proxy_claims?.length
                ? `/claim-response?id=${claimMain.id}`
                : ``
            }
            className='fw-medium'
            style={{ cursor: 'default' }}
          >
            <Button
              className='mt-3'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              disabled={!claimMain.proxy_claims?.length}
            >
              {t('write an answer')}
            </Button>
          </Link>
          <h5 className='mt-3'>{t('List of requests')}</h5>
          {claimMain.proxy_claims?.length ? (
            <List
              list={claimMain.proxy_claims.map((claim) => {
                return claim.client_id
                  ? {
                      ...claim,
                      id_link: `/claim-view?id=${claim.id}`,
                      fio_client: `${claim.client_surname} ${claim.client_name} ${claim.client_midname}`
                    }
                  : {
                      ...claim,
                      id_link: `/claim-view?id=${claim.id}`,
                      fio_client: `-`
                    }
              })}
              className={undefined}
              style={{ width: 'fit-content' }}
              fieldsDescription={{
                id: {
                  label: ' ',
                  mappingValue: () => <i className='ri-eye-line fs-15' />,
                  isLink: true,
                  linkFieldName: 'id_link'
                },
                claim_number: {},
                fio_client: {},
                claim_topic_name: {},
                claim_subtopic_name: {},
                created_at: { isDate: true },
                updated_at: { isDate: true },
                status: {},
                days_to_reply: {},
                assignee_username: {},
                claim_subtopic_priority: { label: 'priority' },
                plan_date: {}
              }}
              hover
              actions={undefined}
            />
          ) : (
            <div>{t('Request not processed')}</div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
