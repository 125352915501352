import { api } from './api'

type TemplateVariables = {
  [key: string]: string
}

export const TemplatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateVariables: builder.query<TemplateVariables, void>({
      query: () => ({
        url: `/crm/v1/communications/templates/template-variables`,
      }),
    }),
  }),
})

export const { useGetTemplateVariablesQuery } = TemplatesApi
