import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
} from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import PreviewCardHeader from 'src/Components/Common/PreviewCardHeader'
import UiContent from 'src/Components/Common/UiContent'

import { useTranslation } from 'react-i18next'

import {
  formatNumber,
  getFormattingNumberOptions,
  setFormattingNumberOptions,
  clearFormattingNumberOptions,
} from 'src/utils'

const PREVIEW_X = 100998999.99

export const Settings = () => {
  const { t } = useTranslation()

  const [formatOptions, setFormatOptions] = useState({
    decimalSeparator: '',
    groupSeparator: '',
  })
  const [preview, setPreview] = useState('')

  useEffect(() => {
    setFormatOptions(getFormattingNumberOptions())
  }, [])

  useEffect(() => {
    setPreview(
      formatNumber(
        PREVIEW_X,
        2,
        formatOptions.decimalSeparator,
        formatOptions.groupSeparator,
      ),
    )
  }, [formatOptions])

  const pageTitle = t('Settings')
  document.title = pageTitle
  const bread = (
    <BreadCrumb title={t('Formatting data')} pageTitle={pageTitle} />
  )

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          {bread}
          <Row>
            <Col>
              <Card>
                <PreviewCardHeader title={pageTitle} />
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="delimiter_decimal" className="form-label">
                          {t('delimiter_decimal')}
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder=""
                          id="delimiter_decimal"
                          value={formatOptions.decimalSeparator}
                          onChange={(event) => {
                            const value = event.target.value
                            setFormatOptions({
                              ...formatOptions,
                              decimalSeparator: value,
                            })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="delimiter_group" className="form-label">
                          {t('delimiter_group')}
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder=""
                          id="delimiter_group"
                          value={formatOptions.groupSeparator}
                          onChange={(event) => {
                            const value = event.target.value
                            setFormatOptions({
                              ...formatOptions,
                              groupSeparator: value,
                            })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="">{`${t('Preview')}: ${preview}`}</div>
                    </Col>
                    <Col md={12}>
                      <div className="text-end">
                        <Button
                          color="success"
                          style={{ marginRight: 16 }}
                          className="btn btn-primary"
                          onClick={() => {
                            setFormattingNumberOptions(formatOptions)
                          }}
                        >
                          {t('Apply')}
                        </Button>
                        <Button
                          color="success"
                          className="btn btn-primary"
                          onClick={() => {
                            clearFormattingNumberOptions()
                            setFormatOptions(getFormattingNumberOptions())
                          }}
                        >
                          {t('Reset')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
