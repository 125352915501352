import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UseQueryHookArgs } from 'src/services/api'
import { useGetClaimsQuery } from 'src/services/claims'
import { OffsetNotice } from 'src/Components/Common/LimitNotice'
import model from 'src/model'

type props = {
  list: UseQueryHookArgs<typeof useGetClaimsQuery>[]
  fieldsFilter: UseQueryHookArgs<typeof useGetClaimsQuery>
  setFieldsFilter: (el: UseQueryHookArgs<typeof useGetClaimsQuery>) => void
}
export const Pagination: FC<props> = ({
  list,
  fieldsFilter,
  setFieldsFilter,
}) => {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(0)

  const next = () => {
    if (list!.length < model.LIST_LIMIT) return
    const newFields = { ...fieldsFilter }
    newFields.offset = offset + model.LIST_LIMIT
    setFieldsFilter(newFields)
    setOffset((prev) => prev + model.LIST_LIMIT)
  }

  const prev = () => {
    if (offset === 0) return
    const newFields = { ...fieldsFilter }
    newFields.offset = offset - model.LIST_LIMIT
    setFieldsFilter(newFields)
    setOffset((prev) => Math.max(prev - model.LIST_LIMIT, 0))
  }

  return (
    <>
      <div
        className="btn-group gap-2 p-3"
        role="group"
        aria-label="Basic example"
      >
        <button
          type="button"
          className="btn btn-primary d-flex align-items-center"
          disabled={offset === 0}
          onClick={prev}
        >
          <i className="ri-arrow-left-line fs-15 me-2" />
          {`${t('Prev')} ${model.LIST_LIMIT}`}
        </button>
        <button
          type="button"
          className="btn btn-primary d-flex align-items-center"
          disabled={list?.length < model.LIST_LIMIT}
          onClick={next}
        >
          {`${t('Next')} ${model.LIST_LIMIT}`}
          <i className="ri-arrow-right-line fs-15 ms-2" />
        </button>
      </div>
      <OffsetNotice offset={offset} limit={model.LIST_LIMIT} />
    </>
  )
}
