import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  UPDATE_DOCS,
  UPDATE_DOCS_SUCCESS,
  UPDATE_DOCS_FAIL
} from './actionType'

//get all
export const getApplications = (id = '', filter = '') => ({
  type: LIST,
  id,
  filter
})

export const getApplicationsSuccess = (list) => ({
  type: LIST_SUCCESS,
  payload: list
})

export const getApplicationsFail = (error) => ({
  type: LIST_FAIL,
  payload: error
})

//delete
export const deleteApplication = (id) => ({
  type: DELETE,
  payload: id
})

export const deleteApplicationSuccess = () => ({
  type: DELETE_SUCCESS
})

export const deleteApplicationFail = (error) => ({
  type: DELETE_FAIL,
  payload: error
})

//update
export const updateApplication = (entity) => ({
  type: UPDATE,
  payload: entity
})

export const updateApplicationSuccess = () => ({
  type: UPDATE_SUCCESS
})

export const updateApplicationFail = (error) => ({
  type: UPDATE_FAIL,
  payload: error
})

//update docs
export const updateDocs = (entity) => ({
  type: UPDATE_DOCS,
  payload: entity
})

export const updateDocsSuccess = () => ({
  type: UPDATE_DOCS_SUCCESS
})

export const updateDocsFail = (error) => ({
  type: UPDATE_DOCS_FAIL,
  payload: error
})
