import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'

import { useGetGroupsQuery } from 'src/services/calling'

const headers = {
  name: {},
}

export const Groups = () => {
  const { t } = useTranslation()

  const {
    data: list,
    isLoading: listLoading,
    error: listError,
  } = useGetGroupsQuery()

  console.log('list =>', list)
  const title = t('Groups')
  document.title = title
  const metaBar = <MetaBar createLink={'/groups-create'} />

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <div className="table-responsive table-card mt-4">
              {list.length < 1 && (
                <h5 style={{ marginLeft: '15px', marginBottom: '15px' }}>
                  {t('No group created yet')}
                </h5>
              )}
              {list.length > 0 && (
                <Table
                  hover
                  className="table-sm align-middle table-nowrap mb-0"
                >
                  <thead>
                    <tr>
                      <th>{t('Actions')}</th>
                      {Object.keys(headers).map((header) => (
                        <th className="ps-3" key={header} scope="col">
                          {t(headers[header].label || header)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.length > 0 &&
                      list.map((entity) => {
                        return (
                          <tr key={entity.id}>
                            <td style={{ width: '1%' }}>
                              <Link
                                to={`/groups-edit?id=${entity.id}`}
                                className="fw-medium"
                              >
                                <i className="ri-edit-2-line" />
                              </Link>
                            </td>
                            {Object.keys(headers).map((fieldName) => {
                              if (fieldName === 'id')
                                return <td className="ps-3">{entity.id}</td>
                              return (
                                <td className="ps-3" key={fieldName}>
                                  {model.fieldValueToText(
                                    fieldName,
                                    entity,
                                    headers,
                                  )}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
