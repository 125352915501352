import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import EntityInfo from 'src/Components/Common/EntityInfo'
import Related from './Related'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'

import { getProfiles } from 'src/store/actions'

import { Row, Card, CardBody, Button, Alert } from 'reactstrap'

const columnsDescription = [
  /*['profile_id'], ['created_at'], ['updated_at'],*/ ['profile_status'],
  ['phone'],
  ['phone_status'],
  ['email'],
  ['email_status'],
  ['lead_id'],
  ['track_id']
]

export const ProfileView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [profileId, setProfileId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [resetLkSuccess, setResetLkSuccess] = useState(false)
  const [resetLkError, setResetLkError] = useState(false)
  const [paramError, setParamError] = useState(null)
  const { profile, listLoading, listError } = useSelector((state) => ({
    profile:
      state.profiles.list && state.profiles.list.length
        ? state.profiles.list[0]
        : null,
    listLoading: state.profiles.listLoading,
    listError: state.profiles.listError
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setProfileId(id || null)
    if (id) dispatch(getProfiles(id))
    else setParamError('No profile id found')
  }, [])

  useEffect(() => {
    if (!profile) return
    setPageReady(true)
  }, [profile])

  const handleResetLk = () => {
    Client.resetLk({ phone: profile.phone })
      .then(() => setResetLkSuccess(true))
      .catch((error) => setResetLkError(error))
  }

  const title = t('View profile')
  document.title = title
  const bread = <BreadCrumb title={title} />

  if (paramError || listError) {
    return listError ? (
      <ErrorPage backLink={'/profiles'} title={title} error={listError} />
    ) : (
      <ErrorPage backLink={'/profiles'} title={title} error={paramError} />
    )
  }

  if (!pageReady || listLoading) {
    return <LoadingPage backLink={'/profiles'} title={title} />
  }

  const metaBar = <MetaBar backLink={'/profiles'} entity={profile} />
  console.log('profile =>', profile)

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className='mt-4'>
              <EntityInfo
                entity={profile}
                entityId={profile.id}
                entityType={Client.ENTITY_TYPE.PROFILES}
                columnsDescription={columnsDescription}
              />
            </Row>
            <Button style={{ marginTop: '5px' }} onClick={handleResetLk}>
              {t('Reset-lk')}
            </Button>
            {resetLkSuccess && (
              <Alert style={{ marginTop: '5px' }} color='success'>
                <strong> {t('Operation success')}</strong>
              </Alert>
            )}
            {resetLkError && (
              <Alert style={{ marginTop: '5px' }} color='danger'>
                <strong> {resetLkError}</strong>
              </Alert>
            )}
            {profile.client && profile.client.id && (
              <Row className='mt-5'>
                <Related profile={profile} />
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
