import { call, put, takeEvery, all, fork } from 'redux-saga/effects'

// Crypto Redux States
import {
  GET_STRATEGIES,
  GET_STRATEGY,
  CREATE_STRATEGY,
  UPDATE_STRATEGY,
} from './actionType'

import {
  getStrategies as getStrategiesAction,
  getStrategiesSuccess,
  getStrategiesFail,
  getStrategySuccess,
  getStrategyFail,
  createStrategySuccess,
  createStrategyFail,
  updateStrategySuccess,
  updateStrategyFail,
} from './action'

import Client from '../../client'

function* getStrategies({ filter }) {
  try {
    const response = yield call(
      Client.getList,
      Client.ENTITY_TYPE.STRATEGIES,
      filter,
    )
    yield put(getStrategiesSuccess(response))
  } catch (error) {
    yield put(getStrategiesFail(error))
  }
}

function* getStrategy({ id }) {
  try {
    const response = yield call(
      Client.getEntity,
      Client.ENTITY_TYPE.STRATEGIES,
      id,
    )
    yield put(getStrategySuccess(response))
  } catch (error) {
    yield put(getStrategyFail(error))
  }
}

function* createStrategy({ payload }) {
  try {
    const response = yield call(
      Client.createEntity,
      Client.ENTITY_TYPE.STRATEGIES,
      payload,
    )
    yield put(createStrategySuccess())
    yield put(getStrategiesAction())
  } catch (error) {
    yield put(createStrategyFail(error))
  }
}

function* updateStrategy({ payload }) {
  try {
    const response = yield call(
      Client.updateEntity,
      Client.ENTITY_TYPE.STRATEGIES,
      payload,
    )
    yield put(updateStrategySuccess())
    yield put(getStrategiesAction())
  } catch (error) {
    yield put(updateStrategyFail(error))
  }
}

export function* watchOnGetStrategies() {
  yield takeEvery(GET_STRATEGIES, getStrategies)
}
export function* watchOnGetStrategy() {
  yield takeEvery(GET_STRATEGY, getStrategy)
}

export function* watchOnCreateStrategy() {
  yield takeEvery(CREATE_STRATEGY, createStrategy)
}

export function* watchOnUpdateStrategy() {
  yield takeEvery(UPDATE_STRATEGY, updateStrategy)
}

function* StrategiesSaga() {
  yield all([
    fork(watchOnGetStrategies),
    fork(watchOnGetStrategy),
    fork(watchOnCreateStrategy),
    fork(watchOnUpdateStrategy),
  ])
}

export default StrategiesSaga
