import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  CardFooter,
  Card,
  CardBody,
  Button,
  Input,
  Badge,
  ModalBody,
  ModalHeader,
  Modal,
  Form
} from 'reactstrap'
import { useParams, Link } from 'react-router-dom'

import {
  useGetChatQuery,
  useGetChatTemplatesQuery,
  useSendMessageMutation,
  useUpdateChatMutation,
  useReadMessageMutation,
  useGetMessagesQuery
} from 'src/services/chats'
import MessageList from 'src/Components/Common/EntitiesView/Chat/MessageList'
import { toast } from 'react-toastify'
import { DangerAlert } from '../ErrorPage'

export const ChatView: FC = () => {
  const { t } = useTranslation()

  const { id } = useParams()

  const [message, setMessage] = useState<string>('')

  const { data: chat } = useGetChatQuery(id!, { skip: id == null })

  const [updateChat] = useUpdateChatMutation()

  const { data: messages, error: getMessagesError } = useGetMessagesQuery(
    { chatId: id?.toString()! },
    { skip: id == null }
  )

  const { data: chatTemplates } = useGetChatTemplatesQuery()

  const [sendMessage] = useSendMessageMutation()

  const [readMessage] = useReadMessageMutation()

  useEffect(() => {
    messages?.forEach((message) => {
      if (message.is_new && message.author_type !== 'user') readMessage(message)
    })
    // trigger useEffect only if message count is changed
    // but not the message address in stack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages?.length])

  const handleChatTemplateClick = useCallback(
    (template: string) => setMessage(template),
    []
  )

  const handleSubmit = async () => {
    try {
      await sendMessage({
        chat_id: id!,
        content_type: 'text',
        content: message
      }).unwrap()
      setMessage('')
    } catch (error) {
      toast.error(t('ErrorSendMessage'))
    }
  }

  const title = t('View chat')
  document.title = title

  // if (isError) {
  //   return <ErrorPage backLink={'/chats/my'} title={title} error={error} />
  // }
  //
  // if (!chat) {
  //   return <LoadingPage backLink={'/chats/my'} title={title} />
  // }

  const sendImage = (file: File) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (typeof event.target?.result === 'string')
        sendMessage({
          content_type: 'image',
          content: event.target.result.split(',')[1] as string,
          chat_id: id!
        })
    }
    reader.readAsDataURL(file)
  }

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen((prev) => !prev)

  const inputRef = useRef<any | null>(null)

  return (
    <div className='page-content'>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('Close chat')}?</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={async (event) => {
              event.preventDefault()
              try {
                await updateChat({ ...chat!, status: 'Closed' }).unwrap()
                toast.success(t('Successfully closed chat'))
              } catch (error) {
                toast.error(t('Error closing chat'))
              } finally {
                toggle()
              }
            }}
          >
            <div className='d-flex justify-content-between align-items-center gap-4'>
              <Button type='submit' color='danger' block>
                {t('Yes')}
              </Button>
              <Button onClick={toggle} color='info' block>
                {t('No')}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <BreadCrumb
        title={
          <div className='d-flex gap-2'>
            {t(title)}
            <Badge color='primary'>{chat?.company_name}</Badge>
            <Badge>{chat?.channel}</Badge>
            {chat?.profile_id && (
              <Link to={`profile-view?id=${chat?.profile_id}`}>
                {t('profile_id')}: {chat?.profile_id}
              </Link>
            )}
          </div>
        }
      />

      <Card>
        <CardBody>
          <MetaBar backLink='/chats/my'>
            <>
              <div className='d-flex align-items-center gap-1'>
                <h5 style={{ marginBottom: 'unset' }}>{chat?.client_name}</h5>
                <h5 style={{ marginBottom: 'unset' }}>{chat?.client_phone}</h5>
              </div>
              <div className='d-flex gap-2 align-items-center flex-grow-1 justify-content-end'>
                <Button
                  style={{ marginLeft: '4px' }}
                  color='danger'
                  onClick={toggle}
                >
                  {t('Close chat')}
                </Button>
              </div>
            </>
          </MetaBar>

          <div style={{ marginTop: '8px' }} />

          {messages && messages.length > 0 ? (
            <MessageList messages={messages} />
          ) : getMessagesError ? (
            <DangerAlert error={getMessagesError} />
          ) : (
            <div>{t('ChatHistoryEmpty')}</div>
          )}
        </CardBody>

        <div
          style={{
            display: 'flex',
            gap: '8px',
            flexWrap: 'nowrap',
            overflow: 'auto',
            padding: '16px'
          }}
        >
          {message.length === 0 &&
            chatTemplates?.map((chatTemplate) => (
              <Button
                style={{ flexShrink: 0 }}
                key={chatTemplate.id}
                type='button'
                outline
                size='sm'
                color='secondary'
                onClick={() =>
                  handleChatTemplateClick(chatTemplate.text_template)
                }
                disabled={chat?.status === 'Closed' || !!getMessagesError}
              >
                {chatTemplate.text_template}
              </Button>
            ))}
        </div>

        <CardFooter className='d-flex gap-3'>
          <Input
            type='text'
            placeholder={t('EnterChatMessage')}
            value={message}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && message.length > 0) {
                handleSubmit()
              }
            }}
            onChange={(e) => setMessage(e.target.value)}
            disabled={chat?.status === 'Closed' || !!getMessagesError}
          />

          <input
            ref={inputRef}
            accept='image/*'
            type='file'
            onChange={(event) =>
              event.target?.files && sendImage(event.target.files[0])
            }
            disabled={chat?.status === 'Closed'}
            hidden
          />

          <Button
            disabled={chat?.status === 'Closed' || !!getMessagesError}
            size='sm'
            onClick={() => inputRef.current?.click()}
          >
            <i className='bx bx-paperclip bx-md' />
          </Button>

          <Button
            type='button'
            onClick={handleSubmit}
            disabled={
              message.length <= 0 ||
              chat?.status === 'Closed' ||
              !!getMessagesError
            }
          >
            {t('Reply')}
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}
