import { call, put, takeEvery, all, fork } from 'redux-saga/effects'

// Crypto Redux States
import { LIST, DELETE, UPDATE, UPDATE_DOCS } from './actionType'

import {
  getApplications,
  getApplicationsFail,
  getApplicationsSuccess,
  deleteApplicationSuccess,
  deleteApplicationFail,
  updateApplicationSuccess,
  updateApplicationFail,
  updateDocsSuccess,
  updateDocsFail,
} from './action'

import Client from '../../client'

function* deleteEntity({ payload }) {
  try {
    const response = yield call(
      Client.deleteEntity,
      Client.ENTITY_TYPE.APPLICATIONS,
      payload,
    )
    yield put(deleteApplicationSuccess())
    yield put(getApplications())
  } catch (error) {
    yield put(deleteApplicationFail(error))
  }
}

function* updateEntity({ payload }) {
  try {
    const response = yield call(
      Client.updateEntity,
      Client.ENTITY_TYPE.APPLICATIONS,
      payload,
    )
    yield put(updateApplicationSuccess())
    yield put(getApplications())
  } catch (error) {
    yield put(updateApplicationFail(error))
  }
}

function* updateDocs({ payload }) {
  try {
    const responseStart = yield call(
      Client.verifyApplicationDocumentsStart,
      payload.application_id,
    )
    const responseEnd = yield call(
      Client.verifyApplicationDocumentsEnd,
      payload,
    )
    yield put(updateDocsSuccess())
    //yield put(getApplications(payload.id));
  } catch (error) {
    yield put(updateDocsFail(error))
  }
}

function* getList({ id, filter }) {
  try {
    if (id) {
      const response = yield call(
        Client.getEntity,
        Client.ENTITY_TYPE.APPLICATIONS,
        id,
      )
      // try {
      //   const verification_process = yield call(
      //     Client.getApplicationVerification,
      //     id,
      //   )
      //   response.verification_process = verification_process
      // } catch {
      //   response.verification_process = {}
      // }
      try {
        const verificationHistory = yield call(Client.decideInfo, id)
        // response.verificationHistory = verificationHistory;
        response.verificationHistory = []
      } catch (err) {
        response.verificationHistory = []
      }
      try {
        const documents = yield call(Client.getApplicationDocuments, id)
        response.documents = documents.documents
      } catch (err) {
        response.documents = []
      }
      try {
        const documentsSigned = yield call(Client.getApplicationSignedDocs, id)
        response.documentsSigned = documentsSigned
      } catch {
        response.documentsSigned = []
      }
      try {
        if (response.status === 'Issued') {
          const loan = yield call(Client.getApplicationLoan, id)
          response.loan = loan

          try {
            const card = yield call(Client.getLoanCard, response.loan.id)
            response.loan.loan_issue_card = card.data
          } catch {
            response.loan.loan_issue_card = null
          }
        } else throw {}
      } catch {
        response.loan = null
      }
      yield put(getApplicationsSuccess([response]))
    } else {
      const response = yield call(
        Client.getList,
        Client.ENTITY_TYPE.APPLICATIONS,
        filter,
      )
      yield put(getApplicationsSuccess(response))
    }
  } catch (error) {
    yield put(getApplicationsFail(error))
  }
}

export function* watchOnUpdateDocs() {
  yield takeEvery(UPDATE_DOCS, updateDocs)
}

export function* watchOnGetList() {
  yield takeEvery(LIST, getList)
}

export function* watchOnDelete() {
  yield takeEvery(DELETE, deleteEntity)
}

export function* watchOnUpdate() {
  yield takeEvery(UPDATE, updateEntity)
}

function* applicationsSaga() {
  yield all([
    fork(watchOnGetList),
    fork(watchOnDelete),
    fork(watchOnUpdate),
    fork(watchOnUpdateDocs),
  ])
}

export default applicationsSaga
