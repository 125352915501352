import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetApplicationsUPRIDQuery,
  useGetApplicationsUPRIDReportMutation
} from 'src/services/applications'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import List from './List'

type ApplicationsUpridProps = {
  applicationId: string
  style: any
}

const fieldsDescription = {
  id: {},
  company: {},
  created_at: { isDateTime: true }
}

export const ApplicationsUprid: FC<ApplicationsUpridProps> = ({
  applicationId,
  style
}) => {
  const { t } = useTranslation()

  const { data: upridList, error } = useGetApplicationsUPRIDQuery(
    applicationId,
    { skip: applicationId == null }
  )

  const [upridReport] = useGetApplicationsUPRIDReportMutation()

  const showReport = (id: number | string) => {
    upridReport({ report_id: id }).then((response: any) => {
      const htmlContent = JSON.stringify(response)
      const newWindow = window.open('', '_blank')
      if (newWindow) {
        newWindow.document.write(htmlContent)
        newWindow.document.close()
      } else {
        console.error('Не удалось открыть новое окно!')
      }
    })
  }

  if (!upridList || !upridList.length) {
    return (
      <div style={style}>
        <dl className='row mb-0'>
          <small>{t('No_signed_documents')}</small>
        </dl>
      </div>
    )
  }

  return (
    <>
      {error ? <DangerAlert error={error} /> : null}
      <List
        hover
        list={upridList?.map((doc) => ({
          ...doc,
          onPress: () => showReport(doc.id)
        }))}
        fieldsDescription={fieldsDescription}
        style={style}
        // @ts-expect-error TODO: check if this property is used at all
        onRowPress={showReport}
      />
    </>
  )
}
