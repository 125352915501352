import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'

export default function OutComming(props: {
  answer: Function
  reject: Function
  hold: Function
  unHold: Function
  call: boolean
  id: string
}) {
  const { answer, reject, hold, unHold, call, id } = props
  const [muted, setMuted] = useState(false)
  return (
    <div>
      {call && (
        <>
          <div>Входящий номер: {id}</div>
          <br />
          <div className="d-flex">
            <div style={{ marginRight: '10px' }}>Микрофон:</div>
            <div
              className={`bx ${
                muted ? 'bxs-microphone-off' : 'bxs-microphone'
              } fs-22`}
              onClick={() => {
                if (!muted) {
                  setMuted(true)
                  hold()
                } else {
                  setMuted(false)
                  unHold()
                }
              }}
            ></div>
          </div>
        </>
      )}
      <div>
        <br />
        {!call && (
          <Button
            type="button"
            color="success"
            onClick={() => {
              answer()
            }}
          >
            Принять звонок
          </Button>
        )}
        <Button
          type="button"
          color="danger"
          onClick={() => {
            reject()
          }}
        >
          {call ? 'Завершить звонок' : 'Отклонить звонок'}
        </Button>
      </div>
    </div>
  )
}
