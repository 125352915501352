import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap'
import {
  mapStatusToClassName,
  mapStatusToName,
} from 'src/Components/Common/EntitiesView/Communications'
import Loader from 'src/Components/Common/Loader'
import { UseQueryHookResultData } from 'src/services/api'
import {
  useGetCommunicationRecordQuery,
  useGetCommunicationQuery,
  useGetCommunicationsQuery,
} from 'src/services/communications'
import { formatDateWithDash } from 'src/utils'

type Props = {
  communication?: UseQueryHookResultData<typeof useGetCommunicationsQuery>[0]
}

export const CommunicationView: FC<Props> = ({ communication }) => {
  const { t } = useTranslation()

  /**
   * communication extra currently is only available for communication with type=sms and =email-outgoing
   * in all other cases communication_id is null and the only data rendered is just communication from props
   * however if communication is with type=call-robot then it will have record_id which would allow to get record audio
   */

  const { data: communicationExtra, isFetching: isCommunicationExtraFetching } =
    useGetCommunicationQuery(
      {
        id: communication?.communication_id!,
        type: communication?.type!,
      },
      {
        skip: communication?.communication_id == null || !communication?.type,
      },
    )

  const { data: record, isFetching: isRecordFetching } =
    useGetCommunicationRecordQuery(communication?.record_id!, {
      skip: communication?.record_id == null,
    })

  if (isCommunicationExtraFetching || isRecordFetching) return <Loader />

  return (
    <ListGroup>
      <ListGroupItem>
        <Row>
          <Col>{t('ID')}</Col>
          <Col>{communication?.id}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('profile_id')}</Col>
          <Col>{communication?.profile_id}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('created')}</Col>
          <Col>{formatDateWithDash(communication?.created_at)}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('updated')}</Col>
          <Col>{formatDateWithDash(communication?.updated_at)}</Col>
        </Row>
      </ListGroupItem>

      <ListGroupItem>
        <Row>
          <Col>{t('type')}</Col>
          <Col>{communication?.type}</Col>
        </Row>
      </ListGroupItem>

      {communication?.status && (
        <ListGroupItem>
          <Row>
            <Col>{t('status')}</Col>
            <Col className={mapStatusToClassName(communication.status)}>
              {t(mapStatusToName(communication.status))}
            </Col>
          </Row>
        </ListGroupItem>
      )}

      {communicationExtra && (
        <>
          <ListGroupItem>
            <Row>
              <Col>{t('Message')}</Col>
              <Col>{communicationExtra.request_data}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('phone')}</Col>
              <Col>{communicationExtra.phone}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('Email')}</Col>
              <Col>{communicationExtra.email}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('Service ID')}</Col>
              <Col>{communicationExtra.service_id}</Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row>
              <Col>{t('Type ID')}</Col>
              <Col>{communicationExtra.type_id}</Col>
            </Row>
          </ListGroupItem>
        </>
      )}

      {record && (
        <ListGroupItem data-testid="list-group-item-record">
          <Row>
            <Col>
              <audio controls className="w-100">
                <source src={record} />
              </audio>
            </Col>
          </Row>
        </ListGroupItem>
      )}
    </ListGroup>
  )
}
