import { useTranslation } from 'react-i18next'
import { omit } from 'src/utils'

import Client from '../../../client'
import Tabs from '../../../Components/Common/Tabs'

const Related = ({ profile }) => {
  const { t } = useTranslation()

  const items = []
  if (profile.client) {
    const { ...client } = profile.client
    items.push(
      {
        title: `${t('Client')}`, //`` id: ${loan.profile.client.id}`,
        entity: client,
        entityId: client.id,
        entityType: Client.ENTITY_TYPE.CLIENTS,
        icon: null, //'ri-user-heart-line',
      },
      {
        title: `${t('Dialogue')}`,
        entityId: client.id,
        entityType: Client.ENTITY_TYPE.DIALOGUES,
        icon: null, //'ri-user-heart-line',
      },
    )
  }

  items.push({
    title: `${t('CPA')}`, //`` id: ${loan.profile.client.id}`,
    entity: omit(profile.cpa_lead, ['profile_id']),
    entityId: profile.cpa_lead?.id,
    entityType: 'profile_cpa_lead',
    icon: null, //'ri-user-heart-line',
  })

  items.push({
    title: `${t('Block_client')}`,
    entity: { client_id: profile.client.id, profile },
    entityType: 'block_client',
    application: { client_id: profile.client.id, profile },
  })

  return <Tabs items={items} />
}

export default Related
