import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  ModalHeader,
  Label,
  Input
} from 'reactstrap'
import Loader from 'src/Components/Common/Loader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CallReason,
  useAddCallEntityMutation,
  useUpdateCallEntityMutation
} from 'src/services/calling'
import { Link } from 'react-router-dom'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from '../ErrorPage'

interface EditReasonProps {
  reason?: CallReason
  className?: string
}

const EditReason: FC<EditReasonProps> = ({ reason, className }) => {
  const { t } = useTranslation()
  const { isOpen, open, close, toggle } = useDisclosure(false)

  const [addReason, { isLoading: isAdding, error: addError }] =
    useAddCallEntityMutation()
  const [updateReason, { isLoading: isUpdating, error: updateError }] =
    useUpdateCallEntityMutation()

  const toggleModal = () => {
    if (isOpen) {
      validation.resetForm()
    }
    toggle()
  }

  const isEditMode = !!reason

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: reason?.name || ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('Required'))
    }),
    onSubmit: async (values) => {
      const { name } = values

      if (isEditMode) {
        await updateReason({ type: 'reason', type_id: 0, name })
      } else {
        await addReason({ type: 'reason', name })
      }

      if (!(addError || updateError)) {
        close()
      }
    }
  })

  return (
    <div className={`d-flex ${className}`}>
      {isEditMode ? (
        <Link
          to='#'
          color='link'
          className='link-success fs-15'
          onClick={toggle}
        >
          <i className='ri-edit-2-line'></i>
        </Link>
      ) : (
        <Button
          className='btn-label'
          color='primary'
          onClick={open}
          style={{ backgroundColor: '#405189' }}
        >
          <i className='ri-add-line label-icon align-middle fs-16 me-2'></i>
          {t('Add reason')}
        </Button>
      )}
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          {isEditMode ? t('Edit reason') : t('Add reason')}
        </ModalHeader>
        <ModalBody>
          <Form className='needs-validation' onSubmit={validation.handleSubmit}>
            <FormGroup>
              <Label for='validation-name'>{t('Name of the reason')}</Label>
              <Input
                type='text'
                name='name'
                id='validation-name'
                placeholder={t('Enter a new reason')}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name}
                invalid={!!(validation.touched.name && validation.errors.name)}
                //disabled={isEditMode}
              />
              {validation.touched.name && validation.errors.name ? (
                <div className='invalid-feedback d-block'>
                  {validation.errors.name}
                </div>
              ) : null}
            </FormGroup>
            {!!addError && <DangerAlert error={addError} />}
            {!!updateError && <DangerAlert error={updateError} />}
            {isAdding || isUpdating ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                color='primary'
                style={{ backgroundColor: '#405189' }}
                disabled={!validation.values.name}
              >
                {isEditMode ? t('Update') : t('Create')}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditReason
