import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Application from './Application'
import Loan from './Loan'
import Transaction from './Fund'

import Client from '../../../client'
import model from '../../../model'

import { getEntities } from '../../../store/actions'
import { Code } from '../Code'

const fieldsDescription = {
  id: { isShortId: true, isButton: true },
  created_at: { isDate: true }
}

const EntityContent = (props) => {
  const list = props.entity
  const entityType = props.type

  const dispatch = useDispatch()
  const [entityId, setEntityId] = useState('')

  const { entity, entityLoading, entityError } = useSelector((state) => ({
    entity: state.entities[entityType].entity || null,
    entityLoading: state.entities[entityType].entityLoading,
    entityError: state.entities[entityType].entityError
  }))

  const entityOpen = (id) => {
    setEntityId(id)
    if (id) dispatch(getEntities(entityType, id))
  }

  useEffect(() => {
    if (!entityId && props.entity && props.entity.length) {
      entityOpen(props.entity[0].id)
    }
  }, [props.entity])

  //console.log(`SideList: entityType=${entityType}, entity`, entity);

  if (!list || !list.length) return null
  const items = list.map((item) => ({
    ...item,
    onPress: () => {
      entityOpen(item.id)
    }
  }))

  return (
    <div className='d-flex flex-row'>
      <div className='pe-3 ps-3' style={{ maxHeight: 400, overflow: 'auto' }}>
        <div>
          {items.map((listItem, rank) => (
            <div
              key={`${listItem.id}-${rank}`}
              onClick={listItem.onPress}
              className='mb-3 cursor-pointer'
            >
              <small className='text-primary'>
                {model.fieldValueToText('id', listItem, fieldsDescription)}
              </small>
              <br />
              <small className='text-muted'>
                {model.fieldValueToText(
                  'created_at',
                  listItem,
                  fieldsDescription
                )}
              </small>
            </div>
          ))}
        </div>
      </div>
      {entity && (
        <div
          className='pricing-box ribbon-box right card ms-4'
          style={{ height: 'max-content' }}
        >
          <div className='bg-light m-2 p-4 card-body'>
            {entityType === Client.ENTITY_TYPE.APPLICATIONS ? (
              <Application entity={entity} />
            ) : entityType === Client.ENTITY_TYPE.LOANS ? (
              <Loan entity={entity} />
            ) : entityType === Client.ENTITY_TYPE.PAYMENTS ? (
              <Transaction entity={entity} />
            ) : null}
          </div>
          <Code id={entityId} />
        </div>
      )}
    </div>
  )
}

export default EntityContent
