import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from 'reactstrap'
import { AskAboutAction } from '../AskAboutAction'
import { useReloadContractMutation } from 'src/services/loans'

export default function ReloadContract(props: { loanId: string }) {
  const { loanId } = props
  const { t } = useTranslation()
  const [showReload, setShowReload] = useState(false)
  const toggle = useCallback(() => setShowReload((prev) => !prev), [])

  const [
    reloadContract,
    {
      isSuccess: isReloadContractSuccess,
      error: reloadContractError,
      isLoading: isReloadContractLoading
    }
  ] = useReloadContractMutation()

  const handleReload = async () => {
    await reloadContract({ loanId: loanId })
      .unwrap()
      .then(() => toggle())
  }

  return (
    <>
      <Button
        size='sm'
        color='primary'
        style={{ marginTop: '10px', marginBottom: '5px', marginLeft: '10px' }}
        onClick={() => setShowReload(true)}
        disabled={isReloadContractLoading}
      >
        {t('Reload contract')}
      </Button>
      {showReload && (
        <AskAboutAction
          cancelAction={() => setShowReload(false)}
          sureAction={handleReload}
          isOpen={showReload}
          toggle={toggle}
          title={t('Reload contract')}
        />
      )}
      {isReloadContractSuccess && (
        <Alert color='success'>
          <strong> {t('Operation success')}</strong>
        </Alert>
      )}
      {reloadContractError && 'status' in reloadContractError && (
        <Alert color='warning'>
          <strong>{JSON.stringify(reloadContractError.data)}</strong>
        </Alert>
      )}
    </>
  )
}
