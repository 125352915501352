import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'

import {
  ENABLE_ACTIONS,
  ENABLE_DELETE,
  TABLE_HEADERS
} from './constants/queues.constants'
import {
  useDeleteQueueMutation,
  useGetQueuesQuery
} from '../../../services/calling'

export const Queues = () => {
  const { t } = useTranslation()

  const [params, _setParams] = useState({ keyword: '', offset: 0, limit: 50 })

  const {
    data: queues,
    error: queuesError,
    isLoading,
    isFetching
  } = useGetQueuesQuery(params)

  const [deleteQueue, { isLoading: isDeleteLoading }] = useDeleteQueueMutation()

  const archive = async (settingsId: number) => {
    await deleteQueue(settingsId)
  }

  const title = t('Queues')
  document.title = title

  const metaBar = <MetaBar createLink={'/queues-create'} />

  if (queuesError) {
    return <ErrorPage title={title} error={queuesError} />
  }

  if (isLoading || isDeleteLoading || isFetching) {
    return <LoadingPage title={title} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <div className='table-responsive table-card mt-4'>
              <Table hover className='table-sm align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th />
                    {Object.keys(TABLE_HEADERS).map((header) => (
                      <th className='ps-3' key={header} scope='col'>
                        {t(TABLE_HEADERS[header].label || header)}
                      </th>
                    ))}
                    {ENABLE_ACTIONS && <th scope='col'>{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {queues &&
                    queues.length > 0 &&
                    queues.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/queues-view?id=${entity.id}`}
                              className='fw-medium'
                            >
                              <i className='ri-eye-line fs-15' />
                            </Link>
                          </td>
                          {Object.keys(TABLE_HEADERS).map((fieldName) => {
                            if (fieldName === 'id')
                              return (
                                <td className='ps-3' key={fieldName}>
                                  {entity.id}
                                </td>
                              )
                            return (
                              <td
                                className='ps-3'
                                key={fieldName}
                                style={{ whiteSpace: 'normal' }}
                              >
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  TABLE_HEADERS
                                )}
                              </td>
                            )
                          })}
                          {ENABLE_ACTIONS && (
                            <td>
                              <div className='hstack gap-3 flex-wrap'>
                                <Link
                                  to={`/queues-edit?id=${entity.id}`}
                                  className='link-success fs-15'
                                >
                                  <i className='ri-edit-2-line'></i>
                                </Link>
                                {ENABLE_DELETE && (
                                  <Link
                                    to='#'
                                    onClick={() => archive(entity.id)}
                                    className='link-danger fs-15'
                                  >
                                    <i className='ri-delete-bin-line'></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
