import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner
} from 'reactstrap'
// Formik validation
import { useFormik } from 'formik'
import {
  ExtensionSettingsKeys,
  useCreateExtensionSettingsMutation,
  useGetExtensionSettingsByIdQuery,
  useUpdateExtensionSettingsByIdMutation
} from '../../../services/external-services-settings'

const boolean_options = [
  { name: 'true', defaultValue: 1 },
  { name: 'false', defaultValue: 0 }
]
type InputInitialsSettings = {
  label: string
  initial: string | number
  placeholder: string
  options?: typeof boolean_options
}
type InputKeys =
  | Extract<ExtensionSettingsKeys, 'enabled' | 'name'>
  | 'subtract_when_issuing'
  | 'amount'
type FormValues = Record<InputKeys, string>

const inputInitials: Record<InputKeys, InputInitialsSettings> = {
  name: { label: 'name', initial: 'Call4Life', placeholder: 'ex: Call4Life' },
  enabled: {
    label: 'enabled',
    initial: 'true',
    placeholder: '',
    options: boolean_options
  },
  subtract_when_issuing: {
    label: 'subtract_when_issuing',
    initial: 'true',
    placeholder: '',
    options: boolean_options
  },
  amount: { label: 'amount', initial: 1000, placeholder: 'ex: 1000' }
}
const inputKeys = Object.keys(inputInitials) as InputKeys[]

export const ExternalServicesSettingsEdit = () => {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')
  const {
    data: entity,
    isLoading,
    isError,
    error
  } = useGetExtensionSettingsByIdQuery(id, {
    skip: !id
  })
  const [
    updateExternalServicesSettings,
    { isLoading: isUpdateLoading, error: updateError }
  ] = useUpdateExtensionSettingsByIdMutation()
  const [
    createExternalServicesSettings,
    { isLoading: isCreateLoading, error: createError }
  ] = useCreateExtensionSettingsMutation()
  const [initialFormValues, setInitialFormValues] = useState<
    Partial<FormValues>
  >({})

  useEffect(() => {
    if (id && !isLoading && entity) {
      const generatedInitialValues = inputKeys.reduce<Partial<FormValues>>(
        (acc, key) => {
          acc[key] =
            entity && entity[key] !== null && entity[key] !== undefined
              ? entity[key]?.toString()
              : ''
          return acc
        },
        {}
      )

      setInitialFormValues(generatedInitialValues)
    }
    if (!id) {
      const generatedInitialValues = Object.entries(inputInitials).reduce<
        Record<string, unknown>
      >((acc, [key, value]) => {
        acc[key] = value.initial
        return acc
      }, {})
      setInitialFormValues(generatedInitialValues)
    }
  }, [entity, id, isLoading])

  const validation = useFormik<Partial<FormValues>>({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: async (values) => {
      const { enabled, ...data } = values
      if (id) {
        const res = await updateExternalServicesSettings({
          id: id,
          enabled: enabled === 'true',
          ...data
        })
        if ('error' in res) console.log(res.error)
      } else
        createExternalServicesSettings({ enabled: enabled === 'true', ...data })
    }
  })

  const title = `${t('External_services_settings')}: ${
    id ? t('Edit') : t('Create')
  }`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color='primary'>{t('Loading...')}</Spinner>
  const metaBar = (
    <MetaBar backLink={'/external-services-settings'} entity={entity} />
  )

  if (isError) {
    return (
      <ErrorPage
        backLink={'/external-services-settings'}
        title={title}
        error={error}
      />
    )
  }

  if (isLoading || (!entity && id)) {
    return (
      <LoadingPage backLink={'/external-services-settings'} title={title} />
    )
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className='needs-validation mt-4'
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                {inputKeys.map((fieldKey) => {
                  const htmlForId = `validation-${fieldKey}`
                  return (
                    <Col className='col-auto' key={`${fieldKey}`}>
                      <FormGroup className='mb-3'>
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        {inputInitials[fieldKey].options &&
                        inputInitials[fieldKey]?.options?.length ? (
                          <select
                            name={`${fieldKey}`}
                            id={htmlForId}
                            className='form-select form-select-sm'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                          >
                            {inputInitials[fieldKey]?.options?.map((option) => (
                              <option
                                key={option.name}
                                defaultValue={option.defaultValue}
                              >
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <Input
                            name={`${fieldKey}`}
                            placeholder={inputInitials[fieldKey].placeholder}
                            type='text'
                            className='form-control form-control-sm'
                            id={htmlForId}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey] || ''}
                            invalid={
                              !!(
                                validation.touched[fieldKey] &&
                                validation.errors[fieldKey]
                              )
                            }
                          />
                        )}
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type='invalid'>
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {!!updateError && (
                <Alert color='warning'>
                  <strong>{t('Something went wrong')}</strong>
                </Alert>
              )}
              {!!createError && (
                <Alert color='warning'>
                  <strong>{t('Something went wrong')}</strong>
                </Alert>
              )}

              {isUpdateLoading || isCreateLoading ? (
                loading
              ) : (
                <Button
                  className='text-nowrap'
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  type='submit'
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
