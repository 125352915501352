import {
  LIST,
  LIST_SUCCESS,
  LIST_FAIL,

  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,

  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,
} from "./actionType";

//get all
export const getExternalServicesSettings = (id = null, filter = '') => ({
  type: LIST,
  id,
  filter,
})

export const getExternalServicesSettingsSuccess = list => ({
  type: LIST_SUCCESS,
  payload: list,
})

export const getExternalServicesSettingsFail = error => ({
  type: LIST_FAIL,
  payload: error,
})

//delete
export const deleteExternalServicesSettings = (id) => ({
  type: DELETE,
  payload: id,
})

export const deleteExternalServicesSettingsSuccess = () => ({
  type: DELETE_SUCCESS,
})

export const deleteExternalServicesSettingsFail = error => ({
  type: DELETE_FAIL,
  payload: error,
})

//update
export const updateExternalServicesSettings = (entity) => ({
  type: UPDATE,
  payload: entity,
})

export const updateExternalServicesSettingsSuccess = (entity) => ({
  type: UPDATE_SUCCESS,
  payload: entity,
})

export const updateExternalServicesSettingsFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})

//create
export const createExternalServicesSettings = (entity) => ({
  type: CREATE,
  payload: entity,
})

export const createExternalServicesSettingsSuccess = () => ({
  type: CREATE_SUCCESS,
})

export const createExternalServicesSettingsFail = error => ({
  type: CREATE_FAIL,
  payload: error,
})
