import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import 'react-perfect-scrollbar/dist/css/styles.css'

import { Container } from 'reactstrap'

import EntityInfo from 'src/Components/Common/EntityInfo'
import Client from 'src/client'
import ErrorPage from '../ErrorPage'

//redux
import { getDialogues } from 'src/store/actions'

export const Dialogues = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [chatClientId, setChatClientId] = useState('')
  const [chatClientName, setChatClientName] = useState('')

  const { chats, listError } = useSelector((state) => ({
    chats: state.dialogues.list,
    listLoading: state.dialogues.listLoading,
    listError: state.dialogues.listError
  }))

  useEffect(() => {
    dispatch(getDialogues())
  }, [dispatch, window.location])

  useEffect(() => {
    if (!chatClientId && chats && chats.length) {
      userChatOpen(chats[0].id)
    }
  }, [chats])

  //Use For Chat Box
  const userChatOpen = (id) => {
    setChatClientId(id)
    if (chats && chats.length) {
      const filtered = chats.filter((ch) => ch.id === id)
      if (filtered.length) setChatClientName(filtered[0].name)
    }
  }

  //serach recent user
  const searchUsers = () => {
    let input, filter, ul, li, a, i, txtValue
    input = document.getElementById('search-user')
    filter = input.value.toUpperCase()
    let userList = document.getElementsByClassName('users-list')
    Array.prototype.forEach.call(userList, function (el) {
      li = el.getElementsByTagName('li')
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName('a')[0]
        txtValue = a.textContent || a.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = ''
        } else {
          li[i].style.display = 'none'
        }
      }
    })
  }

  document.title = t('Dialogues')

  console.log('chats =>', chats)

  if (listError) {
    return <ErrorPage title={t('Dialogues')} error={listError} />
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div className='chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1'>
            <div className='chat-leftsidebar'>
              <div className='px-4 pt-4 mb-4'>
                <div className='d-flex align-items-start'>
                  <div className='flex-grow-1'>
                    <h5 className='mb-4'>{t('Dialogues')}</h5>
                  </div>
                </div>
                <div className='search-box'>
                  <input
                    onKeyUp={searchUsers}
                    id='search-user'
                    type='text'
                    className='form-control bg-light border-light'
                    placeholder={t('search_here')}
                  />
                  <i className='ri-search-2-line search-icon'></i>
                </div>
              </div>

              {/* <PerfectScrollbar className="chat-room-list"> */}
              {/*   <div className="chat-message-list"> */}
              {/*     <ul */}
              {/*       className="list-unstyled chat-list chat-user-list users-list" */}
              {/*       id="userList" */}
              {/*     > */}
              {/*       {(chats || []).map(({ id, name }) => ( */}
              {/*         <li key={id}> */}
              {/*           <Link to="#" onClick={() => userChatOpen(id)}> */}
              {/*             <div className="d-flex align-items-center"> */}
              {/*               <div className="flex-grow-1 overflow-hidden"> */}
              {/*                 <p className="mb-0">{name}</p> */}
              {/*               </div> */}
              {/*             </div> */}
              {/*           </Link> */}
              {/*         </li> */}
              {/*       ))} */}
              {/*     </ul> */}
              {/*   </div> */}
              {/* </PerfectScrollbar> */}
            </div>
            <EntityInfo
              entityId={chatClientId}
              entityType={Client.ENTITY_TYPE.DIALOGUES}
              fullscreen
              clientName={chatClientName}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
