import { api, omitBlankEntries } from './api'
import {
  ApplicationEntity,
  ApplicationFilters,
  LoanApplication
} from '../pages/Admin/Applications/types/applications.types'

type ApplicationVerification = {
  id: string
  request_id: string
  process: {
    pass: unknown[]
    requirement: unknown[]
    result: unknown[]
  }
}

type ApplicationsTabs = {
  id: string | number
  company: string
  created_at: string
}

export const {
  useGetApplicationsQuery,
  useGetSingleApplicationQuery,
  useGetApplicationVerificationQuery,
  useGetBluredOtpMutation,
  useGetApplicationsKIQuery,
  useGetApplicationsKIReportMutation,
  useGetApplicationsUPRIDQuery,
  useGetApplicationsUPRIDReportMutation,
  useApplicationAsFraudulentMutation
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplications: builder.query<
      ApplicationEntity[],
      Partial<ApplicationFilters> & { offset: number; limit: number }
    >({
      query: (params) => ({
        url: `crm/v1/applications`,
        params
      })
    }),
    getSingleApplication: builder.query<LoanApplication, string>({
      query: (id) => `crm/v1/applications/${id}`
    }),
    applicationAsFraudulent: builder.mutation<Record<string, boolean>, string>({
      query: (id) => ({
        url: `/crm/v1/applications/${id}/fraud`,
        method: 'POST'
      })
    }),
    getApplicationVerification: builder.query<ApplicationVerification, string>({
      query: (id) => `crm/v1/applications/${id}/verification`
    }),

    getBluredOtp: builder.mutation<any, any>({
      query: ({ request_id }) => ({
        url: `crm/v1/applications/${request_id}/otp`,
        method: 'GET'
      })
    }),

    getApplicationsKI: builder.query<ApplicationsTabs[], string>({
      query: (id) => `/crm/v1/applications/${id}/ki-requests`
    }),
    getApplicationsKIReport: builder.mutation<any, any>({
      query: (params) => ({
        url: `/crm/v1/applications/ki-report`,
        method: 'GET',
        params: omitBlankEntries(params),
        headers: {
          'Content-Type': 'text/plain; charset=UTF-8'
        },
        responseHandler: (response) => response.text()
      })
    }),
    getApplicationsUPRID: builder.query<ApplicationsTabs[], string>({
      query: (id) => `/crm/v1/applications/${id}/uprid-requests`
    }),
    getApplicationsUPRIDReport: builder.mutation<any, any>({
      query: (params) => ({
        url: `/crm/v1/applications/uprid-result`,
        method: 'GET',
        params: omitBlankEntries(params)
      })
    })
  })
})
