import { useCallback, useState } from 'react'

export const useDisclosure = (
  initialState?: boolean,
  options?: {
    onOpen?: (state: boolean) => any
    onClose?: (state: boolean) => any
  }
) => {
  const [isOpen, setIsOpen] = useState(initialState)

  const open = useCallback(() => {
    setIsOpen(true)
    options?.onOpen?.(true)
  }, [options])

  const close = useCallback(() => {
    setIsOpen(false)
    options?.onClose?.(false)
  }, [options])

  const toggle = useCallback(
    () => (isOpen ? close() : open()),
    [close, isOpen, open]
  )

  return { isOpen, open, close, toggle }
}
