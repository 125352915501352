import Table4Columns from './Table4Columns'

const columnsDescriptionDefault = [
  ['status'],
  ['utm_source'],
  ['utm_medium'],
  ['utm_campaign'],
]

const fieldsDescription = {
  status: {
    fieldName: 'loan_result',
    mappingValue: (value) => {
      return value === '1'
        ? 'pending'
        : value === '2'
        ? 'positive_postback'
        : value === '3'
        ? 'negative_postback'
        : '-'
    },
    mappingClassName: (value) => {
      return value === '2' ? 'text-success' : value === '3' ? 'text-danger' : ''
    },
  },
}

const EntityContent = ({ entity, columnsDescription }) => {
  return (
    <Table4Columns
      columnsDescription={columnsDescription || columnsDescriptionDefault}
      fieldsDescription={fieldsDescription}
      className="table-sm align-middle mb-0"
      style={{ width: 'fit-content' }}
      entity={entity.lead_data || {}}
    />
  )
}

export default EntityContent
