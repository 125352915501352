import { api, omitBlankEntries } from './api'

type Promocode = {
  id: number
  promocode: string
  sale: number
  begin_date: string
  end_date: string
  limitless: boolean
  available_usages: number | null
}

const promocodesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromocodes: builder.query<
      Promocode[],
      {
        limit: number
        offset: number
      }
    >({
      query: (params) => ({ url: 'crm/v1/promocodes', params }),
      providesTags: (result = []) => [
        // because backend's id is a number and rtkq compares ids strictly (===)
        // and we only using it in URL query it's easier to store it as a string
        ...result.map(
          ({ id }) => ({ type: 'Promocodes', id: `${id}` }) as const,
        ),
        { type: 'Promocodes' as const, id: 'LIST' },
      ],
    }),
    getPromocode: builder.query<Promocode, number | string>({
      query: (id) => `crm/v1/promocodes/${id}`,
      providesTags: (_result, _err, id) => [
        { type: 'Promocodes', id: `${id}` },
      ],
    }),
    updatePromocode: builder.mutation<void, Partial<Promocode>>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/promocodes/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: (_result, _err, { id }) => [
        { type: 'Promocodes', id: `${id}` },
      ],
    }),
    addPromocode: builder.mutation<void, Partial<Promocode>>({
      query: (body) => ({
        url: `crm/v1/promocodes`,
        method: 'POST',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: () => [{ type: 'Promocodes', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetPromocodesQuery,
  useGetPromocodeQuery,
  useUpdatePromocodeMutation,
  useAddPromocodeMutation,
} = promocodesApi
