import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import List from 'src/Components/Common/EntitiesView/List'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  FormFeedback
} from 'reactstrap'
import { useFormik } from 'formik'
import { mapBooleanStringToBoolean, noop } from 'src/utils'
import * as Yup from 'yup'
import { DangerAlert } from '../ErrorPage'
import {
  PropsWithDisclosure,
  useModalDisclosureContext
} from 'src/ModalDisclosureProvider'
import {
  Phone,
  useAddPhoneMutation,
  useGetPhonesQuery,
  useUpdatePhoneMutation
} from 'src/services/phones'
import { toast } from 'react-toastify'
import WebPhoneUI from 'src/Components/WebPhone/ui/WebPhoneUI'
import { Loan } from 'src/services/loans'
import { Profile } from 'src/services/profiles'
import { LIST_LIMIT } from 'src/client'

export const CallFeedbacks = ({
  phonePrefix,
  clientId,
  open,
  setPhone
}: {
  phonePrefix?: Loan['phone_prefix']
  clientId?: Profile['client']['id']
  open: () => void
  setPhone: (phone: string) => void
}) => {
  const modalContext = useModalDisclosureContext()
  const { t } = useTranslation()
  const { data: list, error } = useGetPhonesQuery({
    client_id: clientId,
    limit: LIST_LIMIT,
    offset: 0
  })

  const [updateFeedback] = useUpdatePhoneMutation()

  const [addFeedback] = useAddPhoneMutation()

  const handleEditCallFeedback = async (
    feedback: NonNullable<typeof list>[0]
  ) => {
    try {
      const values = await modalContext.open<
        Pick<Phone, 'phone' | 'comment' | 'dont_call'>
      >((props) => (
        <>
          <ModalHeader toggle={props.reject}>{t('Edit')}</ModalHeader>
          <ModalBody>
            <EditCallFeedbackForm feedback={feedback} {...props} />
          </ModalBody>
        </>
      ))
      try {
        await updateFeedback({
          oldNumber: feedback?.phone!,
          newNumber: values.phone,
          comment: values.comment,
          dont_call: mapBooleanStringToBoolean(values.dont_call),
          client_id: clientId
        }).unwrap()
        toast.success(t('Successfully updated'), {
          autoClose: 2000,
          closeOnClick: false
        })
      } catch (error) {
        toast.error(t('Error updating'), {
          autoClose: 2000,
          closeOnClick: false
        })
      }
    } catch (error) {
      // noop
    }
  }

  const handleAddCallFeedback = async () => {
    try {
      const values = await modalContext.open<
        Pick<Phone, 'phone' | 'comment' | 'dont_call'>
      >((props) => (
        <>
          <ModalHeader toggle={props.reject}>{t('Create')}</ModalHeader>
          <ModalBody>
            <EditCallFeedbackForm {...props} />
          </ModalBody>
        </>
      ))

      try {
        await addFeedback({
          phone: values.phone,
          comment: values.comment,
          dont_call: mapBooleanStringToBoolean(values.dont_call)!,
          client_id: clientId!
        }).unwrap()
        toast.success(t('Successfully added'), {
          autoClose: 2000,
          closeOnClick: false
        })
      } catch (error) {
        toast.error(t('Error adding'), {
          autoClose: 2000,
          closeOnClick: false
        })
      }
    } catch (error) {
      // noop
    }
  }

  const handleOpenWebphoneClick = (phone: string) => {
    setPhone(phone)
    open()
    modalContext
      .open((props) => (
        <WebPhoneUI
          {...props}
          numberFromLoan={`${phonePrefix || ''}${phone}`}
        />
      ))
      .catch(noop)
  }

  return (
    <div>
      <Button onClick={handleAddCallFeedback}>{t('Add')}</Button>
      {error && <DangerAlert error={error} />}
      {list?.length ? (
        <List
          list={list}
          control={(item: (typeof list)[0]) => (
            <td className='d-flex flex-row gap-2'>
              <button
                onClick={() => handleOpenWebphoneClick(item.phone)}
                className='btn text-success p-0'
                disabled={item.dont_call}
              >
                <i className='ri-phone-fill webphone-icon fs-22' />
              </button>
            </td>
          )}
          fieldsDescription={{
            phone: {
              label: 'phone',
              isButton: true,
              onClick: handleEditCallFeedback
            },
            comment: { label: 'comment' },
            created_at: { label: 'created_at', isDate: true },
            updated_at: { label: 'updated_at', isDate: true },
            dont_call: {
              label: 'Do not call',
              mappingValue: (value: boolean) => t(`${value}`)
            }
          }}
        />
      ) : (
        <div style={{ marginBottom: '10px' }}>
          <dl className='row mb-0'>
            <small>{t('No data')}</small>
          </dl>
        </div>
      )}
    </div>
  )
}

const EditCallFeedbackForm: FC<
  PropsWithDisclosure<{
    feedback?: Phone
  }>
> = ({ feedback, ...props }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: '',
      comment: '',
      dont_call: false,
      ...feedback
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .matches(/^[0-9]{11}$/, 'Phone number must be exactly 10 digits')
        .required(t('Field is required')),
      comment: Yup.string().optional().nullable(),
      dont_call: Yup.boolean()
        .oneOf([true, false], t('Select true or false'))
        .required(t('Field is required'))
    }),
    onSubmit: props.resolve
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Label for='phone'>{t('phone')}</Label>
        <Input
          id='phone'
          name='phone'
          type='text'
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.phone}
        />
        <FormFeedback>{formik.errors.phone}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for='comment'>{t('Comment')}</Label>
        <Input
          id='comment'
          name='comment'
          type='textarea'
          rows={7}
          value={formik.values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.comment}
        />
        <FormFeedback>{formik.errors.comment}</FormFeedback>
      </FormGroup>

      <FormGroup className='d-flex gap-2'>
        <Label for='dont_call'>{t('Do not call')}</Label>
        <Input
          id='dont_call'
          name='dont_call'
          type='checkbox'
          checked={formik.values.dont_call}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.dont_call}
        />
        <FormFeedback>{formik.errors.dont_call}</FormFeedback>
      </FormGroup>
      <Button type='submit' color='primary' block>
        {t('Save')}
      </Button>
    </Form>
  )
}
