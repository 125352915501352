import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardBody,
  Input,
  Form,
  Row,
  Label,
  Spinner,
  Alert,
  FormFeedback,
  FormGroup,
  Col,
} from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'

import Client from 'src/client'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { typeTemplates } from './index'
import { useGetCompanyNamesQuery } from 'src/services/companies'
import { useGetTemplateVariablesQuery } from 'src/services/templates'

const inputInitials = {
  template: {
    label: 'template',
    inputType: 'textarea',
    initial: '',
    options: ['sms', 'phonerobot', 'chat'],
  },
  text_template: {
    label: 'text template',
    inputType: 'textarea',
    initial: '',
    options: ['email'],
  },
  html_template: {
    label: 'html template',
    inputType: 'textarea',
    initial: '',
    options: ['email'],
  },
  subject: {
    label: 'subject',
    initial: '',
    options: ['email'],
  },
  preferable_sender_id: {
    label: 'preferable_sender_id',
    initial: null,
    options: ['sms', 'phonerobot', 'chat', 'email'],
  },
  is_service: {
    label: 'is_service',
    initial: null,
    options: ['sms', 'phonerobot', 'chat', 'email'],
  },
}

export const TemplateEdit = () => {
  const { t } = useTranslation()
  const [id, setId] = useState(null)
  const [type, setType] = useState(null)
  const [state, setState] = useState([])
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const [paramError, setParamError] = useState(null)

  const { data: companyNames } = useGetCompanyNamesQuery({})
  const { data: templateVariables } = useGetTemplateVariablesQuery({})

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    const type = params.get('type')
    setId(id || null)
    setType(type || null)
    if (id) {
      // edit mode
      Client.getTemplate(type, id)
        .then((data) => setState(data))
        .catch((err) => setParamError(err))
        .finally(() => setPageReady(false))
    } else {
      // create mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] = inputInitials[key].initial
      })
      setInitialFormValues(initialFormValues)
      setPageReady(true)
      setType('sms')
    }
  }, [window.location])

  useEffect(() => {
    if (id && state) {
      //edit mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] =
          state[key] !== null && state[key] !== undefined
            ? state[key].toString()
            : null
      })
      setInitialFormValues(initialFormValues)
      setPageReady(true)
    }
  }, [state])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      if (values.is_service === '-') values.is_service = null
      if (values.is_service === 'true') values.is_service = true
      if (values.is_service === 'false') values.is_service = false

      if (values.preferable_sender_id === 0) values.preferable_sender_id = null
      if (id) {
        Client.updateTemplate(type, id, { ...values })
          .then(() => setSuccessful(true))
          .catch((err) => {
            setSuccessful(false)
            setParamError(err)
          })
      } else {
        Client.createTemplate(type, { ...values })
          .then(() => setSuccessful(true))
          .catch((err) => {
            setSuccessful(false)
            setParamError(err)
          })
      }
    },
  })

  const title = `${t('templates')}: ${id ? t('Edit') : t('Create')}`
  document.title = title
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>

  return (
    <div className="page-content">
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar backLink={'/strategy/templates'} entity={state} />
          <Form
            className="needs-validation mt-4"
            onSubmit={(e) => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            {id ? null : (
              <FormGroup>
                <Label for={'validation-type'}>{t('type')}</Label>
                <Input
                  id="validation-type"
                  name="type"
                  type="select"
                  onChange={(e) => setType(e.target.value)}
                  defaultValue={'sms'}
                  data-testid="template-edit-select"
                >
                  {typeTemplates.map((act) => {
                    return <option key={act}>{act}</option>
                  })}
                </Input>
              </FormGroup>
            )}
            <Row>
              {Object.keys(inputInitials).map((fieldKey, i) => {
                const htmlForId = `validation-${fieldKey}`
                const optionsValue = inputInitials[fieldKey].options
                if (optionsValue?.includes(type)) {
                  if (inputInitials[fieldKey].label === 'is_service') {
                    return (
                      <FormGroup className="mb-3" key={`${fieldKey}`}>
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        <select
                          name={`${fieldKey}`}
                          className="form-control form-control-sm"
                          id={htmlForId}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values[fieldKey] || '-'}
                          invalid={
                            !!(
                              validation.touched[fieldKey] &&
                              validation.errors[fieldKey]
                            )
                          }
                          data-testid={`template-edit-${fieldKey}`}
                        >
                          <option value="-">-</option>
                          <option value={true}>{t('yes')}</option>
                          <option value={false}>{t('no')}</option>
                        </select>
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type="invalid">
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    )
                  }
                  if (
                    inputInitials[fieldKey].label === 'preferable_sender_id'
                  ) {
                    return (
                      <FormGroup className="mb-3" key={`${fieldKey}`}>
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        <select
                          name={`${fieldKey}`}
                          className="form-control form-control-sm"
                          id={htmlForId}
                          onChange={(e) =>
                            validation.setFieldValue(
                              fieldKey,
                              Number(e.target.value),
                            )
                          }
                          onBlur={validation.handleBlur}
                          value={validation.values[fieldKey] || '-'}
                          data-testid={`template-edit-${fieldKey}`}
                        >
                          <option value="0">-</option>
                          {companyNames?.map((company) => (
                            <option value={company.id}>
                              {company.short_name}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    )
                  }
                  return (
                    <FormGroup className="mb-3" key={`${fieldKey}`}>
                      <Label htmlFor={htmlForId}>
                        {t(inputInitials[fieldKey].label)}
                      </Label>
                      <Input
                        name={`${fieldKey}`}
                        type={inputInitials[fieldKey].inputType || 'text'}
                        className="form-control form-control-sm"
                        id={htmlForId}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values[fieldKey] || ''}
                        invalid={
                          !!(
                            validation.touched[fieldKey] &&
                            validation.errors[fieldKey]
                          )
                        }
                        data-testid={`template-edit-${fieldKey}`}
                      />
                      {validation.touched[fieldKey] &&
                      validation.errors[fieldKey] ? (
                        <FormFeedback type="invalid">
                          {validation.errors[fieldKey]}
                        </FormFeedback>
                      ) : null}
                      {inputInitials[fieldKey].label === 'template' && (
                        <div className="mt-2">
                          <Col>{t('Available Variables')}:</Col>
                          {templateVariables &&
                            Object.keys(templateVariables).map((fieldName) => (
                              <Col>
                                {fieldName}: {templateVariables[fieldName]}
                              </Col>
                            ))}
                          <span>
                            {t("Don't forget to keep the curly braces")}
                          </span>
                        </div>
                      )}
                    </FormGroup>
                  )
                }
              })}
            </Row>
            {!!paramError && (
              <Alert color="warning">
                <strong> {paramError.toString()} </strong>
              </Alert>
            )}
            {successful && (
              <Alert color="success">
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            {!pageReady ? (
              loading
            ) : (
              <Button
                className="text-nowrap"
                color="primary"
                style={{ backgroundColor: '#405189' }}
                type="submit"
                data-testid="template-edit-submit"
              >
                {id ? t('Update') : t('Create')}
              </Button>
            )}
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
