import {
  UnionClientEntity,
  useUpdateClientAddressMutation,
  useUpdateClientDocumentsMutation,
  useUpdateClientMutation,
  useUpdateClientWorkMutation,
  WorkDataType
} from '../../../../services/clients'
import { useCallback } from 'react'
import model from '../../../../model'
import { Addresses } from '../Edit'

type HandleSubmitArgs = {
  addresses: Addresses
  id: string | null
  values: Partial<UnionClientEntity>
  valueError: {
    fact_address: boolean
    reg_address: boolean
    work_address: boolean
  }
}

const removeFromForm = [
  'middle_name',
  'birth_date',
  'birth_place',
  'first_name',
  'last_name',
  'snils',
  'typework',
  'work_address',
  'occupation',
  'salary',
  'extsalary',
  'work_phone'
]

const useGetClientUpdate = () => {
  const [
    updateClient,
    {
      isLoading: isClientUpdateLoading,
      error: updateClientError,
      isSuccess: clientSuccess
    }
  ] = useUpdateClientMutation()
  const [
    updateWork,
    {
      isLoading: isUpdateWorkLoading,
      error: updateWorkError,
      isSuccess: workSuccess
    }
  ] = useUpdateClientWorkMutation()
  const [
    updateAddress,
    {
      isLoading: isUpdateAddressLoading,
      error: updateAddressError,
      isSuccess: addressSuccess
    }
  ] = useUpdateClientAddressMutation()
  const [
    updateDocuments,
    {
      isLoading: isUpdateDocumentsLoading,
      error: updateDocumentsError,
      isSuccess: documentsSuccess
    }
  ] = useUpdateClientDocumentsMutation()

  const handleSubmit = useCallback(
    ({ values, id, addresses, valueError }: HandleSubmitArgs) => {
      if (!id) return
      const updatedClient = { id: id, ...values }
      updatedClient.gender = model.getGenderCode(updatedClient.gender)
      const workData: WorkDataType = {
        client_id: id,
        typework: updatedClient.typework,
        placework: updatedClient.work_address,
        occupation: updatedClient.occupation,
        salary: Number(updatedClient.salary),
        phonework: updatedClient.work_phone
      }

      if (updatedClient.extsalary) {
        workData.extsalary = Number(updatedClient.extsalary)
      }

      const documentsData = {
        client_id: id,
        snils: updatedClient.snils,
        inn: updatedClient.inn
      }

      const addressData = {
        client_id: id,
        addresses: {
          fact_address: addresses?.fact_address.data,
          reg_address: addresses?.reg_address.data,
          work_address: addresses?.work_address.data
        },
        addresses_label: {
          fact_address: addresses?.fact_address.value,
          reg_address: addresses?.reg_address.value,
          work_address: addresses?.work_address.value
        }
      }

      const form = document.getElementById('formElem') as HTMLFormElement | null
      if (!form) return
      const formData = new FormData(form)
      formData.set('client_id', updatedClient.id || '')
      formData.set('name', updatedClient.first_name || '')
      formData.set('surname', updatedClient.last_name || '')
      formData.set('midname', updatedClient.middle_name || '')
      formData.set('docdate', `${updatedClient.docdate}`)
      formData.set('birthdate', `${updatedClient.birth_date}`)
      formData.set('birthplace', `${updatedClient.birth_place}`)

      removeFromForm.forEach((key) => formData.delete(key))
      if (
        !valueError.fact_address &&
        !valueError.reg_address &&
        !valueError.work_address
      ) {
        void Promise.all([
          updateClient(formData),
          updateAddress(addressData),
          updateDocuments(documentsData),
          updateWork(workData)
        ])
      }
    },
    [updateAddress, updateClient, updateDocuments, updateWork]
  )
  const updateError =
    updateAddressError ||
    updateDocumentsError ||
    updateWorkError ||
    updateClientError
  const updateLoading =
    isClientUpdateLoading ||
    isUpdateAddressLoading ||
    isUpdateWorkLoading ||
    isUpdateDocumentsLoading
  const isUpdateSuccess =
    clientSuccess && workSuccess && documentsSuccess && addressSuccess

  return { handleSubmit, updateError, updateLoading, isUpdateSuccess }
}

export default useGetClientUpdate
