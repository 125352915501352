import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Client from '../../../client'
import Loader from '../Loader'
import { Alert, Button } from 'reactstrap'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const EntityContent = ({ entityId, visible }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [tags, setTags] = useState([])
  const [selected, setSelected] = useState([])
  const [tagsSuccess, setTagsSuccess] = useState(false)
  const [tagsError, setTagsError] = useState()

  const fetchTags = async () => {
    setIsLoading(true)
    try {
      const res = await Client.getLoansTags()
      console.log('getLoansTags', res)
      setTags(res)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      fetchTags()
    }
  }, [visible])

  const setMark = () => {
    Client.setLoansTagsByID(entityId, selected)
      .then((res) => res.successful && setTagsSuccess(true))
      .catch((err) => setTagsError(err))
  }

  const removeMark = () => {
    Client.removeLoansTagsByID(entityId, selected)
  }

  useEffect(() => {
    console.log(selected, '!!!!!')
  }, [selected])

  return (
    <div>
      {error && <DangerAlert error={error} />}
      {isLoading && <Loader />}

      {!isLoading && (
        <div className="d-flex flex-column gap-2">
          <select
            className="form-select form-select-sm"
            style={{ width: '40%', textOverflow: 'ellipsis' }}
            defaultValue={selected}
            onChange={(e) => {
              if (Number(e.target.value) === 0) {
                setSelected([])
              } else {
                setSelected([Number(e.target.value)])
              }
            }}
          >
            <option value={''}></option>
            {tags.map((item) => (
              <option key={item.id} value={item.id}>
                {item.tag}
              </option>
            ))}
          </select>
          <div className="d-flex gap-3">
            <Button
              size="sm"
              onClick={setMark}
              disabled={selected.length === 0}
            >
              {t('Save')}
            </Button>
            <Button
              size="sm"
              onClick={removeMark}
              color="danger"
              disabled={selected.length === 0}
            >
              {t('Delete')}
            </Button>
          </div>
          {tagsSuccess && <Alert>{t('Done')}</Alert>}
          {tagsError && <Alert>{tagsError}</Alert>}
        </div>
      )}
    </div>
  )
}

export default EntityContent
