import { createSlice } from '@reduxjs/toolkit'
import { api, omitBlankEntries } from './api'

type User = {
  id: string
  username: string
  surname: string
  name: string
  midname: string
  state: string | null
  blocked_cause: string | null
  phone: string | null
  email: string | null
  company: string | null
}

type Role = {
  id: string
  name: string
  path: string
  subGroups: []
}

type UserInfo = {
  id: string
  status: string
  username: string
  surname: string
  name: string
  midname: string
  roles: string[]
  permissions: string[]
  asterisk: {
    login: string
    password: string
    url: string
  }
  last_login: string
}

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useGetUsersQuery,
  useDownloadUsersMutation,
  useGetRolesQuery,
  useAddUserToRoleMutation,
  useRemoveUserFromRoleMutation,
  useGetUserInfoQuery,
  useGetUserStatusesQuery,
  useSetUserStatusMutation,
  useGetPermissionsQuery,
  useGetUserPermissionsQuery,
  useUpdateUserPermissionsMutation,
  endpoints
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      User[],
      (Partial<User> & { offset?: number; limit?: number }) | void
    >({
      query: (params) => ({
        url: '/crm/v1/keycloak/users',
        params: omitBlankEntries(params)
      }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'UserItem', id }) as const),
        { type: 'UserItem' as const, id: 'LIST' }
      ]
    }),
    downloadUsers: builder.mutation<
      null,
      (Partial<User> & { offset?: number; limit?: number }) | void
    >({
      queryFn: async (params, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: '/crm/v1/keycloak/users/csv',
          params: omitBlankEntries(params),
          responseHandler: (response) => response.blob()
        })
        const hiddenElement = document.createElement('a')
        const url = window.URL || window.webkitURL
        const blobCSV = url.createObjectURL(result.data)
        hiddenElement.href = blobCSV
        hiddenElement.target = '_blank'
        hiddenElement.download = `users.csv`
        hiddenElement.click()
        hiddenElement.remove()

        return { data: null }
      }
    }),
    getUser: builder.query<User, string | number>({
      query: (id) => ({
        url: `crm/v1/keycloak/users/${id}/detailed`
      }),
      providesTags: (_result, _err, id) => [
        { type: 'Claims', id: id.toString() }
      ]
    }),
    createUser: builder.mutation<void, Partial<User>>({
      query: (body) => ({
        url: `crm/v1/keycloak/user`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: () => ['UserItem']
    }),
    // getRoles instead of getGroups because:
    // 1. calling.ts already has getGroups
    // 2. /keycloak/groups referred as roles
    getRoles: builder.query<Role[], void>({
      query: () => '/crm/v1/keycloak/groups',
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Roles', id }) as const),
        { type: 'Roles' as const, id: 'LIST' }
      ]
    }),
    removeUserFromRole: builder.mutation<
      void,
      { name: Role['name']; username: User['username'] }
    >({
      query: ({ name, username }) => ({
        url: `/crm/v1/keycloak/groups/${name}/remove-user`,
        method: 'DELETE',
        body: { username }
      }),
      invalidatesTags: () => ['UserPermission']
    }),
    addUserToRole: builder.mutation<
      void,
      { name: Role['name']; username: User['username'] }
    >({
      query: ({ name, username }) => ({
        url: `/crm/v1/keycloak/groups/${name}/add-user`,
        method: 'POST',
        body: { username }
      }),
      invalidatesTags: () => ['UserPermission']
    }),
    getUserInfo: builder.query<UserInfo, void>({
      query: () => 'crm/v1/keycloak/users/info',
      providesTags: () => ['User']
    }),
    getUserStatuses: builder.query<string[], void>({
      query: () => 'crm/v1/keycloak/users/statuses',
      transformResponse: ({ result }) => result
    }),
    setUserStatus: builder.mutation<string, void>({
      query: (status) => ({
        url: 'crm/v1/keycloak/users/status',
        method: 'POST',
        body: { status }
      }),
      invalidatesTags: () => ['User']
    }),
    getPermissions: builder.query<{ [key in string]: number }, void>({
      query: () => '/crm/v1/keycloak/existing-permissions'
    }),
    // { crm_api: [], verifications: [ 'PERMISSION_1', 'PERMISSION_2' ]}
    getUserPermissions: builder.query<
      { [key in string]: string[] },
      string | number
    >({
      query: (id) => `/crm/v1/keycloak/users/${id}/permissions`,
      providesTags: () => ['UserPermission']
    }),
    updateUserPermissions: builder.mutation<
      string[],
      { username: User['username']; permissions: string[] }
    >({
      query: ({ username, permissions }) => ({
        url: `/crm/v1/keycloak/users/${username}/add-permissions`,
        method: 'POST',
        body: { permissions }
      }),
      invalidatesTags: () => ['UserPermission']
    })
  })
})

// @deprecated use RTKQ hooks instead of useSelector(state => state.Login...)
export const { reducer: authReducer } = createSlice({
  name: 'auth',
  initialState: { userInfo: null } as { userInfo: UserInfo | null },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.userInfo = payload
      }
    )
  }
})
