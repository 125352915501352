import { InitialAgenciesFields } from '../types/agencies.types'

export const FIELDS_DESCRIPTION = {
  id: { isLink: true, linkFieldName: 'id_link' },
  full_name: {},
  short_name: {},
  registry_email: {},
  communication_phone: {}
  // is_external: { label: 'компания', isExternal: true },
}

const booleanOptions = [
  { name: 'внешняя', value: true },
  { name: 'внутренняя', value: false }
]

export const INITIAL_AGENCIES_FIELDS: InitialAgenciesFields = {
  full_name: {
    label: 'full_name',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  short_name: {
    label: 'short_name',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  inn: { label: 'inn', initial: '', placeholder: '', fullWidth: true },
  kpp: { label: 'kpp', initial: '', placeholder: '', fullWidth: true },
  ogrn: { label: 'ogrn', initial: '', placeholder: '', fullWidth: true },

  communication_phone: {
    label: 'communication_phone',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  customer_phone: {
    label: 'customer_phone',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  communication_email: {
    label: 'communication_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  customer_support_email: {
    label: 'customer_support_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  registry_email: {
    label: 'registry_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  postal_address: {
    label: 'postal_address',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  legal_address: {
    label: 'legal_address',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  is_external: {
    label: 'is_external',
    initial: true,
    fullWidth: true,
    options: booleanOptions
  }
}

export const INITIAL_AGENCY_FIELDS = {
  full_name: {
    label: 'full_name',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  short_name: {
    label: 'short_name',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  inn: { label: 'inn', initial: '', placeholder: '', fullWidth: true },
  kpp: { label: 'kpp', initial: '', placeholder: '', fullWidth: true },
  ogrn: { label: 'ogrn', initial: '', placeholder: '', fullWidth: true },

  communication_phone: {
    label: 'communication_phone',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  customer_phone: {
    label: 'customer_phone',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  communication_email: {
    label: 'communication_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  customer_support_email: {
    label: 'customer_support_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  registry_email: {
    label: 'registry_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  postal_address: {
    label: 'postal_address',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  legal_address: {
    label: 'legal_address',
    initial: '',
    placeholder: '',
    fullWidth: true
  }

  // is_external: {
  //   label: 'is_external',
  //   initial: '',
  //   placeholder: '',
  //   fullWidth: true,
  //   isExternal: true,
  // },
}
