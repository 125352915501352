import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  Button,
  ButtonGroup,
  FormFeedback
} from 'reactstrap'
import { useSearchParams } from 'react-router-dom'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import * as Yup from 'yup'
import {
  useAddMainCompanyMutation,
  useEditMainCompanyMutation,
  useGetMainCompanyViewQuery
} from 'src/services/companies'
import { UseQueryHookResultData } from 'src/services/api'

type InputCompany = {
  [key: string]: {
    label: string
    number?: boolean
    boolean?: boolean
    options?: {
      name: string
      value: string | number
    }[]
    edit?: boolean
  }
}

const inputInitials: InputCompany = {
  full_name: { label: 'full_name' },
  short_name: { label: 'short_name' },
  ceo_name: { label: 'ceo_name' },
  other_name: { label: 'other name' },
  lei: { label: 'lei' },
  tax_code: {
    label: 'tax_code',
    options: [
      { name: 'Russian Federation', value: '1' },
      { name: 'foreign state', value: '2' }
    ]
  },
  right_of_claim_code: {
    label: 'right_of_claim_code',
    options: [
      { name: 'credit organisation', value: 1 },
      { name: 'non -credit financial organization', value: 2 },
      { name: 'collection organization', value: 3 },
      { name: 'another organization', value: 99 }
    ]
  },
  inn: { label: 'inn' },
  kpp: { label: 'kpp' },
  ogrn: { label: 'ogrn' },
  legal_address: { label: 'legal_address' },
  postal_address: { label: 'postal_address' },
  legal_email: { label: 'legal email' },
  communication_email: { label: 'communication_email' },
  // registry_email: { label: 'registry_email' },
  customer_support_email: { label: 'customer_support_email' },
  phone: { label: 'phone' },
  phone_prefix: { label: 'phone_prefix', number: true },
  // logo_url: { label: 'logo_url' },
  // is_owner: { label: 'is owner', boolean: true },
  // blocked: { label: 'blocked', boolean: true },
  bank_name: { label: 'bank_name' },
  bank_account: { label: 'bank_account' },
  bank_corr_account: { label: 'bank corr account' },
  bic: { label: 'bic' },
  website: { label: 'website' }
}

export const EditCompany = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const { data: company } = useGetMainCompanyViewQuery(
    searchParams.get('id')!,
    { skip: searchParams.get('id') == null }
  )

  const [
    addCompany,
    {
      isSuccess: isSuccessAddCompany,
      error: createErrorAddCompany,
      isLoading: isLoadingAddCompany
    }
  ] = useAddMainCompanyMutation()

  const [
    editCompany,
    {
      isSuccess: isSuccessEditCompany,
      error: createErrorEditCompany,
      isLoading: isLoadingEditCompany
    }
  ] = useEditMainCompanyMutation()

  const title = searchParams.get('id') ? t('Edit company') : t('Add company')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: '',
      short_name: '',
      ceo_name: '',
      inn: '',
      kpp: '',
      ogrn: '',
      legal_address: '',
      postal_address: '',
      legal_email: '',
      communication_email: '',
      registry_email: '',
      customer_support_email: '',
      phone: '',
      logo_url: '',
      phone_prefix: null,
      website: '',
      bank_name: '',
      bank_account: '',
      bank_corr_account: '',
      bic: '',
      is_owner: false,
      blocked: null,
      other_name: '',
      lei: '',
      tax_code: '',
      right_of_claim_code: '',
      ...company
    } as UseQueryHookResultData<typeof useGetMainCompanyViewQuery>,
    validationSchema: Yup.object().shape({
      full_name: Yup.string().nullable().required(t('Fill in the field')),
      short_name: Yup.string().nullable().required(t('Fill in the field')),
      ceo_name: Yup.string().nullable().required(t('Fill in the field')),
      inn: Yup.string()
        .nullable()
        .matches(/^\d{10}$/, t('Fill in the field')),
      kpp: Yup.string()
        .nullable()
        .matches(/^\d{9}$/, t('Fill in the field')),
      ogrn: Yup.string()
        .nullable()
        .matches(/^\d{13}$/, t('Fill in the field')),
      legal_address: Yup.string().nullable().required(t('Fill in the field')),
      postal_address: Yup.string().nullable().required(t('Fill in the field')),
      legal_email: Yup.string().nullable().required(t('Fill in the field')),
      customer_support_email: Yup.string()
        .nullable()
        .required(t('Fill in the field')),
      communication_email: Yup.string()
        .nullable()
        .required(t('Fill in the field')),
      phone: Yup.string().nullable().required(t('Fill in the field')),
      bank_name: Yup.string().nullable().required(t('Fill in the field')),
      bank_account: Yup.string()
        .nullable()
        .matches(/^\d{20}$/, t('Fill in the field'))
        .required(t('Fill in the field')),
      bank_corr_account: Yup.string()
        .nullable()
        .matches(/^\d{20}$/, t('Fill in the field'))
        .required(t('Fill in the field')),
      bic: Yup.string()
        .nullable()
        .matches(/^\d{9}$/, t('Fill in the field'))
        .required(t('Fill in the field')),
      right_of_claim_code: Yup.string()
        .nullable()
        .matches(/^\d{1}$/, t('Fill in the field')),
      tax_code: Yup.string().nullable().required(t('Fill in the field'))
    }),
    onSubmit: (values) => {
      values.right_of_claim_code = Number(values.right_of_claim_code)
      values.bank_account = String(values.bank_account)
      values.bank_corr_account = String(values.bank_corr_account)
      values.bic = String(values.bic)

      if (searchParams.get('id')) {
        editCompany(values)
      } else {
        addCompany(values)
      }
    }
  })

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar
            createLink={undefined}
            backLink={'/companies'}
            editLink={undefined}
            entity={undefined}
            buttons={undefined}
            disableBorder={undefined}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              {Object.keys(inputInitials).map((fieldKey: string) => {
                return (
                  <Col className='col-3' key={`${fieldKey}`}>
                    <FormGroup className='mb-3'>
                      <Label htmlFor={fieldKey}>
                        {t(inputInitials[fieldKey].label)}
                      </Label>
                      {inputInitials[fieldKey].number ? (
                        <Input
                          name={fieldKey}
                          type='number'
                          id={fieldKey}
                          className='form-control form-control-sm'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          /*@ts-ignore*/
                          value={formik.values[fieldKey] || ''}
                          disabled={isLoadingAddCompany || isLoadingEditCompany}
                        />
                      ) : inputInitials[fieldKey].options ? (
                        <select
                          name={fieldKey}
                          id={fieldKey}
                          className='form-select form-select-sm'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          /*@ts-ignore*/
                          value={formik.values[fieldKey] || ''}
                          disabled={isLoadingAddCompany || isLoadingEditCompany}
                        >
                          <option hidden disabled selected></option>
                          {inputInitials[fieldKey].options?.map(
                            (option: any) => (
                              <option key={option.name} value={option.value}>
                                {t(option.name)}
                              </option>
                            )
                          )}
                        </select>
                      ) : inputInitials[fieldKey].boolean ? (
                        <ButtonGroup className='w-100'>
                          <Button
                            color='primary'
                            outline
                            /*@ts-ignore*/
                            active={formik.values[fieldKey] === true}
                            size='sm'
                            onClick={() => formik.setFieldValue(fieldKey, true)}
                            disabled={
                              isLoadingAddCompany || isLoadingEditCompany
                            }
                          >
                            {t('yes')}
                          </Button>
                          <Button
                            color='primary'
                            outline
                            /*@ts-ignore*/
                            active={formik.values[fieldKey] === false}
                            size='sm'
                            onClick={() =>
                              formik.setFieldValue(fieldKey, false)
                            }
                            disabled={
                              isLoadingAddCompany || isLoadingEditCompany
                            }
                          >
                            {t('no')}
                          </Button>
                        </ButtonGroup>
                      ) : (
                        <Input
                          name={fieldKey}
                          type='text'
                          id={fieldKey}
                          className='form-control form-control-sm'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          /*@ts-ignore*/
                          value={formik.values[fieldKey] || ''}
                          disabled={isLoadingAddCompany || isLoadingEditCompany}
                        />
                      )}
                    </FormGroup>
                    {
                      /*@ts-ignore*/
                      formik.touched[fieldKey] && formik.errors[fieldKey] ? (
                        <FormFeedback
                          style={{ display: 'block', marginBottom: '10px' }}
                          type='invalid'
                        >
                          <Alert color='warning'>
                            {
                              /*@ts-ignore*/
                              <strong> {formik.errors[fieldKey]} </strong>
                            }
                          </Alert>
                        </FormFeedback>
                      ) : null
                    }
                  </Col>
                )
              })}
            </Row>

            {createErrorAddCompany && (
              <Alert color='warning'>
                <strong> {JSON.stringify(createErrorAddCompany)} </strong>
              </Alert>
            )}
            {createErrorEditCompany && (
              <Alert color='warning'>
                <strong> {JSON.stringify(createErrorEditCompany)} </strong>
              </Alert>
            )}

            {(isSuccessAddCompany || isSuccessEditCompany) && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}
            <Button
              type='submit'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              disabled={isLoadingAddCompany || isLoadingEditCompany}
            >
              {searchParams.get('id') ? t('Edit') : t('Create')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
