export const LIST = "GET_EXTERNAL_SERVICES_SETTINGS"
export const LIST_SUCCESS = "GET_EXTERNAL_SERVICES_SETTINGS_SUCCESS"
export const LIST_FAIL = "GET_EXTERNAL_SERVICES_SETTINGSS_FAIL"

export const DELETE = "DELETE_EXTERNAL_SERVICES_SETTINGS"
export const DELETE_SUCCESS = "DELETE_EXTERNAL_SERVICES_SETTINGS_SUCCESS"
export const DELETE_FAIL = "DELETE_EXTERNAL_SERVICES_SETTINGS_FAIL"

export const UPDATE = "UPDATE_EXTERNAL_SERVICES_SETTINGS"
export const UPDATE_SUCCESS = "UPDATE_EXTERNAL_SERVICES_SETTINGS_SUCCESS"
export const UPDATE_FAIL = "UPDATE_EXTERNAL_SERVICES_SETTINGS_FAIL"

export const CREATE = "CREATE_EXTERNAL_SERVICES_SETTINGS"
export const CREATE_SUCCESS = "CREATE_EXTERNAL_SERVICES_SETTINGS_SUCCESS"
export const CREATE_FAIL = "CREATE_EXTERNAL_SERVICES_SETTINGS_FAIL"
