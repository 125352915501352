export const LIST = "LIST_PRODUCT_EXTENSIONS"
export const LIST_SUCCESS = "LIST_PRODUCT_EXTENSIONS_SUCCESS"
export const LIST_FAIL = "LIST_PRODUCT_EXTENSIONS_FAIL"

export const DELETE = "DELETE_PRODUCT_EXTENSIONS"
export const DELETE_SUCCESS = "DELETE_PRODUCT_EXTENSIONS_SUCCESS"
export const DELETE_FAIL = "DELETE_PRODUCT_EXTENSIONS_FAIL"

export const UPDATE = "UPDATE_PRODUCT_EXTENSIONS"
export const UPDATE_SUCCESS = "UPDATE_PRODUCT_EXTENSIONS_SUCCESS"
export const UPDATE_FAIL = "UPDATE_PRODUCT_EXTENSIONS_FAIL"
