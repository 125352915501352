import { api, omitBlankEntries } from './api'

export type Promotion = {
  id: number
  promo_name: string
  main_body_writeoff: number
  interest_writeoff: number
  fines_writeoff: number
  interest_freezing: boolean
  fines_freezing: boolean
  offer_duration: number
}

const promotionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromotions: builder.query<Promotion[], void>({
      query: () => 'crm/v1/promotions',
      providesTags: (result = []) => [
        ...result.map(
          ({ id }) => ({ type: 'Promotions', id: `${id}` }) as const,
        ),
        { type: 'Promotions' as const, id: 'LIST' },
      ],
    }),
    getPromotion: builder.query<Promotion, string | number>({
      query: (id) => `crm/v1/promotions/${id}`,
      providesTags: (result, _err, id) => [{ type: 'Promotions', id: `${id}` }],
    }),
    updatePromotion: builder.mutation<void, Partial<Promotion>>({
      query: ({ id, ...body }) => ({
        url: `crm/v1/promotions/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: (_result, _err, { id }) => [
        { type: 'Promotions', id: `${id}` },
      ],
    }),
    addPromotion: builder.mutation<void, Partial<Promotion>>({
      query: (body) => ({
        url: `crm/v1/promotions`,
        method: 'POST',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: () => [{ type: 'Promotions', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetPromotionsQuery,
  useGetPromotionQuery,
  useUpdatePromotionMutation,
  useAddPromotionMutation,
} = promotionsApi
