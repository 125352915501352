import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  useGetChatGlobalSettingsQuery,
  useGetChatUserSettingsQuery,
  useUpdateChatUserSettingsMutation,
} from 'src/services/chats'
import { omit } from 'src/utils'
import * as Yup from 'yup'

export const ChatUserSettingsEdit = () => {
  const { id } = useParams()

  const { t } = useTranslation()

  const { data: globalSettings } = useGetChatGlobalSettingsQuery()

  const {
    data: userSettings,
    isFetching,
    error: getError,
  } = useGetChatUserSettingsQuery(id!, {
    skip: id == null,
  })

  const [
    updateUserSettings,
    { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccess },
  ] = useUpdateChatUserSettingsMutation()

  const title = t('Edit user settings')
  document.title = title

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: id,
      // global settings always return {..., user_id: null}
      // which override useParams id to null if userSettings not present
      ...omit(globalSettings, ['user_id']),
      ...userSettings,
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: updateUserSettings,
  })

  return (
    <div className="page-content">
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <MetaBar backLink={'/chats/settings'} entity={userSettings} />

          <Form onSubmit={formik.handleSubmit} className="mt-4">
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="idle_message">{t('idle_message')}</Label>
                  <Input
                    name="idle_message"
                    type="text"
                    id="idle_message"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.idle_message}
                    disabled={isUpdating || isFetching}
                    invalid={
                      formik.touched.idle_message &&
                      !!formik.errors.idle_message
                    }
                  />
                  <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {t(formik.errors.idle_message || '')}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="idle_interval">{t('idle_interval')}</Label>
                  <InputGroup>
                    <Input
                      name="idle_interval"
                      type="number"
                      id="idle_interval"
                      className="form-control form-control-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.idle_interval}
                      disabled={isUpdating || isFetching}
                      invalid={
                        formik.touched.idle_interval &&
                        !!formik.errors.idle_interval
                      }
                    />
                    <InputGroupText>{t('Minutes')}</InputGroupText>
                  </InputGroup>
                  <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {t(formik.errors.idle_interval || '')}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="close_interval">{t('close_interval')}</Label>
                  <InputGroup>
                    <Input
                      name="close_interval"
                      type="number"
                      id="close_interval"
                      className="form-control form-control-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.close_interval}
                      disabled={isUpdating || isFetching}
                      invalid={
                        formik.touched.close_interval &&
                        !!formik.errors.close_interval
                      }
                    />
                    <InputGroupText>{t('Minutes')}</InputGroupText>
                  </InputGroup>
                  <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {t(formik.errors.close_interval || '')}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="max_chats_limit">
                    {t('max_chats_limit')}
                  </Label>
                  <Input
                    name="max_chats_limit"
                    type="number"
                    id="max_chats_limit"
                    className="form-control form-control-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.max_chats_limit}
                    disabled={isUpdating || isFetching}
                    invalid={
                      formik.touched.max_chats_limit &&
                      !!formik.errors.max_chats_limit
                    }
                  />
                  <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {t(formik.errors.max_chats_limit || '')}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            {updateError && (
              <Alert color="warning">
                <strong> {JSON.stringify(updateError)} </strong>
              </Alert>
            )}

            {getError &&
              // when no settings found 409 status code is return
              // so it best no to show it as it is expected
              // @ts-expect-error Property 'status' does not exist on type 'FetchBaseQueryError
              getError.status !== 409 && (
                <Alert color="warning">
                  <strong> {JSON.stringify(getError)} </strong>
                </Alert>
              )}

            {isUpdateSuccess && (
              <Alert color="success">
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button
              className="text-nowrap"
              color="primary"
              style={{ backgroundColor: '#405189' }}
              type="submit"
              disabled={
                !formik.isValid || isUpdating || isFetching || !formik.dirty
              }
            >
              {t('Update')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
