import { useMemo, useState } from 'react'
import Client from '../../../client'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap'

const companyName = [
  'privetsosed',
  'moneyfortress',
  'blanksheet',
  'equitacapital',
]

export default function MessageSenders({ loan_id }) {
  const { t } = useTranslation()
  const [showSender, setShowSender] = useState(false)
  const [templates, setTemplates] = useState([])
  const [value, setValue] = useState(null)
  const [selectMessageType, setSelectMessageType] = useState('email')
  const [companyValue, setCompanyValue] = useState('privetsosed')
  const [isRightSendMessage, setIsRightSendMessage] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const selectedEmail = useMemo(
    () => selectMessageType === 'email',
    [selectMessageType],
  )

  const getTemplateSms = async () => {
    Client.getSmsTemplates(loan_id)
      .then((res) => {
        setTemplates(res)
        setShowSender(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTemplateEmail = async () => {
    Client.getTemplates('email')
      .then((res) => {
        setTemplates(
          res.length && res.filter((template) => template.is_service !== true),
        )
        setShowSender(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onMessageSendClick = () => {
    selectedEmail ? getTemplateEmail() : getTemplateSms()

    Client.getRightSendMessage(loan_id).then(({ fz, timezone }) => {
      fz && timezone && setIsRightSendMessage(true)
    })
  }

  const sendSms = () => {
    Client.sendMessageByLoanId(loan_id, {
      type: selectMessageType,
      template_id: Number(value),
    })
      .then(() => setSuccess(true))
      .catch((err) => {
        setError(err)
      })
  }

  const sendEmail = () => {
    Client.sendMessageByLoanId(loan_id, {
      type: selectMessageType,
      template_id: Number(value),
      company_name: companyValue,
    })
      .then(() => setSuccess(true))
      .catch((err) => {
        setError(err)
      })
  }

  const toggle = () => {
    setShowSender(false)
    setValue(null)
    setSelectMessageType('email')
    setError('')
    setSuccess(false)
  }

  return (
    <>
      <Button
        type="button"
        onClick={onMessageSendClick}
        size="sm"
        disabled={showSender}
      >
        {t('send_message')}
      </Button>
      <Modal isOpen={showSender} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('send_message')}</ModalHeader>
        <ModalBody>
          <select
            className="form-select form-select-sm"
            onChange={(e) => {
              setError('')
              setSuccess(false)
              setTemplates([])
              e.target.value === 'email' ? getTemplateEmail() : getTemplateSms()
              setSelectMessageType(e.target.value)
            }}
            style={{ textOverflow: 'ellipsis', marginBottom: '10px' }}
          >
            <option key="email" value="email">
              {t('send_email')}
            </option>
            <option key="sms" value="sms">
              {t('send_SMS')}
            </option>
          </select>

          <select
            className="form-select form-select-sm"
            onChange={(e) => setValue(e.target.value)}
            style={{ textOverflow: 'ellipsis' }}
          >
            <option key={'-'} hidden>
              {'-'}
            </option>
            {templates.length &&
              templates.map((item) => (
                <option
                  key={selectedEmail ? item.id : item.OrmSmsTypes.id}
                  value={selectedEmail ? item.id : item.OrmSmsTypes.id}
                >
                  {selectedEmail
                    ? item.text_template
                    : item.OrmSmsTypes.template}
                </option>
              ))}
          </select>

          {selectedEmail && (
            <select
              className="form-select form-select-sm"
              onChange={(e) => setCompanyValue(e.target.value)}
              style={{ textOverflow: 'ellipsis', marginTop: '10px' }}
            >
              {companyName.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          )}

          <div className="mt-2">
            {success && <Alert>{t('smscode_sent_at')}</Alert>}
            {error && <Alert color="danger">{error}</Alert>}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => (selectedEmail ? sendEmail() : sendSms())}
            disabled={value === null || !isRightSendMessage}
          >
            {selectedEmail ? t('send_email') : t('send_SMS')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
