import {
  useCreateWriteoffTypeMutation,
  useDeleteWriteoffTypeMutation,
  useGetWriteoffTypesQuery,
  useUpdateWriteoffTypeMutation
} from 'src/services/loans'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorPage, { DangerAlert } from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import List from 'src/Components/Common/EntitiesView/List'

export const WriteOffEditorStatus = () => {
  const { data: writeOffTypes, error, isError } = useGetWriteoffTypesQuery()

  const [
    createWriteoffTypes,
    { isLoading: isLoadingCreateWriteoffTypes, error: createWriteoffTypesError }
  ] = useCreateWriteoffTypeMutation()

  const [
    deleteWriteOffType,
    { isLoading: isLoadingDeleteWriteOffType, error: deleteWriteOffTypeError }
  ] = useDeleteWriteoffTypeMutation()

  const [
    updateWriteOffType,
    { isLoading: isLoadingUpdateWriteOffType, error: updateWriteOffTypeError }
  ] = useUpdateWriteoffTypeMutation()

  const [show, setShow] = useState(false)
  const [value, setValue] = useState('')
  const [statusDescription, setStatusDescription] = useState('')

  const toggleModal = () => {
    setShow((prev) => !prev)
  }

  const handleCreateStatus = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      await createWriteoffTypes({ status: value }).unwrap()
      setValue('')
    } catch (error) {
      //error
    }
  }

  const handleUpdateStatus = async (
    event: FormEvent<HTMLFormElement>,
    editValue: string
  ) => {
    event.preventDefault()
    try {
      await updateWriteOffType({
        new_writeoff_status: editValue,
        status: statusDescription
      }).unwrap()
      toggleModal()
    } catch (error) {
      //error
    }
  }

  const handleDeleteStatus = async (status_description: string) => {
    try {
      await deleteWriteOffType(status_description).unwrap()
    } catch (error) {
      //error
    }
  }

  const { t } = useTranslation()

  const title = t('statuses')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  if (!writeOffTypes) {
    return <LoadingPage title={title} />
  }

  const isLoading =
    isLoadingCreateWriteoffTypes ||
    isLoadingDeleteWriteOffType ||
    isLoadingUpdateWriteOffType

  if (isLoading) {
    return <LoadingPage />
  }

  const fieldsDescription = {
    status_description: {
      isButton: true,
      onClick: (status: { status_id: number; status_description: string }) => {
        toggleModal()
        setStatusDescription(status?.status_description)
      },
      label: title
    }
  }

  return (
    <div className='page-content'>
      <ChangeStatusModal
        onSubmit={handleUpdateStatus}
        statusDescription={statusDescription}
        isOpen={show}
        onToggle={toggleModal}
      />
      <BreadCrumb title={title} />
      <Col md={3}>
        <Form onSubmit={handleCreateStatus}>
          <FormGroup className='mb-3 mt-3'>
            <Label htmlFor={'client-status'}>{t('client_status')}</Label>
            <Input
              placeholder={t('enter_status')}
              className='form-control form-control-sm mb-3'
              id='client-status'
              onChange={(event) => setValue(event.currentTarget.value)}
              value={value}
            />
            <Button
              type='submit'
              color='primary'
              disabled={!value.trim()}
              style={{ backgroundColor: '#405189' }}
            >
              {t('add_status')}
            </Button>
          </FormGroup>
        </Form>
      </Col>
      <Card>
        <CardBody>
          <List
            list={writeOffTypes}
            style={{ width: 'fit-content' }}
            fieldsDescription={fieldsDescription}
            actions={writeOffTypes.map((types) => {
              return (
                <Button
                  key={types.status_id}
                  className='btn-icon'
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  size='sm'
                  onClick={() => handleDeleteStatus(types?.status_description)}
                >
                  <i className='ri-delete-bin-line'></i>
                </Button>
              )
            })}
            hover
          />
        </CardBody>
      </Card>
      {createWriteoffTypesError && (
        <DangerAlert error={createWriteoffTypesError} />
      )}
      {deleteWriteOffTypeError && (
        <DangerAlert error={deleteWriteOffTypeError} />
      )}
      {updateWriteOffTypeError && (
        <DangerAlert error={updateWriteOffTypeError} />
      )}
    </div>
  )
}

const ChangeStatusModal = ({
  onToggle,
  isOpen,
  statusDescription,
  onSubmit
}: {
  onToggle: () => void
  isOpen?: boolean
  statusDescription: string
  onSubmit: (e: FormEvent<HTMLFormElement>, editValue: string) => void
}) => {
  const { t } = useTranslation()

  const [editValue, setEditValue] = useState(statusDescription || '')

  useEffect(() => {
    if (isOpen) {
      setEditValue(statusDescription || '')
    }
  }, [isOpen, statusDescription])

  return (
    <Modal isOpen={isOpen} toggle={onToggle} centered>
      <Form onSubmit={(e) => onSubmit(e, editValue)}>
        <ModalHeader toggle={onToggle}>{t('editing_status')}</ModalHeader>
        <ModalBody>
          <Col>
            <FormGroup className='mb-3'>
              <Input
                value={editValue}
                type='text'
                className='form-control form-control-sm'
                onChange={(event) => setEditValue(event.currentTarget.value)}
              />
            </FormGroup>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            block
            type={'submit'}
            disabled={statusDescription === editValue.trim()}
          >
            {t('Edit')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
